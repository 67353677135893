import React from 'react';
import Img from '../Img.Component';
import numeral from "numeral";
import {showModal} from "../../../actions/baseActions";
import {connect} from "react-redux";
import ModalTypes from "../../../enums/Modal.Types.Enum";

export const RectangleSecondProduct = ({
										   showModal,
										   productName = undefined,
										   imgPath = undefined,
										   price = undefined,
										   description = undefined,
										   productSlug = undefined,
											 isCatering
									   }) => (
	<div className={'RectangleItem'} title={productName} onClick={() => {
		if(!productSlug)
			return false;
		showModal(ModalTypes.ITEM_DETAILS_MODAL, true, {productSlug, isCatering: isCatering})
	}}>
		<Img src={imgPath} alt="burger"/>
		<p>{productName}</p>
		{description && <span title={description} style={{textTransform: 'capitalize'}}>{description.length > 30 ? description.slice(0,30)+'...': description}</span>}
		<span className={'PanelMin'}>{numeral(price).format('$ 0,0[.]00')}</span>
	</div>
);

const mapDispatchToProps = {
	showModal
};

export default connect(null, mapDispatchToProps)(RectangleSecondProduct);
