import React from 'react';
import {showModal} from "../../../actions/baseActions";
import Img from '../../HelpersComponents/Img.Component';
import {connect} from "react-redux";
import ModalTypes from "../../../enums/Modal.Types.Enum";

export const SquareProduct = (
	{
		showModal,
		imgPath = undefined,
		name = undefined,
		categories = undefined,
		cal = undefined,
		slug = undefined,
		restaurantName = undefined,
		isCatering
	}
) => (
	<div className={'SquareItem'}
			 data-catering={isCatering}
			 data-slug={slug}
		 onClick={() => showModal(ModalTypes.ITEM_DETAILS_MODAL, true, {productSlug: slug, isCatering: isCatering})}
	>
		<Img src={imgPath} alt="burger"/>
		{name && <p>{name}</p>}
		{categories && <span className={'CategoryName'} title={categories}>{categories}</span>}
		{restaurantName !== undefined && <span className={'PanelCalories'}>{restaurantName}</span>}
	</div>
);


const mapStateToProps = state => ({
	isCatering:state.isCatering
});

const mapDispatchToProps = {
	showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(SquareProduct);
