import {messageType, showMessage} from "../services/Utilities";
import Config from 'Config';
import getStore from "../store/configureStore";
import newOrderSound from "../sounds/sound-1.wav";
import WssAction from '../enums/WSS.Action.Enum';
import {getUserLocation} from '../services/User.Service';
import {updateDriverLocation} from "../services/Driver.Service";

const store = getStore();
const newOrderNotify = new Audio(newOrderSound);

let DriverWssConnection = undefined;
let DriverLocationInterval = undefined;

const updateLocation = () => {
	getUserLocation(false).then(address =>
		updateDriverLocation({
			lat: address.lat,
			log: address.log,
			address: address.address,
			city: address.city,
			state: address.state
		})
	)
};

export const initDriverConnection = (driverSlug) => {
	const {user} = store.getState();
	if (driverSlug && user.data.active) {
		DriverWssConnection = new WebSocket(`${Config.WssUrl}driver?username=${driverSlug}`);
		
		DriverWssConnection.onopen = () => {
			if (DriverWssConnection) {
				updateLocation();
				// update location every 5 minute
				DriverLocationInterval = setInterval(updateLocation, 300000);
				console.log('Driver connection was created successfully.');
			}
		};
		
		DriverWssConnection.onclose = event => {
			if (event.wasClean) {
				console.log('Driver  session disconnect successfully');
			} else {
				console.log('Code: ' + event.code + ' reason: ' + event.reason);
			}
			
			clearInterval(DriverLocationInterval);
		};
		
		DriverWssConnection.onerror = error => {
			console.log("error", error.message);
		};
		
		DriverWssConnection.onmessage = event => {
			try {
				const data = JSON.parse(event.data);
				if ('action' in data) {
					if (data.action === WssAction.ACTION_SUBMITTED) {
						if (store.getState().audioState) {
							newOrderNotify.play();
						}
						showMessage(messageType.success, 'New Order')
					}
					
					document.dispatchEvent(new CustomEvent('DriverConnection', {
						detail: data
					}));
				}
			} catch (e) {
				console.log(e);
			}
		};
	}
	return DriverWssConnection;
};

export const getDriverWssConnection = (driverSlug = null) => {
	if (DriverWssConnection && DriverWssConnection.readyState) {
		return DriverWssConnection;
	} else if (driverSlug) {
		DriverWssConnection = initDriverConnection(driverSlug);
		return DriverWssConnection;
	}
	return false;
};

export const closeDriverConnection = () => {
	if (DriverWssConnection) {
		DriverWssConnection.close();
		return true;
	}
	return false;
};

/**
 * @deprecated
 * @param message
 * @returns {boolean}
 */
export const sendDriverWssMessage = message => {
	if (DriverWssConnection) {
		DriverWssConnection.send(JSON.stringify(message))
	}
	return false;
};
