import React from "react";
import {getDriverBalanceHistory} from "../../../services/Driver.Service";
import {Table, Tag} from "antd4";
import numeral from "numeral";
import {showLoading} from "../../../actions/baseActions";
import {connect} from "react-redux";

const BalanceHistoryTypes = {
		ADD: 1,
		PAY: 2
};

const Columns = [
		{
				title: 'Order ID',
				dataIndex: 'orderId',
				key: 'orderId',
				render: id => id ? `# ${id}` : '',
		},
		{
				title: 'Type',
				dataIndex: 'type',
				key: 'type',
				render: type => (
						<span>{type === BalanceHistoryTypes.ADD ? <Tag color={'blue'}>Addition</Tag> : (
								<Tag color={'red'}>Payment</Tag>)}</span>
				),
		},
		{
				title: 'Amount',
				key: 'amount',
				dataIndex: 'amount',
				sortDirections: ['descend', 'ascend'],
				render: (price, item) => (
						<span><Tag
								color={item.type === BalanceHistoryTypes.ADD ? 'blue' : 'red'}>{numeral(Math.abs(price)).format('$ 0,0[.]00')}</Tag></span>
				),
		}
];

const DriverBalanceHistory = ({showLoading, balance}) => {
		const [balanceHistory, setBalanceHistory] = React.useState([]);
		
		React.useEffect(() => {
				showLoading(true);
				getDriverBalanceHistory().then(data => {
						setBalanceHistory(data);
				}).finally(() => showLoading(false));
		}, []);
		
		return (
				<div className={"BalanceHistory"}>
						<Table
								showHeader={true}
								footer={() => <Tag color={'green'}>{numeral(balance).format('$ 0,0[.]00')}</Tag>}
								columns={Columns}
								dataSource={balanceHistory}
								rowKey={'id'}
								size={'middle'}
								pagination={{defaultPageSize: 25}}
						/>
				</div>
		);
};
const mapStateToProps = state => ({
		balance: state.user.data.balance
});

const mapDispatchToProps = {
		showLoading
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(DriverBalanceHistory);
