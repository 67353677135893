import React, {Component} from "react";
import {Link} from "react-router-dom";
import {Layout, Menu, Tag} from "antd4";

import Paths from "../../services/Paths";

import PartnerDetails from "./Partner.Details.Component";
import PartnerProductsList from "./ProductsComponents/Partner.Products.Component";
import PartnerEmployees from "./EmployeesComponents/Partner.Employees.Component";
import ProductCreateOrEdit from "./ProductsComponents/CreateOrEdit/ProductCreateOrEdit";
import PartnerCategories from "./Partner.Categories.Component";
import PartnerTabs from "../../enums/Partner.Tabs.Enum";
import PartnerOrders from "./Partner.Orders.Component";
import PartnerCaterings from "./CateringsComponents/Index";
import CateringCreateOrEdit from "./CateringsComponents/CreateOrEdit/Partner.Caterings.CreateOrEdit";

import "../../styles/PartnerStyles/partner.scss";
import {showMessage} from "../../services/Utilities";
import {connect} from "react-redux";
import {showLoading} from "../../actions/baseActions";
import ToggleAudio from "../HelpersComponents/Toggle.Audio.Component";
import PartnerBalanceHistory from "./Partner.Balance.History.Component";
import numeral from "numeral";
import {filterTransactions, getPartnerDetails} from "../../services/Partner.Service";
import {setPartnerRestaurant, updatePartnerRestaurant} from "../../actions/partnerActions";
import {logoutUser} from "../../actions/userActions";
import PartnerContactUsers from "./Partner.Contact.Users.Compoent";
import PartnerReview from "./ReviewsComponent/Partner.Reviews.Compoent";
import PartnerPromotion from "./Partner.Promotion.Compoent";
import PartnerStopTacking from "./Partner.Stop.Tacking.Compoent";
import PartnerNewOrderNotificationComponent from "./Partner.New.OrderNotification.component";
import moment from "moment";

const {Content, Sider} = Layout;

const ActionTypes = {
		"edit": "edit",
		"create": "create"
};

class Partner extends Component {
		routeListener = undefined;
		
		state = {
				collapsed: false,
				menuItemKey: PartnerTabs.orders,
				productSlug: undefined,
				action: undefined
		};
		
		UNSAFE_componentWillMount() {
				this.getBalance();
				this.getBalanceLast();
				const {params} = this.props.match;
				
				if ("tabName" in params && params.tabName in PartnerTabs) {
						this.setState({
								menuItemKey: params.tabName,
								productSlug: params.actionSlug,
								action: params.action in ActionTypes ? params.action : undefined
						});
				} else {
						this.props.history.replace(Paths.basePartner.replace(":tabName", PartnerTabs.orders));
				}
				
				getPartnerDetails().then(result => {
						if (result) {
								if ("countDownClose" in result) {
										if (moment(result.countDownClose) > new Date()) {
												this.changeOpenStatus(result.countDownClose);
										}
								}
								
								if ("workingHours" in result) {
										for (let i = 0; i < result.workingHours.length; i++) {
												if ("times" in result.workingHours[i]) {
												} else {
														result.workingHours[i].times = [{
																openTime: null, closeTime: null
														}];
												}
										}
										this.setState({workingHours: result.workingHours});
								}
								
								this.props.setPartnerRestaurant(result);
						}
				});
				
				this.routeListener = this.props.history.listen((location) => {
						let params = location.pathname.match(/^\/partner\/(details|orders|products|locations|stopTakingOrders|reviews|users|categories|ingredients|caterings|promotions|employees|balance)\/?(edit|create)?\/?(.*)?$/);
						if (params) {
								this.setState({
										menuItemKey: params[1] in PartnerTabs ? params[1] : PartnerTabs.orders,
										action: params[2] in ActionTypes ? params[2] : undefined,
										productSlug: params[3]
								});
						}
				});
		}
		
		changeOpenStatus = (changeOpenStatus) => {
				let interval = setInterval(() => {
						if (moment(changeOpenStatus) <= new Date()) {
								const elements = Array.from(document.getElementsByClassName("close_branch"));
								if (elements.length > 0) {
										elements[0].classList.remove("close_branch");
										elements[0].classList.add("open_branch");
										elements[0].innerHTML = "OPEN";
								}
								showMessage("success", "We are online now");
								clearInterval(interval);
						}
				}, 1500);
		};
		
		getBalance() {
				let now = (new Date());
				now.setMonth(now.getMonth() - 1);
				
				filterTransactions(
						moment(now).format("YYYY-MM-DD"),
						moment(new Date()).format("YYYY-MM-DD")
				).then(result => {
						if (result) {
								this.setState({
										thisMonth: result["allTransactionsByDate"]
								});
						}
				});
		}
		
		getBalanceLast() {
				let now = new Date();
				now.setMonth(now.getMonth() - 2);
				
				filterTransactions(
						moment(now).format("YYYY-MM-DD"),
						moment(new Date().setMonth(new Date().getMonth() - 1)).format("YYYY-MM-DD")
				).then(result => {
						if (result) {
								this.setState({
										lastMont: result["allTransactionsByDate"]
								});
						}
				});
		}
		
		componentWillUnmount() {
				this.routeListener();
		}
		
		componentDidMount() {
				window.addEventListener("click", this.handleOutsideClick);
		}
		
		handleOutsideClick = e => {
				if (this.node && !this.node.contains(e.target)) {
						this.setState({sidebarOpen: false});
				}
		};
		
		
		onCollapse = collapsed => {
				this.setState({collapsed});
		};
		
		onMenuItemClick = ({key}) => {
				if (key in PartnerTabs) {
						
						this.setState({sidebarOpen: false, menuItemKey: key});
						this.props.history.replace(Paths.basePartner.replace(":tabName", PartnerTabs[key]));
				}
		};
		
		changeDate = value => {
				if (value)
						this.setState({workingHours: value});
		};
		
		getContent = () => {
				const {menuItemKey, productSlug, action} = this.state;
				switch (menuItemKey) {
						case PartnerTabs.details:
								return <PartnerDetails
										changeDate={this.changeDate}
								/>;
						case PartnerTabs.balance:
								return <PartnerBalanceHistory/>;
						case PartnerTabs.products:
								if (action) {
										return <ProductCreateOrEdit productSlug={productSlug}/>;
								}
								return <PartnerProductsList/>;
						case PartnerTabs.reviews:
								return <PartnerReview/>;
						case PartnerTabs.promotions:
								return <PartnerPromotion/>;
						case PartnerTabs.users:
								return <PartnerContactUsers/>;
						case PartnerTabs.categories:
								return <PartnerCategories/>;
						case PartnerTabs.orders:
								return <PartnerOrders/>;
						case PartnerTabs.stopTakingOrders:
								return <PartnerStopTacking/>;
						case PartnerTabs.caterings:
								if (action) {
										return <CateringCreateOrEdit actionSlug={productSlug}/>;
								}
								return <PartnerCaterings/>;
						case PartnerTabs.employees:
								return <PartnerEmployees/>;
						default:
								return <p>Page Not Found</p>;
				}
				
		};
		
		
		render() {
				const {restaurant} = this.props;
				const {menuItemKey, collapsed, lastMont, thisMonth, sidebarOpen} = this.state;
				
				const {logoutUser} = this.props;
				
				let sumLastMonth = 0, sum = 0;
				
				if (lastMont) {
						sumLastMonth = lastMont.reduce((accumulator, object) => {
								return accumulator + (object.cancel ? 0 : object.totalPrice + object.tipAmount + object.tax);
						}, 0);
				}
				if (thisMonth) {
						sum = thisMonth.reduce((accumulator, object) => {
								return accumulator + (object.cancel ? 0 : object.totalPrice + object.tipAmount + object.tax);
						}, 0);
				}
				
				return (
						<div className="PartnerPage">
								<nav
										className={`Nav`} style={{width: "auto"}}>
										<div className={sidebarOpen ? "MobileMenu active" : "MobileMenu"}
												 onClick={this.sidebarOpen}>
												<span/>
										</div>
										<div className={sidebarOpen ? `sidebar-custom active` : `sidebar-custom`} ref={node => {
												this.node = node;
										}}>
												<Sider collapsible collapsed={collapsed} onCollapse={this.onCollapse}>
														<Menu mode="inline"
																	defaultSelectedKeys={[menuItemKey]}
																	selectedKeys={[menuItemKey]}
																	onClick={this.onMenuItemClick}>
																<li className={"toggleClassLi"}><ToggleAudio/></li>
																<Menu.Item key={PartnerTabs.details}>
																		<span>Restaurant</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.products}>
																		<span>Products</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.categories}>
																		<span>Categories</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.orders}>
																		<span>Orders</span>
																</Menu.Item>
																<Menu.Item className={"start_stop_tacking"} key={PartnerTabs.stopTakingOrders}>
																		<span>Stop taking orders</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.caterings}>
																		<span>Caterings</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.balance}>
																		<span>Financial</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.promotions}>
																		<span>Promotions</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.users}>
																		<span>Users</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.reviews}>
																		<span>Reviews</span>
																</Menu.Item>
																<Menu.Item key={PartnerTabs.driver}>
																		<span>Rate a driver</span>
																</Menu.Item>
																<li>
																		{collapsed ? (
																				<Tag color={"blue"} title={numeral(sum).format("$ 0,0[.]00")}
																						 style={{marginLeft: "-5px"}}>$</Tag>) : (
																				<>
																						<b>Balance: </b>
																						<Tag color={"blue"}>{numeral(restaurant && restaurant.balance ? restaurant.balance : 0).format("$ 0,0[.]00")}</Tag>
																				</>
																		)}
																</li>
																<li>
																		{collapsed ? (
																				<Tag color={"blue"} title={numeral(sumLastMonth).format("$ 0,0[.]00")}
																						 style={{marginLeft: "-5px"}}>$</Tag>) : (
																				<>
																						<b>Last Month: </b>
																						<Tag color={"blue"}>{numeral(sumLastMonth).format("$ 0,0[.]00")}</Tag>
																				</>
																		)}
																</li>
														</Menu>
														<div className={"burger-menu-footer"}>
																<Link to="#" onClick={logoutUser} className={"logout"}>
																		Log out
																</Link>
														</div>
												</Sider>
										</div>
								</nav>
								<Layout style={{minHeight: "50vh"}}>
										<Layout>
												<Content style={{margin: "0 16px"}}>
														<div style={{marginTop: "16px"}}>
																{this.getContent()}
																<PartnerNewOrderNotificationComponent/>
														</div>
												</Content>
										</Layout>
								</Layout>
						</div>
				);
		}
		
		sidebarOpen = evt => {
				evt.stopPropagation();
				const {sidebarOpen} = this.state;
				this.setState({
						sidebarOpen: !sidebarOpen
				});
		};
}

const mapStateToProps = state => ({
		user: state.user.data,
		audioState: state.audioState,
		restaurant: state.partnerRestaurant,
});

const mapDispatchToProps = {
		showLoading,
		logoutUser,
		setPartnerRestaurant,
		updatePartnerRestaurant
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(Partner);

