import React from "react";
import {Input, Modal} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../../actions/baseActions";
import numeral from "numeral";
import "../../../styles/PartnerStyles/partner.scss";
import SizeGroups from "../../ModalComponent/Item.Details.Modal.Component/Partials/SizeGroups";
import Ingredients from "../../ModalComponent/Item.Details.Modal.Component/Partials/Ingredients";
import "../../../styles/LayoutStyle/layout.scss";
import {getSingleProductDetails} from "../../../api/Home.API";
import {ReactComponent as Minus} from "../../../img/ic_arrow_next.svg";

class PartnerIngridientAddItemModal extends React.Component {
		state = {
				products: [],
				addItems: []
		};
		
		handleSizeChange = event => {
				const {indexedSizeGroups} = this.state.dataProd;
				
				const productSize = indexedSizeGroups[event.target.group]["indexedSize"][event.target.value];
				
				if (productSize) {
						this.state.dataProd.item = Object.assign({}, this.state.dataProd.item, {
								size: {
										slug: event.target.value,
										group: event.target.group
								}
						});
						this.setState({
								dataProd: this.state.dataProd
						}, this.calculatePrice);
				}
		};
		
		componentWillReceiveProps(nextProps) {
				// When modal is shown
				getSingleProductDetails(nextProps.slug).then(result => {
						if (result.success) {
								result.data.item = {
										counts: 1,
										ingredients: {},
										itemComment: "",
										price: result.data.price,
										singlePrice: result.data.price,
										size: this.findDefaultSize(result.data.sizeGroups)
								};
								this.setState({dataProd: result.data});
								this.calculatePrice()
								
						}
				});
		}
		
		findDefaultSize = groups => {
				for (let i = 0; i < groups.length; i++) {
						if (groups[i] && "size" in groups[i] && groups[i].size[0]) {
								return {
										slug: groups[i].size[0].slug,
										group: groups[i].slug
								};
						}
				}
				return false;
		};
		handleIngredientsChange = (groupSlug, values) => {
				const {item, ingredients} = this.state.dataProd;
				
				const group = ingredients.find(item => item.slug === groupSlug);
				if ("selectableCounts" in group && values.length > group.selectableCounts) {
						values = values.splice(1, group.selectableCounts);
				}
				item.ingredients[groupSlug] = values;
				this.state.dataProd.item = item;
				this.setState({dataProd: this.state.dataProd}, this.calculatePrice);
		};
		
		handleCommentChange = event => {
				this.state.dataProd.item.itemComment = event.target.value;
				this.setState({dataProd: this.state.dataProd});
		};
		
		calculatePrice = () => {
				const {price, indexedIngredients, indexedSizeGroups} = this.state.dataProd;
				const {counts, size, ingredients} = this.state.dataProd.item;
				
				const productSize = size && size.group && "group" in size ? indexedSizeGroups[size.group]["indexedSize"][size.slug] : false;
				const sizePlusPrice = productSize && productSize.plusPrice ? productSize.plusPrice : 0;
				
				let ingredientPlusPrice = 0;
				
				for (let groupKey in ingredients) {
						if (ingredients.hasOwnProperty(groupKey)) {
								for (let ingredientKey of ingredients[groupKey]) {
										let ingredient = indexedIngredients[groupKey]["indexedIngredient"][ingredientKey];
										ingredientPlusPrice += ingredient && ingredient.plusPrice ? ingredient.plusPrice : 0;
								}
						}
				}
				this.state.dataProd.item = Object.assign({}, this.state.dataProd.item, {
						price: (price + sizePlusPrice + ingredientPlusPrice) * counts,
						singlePrice: price + sizePlusPrice + ingredientPlusPrice
				});
				
				this.setState({
						dataProd: this.state.dataProd
				});
		};
		addToBag = e => {
				const {dataProd} = this.state;
				const product = {
						counts: dataProd.item.counts,
						deliveryTime: dataProd.deliveryTime,
						id: dataProd.id,
						ingredients: dataProd.item.ingredients,
						itemComment: dataProd.item.itemComment,
						price: dataProd.item.price,
						product: {slug: dataProd.slug},
						singlePrice: dataProd.price,
						size: dataProd.item.size,
						details: dataProd
				};
				this.props.onUpdateItem(product);
		};
		handleQuantityChange = counts => {
				if (counts === 0) return;
				
				const {dataProd} = this.state;
				dataProd.item.counts = counts;
				this.setState({dataProd}, this.calculatePrice);
		};
		
		render() {
				const {visible} = this.props;
				const {dataProd} = this.state;
				
				return (
						<Modal
								title="Add Items"
								className={"CustomItemDetails ingrid"}
								visible={visible}
								footer={false}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: false});
										this.setState({addItems: []});
								}}>
								{dataProd ?
										<div className={"ItemDetailsRight"}>
												<div className={"Count"}>
														<p className={"Quantity"}>Quantity</p>
														<button
																className={"RemoveCountLeft"}
																onClick={() => this.handleQuantityChange(dataProd.item.counts - 1)}
														>
																<Minus/>
														</button>
														<span>{dataProd.item.counts}</span>
														<button
																className={"RemoveCountRight"}
																onClick={() => this.handleQuantityChange(dataProd.item.counts + 1)}
														>
																<Minus/>
														</button>
												</div>
												<div className={"Options"}>
														{dataProd.sizeGroups && dataProd.sizeGroups.length ?
																<SizeGroups
																		sizeGroups={dataProd.sizeGroups}
																		itemSizeSlug={dataProd.item.size.slug}
																		handleSizeChange={this.handleSizeChange}
																/>
																: null
														}
														
														{dataProd.ingredients && dataProd.ingredients.length ?
																<Ingredients
																		item={dataProd.item}
																		ingredients={dataProd.ingredients}
																		handleIngredientsChange={this.handleIngredientsChange}
																/>
																: null
														}
														
														<div className="AddComment">
																<h4>Add a Comment</h4>
																<Input.TextArea
																		name="item_comment"
																		id="ItemComment"
																		rows={3}
																		value={dataProd.item.itemComment}
																		style={{resize: "none"}}
																		onChange={this.handleCommentChange}
																/>
														</div>
												</div>
												<div className="AddBugPartner">
														<button
																onClick={this.addToBag}
														>
																Add - {numeral(dataProd.item.price).format("$ 0,0[.]00")}
														</button>
												</div>
										</div> : ""}
						</Modal>
				);
		}
}


PartnerIngridientAddItemModal.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired,
		onUpdateItem: PropTypes.func.isRequired
};

PartnerIngridientAddItemModal.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		onUpdateItem: f => f,
		dataProd: null,
		slug: null
};

export default connect(
		() => (
				{}
		),
		{
				showLoading,
				showModal
		}
)(PartnerIngridientAddItemModal);
