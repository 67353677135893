import React, {Component} from "react";
import Clock from "./Clock";
import moment from "moment";

class Count extends Component {
		constructor(props) {
				super(props);
				const {countdown} = this.props;
				
				this.state = {
						deadline: countdown ? moment(props.countdown) : null
				}
		}
		
		UNSAFE_componentWillReceiveProps(nextProps) {
				if ('countdown' in nextProps) {
						const {countdown} = nextProps;
						this.setState({deadline: countdown ? moment(nextProps.countdown) : null});
				}
		}
		
		render() {
				const {deadline} = this.state;
				return (
						<div className="App">
								<Clock deadline={deadline}/>
						</div>
				);
		}
}

export default Count;
