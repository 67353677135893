const Paths = {
	home: '/',
	about: '/about',
	partnerUs: '/partner-with-us',
	delivery: '/deliver-with-meameal',
	preview: '/preview',
	order: '/order',
	search: '/search',
	account: '/account',
	restaurant: '/restaurant/:restaurantSlug',
	resetPassword: "/user/password/reset/:token",
	
	/**
	 * Partner
	 */
	basePartner: '/partner/:tabName',
	partner: '/partner/:tabName(orders|details|products|locations|stopTakingOrders|reviews|users|categories|ingredients|caterings|promotions|employees|balance)/:action(edit|create)?/:actionSlug?',
	partnerOrders: '/partner/orders',
	/**
	 * Driver
	 */
	driver: '/driver',
	signIn: '/login',
	
	/**
	 * Employee
	 */

	partnerManager: '/employee'
};
export default Paths;
