import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Paths from "../../../services/Paths";
import {showModal} from "../../../actions/baseActions";
import ModalTypes from "../../../enums/Modal.Types.Enum";
import AccountTabs from "../../../enums/Account.Tabs.Enum";
import {logoutUser} from "../../../actions/userActions";
import {hasRoleClient, isMobileDevice} from "../../../services/Utilities";

class NavSignIn extends Component {
		constructor(props) {
				super(props);
				
				this.state = {
						activeDropdown: false
				};
				const {user} = props;
				this.isMobile = isMobileDevice();
			
		}
		
		setActiveDropdown = state => {
				this.setState({
						activeDropdown: state
				})
		};
		
		render() {
				const {activeDropdown} = this.state;
				const {user, showModal, logoutUser, currentLocation} = this.props;
				const isClient = user && user.data && user.data.roles && hasRoleClient(user.data.roles[0])
				
				if ((user.isAuth && isClient && currentLocation.address) || (user.isAuth && !isClient)) {
						return isClient ?
								<>
										<li
												className={"UserAccountLink"}
												onMouseLeave={() => {
														!this.isMobile && this.setActiveDropdown(false);
												}}
										>
												<Link
														to={isClient ? Paths.account : '#'}
														onMouseEnter={() => {
																!this.isMobile && this.setActiveDropdown(true);
														}}
												>
														Hi, {user.data.clientFullName}
												</Link>
												{activeDropdown && <div className={"userDropdown"}>
														<ul>
																{isClient &&
																(<>
																		<li className='userDropdownLi'>
																				<Link to={{
																						pathname: Paths.account,
																						state: {
																								activeTabKey: AccountTabs.orders
																						}
																				}}
																							onClick={() => this.setActiveDropdown(false)}
																				>
																						Orders
																				</Link>
																		</li>
																		<li className='userDropdownLi'>
																				<Link to={{
																						pathname: Paths.account,
																						state: {
																								activeTabKey: AccountTabs.settings
																						}
																				}}
																							onClick={() => this.setActiveDropdown(false)}
																				>
																						Settings
																				</Link>
																		</li>
																</>)}
																<li className='userDropdownLi'>
																		<Link to='#' onClick={logoutUser}>
																				Log out
																		</Link>
																</li>
														</ul>
												</div>}
										</li>
								</> : ''
				} else if (user.isAuth) {
						
						return (
								<>
										<li className='userDropdownLi' style={{cursor: 'default'}}>Hi, {user.data.clientFullName}</li>
										<li onClick={logoutUser}>Log out</li>
								</>
						);
				} else {
						return (
								<>
										<li onClick={() => showModal(ModalTypes.SIGN_IN_MODAL)}>Login</li>
										<li className={"MenuSignUp"} onClick={() => showModal(ModalTypes.REGISTRATION_MODAL)}>Sign up</li>
								</>
						);
				}
		}
}

NavSignIn.propTypes = {
		showModal: PropTypes.func.isRequired,
		user: PropTypes.object.isRequired,
		loading: PropTypes.bool,
		currentLocation: PropTypes.object
};

const mapStateToProps = state => ({
		user: state.user,
		loading: state.loading,
		currentLocation: state.currentLocation.address,
});

const mapDispatchToProps = {
		showModal,
		logoutUser
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(NavSignIn);
