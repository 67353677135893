import React from "react";
import { Radio } from "antd4";
import numeral from "numeral";

const SizeGroups = ({ sizeGroups, itemSizeSlug, handleSizeChange }) => (
  <React.Fragment>
    <div className="ItemDetailsSize">
      <Radio.Group
        value={itemSizeSlug}
        onChange={handleSizeChange}
        defaultValue={itemSizeSlug}
      >
        {sizeGroups.map(group => (
          <React.Fragment key={group.slug}>
            <div className="GroupTitle">{group.name}</div>
            <ul>
              {group.size.map((size, i) => (
                <li key={size.slug}>
                  <p
                    className={"SizeItem"}
                    style={{ textTransform: "capitalize" }}
                  >
                    <Radio
                      group={group.slug}
                      value={size.slug}
                    >
                      {size.name}
                      {size.plusPrice ? (
                          <span className={"PlusPrice"}>
                            {numeral(size.plusPrice).format("+ $0,0[.]00")}
                          </span>)
                        : ""}
                    </Radio>
                  </p>
                </li>
              ))}
            </ul>
          </React.Fragment>
        ))}
      </Radio.Group>
    </div>
  </React.Fragment>
);

export default SizeGroups;