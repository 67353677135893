import React from 'react'
import Panel from "../HelpersComponents/Panel.Component";
import RectangleSecondProduct from "../HelpersComponents/ProductsComponents/RectangleSecond.Product.Component";

export const RestaurantMenu = ({categories = [], isCatering = false, restaurantDownloadLink}) => {
	return (
		<div id={'RestaurantMenu'}>
			{categories.map((category, index) => (
				<Panel
					key={index}
					className={"PopularPanel"}
					title={category.name}
					contentScrollable={false}
				>
					{isCatering ? (
						category.caterings && category.caterings.map(item => (
							<RectangleSecondProduct
								key={item.slug}
								imgPath={item.downloadLink || restaurantDownloadLink}
								productName={item.name}
								productSlug={item.slug}
								price={item.price}
								description={item.description}
								isCatering={isCatering}
							/>
						))
					) : (
						category.products.map(product => (
							<RectangleSecondProduct
								key={product.slug}
								imgPath={product.downloadLink || restaurantDownloadLink}
								productName={product.name}
								productSlug={product.slug}
								price={product.price}
								description={product.description}
							/>
						))
					)}
				</Panel>
			))}
		</div>
	)
};

export default RestaurantMenu;
