import * as RestaurantAPI from '../api/Restaurant.API';
import * as ReviewAPI from '../api/Review.API';
import {handleResponse} from "./Utilities";
import {StringifyUrlEncoded} from "../api/Http.Client";

export const getRestaurantDetails = async (restaurantSlug, isCatering = false) => {
	const result = await RestaurantAPI.getRestaurantDetails(restaurantSlug, isCatering);
	
	return handleResponse(
		result,
		undefined,
		() => result.data,
		() => false
	)
};

export const getRestaurantProducts = async (restaurantSlug, offset = 1, limit = 5, filterModel = {}) => {
	
	const queryModel = StringifyUrlEncoded({
		restaurant: restaurantSlug,
		offset,
		limit,
		...filterModel
	});
	
	const result = await RestaurantAPI.getRestaurantProducts(restaurantSlug, queryModel);
	
	return handleResponse(
		result,
		undefined,
		() => result.data,
		() => false
	)
};
