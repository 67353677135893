import React from 'react';
import { Modal, Tooltip } from 'antd4';
import numeral from "numeral";
import { InfoCircleOutlined } from "@ant-design/icons";

export const DeliveryMinimumInfoModal = ({ deliveryMinimum, handleAddItem }) => (
	Modal.info({
		title: 'Delivery Minimum Not Met',
		icon: <InfoCircleOutlined style={{color: '#ffbf00'}}/>,
		content: (
			<span>Restaurant requires {numeral(deliveryMinimum).format('$ 0,0[.]00')} in food (not including taxes and fees) for delivery. <br/>But, there is no minimum.</span>
		),
		maskClosable: true,
		okText: 'Add more items to my cart',
		onOk: handleAddItem
	})
);

export const DeliveryMinimumInfo = ({deliveryMinimum, subtotal, isDelivery = true}) => (
	deliveryMinimum > 0 && deliveryMinimum > subtotal && isDelivery ? (
		<div className={'CardTotalMinimum'}>
			{`${numeral(deliveryMinimum).format('$ 0,0[.]00')} minimum for delivery`}
			&nbsp;<Tooltip title={
			<span><b>Delivery Minimum Not Met </b><br/>Restaurant requires {numeral(deliveryMinimum).format('$ 0,0[.]00')} in food (not including taxes and fees) for delivery. <br/>But, there is no minimum.</span>}>
			<InfoCircleOutlined/>
		</Tooltip>
		</div>
	) : ''
);
