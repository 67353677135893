import React from 'react'
import Rate from "antd4/lib/rate";
import Img from '../HelpersComponents/Img.Component';

export const Review = ({image = false, colorCode, name, date, rate, review, answer, restaurantName, restaurantImg}) => (
		
		<li>
				<div>
						<div className={"user"}>
								{image ? <Img src={image} alt=""/> : <span style={{backgroundColor: colorCode}}>{name[0]}</span>}
						</div>
						<div className={"ReviewName"}>
								<p>{name} <span>{date}</span></p>
								<Rate disabled defaultValue={rate}/>
						</div>
				</div>
				<p>{review}</p>
				{answer && <ul>
						<li>
								<div>
										<div className={"user"}>
												{restaurantImg ? <Img src={restaurantImg} alt={restaurantName}/> : <span style={{backgroundColor: colorCode}}>{restaurantName[0]}</span>}
										</div>
										<div className={"ReviewName"}>
												<p>{restaurantName}</p>
										</div>
								</div>
								<p>{answer}</p>
						</li>
				</ul>}
		</li>
);

export default Review;
