import React from "react";
import {Button, Input, Form, DatePicker} from "antd4";
import {addCoupon} from "../../../services/Partner.Service";
import {onlyNumeric} from "../../../services/Utilities";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import Modal from "../../HelpersComponents/Modal.Component";
import moment from "moment";

class CouponModal extends React.Component {
		state = {
				dateCoupon: null
		};
		
		handleSubmit = values => {
				if (values.date)
						values.date = moment(values.date).format('Y-MM-DD');
				
				this.props.showLoading(true);
				addCoupon(values).then(result => {
						if (result) {
								this.props.onCategoryUserAdded(result);
								this.props.onVisibleUsersChange(false);
								this.setState({dateCoupon: null});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		render() {
				const {visible, editUserInfo} = this.props;
				return (
						<Modal
								title="Promotion"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleUsersChange(false);
								}}
								footer={false}
						>
								<div onKeyPress={this.onEnter}>
										<Form name={"addCategoryForm"} onFinish={this.handleSubmit} className="login-form"
													initialValues={{date: editUserInfo ? moment(editUserInfo.date) : null}}>
												<div className="GlobalInput " style={{height: 0}}>
														<Form.Item
																name={"couponId"}
																initialValue={editUserInfo ? editUserInfo.id : null}
														>
																<Input type={"hidden"}/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"Code coupon"}
																name={"code"}
																rules={[{required: true, message: "Please input Code!"}]}
																initialValue={editUserInfo ? editUserInfo.code : ""}
														>
																<Input placeholder="Code coupon"/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"Count"}
																name={"count"}
																rules={[{required: true, message: "Please input Count"}]}
																initialValue={editUserInfo ? editUserInfo.count : ""}
														>
																<Input placeholder="Count" onKeyPress={onlyNumeric}/>
														</Form.Item>
												</div>
												<div>
														<Form.Item
																label={"Date"}
																name={"date"}
														
														>
																<DatePicker
																		onChange={(e, s) => (this.setState({dateCoupon: s}))}
																		placeholder="Date"
																		format="YYYY-MM-DD"
																/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																name={"discountPercent"}
																label={"Discount Percent %"}
																rules={[{required: true, message: "Please input Discount Percent %"}]}
																initialValue={editUserInfo ? editUserInfo.discountPercent : ""}
														>
																<Input onKeyPress={onlyNumeric} placeholder="Discount Percent"/>
														</Form.Item>
												</div>
												<Form.Item>
														<Button htmlType="submit">{editUserInfo ? "Update Coupon" : "Add coupon"}</Button>
												</Form.Item>
										</Form>
								</div>
						</Modal>
				);
		}
}


CouponModal.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleUsersChange: PropTypes.func.isRequired,
		onCategoryUserAdded: PropTypes.func.isRequired
};

CouponModal.defaultProps = {
		visible: false,
		editUserInfo: null,
		onVisibleUsersChange: f => f,
		onCategoryUserAdded: f => f
};

export default connect(
		() => ({}),
		{showLoading}
)(CouponModal);
