import initialState from "../store/_initialState";
import {RESTAURANTS} from "../actions/_actionTypes";

export const restaurantsReducer = (state = initialState.restaurants, action) => {
	if (action.type === RESTAURANTS.SET_RESTAURANTS) {
		return action.payload;
	}
	return state;
};

export const selectedRestaurantReducer = (state = initialState.selectedRestaurant, action) => {
	switch (action.type) {
		case RESTAURANTS.SET_SELECTED_RESTAURANT:
			return action.payload;
		case RESTAURANTS.REMOVE_SELECTED_RESTAURANT:
			return false;
		default:
			return state;
	}
};
