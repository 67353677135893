import React from 'react';
import {Redirect, Switch, Route} from 'react-router-dom';
import Paths from '../services/Paths';
// Components
import {connect} from "react-redux";
import HomePage from "./HomeComponents/Home.Component";
import About from './AboutComponents/About.Component'
import PartnerUs from './SupportComponent/PartnerUs.Component'
import Order from './OrderComponents/Order.Component'
import Search from './SearchComponents/Search.Component'
import Preview from './LayoutComponetns/Preview.Component'
import Account from "./AccountComponents/Account.Component";
import Restaurant from "./RestaurantsComponents/Restaurant.Component";
import {Roles as ServerRoles} from './../enums/Server.Sider.Roles.enum';
import Partner from "./PartnerComponent/Partner.Index.Component";
import Driver from './DriverComponents/Driver.Component';
import PartnerManager from './PartnerManagerComponents/Partner.Manager.Component';
import ResetPassword from './SecurityComponents/Ressetting/Reset.Password.Component';
import Delivery from "./SupportComponent/Delivery.Component";
import NoMatch from "./LayoutComponetns/PageNotFound.Component";
import { hasRole, hasRoleClient, redirectToRolePage } from "../services/Utilities";

const MainRouters = ({isAuth, user}) => {
	const RoleRoute = ({component: Component, availableRoles = [], ...rest}) => {
		return (
			<Route
				{...rest}
				render={props =>
					isAuth ? (
						hasRole(user, availableRoles) ? (
							<Component {...props} />
						) : (
							<NoMatch/>
						)
					) : (
						<Redirect
							to={{
								pathname: Paths.home,
								state: {from: props.location}
							}}
						/>
					)
				}
			/>);
	};

	const HomePageRoute = ({component: Component, ...rest}) => {
			return (
			<Route
				{...rest}
				render={ props =>
					!isAuth || hasRoleClient(user.roles[0]) ?
						 (
							<Component {...props} />
						) : (
							redirectToRolePage(props.location, user.roles[0])
						)
				}
			/>
		)
	}
	
	return (
		<Switch>
			<HomePageRoute exact path={Paths.home} component={HomePage}/>

			<Route exact path={Paths.delivery} component={Delivery}/>
			<Route exact path={Paths.partnerUs} component={PartnerUs}/>
			<Route exact path={Paths.search} component={Search}/>
			<Route exact path={Paths.restaurant} component={Restaurant}/>
			{/*Todo check if used resetPassword component*/}
			<Route exact path={Paths.resetPassword} component={ResetPassword}/>
			{/*TODO check signIn*/}
			<Route path={Paths.signIn} component={() => <Redirect to={Paths.home}/>}/>

			<Route exact path={Paths.preview} component={Preview}/>
			<Route exact path={Paths.about} component={About}/>

			<RoleRoute exact path={Paths.account} component={Account} availableRoles={[ServerRoles.ROLE_CLIENT]}/>
			<RoleRoute exact path={Paths.order} component={Order} availableRoles={[ServerRoles.ROLE_CLIENT]}/>

			{'restaurant' in user && user.restaurant.id ? (
				<RoleRoute exact path={Paths.partner} component={Partner}
						   availableRoles={[ServerRoles.ROLE_PARTNER]}/>
			) : ''}
			<RoleRoute exact path={Paths.driver} component={Driver} availableRoles={[ServerRoles.ROLE_DRIVER]}/>
			<Route component={NoMatch}/>
		</Switch>
	);
};

const mapStateToProps = state => ({
	isAuth: state.user.isAuth,
	user: state.user.data
});

export default connect(mapStateToProps, {})(MainRouters);
