import React, {useEffect, useState} from "react";
import ModalTypes from "../../enums/Modal.Types.Enum";
import Modal from "../HelpersComponents/Modal.Component";
import {showModal} from "../../actions/baseActions";
import {connect} from "react-redux";
import Img from "../HelpersComponents/Img.Component";
import numeral from "numeral";
import moment from "moment";
import {getOrderItemById} from "../../api/Order.API";
import {Spin, Input} from "antd4";

const OrderItemDetailsModal = (props) => {
		
		const {orderDetailsModalVisible, showModal, modalProps} = props;
		const {userAddress, id, restaurantName} = modalProps;
		const [singleOrder, setSingleOrder] = useState(null);
		const [restaurant, setRestaurant] = useState(null);
		const [loading, setLoading] = useState(true);
		useEffect(() => {
				getOrderItemById(id).then((res) => {
						if (res && res.data && res.success) {
								
								setSingleOrder(res.data.orderItem)
								setRestaurant(res.data.restaurant)
						}
						setLoading(false)
				})
		}, [])
		
		const {cnt, comment, price, product, location, catering, ingredients, created} = singleOrder || {};
		const {description, downloadLink, name} = product ? product : catering || {};
		const address = userAddress ? userAddress : location && location.address && location.address.address;
		
		const renderIngredients = () => {
				if (!loading) {
						const group = 'product' in singleOrder ? 'ingredientsGroup' : 'cateringIngredient';
						
						const ingredientGroups = {};
						ingredients.map(item => {
								const ingredient = item.ingredient
								const groupName = ingredient[group].name;
								
								if (ingredientGroups.hasOwnProperty(groupName)) {
										ingredientGroups[groupName] = [...ingredientGroups[groupName], ...[{
												name: ingredient.name,
												plusPrice: ingredient.plusPrice
										}]]
								} else {
										ingredientGroups[groupName] = [{name: ingredient.name, plusPrice: ingredient.plusPrice}]
								}
						})
						return Object.keys(ingredientGroups).map((key, index) =>
								<div key={index}>
										<div className={'GroupTitle'}>{key}</div>
										{ingredientGroups[key].map((item, index) =>
												<p key={index}>{item.name}</p>
										)}
								</div>
						)
				}
		}
		
		const saveReview = e => {
				showModal(ModalTypes.ORDER_DETAILS_MODAL, false)
		}
		
		return (
				<Modal
						className={"CustomItemDetails singleOrderItemModal"}
						visible={orderDetailsModalVisible}
						onCancel={() => showModal(ModalTypes.ORDER_DETAILS_MODAL, false)}
				>
						<Spin spinning={loading}>
								{!!singleOrder &&
								<div className="container-fluid">
										<div className="row">
												<div className="col-md-6 pl-0 pr-0 position-static">
														<div className={"ItemDetailsLeft"}>
																{downloadLink && <Img src={downloadLink} alt=""/>}
																{name && <h4>{name}</h4>}
																{!!restaurantName && <p className={"ItemDetailsCal"}>{restaurantName}</p>}
																<p className={"ItemDetailsText"}>{!!description && description}</p>
																<p className={"ItemDetailsPrice"}>Price: {numeral(price).format('$0,0[.]00')}</p>
																{!!cnt &&
																<div className={"Count"}>
																		<p className={"Quantity"}>Quantity:</p>
																		<span>{cnt}</span>
																</div>
																}
														</div>
												</div>
												<div className="col-md-6 pl-0 pr-0">
														<div className={"ItemDetailsRight"}>
																<div className={'Options'}>
																		<div className={'GroupTitle'}>Details</div>
																		{address && <p><b>Address: </b>{address}</p>}
																		{created && <p><b>Created on: </b>{moment(created).format('DD MMMM YYYY, LT')}</p>}
																		
																		{renderIngredients()}
																		
																		{comment &&
																		<>
																				<div className={'GroupTitle'}>Comment:</div>
																				<Input.TextArea
																						name="item_comment"
																						id="ItemComment"
																						rows={3}
																						value={comment}
																						style={{resize: 'none', color: '#232323'}}
																						disabled
																				/>
																		</>
																		}
																</div>
														</div>
												</div>
										</div>
								</div>
								}
						</Spin>
				</Modal>
		)
}

const mapStateToProps = state => ({
		orderDetailsModalVisible: state.modalVisible.orderDetails,
		modalProps: state.modalVisible.modalProps
});

const mapDispatchToProps = {
		showModal
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(OrderItemDetailsModal);
