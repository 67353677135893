import React from "react";
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showModal} from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import Img from '../HelpersComponents/Img.Component'

const MapModal = ({showImgVisible, showModal, customProps={}}) => {
    return (
        <Modal
            className={"ShowImg"}
            visible={showImgVisible}
            onCancel={() => showModal(ModalTypes.IMG_SHOW, false)}
        >
            <Img style={{width: '100%'}} src={customProps.downloadLink}/>
        </Modal>
    )
};

MapModal.propTypes = {
    showImgVisible: PropTypes.bool.isRequired,
    customProps: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    showImgVisible: state.modalVisible.showImgVisible,
    customProps: state.modalVisible.modalProps,
});

const mapDispatchToProps = {
    showModal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapModal);
