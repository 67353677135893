import AddAddress from "../../../HelpersComponents/Add.Address.Component";
import React from "react";
import Img from "../../../HelpersComponents/Img.Component";
import nextArrowIcon from "../../../../img/next.svg";

const AddressComponent = ({location, confirmLocation, onAddressSelected,err}) => (
  <div className="HomeSearchComponent ItemDetailsRightAddress">
    <h4>Add your delivery address first to order</h4>
    <AddAddress
      onAddressSelected={onAddressSelected}
      showButton={false}
      inputPlaceholder={"Enter your delivery address"}
    />
    <small className="errInputBoxMsg">{err}</small>
    <div className="GetFood">
      <button
        onClick={confirmLocation}
      >
        <Img src={nextArrowIcon} alt={'Get Food'} isLocale={true} className={'getFoodIcon'}/>
      </button>
    </div>
  </div>
);

export default AddressComponent;