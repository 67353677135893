import * as Http from "./Http.Client";
import {HandleResponseState, StringifyUrlEncoded} from "./Http.Client";

export const getPartnerDetails = () =>
		Http.Get("api/v1/partner/details", {}, true)
				.then(HandleResponseState);
	
export const updatePartnerDetails = model =>
		Http.Post("api/v1/partner/details", {}, model, true, true)
				.then(HandleResponseState);

export const updatePartnerSettingsDetails = model =>
		Http.Post("api/v1/partner/settings", {}, model, true)
				.then(HandleResponseState);

export const setStopTacking = durationType =>
		Http.Post(`api/v1/partner/open-close-hours?durationType=${durationType}`, {}, null, true, true)
				.then(HandleResponseState);

export const filterTransactions = (startDate = null, endDate = null, type = null) => {
		const model = {};
		if (startDate) model.startDate = startDate
		if (endDate) model.endDate = endDate
		if (type) model.type = type
		
		return Http.Get(`api/v1/partner/transactions?${StringifyUrlEncoded(model)}`, {}, true)
				.then(HandleResponseState);
}

export const getPartnerBalanceHistory = () =>
		Http.Get("api/v1/partner/balance-history", {}, true)
				.then(HandleResponseState);

export const addNewCategory = model =>
		Http.Post("api/v1/partner/category", {}, model, true)
				.then(HandleResponseState);

export const getCategories = model =>
		Http.Get("api/v1/partner/categories", {}, true)
				.then(HandleResponseState);

export const getDietFriendlies = () =>
		Http.Get("api/v1/diet-friendlies", {}, true)
				.then(HandleResponseState);

//////////////////////////////
export const addCoupon = model =>
		Http.Post("api/v1/partner/add/coupon", {}, model, true, true)
				.then(HandleResponseState);

export const deleteCoupon = couponId =>
		Http.Delete(`api/v1/partner/coupon/${couponId}`, {}, {}, true, true)
				.then(HandleResponseState);

export const updateWorkingHours = model =>
		Http.Post("api/v1/partner/working-hours", {}, model, true)
				.then(HandleResponseState);

export const updateStartStopTimePickup = model =>
		Http.Post("api/v1/partner/working-hours-pickup", {}, model, true, true)
				.then(HandleResponseState);

export const getIngredients = () =>
		Http.Get('api/v1/partner/ingredients', {})
				.then(result => result ? result : false);

export const addNewIngredient = model =>
		Http.Post("api/v1/partner/ingredient", {}, model, true)
				.then(HandleResponseState);

export const addNewPartnerProduct = model =>
		Http.Post("api/v1/partner/product", {}, model, true, true)
				.then(HandleResponseState);

export const updatePartnerProduct = (model, productSlug) =>
		Http.Post(`api/v1/partner/product/${productSlug}`, {}, model, true, true)
				.then(HandleResponseState);

export const getPartnerProducts = (categorySlug) =>
		Http.Get(`api/v1/partner/products${categorySlug ? "?categorySlug=" + categorySlug : ``}`, {}, true)
				.then(HandleResponseState);

export const getPartnerProduct = productSlug =>
		Http.Get(`api/v1/partner/product/${productSlug}`, {}, true)
				.then(HandleResponseState);

export const deletePartnerProduct = productSlug =>
		Http.Delete(`api/v1/partner/product/${productSlug}`, {}, {}, true, true)
				.then(HandleResponseState);

export const getPartnerEmployees = () =>
		Http.Get("api/v1/partner/employees", {}, true)
				.then(HandleResponseState);

export const accessEmploy = model =>
		Http.Post("api/v1/partner/employ/access", {}, model, true)
				.then(HandleResponseState);

export const getContactUsers = () =>
		Http.Get(`api/v1/partner/workers`, {}, true)
				.then(result => result ? result : false);

export const updateContactUser = (userId, model) =>
		Http.Post(`api/v1/partner/worker/${userId}`, {}, model, true)
				.then(HandleResponseState);

export const createContactUser = model =>
		Http.Put("api/v1/partner/worker", {}, model, true)
				.then(HandleResponseState);

export const deleteContactUser = userId =>
		Http.Delete(`api/v1/partner/worker/${userId}`, {}, {}, true, true)
				.then(HandleResponseState);

/**
 * Partner Orders
 */

export const cancelOrder = orderSlug =>
		Http.Post(`api/v1/partner/order/cancel/${orderSlug}`, {}, {}, true, true)
				.then(HandleResponseState);

export const cancelOrderItem = orderItemId =>
		Http.Post(`api/v1/partner/order-item/cancel/${orderItemId}`, {}, {}, true, true)
				.then(HandleResponseState);

export const refund = (orderSlug, model) =>
		Http.Post(`api/v1/partner/order/refound/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);



export const removeDeliveryFee = orderSlug =>
		Http.Post(`api/v1/partner/order/remove-delivery-fee/${orderSlug}`, {}, {}, true)
				.then(HandleResponseState);

export const chargeExtra = (orderSlug, model) =>
		Http.Post(`api/v1/partner/order/charge-extra/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);

export const sendTestOrder = () =>
		Http.Post(`api/v1/partner/send/test/order`, {}, {}, true)
				.then(HandleResponseState);

/**
 * @deprecated
 */
export const updateOrderStatus = (orderSlug, model) =>
		Http.Post(`api/v1/partner/order/status/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);

export const partnerAcceptOrder = (orderSlug, model) =>
		Http.Post(`api/v1/partner/order/accept/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);

export const partnerFinishOrder = orderSlug =>
		Http.Post(`api/v1/partner/order/finish/${orderSlug}`, {}, {}, true, true)
				.then(HandleResponseState);

export const getOrders = (startDate = null, endDate = null) => {
		const model = {};
		if (startDate) model.startDate = startDate
		if (endDate) model.endDate = endDate
		
		return Http.Get(`api/v1/partner/orders?${StringifyUrlEncoded(model)}`, {}, true)
				.then(HandleResponseState);
};

export const getOrder = orderSlug =>
		Http.Get(`api/v1/partner/order/${orderSlug}`, {}, true)
				.then(HandleResponseState);

export const setAddItemProduct = code =>
		Http.Post(`api/v1/partner/order/add-product`, {}, code, true,true)
				.then(HandleResponseState);
