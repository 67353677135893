import React from 'react';
import Config from 'Config';
import Autocomplete from 'react-google-autocomplete';
import {GoogleApiWrapper} from 'google-maps-react';

class Address extends React.Component {
    constructor(props) {
        super(props);

        this.address = React.createRef();
    }
    
    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.address.current && 'input' in this.address.current.refs) {
            this.address.current.refs.input.value = nextProps.defaultValue ? nextProps.defaultValue : '';
        }
    }
    
    handleOnChange = evt => {
        if (!evt.target.value.length) {
            // this.props.onPlaceRemove();
        }
        // this.address.current.autocomplete.setFields(["address_components", "formatted_address"])
        //
    };
    
    render() {
        let props = {...this.props};
        // delete props.onPlaceRemove;
        return (
            <div className="vehicleInformation">
                <Autocomplete
                    {...props}
                    fields={['address_components', 'geometry.location', 'place_id', 'formatted_address','utc_offset_minutes']}
                    onChange={this.handleOnChange}
                    loaded={props.loaded ? 'true' : 'false'}
                    ref={this.address}
                    types={[]}
                />
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: Config.GoogleMapKey
})(Address)
