import React from 'react';
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import {showLoading} from "../../actions/baseActions";
import {connect} from "react-redux";
import {Button, Input, Form, Spin} from 'antd4';
import {Link} from 'react-router-dom';
import {showModal} from '../../actions/baseActions';
import ModalTypes from "../../enums/Modal.Types.Enum";
import {requestVerificationCode, verifyVerificationCode} from "../../services/User.Service";

export const ForgotPasswordVerificationCodeModal = ({
																												forgotPasswordVerifyCodeModalVisible,
																												showModal,
																												loading,
																												showLoading,
																												modalProps
																										}) => {
		const {email} = modalProps;
		
		const handleSubmit = values => {
				if ('code' in values) {
						showLoading(true);
						verifyVerificationCode(values.code).then(result => {
								if(result){
										console.log(result)
								}
						}).finally(() => {
							showLoading(false);
								// showModal(ModalTypes.FORGOT_PASSWORD_VERIFY_CODE, false);
						});
				}
		};
		
		const resendCode = () => {
				showLoading(true);
				requestVerificationCode(email).finally(() => {
						showLoading(false);
				});
		};
		
		return (
				<Modal
						className={"SignIn"}
						visible={forgotPasswordVerifyCodeModalVisible}
						onCancel={() => showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)}
				>
						<Spin spinning={loading}>
								<p className={'SignInTitle text-center'}>Please check your email</p>
								<p className={'text-center'}>We have sent a code to {email}</p>
								<div>
										
										<Form
												onFinish={handleSubmit}
												className="login-form"
												name={'forgot_password_request'}
										>
												<Form.Item
														label={'Verification Code'}
														name={'code'}
														rules={[{
																max: 4,
																min: 4,
																required: true,
																message: 'You must provide a valid Code.'
														}]}
												>
														<Input max={4} min={4}/>
												</Form.Item>
												<Button type="primary" htmlType="submit" className="login-form-button">
														Verify code
												</Button>
												<p className={'DontHaveAccount'}>
														Didn't get a code ?&nbsp;
														<Link to={'#'} onClick={resendCode}>Click to resend</Link></p>
										</Form>
								</div>
						</Spin>
				</Modal>
		)
};

ForgotPasswordVerificationCodeModal.propTypes = {
		forgotPasswordVerifyCodeModalVisible: PropTypes.bool.isRequired,
		showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
		forgotPasswordVerifyCodeModalVisible: state.modalVisible.forgotPasswordVerifyCode,
		modalProps: state.modalVisible.modalProps,
		loading: state.loading
});

const mapDispatchToProps = {
		showModal,
		showLoading
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(ForgotPasswordVerificationCodeModal);
