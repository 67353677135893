import React, {Component} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../../actions/baseActions";
import {getPartnerEmployees} from "../../../services/Partner.Service";
import {Tabs, Table, Button} from 'antd4';
import {Link} from "react-router-dom";
import Paths from "../../../services/Paths";
import {accessEmploy} from '../../../api/Partner.API';


const {TabPane} = Tabs;

class PartnerEmployees extends Component {
	state = {
		employees: [],
		employAccess:{},
	};

	columns = [
		{
			title: 'Id',
			key: 'id',
			dataIndex: 'id',
		}, {
			title: 'Email',
			key: 'email',
			dataIndex: 'email',
		}, {
			title: 'UserName',
			key: 'username',
			dataIndex: 'username',
		},{
			title: 'Order Cancel Access',
			key: 'id',
			render: (key) => (
				<Button style={{backgroundColor: this.state.employAccess[key.id] ? "red" :"green"}} onClick={() => this.sendAccess(key.id, this.state.employAccess[key.id] ?? false)}>
					{this.state.employAccess[key.id] ? 'Cancel Access': 'Give Access'}
				</Button>
			)
		}
	];

	sendAccess = async (employId,access) => {
		let accessEmployee = await accessEmploy({employId,access});
		const old=this.state.employAccess;
		old[accessEmployee.data.id]=accessEmployee.data.refundAccess;
		this.setState({employAccess: old})
	}
	makeArr = (data) => {
		let newObj=new Object()
		for (let i = 0; i < data.length; i++){
			newObj[data[i].id]=data[i].refundAccess;
		}

		this.setState({employAccess: newObj})
	}
	UNSAFE_componentWillMount() {
		this.props.showLoading(true);

		getPartnerEmployees().then(result => {
			if (result) {
				this.setState({employees: result})
				this.makeArr(result);
			}
		}).finally(() => {
			this.props.showLoading(false);
		})
	}

	render() {
		const {employees} = this.state;

		return (
			<div className={"Content"}>
				<section className={'ContentHeader'}>
					<h1>Locations</h1>
					{!employees.length &&
					<Link to={`${Paths.basePartner.replace(":tabName", "locations")}/create`}
						  className={"AddNewButton"}>
						Add Location
					</Link>}
				</section>
				<section className={"ContentBody"}>
					<div>
						<Tabs defaultActiveKey="employees">
							<TabPane tab="Employees" key="employees">
								<div>
									<Table
										columns={this.columns}
										dataSource={employees.length ? employees : []}
										rowKey={'id'}
										size={'middle'}
									/>
								</div>
							</TabPane>
						</Tabs>
					</div>
				</section>
			</div>
		)
	}
}

export default withRouter(connect(null, {showLoading, showModal},)(PartnerEmployees));
