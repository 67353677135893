import React from "react";
import {Form, Input, Button, Col, Row, InputNumber} from "antd4";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {onlyNumeric} from "../../../../../services/Utilities";

const rules = [{required: true}];

const IngredientFormList = () => (
		<div className={"col-lg-4"}>
				<div className={"FieldSet"} data-title="Ingredients">
						
						<Form.List name="ingredients">
								{(fields, {add, remove}) => {
										return (
												<div>
														{fields.map((field, index) => (
																<Row key={field.key} id={'separated_form_div'}>
																		<Col flex="none">
																				<MinusCircleOutlined
																						className="dynamic-delete-button"
																						onClick={() => {
																								remove(field.name);
																						}}
																				/>
																		</Col>
																		<Col>
																				<Form.Item
																						label='Group Name:'
																						name={[field.name, "name"]}
																						fieldKey={[field.fieldKey, "name"]}
																						rules={[
																								{
																										required: true,
																										message: "Please input your name"
																								}
																						]}
																				>
																						<Input placeholder="Group Name"/>
																				</Form.Item>
																				<Form.Item
																						name={[field.name, "slug"]}
																						fieldKey={[field.fieldKey, "slug"]}
																				>
																						<Input type={'hidden'}/>
																				</Form.Item>
																		</Col>
																		<Col>
																				<Form.Item
																						label="Selectable Count"
																						name={[field.name, "selectableCounts"]}
																						fieldKey={[field.fieldKey, "selectableCounts"]}
																				>
																						<InputNumber
																								formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
																								parser={value => value.replace(/\$\s?|(,*)/g, "")}
																								placeholder="Selectable counts"
																								min={0}
																								step={1}
																						/>
																				</Form.Item>
																		</Col>
																		
																		{/*Add Ingredient part start*/}
																		
																		<Form.List name={[field.name, "ingredient"]}>
																				{(fields, {add, remove}) => {
																						return (
																								<>
																										{fields.map((field, index) => (
																												<div key={index} className={'ingredientSubFields'}>
																														<Col flex="none">
																																<MinusCircleOutlined
																																		className="dynamic-delete-button"
																																		onClick={() => {
																																				remove(field.name);
																																		}}
																																/>
																														</Col>
																														<Col>
																																<Form.Item
																																		label="Size name"
																																		name={[field.name, "name"]}
																																		fieldKey={[field.fieldKey, "name"]}
																																		rules={rules}
																																>
																																		<Input placeholder="Size Name"/>
																																</Form.Item>
																																<Form.Item
																																		name={[field.name, "slug"]}
																																		fieldKey={[field.fieldKey, "slug"]}
																																>
																																		<Input type={'hidden'}/>
																																</Form.Item>
																														</Col>
																														
																														<Col>
																																<Form.Item
																																		label="Extra Price"
																																		name={[field.name, "plusPrice"]}
																																		fieldKey={[field.fieldKey, "sizeName"]}
																																>
																																		<InputNumber
																																				formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
																																				onKeyPress={onlyNumeric}
																																				parser={value => value.replace(/\$\s?|(,*)/g, "")}
																																				min={0}
																																				step={1}
																																		/>
																																</Form.Item>
																														</Col>
																												</div>
																										))}
																										
																										
																										<Form.Item>
																												<Button
																														type="dashed"
																														onClick={() => {
																																add();
																														}}
																														style={{width: "100%"}}
																												>
																														<PlusCircleOutlined/> Add Ingredient
																												</Button>
																										</Form.Item>
																								
																								</>
																						);
																				}}
																		</Form.List>
																		
																		{/*Add Ingredient part ends*/}
																</Row>
														))}
														<Form.Item>
																<Button
																		type="dashed"
																		onClick={() => {
																				add();
																		}}
																		style={{width: "100%"}}
																>
																		<PlusCircleOutlined/> Add Ingredient Group
																</Button>
														</Form.Item>
												</div>
										);
								}}
						</Form.List>
				</div>
		
		</div>
);

export default IngredientFormList;

