import React, {useState} from 'react';
import {GoogleApiWrapper, InfoWindow, Map as MapComponent, Marker} from "google-maps-react";
import {mapStyle} from "../../HelpersComponents/MapComponents/mapStyle";
import BrunchLocationIcon from "../../../img/brunch-location-icon.png";
import Config from 'Config';

const RestaurantMap = ({name, phone, address, google = {}}) => {
	const [map, setMap] = useState({
		infoWindowVisible: false,
		activeMarker: null,
		selectedPlace: {},
		
	});
	
	const onMarkerClick = (props, marker, e) => {
		setMap({
			selectedPlace: props,
			activeMarker: marker,
			infoWindowVisible: true
		})
	};
	
	const onMapClicked = () => {
		setMap({
			infoWindowVisible: false,
			activeMarker: null,
			selectedPlace: {},
		});
	};
	
	return (
		<div className="RestaurantMap">
			{/*<div>*/}
			{/*	<MapComponent*/}
			{/*		styles={mapStyle}*/}
			{/*		google={google}*/}
			{/*		onClick={onMapClicked}*/}
			{/*		initialCenter={address ? {*/}
			{/*			lat: address.lat,*/}
			{/*			lng: address.log*/}
			{/*		} : undefined}*/}
			{/*		center={address ? {*/}
			{/*			lat: address.lat,*/}
			{/*			lng: address.log*/}
			{/*		} : undefined}*/}
			{/*		mapTypeControl={false}*/}
			{/*		streetViewControl={false}*/}
			{/*	>*/}
			{/*		{address && (*/}
			{/*			<Marker*/}
			{/*				icon={{*/}
			{/*					url: BrunchLocationIcon*/}
			{/*				}}*/}
			{/*				onClick={onMarkerClick}*/}
			{/*				position={{lat: address.lat, lng: address.log}}*/}
			{/*				address={address.address}*/}
			{/*				name={name}*/}
			{/*				phone={phone}*/}
			{/*			/>*/}
			{/*		)}*/}
			{/*		<InfoWindow*/}
			{/*			marker={map.activeMarker}*/}
			{/*			visible={map.infoWindowVisible}>*/}
			{/*			<div>*/}
			{/*				<p><b>Name:</b> &nbsp;{name}</p>*/}
			{/*				<p><b>Address:</b> &nbsp;{address ? address.address : ""}</p>*/}
			{/*				<p><b>Phone:</b> &nbsp;{phone}</p>*/}
			{/*			</div>*/}
			{/*		</InfoWindow>*/}
			{/*	</MapComponent>*/}
			{/*</div>*/}
			{address ? (<p className={'AddressLine'}>{address.address}</p>) : ''}
		</div>
	);
};

export default GoogleApiWrapper({apiKey: Config.GoogleMapKey})(RestaurantMap)
