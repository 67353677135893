import React from "react";
import { Checkbox } from "antd4";
import numeral from "numeral";

const Ingredients = ({ingredients, item, handleIngredientsChange}) => (
  <div className="ItemDetailsRemove">
    {
      ingredients.map(group => (
        <React.Fragment key={group.slug}>
          <div className="GroupTitle">{group.name}
            &nbsp;&nbsp;{group.selectableCounts && (<span
              className="UpTo">(Up To {group.selectableCounts})</span>)}
          </div>
          <ul>
            <Checkbox.Group
              name={group.slug}
              value={item.ingredients[group.slug]}
              onChange={values => handleIngredientsChange(group.slug, values)}
            >

              {group.ingredient.map(ingredient => (
                <li key={ingredient.slug}>
                  <Checkbox
                    value={ingredient.slug}>
                    {ingredient.name}
                    {ingredient.plusPrice ? (
                      <span
                        className={'PlusPrice'}>{numeral(ingredient.plusPrice).format('+ $0,0[.]00')}</span>
                    ) : ''}
                  </Checkbox>
                </li>
              ))}

            </Checkbox.Group>
          </ul>
        </React.Fragment>
      ))
    }
  </div>
);

export default Ingredients;