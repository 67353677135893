import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../actions/baseActions";
import {Checkbox, DatePicker, Select, Table} from "antd4";
import {
		filterTransactions,
		updatePartnerSettings
} from "../../services/Partner.Service";
import numeral from "numeral";
import {Button} from "react-bootstrap";
import {showMessage} from "../../services/Utilities";
import {CloseCircleOutlined} from "@ant-design/icons";
import moment from "moment";
import "../../styles/PartnerStyles/partner.scss";

import PartnerFinancialView from "./ModalComponents/Partner.Financial.View.Modal.Component";
import {updatePartnerRestaurant} from "../../actions/partnerActions";

const {Option} = Select;

class PartnerBalanceHistory extends Component {
		constructor(props) {
				super(props);
				
				this.BalanceHistoryTypes = {
						ADD: 1,
						PAY: 2
				};
				
				let now = new Date();
				now.setMonth(now.getMonth() - 1);
				
				this.state = {
						balanceHistory: [],
						order: null,
						filterData: {
								startDate: moment(now).format("YYYY-MM-DD"),
								endDate: moment(new Date()).format("YYYY-MM-DD"),
								type: null
						},
						totalData: {},
						totalVisible: false,
						showTransactions: false
				};
		}
		
		TransactionDetails = [
				{
						title: "ID",
						key: "id",
						dataIndex: "id",
						sortDirections: ["descend", "ascend"],
						render: (id) => (
								<span>Order #{id}</span>
						)
				},
				{
						title: "Fulfilment type",
						key: "deliveryType",
						dataIndex: "deliveryType",
						render: (deliveryType) => (
								deliveryType === 1 ? "Delivery" : "Pick-up"
						)
				},
				{
						title: "Type",
						key: "type",
						dataIndex: "type",
						render: (price, item) => (
								!item.refund && !item.extraCharge ? "Prepaid order" : this.getRefundOrChargeExtra(item)
						)
				},
				{
						title: "action",
						key: "action",
						dataIndex: "action",
						render: (price, item) => (
								<Button variant={"outline-secondary"} onClick={() => this.setState({visiblePublic: true, order: item})}>
										View
								</Button>
						)
				}
		];
		
		Columns = [
				{
						title: "Tip ($)",
						dataIndex: "tipAmount",
						key: "tipAmount",
						render: tipAmount => numeral(tipAmount).format("$ 0,0[.]00")
				},
				{
						title: "Restaurant total ($)",
						dataIndex: "totalPrice",
						key: "totalPrice",
						render: totalPrice => numeral(totalPrice).format("$ 0,0[.]00")
				},
				{
						title: "Commission ($)",
						dataIndex: "servicePrice",
						key: "servicePrice",
						render: totalPrice => numeral(totalPrice).format("$ 0,0[.]00")
				},
				{
						title: "Withheld TAX ($)",
						dataIndex: "tax",
						key: "tax",
						render: tax => numeral(tax).format("$ 0,0[.]00")
				}
		];
		
		UNSAFE_componentWillMount() {
				const {filterData} = this.state;
				
				this.props.showLoading(true);
				
				filterTransactions(filterData.startDate, filterData.endDate, filterData.type).then(result => {
						if (result) {
								this.setState({
										balanceHistory: result.allTransactionsSumByDate,
										transactionDetails: result.allTransactionsByDate,
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		getRefundOrChargeExtra(item) {
				let htmlss = "";
				htmlss += item.refund ? item.refundReason + " " + item.refund + "$ " : " ";
				htmlss += item.extraCharge ? item.extraChargeReason + " " + item.extraCharge + "$ " : " ";
				return htmlss;
		}
		
		setFilterTime = (value, key) => {
				let {filterData} = this.state;
				filterData[key] = value;
				this.setState({filterData});
		};
		
		filterDate = () => {
				let {filterData} = this.state;
				
				if (!filterData.startDate || !filterData.endDate) {
						showMessage("error", "Please input Date!");
						return;
				}
				this.props.showLoading(true);

				filterTransactions(filterData.startDate, filterData.endDate, filterData.type).then(result => {
						if (result) {
								this.setState({
										balanceHistory: result["allTransactionsSumByDate"],
										transactionDetails: result["allTransactionsByDate"]
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		checkedEmail = (e) => {
				this.props.showLoading(true);
				updatePartnerSettings({
						id: this.props.restaurant.id,
						sendTransactionMail: e.target.checked.toString()
				}).then(result => {
						if (result) {
								this.props.updatePartnerRestaurant(result)
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		handleVisiblePublicChange = visible => {
				this.setState({visiblePublic: visible.show});
		};
		
		render() {
				const {restaurant} = this.props;
				const {visiblePublic, order} = this.state;
				
				const {balanceHistory, transactionDetails, totalVisible, filterData, showTransactions} = this.state;
				if (balanceHistory.length) {
						balanceHistory[0].id = 1;
				}

				return (
						<div className={"Content balance_content"}>
								<PartnerFinancialView
										visible={visiblePublic}
										order={order}
										onVisibleChange={this.handleVisiblePublicChange}
								/>
								<section className={"ContentHeader"}>
										<h1>View transactions</h1>
										
										<div className="d-flex align-items-center">
												<Select
														style={{marginRight: "10px"}}
														placeholder="Filter by type"
														onChange={value => {
																this.setFilterTime(value, "type");
														}}
												>
														<Option value={1}>Delivery</Option>
														<Option value={0}>Pick-up</Option>
												</Select>
												<DatePicker
														placeholder="Start date"
														format="YYYY-MM-DD"
														disabledDate={d => !d || d.isAfter(filterData['endDate'])}
														onChange={(e, s) => {
																this.setFilterTime(s, "startDate");
														}}
														
												/>
												<DatePicker
														placeholder="End date"
														format="YYYY-MM-DD"
														className={"ml-2"}
														disabledDate={d => !d || d.isSameOrBefore(filterData['startDate'])}
														onChange={(e, s) => {
																this.setFilterTime(s, "endDate");
														}}
												/>
												<Button
														className={"AddNewButton ml-3"}
														onClick={this.filterDate}
												>
														Filter
												</Button>
												{totalVisible && <Button
														className={"AddNewButton ml-3"}
														style={{padding: "7px", marginLeft: "5px"}}
														onClick={event => this.setState({totalVisible: false, totalData: {}})}
												>
														<CloseCircleOutlined/>
												</Button>}
										</div>
								</section>
								<section className={"ContentBody"}>
										<div>
												<Checkbox className={"mb-2"}
																	disabled={!restaurant}
																	onChange={evt => this.checkedEmail(evt)}
																	checked={restaurant && restaurant.settings ? restaurant.settings.sendTransactionMail : false}
												>
														Send me a daily transaction summary email
												</Checkbox>
												<h4>Transactions total</h4>
												{filterData.startDate && balanceHistory.length ?
														<b style={{fontSize: "20px"}}>({moment(filterData.startDate).format('MM-DD-Y')}/{moment(filterData.endDate).format('MM-DD-Y')})</b> : ""}
												<Table
														columns={this.Columns}
														dataSource={balanceHistory}
														rowKey={"id"}
														size={"middle"}
														pagination={false}/>
												<br/>
												{transactionDetails && !showTransactions ?
														<button className={"btn btn-danger"}
																		onClick={e => this.setState({showTransactions: true})}>View
																transactions</button> : ""}
												{showTransactions && <div>
														<h4>Transaction details</h4>
														<p>({transactionDetails && transactionDetails.length}) Items</p>
														<Table
																columns={this.TransactionDetails}
																dataSource={transactionDetails}
																rowKey={"id"}
																size={"middle"}
																pagination={{defaultPageSize: 25}}/>
												</div>}
										</div>
								</section>
						
						</div>
				);
		}
}


const mapStateToProps = state => ({
		restaurant: state.partnerRestaurant,
});


const mapDispatchToProps = {
		showLoading,
		showModal,
		updatePartnerRestaurant
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps
)(PartnerBalanceHistory));
