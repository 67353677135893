import React from 'react';
import Modal from '../HelpersComponents/Modal.Component';
import PropTypes from "prop-types";
import {showModal} from '../../actions/baseActions'
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { List, Avatar, Skeleton } from 'antd4';
import driverUser from '../../img/driver-user.png'

const DriverOrderAddressNotesModal = ({showModal, addressNotesVisible, notes, address}) => {
		return (
				<Modal
						className={'DriverAddressNotesModal'}
						visible={addressNotesVisible}
						title={address}
						onCancel={() => showModal(ModalTypes.ADDRESS_NOTES, false)}
				>
						<div className={'DriverAddressNotes'}>
								
										<List
												className="demo-loadmore-list"
												itemLayout="horizontal"
												dataSource={notes}
												renderItem={item =>
														(
																<List.Item>
																		<Skeleton avatar title={false} loading={item.loading} active>
																				<List.Item.Meta
																						avatar={<Avatar src={driverUser} />}
																						title={item.driver.clientFullName}
																						description={item.comment}
																				/>
																		</Skeleton>
																</List.Item>
														)
												}
										/>
						</div>
				</Modal>
		);
};

DriverOrderAddressNotesModal.propTypes = {
		driverAddressNote: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
		addressNotesVisible: state.modalVisible.addressNotes,
		notes: state.modalVisible.modalProps.notes,
		address: state.modalVisible.modalProps.address
});

const mapDispatchToProps = {
		showModal,
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps,
)(DriverOrderAddressNotesModal));
