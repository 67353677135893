import React, {useEffect} from "react";
import {showLoading, showModal} from "../../../actions/baseActions";
import {
		setActualOrder,
		setActualOrders,
		removeActualOrders,
		updateActualOrder,
		removeActualOrder,
		getDriverOrdersList
} from "../../../actions/driverActions";
import {connect} from "react-redux";
import {getAddressNotes, getDriverActualOrder} from "../../../services/Driver.Service";
import {Collapse} from 'antd4';
import {SyncOutlined, ExclamationCircleOutlined, WarningFilled} from '@ant-design/icons';
import locationIcon from "../../../img/maps-and-flags.png";
import phoneIcon from "../../../img/driver-phone.png";
import coinIcon from "../../../img/driver-coin.png";
import chatIcon from "../../../img/driver-comment.png";
import userIcon from "../../../img/driver-user.png";
import orderIcon from "../../../img/driver-order.png";
import markerMessageIcon from "../../../img/marker-message.png";
import {
		OrderItemDriverStatusNumeral,
		OrderItemRestaurantStatusNumeral,
		OrderStatusNumeral
} from "../../../enums/Order.Status.Enum";
import PropTypes from "prop-types";
import numeral from "numeral";
import {updateDriverOrderStatus} from "../../../services/Driver.Service";
import {WssAction} from "../../../enums/WSS.Action.Enum";
import {sendDriverWssMessage} from "../../../Wss/Driver.WSS.Connection";
import {messageType, showMessage, storeOrderDetails} from "../../../services/Utilities";
import ModalTypes from "../../../enums/Modal.Types.Enum";

const {Panel} = Collapse;

const DriverActualOrder = (props) => {
		const {active, actualOrders, showModal} = props;
		useEffect(() => {
				onGetDriverActualOrder();
				document.addEventListener("DriverConnection", receiveMessage);
				
				return () => {
						document.removeEventListener("DriverConnection", receiveMessage);
				}
		}, [])
		
		const onGetDriverActualOrder = (orderSlug = null) => {
				props.showLoading(true);
				getDriverActualOrder(orderSlug).then(result => {
						if (result) {
								if (result.length) {
										result.map(order => {
												setOrderDetails(order);
										})
								} else {
										setOrderDetails(result);
								}
						} else {
								props.removeActualOrders();
						}
				}).finally(() => {
						props.showLoading(false);
				});
		};
		
		const setOrderDetails = async order => {
				const newOrder = await storeOrderDetails(order)
				props.setActualOrder(newOrder);
		}
		
		const setOrdersDetails = async orders => {
				const newOrders = await Promise.all(orders.map(async order => {
						return await storeOrderDetails(order)
				}));
				
				props.setActualOrders(newOrders);
		}
		
		const onUpdateOrderStatus = (lastStatus, newStatus, index) => {
				const actualOrder = actualOrders[index];
				if ("slug" in actualOrder) {
						props.showLoading(true);
						return updateDriverOrderStatus(actualOrder.slug, lastStatus, newStatus).then(order => {
								if (order && "id" in order) {
										if (order.status === OrderStatusNumeral.IS_DONE) {
												props.removeActualOrder(index);
												props.getDriverOrdersList();
										} else {
												setOrderDetails(order);
										}
										
										// already working from server side
										// sendDriverWssMessage({
										// 		action: WssAction.ORDER_UPDATED,
										// 		slug: order.slug,
										// 		restaurant: order.restaurant.slug ? order.restaurant.slug : false,
										// 		driver: props.username
										// });
								}
						}).finally(() => {
								props.showLoading(false);
						});
				}
		};
		
		const receiveMessage = event => {
				const {detail} = event;
				if (detail && "action" in detail) {
						switch (detail.action) {
								case WssAction.ACTION_SUBMITTED:
										if ("slug" in detail) {
												onGetDriverActualOrder(detail.slug);
												props.redirectToActualTab();
										}
										break;
								case WssAction.ORDER_UPDATED:
										if ("slug" in detail) {
												getDriverActualOrder().then(orders => {
														if (orders.length) {
																setOrdersDetails(orders)
														} else {
																props.removeActualOrders();
														}
												});
										}
										if ('message' in detail && detail.message) {
												showMessage(detail.messageType ? detail.messageType : messageType.warning, detail.message);
										}
										break;
								default:
										break;
						}
				}
		};
		
		const getOrderButton = index => {
				if (index in actualOrders) {
						const order = actualOrders[index]
						if (order.driverStatus === OrderItemDriverStatusNumeral.IS_NEW) {
								return (
										<div className="order-accept orderStateButton">
												<button onClick={() => {
														onUpdateOrderStatus(order.driverStatus, OrderItemDriverStatusNumeral.IS_DRIVER_ACCEPTED, index);
												}}
												>
														Accept
												</button>
										</div>
								)
						} else if (order.driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_ACCEPTED) {
								return (
										<div className="order-item-arrive checkLocation">
												<button
														onClick={() => onUpdateOrderStatus(order.driverStatus, OrderItemDriverStatusNumeral.IS_DRIVER_ARRIVED, index)}
												>Arrive
												</button>
										</div>
								);
						} else if (order.driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_ARRIVED && order.restaurantStatus === OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY) {
								return (
										<div className="order-item-depart checkLocation">
												<button
														onClick={() => (
																onUpdateOrderStatus(order.driverStatus, OrderItemDriverStatusNumeral.IS_DRIVER_DEPARTED, index)
														)}
												>Depart
												</button>
										</div>
								);
						} else if (order.driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_DEPARTED && order.status === OrderStatusNumeral.IS_DELIVERY) {
								return (
										<div className="order-end orderStateButton">
												<button
														onClick={() => onUpdateOrderStatus(order.driverStatus, OrderItemDriverStatusNumeral.IS_DRIVER_END, index)}
												>
														End
												</button>
										</div>
								)
						}
				}
				
				return "";
		};
		
		const NoActualOrder = () => (
				<div className={"NoActualOrder"}>
						<div className="ant-empty ant-empty-normal">
								<div className="ant-empty-image">
										<svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
												<g transform="translate(0 1)" fill="none" fillRule="evenodd">
														<ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"/>
														<g fillRule="nonzero" stroke="#D9D9D9">
																<path
																		d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"/>
																<path
																		d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"
																		fill="#FAFAFA"/>
														</g>
												</g>
										</svg>
								</div>
								<p className="ant-empty-description">No Order</p></div>
				</div>
		);
		
		
		const handleAddressNotesClick = address => {
				getAddressNotes(address).then(result => {
						if (result) {
								showModal(ModalTypes.ADDRESS_NOTES, true, {notes: result, address})
						}
				});
		}
		return (
				<div>
						{active ? (
								<button className="refresh" onClick={() => onGetDriverActualOrder()}>
										<span>Refresh</span>
										<SyncOutlined/>
								</button>
						) : (
								<div className={"InActiveDriver"}>
										<WarningFilled/>
										<span>Inactive</span>
								</div>
						)}
						{actualOrders && actualOrders.length ?
								actualOrders.map((actualOrder, index) => {
										if (actualOrder && actualOrder.id) {
												const {
														restaurants,
														deliveryPrice,
														fullName,
														notes,
														phone,
														location,
														totalPrice,
												} = actualOrder;
												return (
														<React.Fragment key={index}>
																<Collapse accordion>
																		{restaurants && Object.keys(restaurants).map(key => (
																						<Panel
																								header={
																										<span><strong>#{actualOrder.id}</strong> - {restaurants[key].locationName}</span>}
																								key={restaurants[key].locationName}
																								extra={
																										<span className={`status-${restaurants[key].restaurantShowStatus}`}>
																										 <ExclamationCircleOutlined/>
																									</span>
																								}
																						>
																								<p className={"branchName"}>
																										<img src={locationIcon} alt=""/>
																										<span>{`${restaurants[key].locationName} (${restaurants[key].location.address})`}</span>
																								</p>
																								<p className={"tel"}>
																										<img src={phoneIcon} alt=""/>
																										<span>{restaurants[key].locationPhone}</span>
																								</p>
																								<p className={"coin"}>
																										<img src={coinIcon} alt=""/>
																										<span>{numeral(restaurants[key].price).format("$ 0,0[.]00")}</span>
																								</p>
																								<div className="items">
																										{restaurants[key].products.map(item => (
																												<div className="item" key={item.id}>
																														<span>{item.product ? item.product.name : item.catering ? item.catering.name : ''}</span> {item.qty}
																												</div>
																										))}
																								</div>
																						</Panel>
																				)
																		)}
																</Collapse>
																<div className="clientInfo">
																		{location && "address" in location && location.address && (
																				<img src={markerMessageIcon} alt="" className={'MarkerMessageIcon'}
																						 onClick={() => handleAddressNotesClick(location.address)}/>)}
																		<p className={"branchName"}>
																				<img src={locationIcon} alt=""/>
																				<span>{location && "address" in location && location.address}</span>
																		</p>
																		<p className={"tel"}>
																				<img src={userIcon} alt=""/>
																				<span>{fullName}</span>
																		</p>
																		{notes && (
																				<p className={"message"}>
																						<img src={chatIcon} alt=""/>
																						<span>{notes}</span>
																				</p>
																		)}
																		<p className={"tel"}>
																				<img src={phoneIcon} alt=""/>
																				<span>{phone}</span>
																		</p>
																		<p className={"order"}>
																				<img src={orderIcon} alt=""/>
																				Order {numeral(totalPrice - deliveryPrice).format("$ 0,0[.]00")} +
																				Delivery {numeral(deliveryPrice).format("$ 0,0[.]00")} = <span>Total {numeral(totalPrice).format("$ 0,0[.]00")}</span>
																		</p>
																		<p className={"coin"}>
																				<img src={coinIcon} alt=""/>
																				<span>{numeral(totalPrice).format("$ 0,0[.]00")}</span>
																		</p>
																		{getOrderButton(index)}
																</div>
														</React.Fragment>
												)
										}
								})
								: <NoActualOrder/>}
				</div>
		);
}

DriverActualOrder.propTypes = {
		redirectToActualTab: PropTypes.func.isRequired,
		actualOrders: PropTypes.array.isRequired,
		active: PropTypes.bool.isRequired,
		username: PropTypes.string.isRequired
};

DriverActualOrder.defaultProps = {
		redirectToActualTab: f => f
};

const mapStateToProps = state => ({
		username: state.user.data.username,
		active: state.user.data.active,
		actualOrders: state.actualOrder
});

const mapDispatchToProps = {
		showLoading,
		showModal,
		setActualOrder,
		setActualOrders,
		removeActualOrders,
		updateActualOrder,
		removeActualOrder,
		getDriverOrdersList
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(DriverActualOrder);
