import React, {Component} from "react";
import {withRouter} from "react-router";
import {getPartnerCategories} from "../../services/Partner.Service";
import {showLoading, showModal} from "../../actions/baseActions";
import {Table, Tag} from "antd4";
import {connect} from "react-redux";
import Img from "../HelpersComponents/Img.Component";
import ModalTypes from "../../enums/Modal.Types.Enum";
import NewCategoryModal from "./ModalComponents/New.Category.Modal.Component";
import getStore from "../../store/configureStore";

class PartnerCategories extends Component {
		state = {
				categories: [],
				visible: false
		};
		
		TableColumns = [
				{
						title: "Name",
						key: "name",
						dataIndex: "name",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => {
								if (a.name < b.name) {
										return -1;
								}
								if (a.name > b.name) {
										return 1;
								}
								return 0;
						}
				},
				{
						title: "Products count",
						key: "productsCount",
						dataIndex: "productsCount",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => a.productsCount - b.productsCount,
						render: (value) => (
								<span>
				<Tag color={"blue"}>
					{value}
				</Tag>
      		</span>
						)
				}, {
						title: "Image",
						key: "downloadLink",
						dataIndex: "downloadLink",
						render: downloadLink => (
								downloadLink ? <Img className={"TableListImg"}
																		onClick={() => this.props.showModal(ModalTypes.IMG_SHOW, true, {downloadLink})}
																		src={downloadLink}/> : ""
						)
				}
		];
		
		UNSAFE_componentWillMount() {
				this.props.showLoading(true);
				getPartnerCategories().then(result => {
						if (result) {
								this.setState({categories: result.categories});
						}
				}).finally(() => this.props.showLoading(false));
		}
		
		handleVisibleChange = visible => {
				this.setState({visible});
		};
		
		handleNewCategoryAdded = newCategory => {
				if (newCategory) {
						this.setState({categories: [newCategory, ...this.state.categories]});
				}
				
		};
		
		render() {
				const {categories, visible} = this.state;
				
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Categories</h1>
										<button className={"AddNewButton"} onClick={() => this.handleVisibleChange(true)}>Add category
										</button>
								</section>
								<section className={"ContentBody"}>
										<div>
												<Table
														columns={this.TableColumns}
														dataSource={categories}
														rowKey={"id"}
														size={"middle"}
														pagination={{
																defaultPageSize: 25
														}}/>
												<NewCategoryModal
														visible={visible}
														onVisibleChange={this.handleVisibleChange}
														onCategoryAdded={this.handleNewCategoryAdded}
												>
												</NewCategoryModal>
										</div>
								</section>
						</div>
				
				);
		}
}

export default withRouter(connect(
		state => ({restaurantSlug: state.partnerRestaurant.slug}),
		{showLoading, showModal}
)(PartnerCategories));
