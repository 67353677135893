import {
	LEFT_MENU,
	LOADING,
	MODAL,
	IMG,
	AUDIO,
	HEADER,
	DRIVER_TIP_TARIFF,
	DELIVERY_TIME
} from "../actions/_actionTypes";
import initialState from "../store/_initialState";
import {setAudioState} from "../services/User.Service";

export const loadingReducer = (state = initialState.loading, action) => {
	switch (action.type) {
		case LOADING.START_LOADING:
			return true;
		case LOADING.STOP_LOADING:
			return false;
		default:
			return state;
	}
};
export const localLoadingReducer = (state = initialState.localLoading, action) => {
	switch (action.type) {
		case LOADING.START_LOCAL_LOADING:
			return true;
		case LOADING.STOP_LOCAL_LOADING:
			return false;
		default:
			return state;
	}
};

export const leftMenuReducer = (state = initialState.leftMenuVisible, action) => {
	switch (action.type) {
		case LEFT_MENU.SHOW_LEFT_MENU:
			document.body.style.overflow = 'hidden';
			return true;
		case LEFT_MENU.HIDE_LEFT_MENU:
			document.body.style.overflow = 'initial';
			return false;
		default:
			return state;
	}
};

export const showHeaderReducer = (state = initialState.showHeader, action) => {
	switch (action.type) {
		case HEADER.SHOW_HEADER:
			return true;
		case HEADER.HIDE_HEADER:
			return false;
		default:
			return state;
	}
};

export const showModalReducer = (state = initialState.modalVisible, action) => {
	if (action.type === MODAL.SHOW_MODAL) {
		const modalType = action.payload && action.payload.modalType;
		const modalState = action.payload && action.payload.state;
		const modalProps = action.payload && action.payload.modalProps;

		if (modalType in state) {
			return Object.assign({}, state, {
				[modalType]: modalState,
				modalProps: modalState ? modalProps : {}
			})
		}
		return state;
		
	}
	return state;
};

export const toggleAudioReducer = (state = initialState.audioState, action) => {
	switch (action.type) {
		case AUDIO.TURN_ON:
			setAudioState(true);
			return true;
		case AUDIO.TURN_OFF:
			setAudioState(false);
			return false;
		default:
			return state;
	}
};

export const showImgReducer = (state = initialState.modalVisible.showImgVisible, action) => {
	switch (action.type) {
		case IMG.SHOW:
			return true;
		case IMG.HIDE:
			return false;
		default:
			return state;
	}
};

export const tipTariff = (state = initialState.tipTariff, action) => {
	switch (action.type) {
		case DRIVER_TIP_TARIFF.SET_DRIVER_TIP_TARIFF:
			return action.payload;
		case DRIVER_TIP_TARIFF.REMOVE_DRIVER_TIP_TARIFF:
			return [];
		default:
			return state;
	}
};

export const updateDeliveryTimeReducer = (state = initialState.deliveryTime, action) =>{
	switch (action.type) {
		case DELIVERY_TIME.UPDATE_DELIVERY_DAY:
			return Object.assign({}, state, {when: action.payload});
		case DELIVERY_TIME.UPDATE_DELIVERY_TIME:
			return Object.assign({}, state, {time: action.payload});
		default:
			return state
	}
};