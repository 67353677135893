import {CreditCard_Type} from "./_actionTypes";
import { validServerTypes } from "../enums/Card.Icons.Enum";

export const handleCardNumberChange = (value, type) => dispatch =>{
    dispatch({
      type: CreditCard_Type.CARD_NUMBER_CHANGE,
      payload: {
        number: value,
        cardType: validServerTypes[type]
      }
    })
};

export const handleHolderNameChange = e => dispatch => {
  dispatch({
    type: CreditCard_Type.HOLDER_NAME_CHANGE,
    holderName: e.target.value
  })
};

export const handleExpDateChange = value => dispatch => {
  dispatch({
    type: CreditCard_Type.EXP_DATE_CHANGE,
    expDate: value
  })
};

export const handleCardCvvChange = e => dispatch => {
  dispatch({
    type: CreditCard_Type.CVV_CHANGE,
    cvv: e.target.value
  })
};

export const onCreditCardSelect = item => dispatch => {
  dispatch({
    type: CreditCard_Type.CARD_SELECT,
    selectedCardId: item
  })
}

export const onCardSaveCheck = () => dispatch => {
  dispatch({ type: CreditCard_Type.CARD_SAVE_CHECK })
}

export const updateCardList = (list) => dispatch => {
  dispatch({
    type: CreditCard_Type.UPDATE_CARD_LIST,
    list: list
  })
}

export const handleZipCodeChange = (code) => dispatch => {
  dispatch({
    type: CreditCard_Type.ZIP_CODE_CHANGE,
    zipCode: code
  })
}

export const clearCreditCard = () => dispatch => {
  dispatch({
    type: CreditCard_Type.CLEAR_CARD
  })
}