import * as HomeApi from '../api/Home.API';
import * as PartnerApi from '../api/Partner.API';
import * as ReviewApi from '../api/Review.API';
import {handleResponse, toFormData} from "./Utilities";
import {handleResponseForbidden} from "./Utilities";

export const addNewCategory = model => {
		return PartnerApi.addNewCategory(model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
};

export const createContactUser = model => {
		return PartnerApi.createContactUser(model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Contact Account has been successfully updated.'
		))
};

export const updateContactUser = (userId, model) => {
		return PartnerApi.updateContactUser(userId, model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Contact Account has been successfully updated.'
		))
};

export const addCoupon = model => {
		const formData = toFormData(model);
		return PartnerApi.addCoupon(formData).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Contact has been successfully updated.'
		))
};

export const addNewIngredient = model => {
		return PartnerApi.addNewIngredient(model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
};

export const getRestaurantUserContact = () => {
		return PartnerApi.getContactUsers()
				.then(result => handleResponseForbidden(result))
};

export const getCoupon = () =>
		HomeApi.getCoupon()
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
				))

export const getCouponPercent = () =>
		HomeApi.getCouponPercent()
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
				))

export const getPartnerBalanceHistory = () =>
		PartnerApi.getPartnerBalanceHistory()
				.then(result => handleResponseForbidden(result));

export const removeDeliveryFee = orderSlug =>
		PartnerApi.removeDeliveryFee(orderSlug)
				.then(result => handleResponse(
						result,
						undefined,
						undefined,
						undefined,
						"Remove delivery fee has been successfully requested."
				));

export const filterTransactions = (startDate = null, endDate = null, type = null) =>
		PartnerApi.filterTransactions(startDate, endDate, type)
				.then(result => handleResponseForbidden(result));

export const getPartnerIngredients = () =>
		PartnerApi.getIngredients()
				.then(result => handleResponseForbidden(result))

/**
 *  Partner Details
 */
export const getPartnerDetails = () => {
		return PartnerApi.getPartnerDetails().then(result => handleResponseForbidden(result));
};

export const updatePartnerDetails = model => {
		const formData = toFormData(model);
		return PartnerApi.updatePartnerDetails(formData).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Restaurant has been successfully updated.'
		))
};
export const updatePartnerSettings = model => {
		return PartnerApi.updatePartnerSettingsDetails(model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Restaurant has been successfully updated.'
		))
};
export const updateWorkingHours = model => {
		return PartnerApi.updateWorkingHours(model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Start stop tacking has been successfully updated.'
		))
};

export const updateStartStopTimePickup = model => {
		const formData = toFormData(model);
		return PartnerApi.updateStartStopTimePickup(formData).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Start stop tacking has been successfully updated.'
		))
};

/**
 * Product
 */
export const getPartnerCategories = () =>
		PartnerApi.getCategories().then(result => handleResponse(result));

export const getPartnerDietFriendlies = () =>
		PartnerApi.getDietFriendlies().then(result => handleResponse(result));


export const getPartnerProducts = (categorySlug = false) =>
		PartnerApi.getPartnerProducts(categorySlug).then(result => handleResponse(result));

export const getPartnerProduct = productSlug => {
		return PartnerApi.getPartnerProduct(productSlug).then(result => handleResponseForbidden(result));
};

export const addNewPartnerProduct = model => {
		const formData = toFormData(model);
		return PartnerApi.addNewPartnerProduct(formData).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Product has been successfully created.'
		))
};

export const updatePartnerProduct = (model, productSlug) => {
		const formData = toFormData(model);
		
		return PartnerApi.updatePartnerProduct(formData, productSlug).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Product has been successfully updated.'
		));
};

export const deletePartnerProduct = productSlug => {
		return PartnerApi.deletePartnerProduct(productSlug).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Product has been successfully deleted.'
		))
};

export const deleteContactUser = userId => {
		return PartnerApi.deleteContactUser(userId).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'User has been successfully deleted.'
		))
};

export const deleteCoupon = couponId =>
		PartnerApi.deleteCoupon(couponId).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Coupon been successfully deleted.'
		));

/**
 * Orders
 */
export const getOrders = (startDate = null, endDate = null) => {
		return PartnerApi.getOrders(startDate, endDate).then(result => handleResponse(result));
};

export const getOrder = orderSlug =>
		PartnerApi.getOrder(orderSlug).then(result => handleResponse(result));

/**
 * @deprecated
 */
export const updateOrderStatus = (orderSlug, lastStatus, newStatus, preparingDuration = null) => {
		return PartnerApi.updateOrderStatus(orderSlug, {lastStatus, newStatus, preparingDuration})
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order has been successfully updated.'
				));
};

export const partnerAcceptOrder = (orderSlug, delay, delayReason) => {
		return PartnerApi.partnerAcceptOrder(orderSlug, {delay, delayReason})
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order has been successfully accepted.'
				));
};

export const partnerFinishOrder = orderSlug => {
		return PartnerApi.partnerFinishOrder(orderSlug)
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order has been successfully updated.'
				));
};


export const cancelOrder = orderSlug => {
		return PartnerApi.cancelOrder(orderSlug).then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order has been successfully updated.'
				));
};

export const cancelOrderItem = orderItemId => {
		return PartnerApi.cancelOrderItem(orderItemId).then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order item has been successfully updated.'
				));
};

export const getPartnerEmployees = partnerSlug => {
		return PartnerApi.getPartnerEmployees(partnerSlug).then(result => handleResponseForbidden(result))
};

export const setAddItemProduct = (product, slug) => {
		const formData = toFormData({ product, slug });
		return PartnerApi.setAddItemProduct(formData).then(result => handleResponse(
				result,
				undefined,
				() => (result)
		));
};

/*******************************************
 *		Partner Reviews
 *******************************************/
export const getPartnerReviews = () => {
		return ReviewApi.getPartnerReviews()
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data)
				));
}

export const getPartnerReview = reviewId => {
		return ReviewApi.getPartnerReview(reviewId)
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data)
				));
}

export const blockPartnerReview = (reviewId, isBlock) => {
		return ReviewApi.blockPartnerReview(reviewId, isBlock ? 'block' : 'unblock')
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data)
				));
}

export const answerPartnerReview = (reviewId, model) => {
		return ReviewApi.answerPartnerReview(reviewId, model)
				.then(result => handleResponse(
						result,
						undefined,
						() => result.data
				));
}

export const refundOrder = (orderSlug, model) => {
		return PartnerApi.refund(orderSlug, model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
};

export const chargeExtraOrder = (orderSlug, model) => {
		return PartnerApi.chargeExtra(orderSlug, model).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
};

export const sendTestOrder = () => {
		return PartnerApi.sendTestOrder().then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
};
