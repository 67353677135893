import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import slickSliderSettings from "../../settings/slickSliderSettings";

class Panel extends Component {
		settings = {};
		
		constructor(props) {
				super(props);
				let state = {
						offset: 1,
				};
				if (props.contentScrollable) {
						this.settings = Object.assign({}, slickSliderSettings, props.contentScrollable);
						state = Object.assign({}, state, {
								slidesToShow: this.settings.slidesToShow || 8
						})
				}
				this.state = state;
				
				this.slider = React.createRef()
		}
		
		UNSAFE_componentWillMount() {
				if (this.props.lazyLoadable) {
						document.addEventListener('filterUpdate', this.resetSlider);
						document.addEventListener('filterReset', this.filterReset)
				}
		}
		
		componentWillUnmount() {
				if (this.props.lazyLoadable) {
						document.removeEventListener('filterUpdate', this.resetSlider);
						document.removeEventListener('filterReset', this.filterReset)
				}
		}
		
		resetSlider = () => {
				if (this.slider && this.slider.current) {
						this.slider.slickGoTo(0)
				}
		};
		
		filterReset = () => {
				let state = {
						offset: 1
				};
				if (this.props.contentScrollable) {
						this.settings = Object.assign({}, slickSliderSettings, this.props.contentScrollable);
						state = Object.assign({}, state, {
								slidesToShow: this.settings.slidesToShow || 8
						})
				}
				this.resetSlider();
				this.setState(state)
		};
		
		renderChildren = () => {
				const {contentScrollable, children} = this.props;
				const {slidesToShow} = this.state;
				
				return contentScrollable ? (
						<div>
								<Slider
										ref={ref => this.slider = ref}
										{...this.settings}
										afterChange={currentSlide => this.afterChange(currentSlide + slidesToShow)}
										beforeChange={(e) => {
												this.props.handleSliderBeforeChange()
										}}
										// onSwipe={(el) => {console.log(this.slider); console.log(el)}}
								>
										{children}
								</Slider>
						</div>
				) : (
						<div className="dis-grid">
								{children}
						</div>
				)
		};
		
		afterChange = currentSlide => {
				this.props.handleSliderAfterChange();
				const {children, lazyLoadable} = this.props;
				const {slidesToShow, offset} = this.state;
				if (children.length && lazyLoadable) {
						if (currentSlide + slidesToShow > children.length) {
								let newOffset = Math.ceil(currentSlide / 10) + 1;
								if (offset !== newOffset) {
										this.setState({offset: newOffset});
										this.props.getNewItems(newOffset, 10);
								}
						}
				}
		};
		
		render() {
				const {className, title, restaurant, description, allowRate, onReviewClick} = this.props;
				return (
						<div className={className}>
								{title ?
										<div className={"PanelTitle " + (allowRate ? 'pointer AllowRate' : '')}>
												<h5>{title}&nbsp;{allowRate && (<button className={'OrderFeedbackRate'}
																																onClick={allowRate && onReviewClick}>Review</button>)}</h5>
												{restaurant ? (<p>{restaurant} restaurants</p>) : ''}
												{description ? (<p>{description}</p>) : ''}
										</div>
										: ''}
								{this.renderChildren()}
						</div>
				)
		};
}

Panel.defaultProps = {
		allowRate: false,
		totalCount: false,
		title: false,
		restaurant: false,
		contentScrollable: false,
		getNewItems: f => f,
		lazyLoadable: false,
		description: false,
		handleSliderBeforeChange: f => f,
		handleSliderAfterChange: f => f
};

Panel.propTypes = {
		allowRate: PropTypes.bool,
		onReviewClick: PropTypes.oneOfType([
				PropTypes.func,
				PropTypes.bool
		]),
		title: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.bool
		]),
		restaurant: PropTypes.oneOfType([
				PropTypes.number,
				PropTypes.bool
		]),
		contentScrollable: PropTypes.oneOfType([
				PropTypes.object,
				PropTypes.bool
		]),
		description: PropTypes.oneOfType([
				PropTypes.string,
				PropTypes.bool
		]),
		className: PropTypes.oneOfType([
				PropTypes.string,
		]),
		lazyLoadable: PropTypes.bool.isRequired
};

export default Panel;
