import React from "react";
import {Button, Col, Form, Input, InputNumber, Row, Select, Upload} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {LoadingOutlined, PlusCircleOutlined} from "@ant-design/icons";
import {addressFormatter, handleBeforeImgUpload, onlyNumeric, phoneNumberFormatter} from "../../../services/Utilities";
import Img from "../../HelpersComponents/Img.Component";
import {updatePartnerDetails, updatePartnerSettings} from "../../../services/Partner.Service";
import Modal from "../../HelpersComponents/Modal.Component";
import TextArea from "antd4/es/input/TextArea";
import Config from "Config";
import Address from "../../HelpersComponents/Address.Component";
import {updatePartnerRestaurant} from "../../../actions/partnerActions";

const { Option } = Select;

class PartnerEditDetails extends React.Component {
		constructor(props) {
				super(props);
				const {restaurant} = props;
				const address = restaurant && restaurant.address ? restaurant.address : false;
				
				this.state = {
						addressInvalid: false,
						address: {
								address: address && 'address' in address ? address.address : null,
								city: address && 'city' in address ? address.city : null,
								state: address && 'state' in address ? address.state : null,
								lat: address && 'lat' in address ? address.lat : null,
								log: address && 'log' in address ? address.log : null,
								zipCode: address && 'zip' in address ? address.zip : null
						}
				};
		}
		
		handleSubmit = values => {
				const {restaurant, updatePartnerRestaurant} = this.props;
				const {address} = this.state;
				const model = Object.assign({}, values, {
						file: restaurant.file,
						fileBanner: restaurant.fileBanner,
						address: address
				});
				
				// delete Base64 string
				delete model.downloadLink;
				delete model.bannerFile;
				
				this.props.showLoading(true);
				
				updatePartnerDetails(model).then(result => {
						if (result) {
								this.props.onVisibleChange({show: false, getOrder: false});
								updatePartnerRestaurant(result);
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		handleSubmitMoreInfo = values => {
				console.log(values);
				this.props.showLoading(true);
				updatePartnerSettings(values).then(result => {
						if (result) {
								this.props.onVisibleChange({show: false, getOrder: false});
								this.props.updatePartnerRestaurant(result);
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		handleCustomRequest = file => {
				const {restaurant, updatePartnerRestaurant} = this.props;
				const reader = new FileReader();
				// Closure to capture the file information.
				reader.onload = (() => (e) => {
						const base64String = window.btoa(e.target.result);
						
						if (base64String) {
								updatePartnerRestaurant(Object.assign({}, restaurant, {
										downloadLink: `data:${file.file.type};base64,` + base64String,
										file: file.file
								}));
						}
				})(file.file);
				// Read in the image file as a data URL.
				reader.readAsBinaryString(file.file);
		};
		
		handleCustomBannerRequest = file => {
				const {restaurant, updatePartnerRestaurant} = this.props;
				const reader = new FileReader();
				// Closure to capture the file information.
				reader.onload = (() => (e) => {
						const base64String = window.btoa(e.target.result);
						if (base64String) {
								updatePartnerRestaurant(Object.assign({}, restaurant, {
										bannerFile: `data:${file.file.type};base64,` + base64String,
										fileBanner: file.file
								}));
						}
				})(file.file);
				// Read in the image file as a data URL.
				reader.readAsBinaryString(file.file);
		};
		
		onPlaceSelected = place => {
				console.log(place)
				const address = addressFormatter(place)
				if (address && address.address) {
						this.setState({address: address, addressInvalid: false})
				} else {
						this.setState({addressInvalid: true})
				}
		}
		
		render() {
				const {visible, restaurant} = this.props;
				const {settings} = restaurant;
				const {address, addressInvalid} = this.state;
				const uploadButton = (
						<div>
								{false ? <LoadingOutlined/> : <PlusCircleOutlined/>}
								<div className="ant-upload-text">Upload</div>
						</div>
				);
				let isBase = true;
				if (/^data:image\/(png|jpeg|gif);base64.*$/.test(restaurant.bannerFile)) {
						isBase = false;
				}
				return (
						<Modal
								title="Edit Profile"
								className="edit_profile"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: false});
								}}>
								<Tabs style={{height: '100%'}}>
										<TabList>
												<Tab>Restaurant information</Tab>
												<Tab>More Info</Tab>
												<Tab>Terms and Agreement</Tab>
										</TabList>
										<TabPanel>
												<Form onFinish={this.handleSubmit} name={"partnerDetailsForm"} style={{marginTop: "15px"}}>
														<Row gutter={16}>
																<Col>
																		<div className="GlobalInput ">
																				<Upload
																						style={{width: "100%"}}
																						name="fileBanner"
																						accept="image/jpg, image/png, image/jpeg"
																						listType="picture-card"
																						className="avatar-uploader banner_img"
																						showUploadList={false}
																						beforeUpload={handleBeforeImgUpload}
																						customRequest={this.handleCustomBannerRequest}
																				>
																						<div className={"banner_profile hover_banner_profile"} style={{
																								minHeight: 0,
																								backgroundImage: "linear-gradient(45deg, #0000003b, #03030333),url(" + (restaurant && restaurant.bannerFile ? isBase ? Config.ServerUrl + restaurant.bannerFile : restaurant.bannerFile : "") + ")",
																								width: "100%",
																								height: "130px"
																						}}>
																						</div>
																				
																				</Upload>
																				
																				<div className="logo_name" style={{
																						position: "absolute",
																						top: "20px",
																						left: "55px"
																				}}>
																						<div className="img">
																								
																								<Form.Item
																										colon={false}
																										required={true}
																								>
																										<Upload
																												name="file"
																												listType="picture-card"
																												className="avatar-uploader"
																												showUploadList={false}
																												beforeUpload={handleBeforeImgUpload}
																												customRequest={this.handleCustomRequest}
																										>
																												{restaurant && restaurant.downloadLink ?
																														<Img src={restaurant.downloadLink} alt="avatar"
																																 style={{width: "100%"}}/> : uploadButton}
																										</Upload>
																								</Form.Item>
																						</div>
																				</div>
																		
																		</div>
																		<div className="row">
																				<div className="GlobalInput col-6">
																						<Form.Item
																								colon={false}
																								name={"name"}
																								label={"Name"}
																								rules={[{required: true, message: "Please input Restaurant Name!"}]}
																								initialValue={restaurant.name}
																						>
																								<Input placeholder="Name"/>
																						</Form.Item>
																				</div>
																				<div className="GlobalInput col-6">
																						<Form.Item
																								colon={false}
																								label={'Address'}
																								className={addressInvalid ? 'has-error AntRequired' : 'AntRequired'}
																						>
																								<Address
																										className={'ant-input'}
																										name={'address'}
																										placeholder={'Address'}
																										defaultValue={address ? address.address : ''}
																										title={address ? address.address : ''}
																										onPlaceSelected={this.onPlaceSelected}
																										types={['address']}
																										componentRestrictions={{country: "us"}}
																										required
																								/>
																								{addressInvalid && (
																										<div className="ant-form-explain">Please input valid
																												address!</div>
																								)}
																						</Form.Item>
																				</div>
																		</div>
																		<div className="row">
																				<div className="GlobalInput col-6">
																						<Form.Item
																								colon={false}
																								name={"email"}
																								label={"Email"}
																								rules={[{
																										required: true,
																										message: "Please input Restaurant Email address!"
																								}]}
																								initialValue={restaurant.email}
																						>
																								<Input placeholder="Email" type={'email'}/>
																						</Form.Item>
																				</div>
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"Phone"}
																								name={"phone"}
																								colon={false}
																								rules={[{
																										required: true,
																										message: "Please input your Phone number!"
																								}]}
																								normalize={phoneNumberFormatter}
																								initialValue={restaurant.phone}
																						>
																								<Input
																										onKeyPress={onlyNumeric}
																										placeholder={"Phone"}
																								/>
																						</Form.Item>
																				</div>
																		</div>
																		<div className="row">
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"FEIN"}
																								name={"fein"}
																								colon={false}
																								rules={[{required: true, message: "Please input FEIN"}]}
																								initialValue={restaurant.fein}>
																								<Input placeholder="FEIN" style={{textTransform: 'upparcase'}}/>
																						</Form.Item>
																				</div>
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"Sales Tax(%)"}
																								name={"salesTax"}
																								colon={false}
																								initialValue={restaurant.salesTax}
																						>
																								<Input type={'number'} disabled={true} defaultValue={9.5}
																											 value={restaurant.salesTax} placeholder="Sales Tax"/>
																						</Form.Item>
																				</div>
																		</div>
																		<div className="GlobalInput">
																						<Form.Item
																								label={"About us"}
																								name={"aboutUs"}
																								colon={false}
																								initialValue={restaurant.aboutUs}
																						>
																								<TextArea
																										placeholder={"Restaurant description"}
																								/>
																						</Form.Item>
																				</div>
																</Col>
														</Row>
														<div className="FormFooter">
																<Form.Item>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</Form.Item>
														</div>
												</Form>
										</TabPanel>
										<TabPanel>
												<Form onFinish={this.handleSubmitMoreInfo} name={"partnerSettings"} style={{marginTop: "15px"}}>
														<Row gutter={16}>
																<Col>
																		<div className="row">
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"Curbside pickup instructions"}
																								name={"curbsidePickupInstructions"}
																								colon={false}
																								initialValue={settings.curbsidePickupInstructions}
																						>
																								<Input
																										placeholder={"Curbside pickup instructions"}
																								/>
																						</Form.Item>
																				</div>
																		</div>
																		<div className="row">
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"Pickup order estimate (default prep time)"}
																								name={"pickupOrderEstimate"}
																								colon={false}
																								initialValue={settings.pickupOrderEstimate}
																						>
																								<InputNumber
																										style={{width: "100%"}}
																										parser={value => value.replace(/\$\s?|(,*)/g, "")}
																										onKeyPress={onlyNumeric}
																										placeholder="10 min"
																										min={5}
																										step={5}
																								/>
																						</Form.Item>
																				</div>
																				<div className="GlobalInput col-6">
																						<Form.Item
																								label={"Default order estimate (default prep time)"}
																								colon={false}
																								name={"deliveryOrderEstimate"}
																								initialValue={settings.deliveryOrderEstimate}
																						>
																								<InputNumber
																										style={{width: "100%"}}
																										parser={value => value.replace(/\$\s?|(,*)/g, "")}
																										onKeyPress={onlyNumeric}
																										placeholder="10 min"
																										min={5}
																										step={5}
																								/>
																						</Form.Item>
																				</div>
																		</div>
																		<div className="row">
																				<div className="GlobalInput">
																						<Form.Item
																								label={"Driver instructions"}
																								name={"driverInstructions"}
																								colon={false}
																								initialValue={settings.driverInstructions}
																						>
																								<TextArea
																										placeholder={"You have not provided any driver instructions"}
																								/>
																						</Form.Item>
																				</div>
																		</div>
																		<div className="row">
																				<div className={"GlobalInput col-6"}>
																						<Form.Item
																								label={"Large Order threshold"}
																								name={"largeOrderThreshold"}
																								colon={false}
																								initialValue={settings.largeOrderThreshold}
																						>
																								<InputNumber
																										addonBefore={"$"}
																										style={{height: '38px', width: '100%'}}
																										onKeyPress={onlyNumeric}
																										placeholder={"250"}
																										min={0}
																										step={1}
																								/>
																						</Form.Item>
																				</div>
																				<div className={"GlobalInput col-6"}>
																						<Form.Item
																								label={"Additional time needed for large orders (minute)"}
																								name={"largeOrderAdditionalMinute"}
																								colon={false}
																								initialValue={settings.largeOrderAdditionalMinute}
																						>
																								<InputNumber
																										addonBefore={"+"}
																										min={0}
																										max={60}
																										style={{height: '38px', width: '100%'}}
																										onKeyPress={onlyNumeric}
																										placeholder={"20 minutes"}
																								/>
																						</Form.Item>
																				</div>
																		</div>
																</Col>
														</Row>
														<div className="FormFooter">
																<Form.Item>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</Form.Item>
														</div>
												</Form>
										</TabPanel>
										<TabPanel style={{height: '100%'}}>
												<div className={'terms_body'} style={{height: '100%', overflow: 'auto', marginTop: '10px'}}>
														<h3>Terms and Conditions</h3>
														<p>Welcome to MeaMeal.com. The MeaMeal.com website (the "Site") is comprised of various web
																pages operated
																by MeaMeal ("MeaMeal"). MeaMeal.com is offered to you conditioned on your acceptance
																without
																modification of the terms, conditions, and notices contained herein (the "Terms"). Your
																use of
																MeaMeal.com constitutes your agreement to all such Terms. Please read these terms
																carefully, and keep a
																copy of them for your reference. </p>
														
														<p>MeaMeal.com is an E-Commerce Site.</p>
														
														<p>MeaMeal is an online food ordering platform for pick up, delivery and catering. </p>
														
														<p className="name">Privacy</p>
														<p>Your use of MeaMeal.com is subject to MeaMeal's Privacy Policy. Please review our Privacy
																Policy, which
																also governs the Site and informs users of our data collection practices.</p>
														
														<p className="name">Electronic Communications</p>
														<p>Visiting MeaMeal.com or sending emails to MeaMeal constitutes electronic communications.
																You consent to
																receive electronic communications and you agree that all agreements, notices,
																disclosures and other
																communications that we provide to you electronically, via email and on the Site, satisfy
																any legal
																requirement that such communications be in writing.</p>
														
														<p className="name">Your Account</p>
														<p>If you use this site, you are responsible for maintaining the confidentiality of your
																account and
																password and for restricting access to your computer, and you agree to accept
																responsibility for all
																activities that occur under your account or password. You may not assign or otherwise
																transfer your
																account to any other person or entity. You acknowledge that MeaMeal is not responsible
																for third party
																access to your account that results from theft or misappropriation of your account.
																MeaMeal and its
																associates reserve the right to refuse or cancel service, terminate accounts, or remove
																or edit content
																in our sole discretion.</p>
														
														<p className="name">Children Under Thirteen</p>
														<p>MeaMeal does not knowingly collect, either online or offline, personal information from
																persons under
																the age of thirteen. If you are under 18, you may use MeaMeal.com only with permission
																of a parent or
																guardian.</p>
														
														<p className="name">Cancellation/Refund Policy</p>
														<p>At MeaMeal.com once your credit/debit card transaction is complete it is final. Refunds
																or credits are
																solely on MeaMeal’s discretion.</p>
														
														<p className="name">Links to Third Party Sites/Third Party Services</p>
														<p>MeaMeal.com may contain links to other websites ("Linked Sites"). The Linked Sites are
																not under the
																control of MeaMeal and MeaMeal is not responsible for the contents of any Linked Site,
																including without
																limitation any link contained in a Linked Site, or any changes or updates to a Linked
																Site. MeaMeal is
																providing these links to you only as a convenience, and the inclusion of any link does
																not imply
																endorsement by MeaMeal of the site or any association with its operators.
																<br/>
																Certain services made available via MeaMeal.com are delivered by third party sites and
																organizations. By
																using any product, service or functionality originating from the MeaMeal.com domain, you
																hereby
																acknowledge and consent that MeaMeal may share such information and data with any third
																party with whom
																MeaMeal has a contractual relationship to provide the requested product, service or
																functionality on
																behalf of MeaMeal.com users and customers.
														</p>
														
														<p className="name">No Unlawful or Prohibited Use/Intellectual Property</p>
														<p>You are granted a non-exclusive, non-transferable, revocable license to access and use
																MeaMeal.com
																strictly in accordance with these terms of use. As a condition of your use of the Site,
																you warrant to
																MeaMeal that you will not use the Site for any purpose that is unlawful or prohibited by
																these Terms.
																You may not use the Site in any manner which could damage, disable, overburden, or
																impair the Site or
																interfere with any other party's use and enjoyment of the Site. You may not obtain or
																attempt to obtain
																any materials or information through any means not intentionally made available or
																provided for through
																the Site.
																<br/>
																All content included as part of the Service, such as text, graphics, logos, images, as
																well as the
																compilation thereof, and any software used on the Site, is the property of MeaMeal or
																its suppliers and
																protected by copyright and other laws that protect intellectual property and proprietary
																rights. You
																agree to observe and abide by all copyright and other proprietary notices, legends or
																other restrictions
																contained in any such content and will not make any changes thereto.
																<br/>
																You will not modify, publish, transmit, reverse engineer, participate in the transfer or
																sale, create
																derivative works, or in any way exploit any of the content, in whole or in part, found
																on the Site.
																MeaMeal content is not for resale. Your use of the Site does not entitle you to make any
																unauthorized
																use of any protected content, and in particular you will not delete or alter any
																proprietary rights or
																attribution notices in any content. You will use protected content solely for your
																personal use, and
																will make no other use of the content without the express written permission of MeaMeal
																and the
																copyright owner. You agree that you do not acquire any ownership rights in any protected
																content. We do
																not grant you any licenses, express or implied, to the intellectual property of MeaMeal
																or our licensors
																except as expressly authorized by these Terms.
														</p>
														
														<p className="name">International Users</p>
														<p>The Service is controlled, operated and administered by MeaMeal from our offices within
																the USA. If you
																access the Service from a location outside the USA, you are responsible for compliance
																with all local
																laws. You agree that you will not use the MeaMeal Content accessed through MeaMeal.com
																in any country or
																in any manner prohibited by any applicable laws, restrictions or regulations.</p>
														
														<p className="name">Indemnification</p>
														<p>You agree to indemnify, defend and hold harmless MeaMeal, its officers, directors,
																employees, agents
																and third parties, for any losses, costs, liabilities and expenses (including reasonable
																attorney's
																fees) relating to or arising out of your use of or inability to use the Site or
																services, any user
																postings made by you, your violation of any terms of this Agreement or your violation of
																any rights of a
																third party, or your violation of any applicable laws, rules or regulations. MeaMeal
																reserves the right,
																at its own cost, to assume the exclusive defense and control of any matter otherwise
																subject to
																indemnification by you, in which event you will fully cooperate with MeaMeal in
																asserting any available
																defenses. </p>
														
														<p className="name">Arbitration</p>
														<p>In the event the parties are not able to resolve any dispute between them arising out of
																or concerning
																these Terms and Conditions, or any provisions hereof, whether in contract, tort, or
																otherwise at law or
																in equity for damages or any other relief, then such dispute shall be resolved only by
																final and binding
																arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral
																arbitrator and
																administered by the American Arbitration Association, or a similar arbitration service
																selected by the
																parties, in a location mutually agreed upon by the parties. The arbitrator's award shall
																be final, and
																judgment may be entered upon it in any court having jurisdiction. In the event that any
																legal or
																equitable action, proceeding or arbitration arises out of or concerns these Terms and
																Conditions, the
																prevailing party shall be entitled to recover its costs and reasonable attorney's fees.
																The parties
																agree to arbitrate all disputes and claims in regards to these Terms and Conditions or
																any disputes
																arising as a result of these Terms and Conditions, whether directly or indirectly,
																including Tort claims
																that are a result of these Terms and Conditions. The parties agree that the Federal
																Arbitration Act
																governs the interpretation and enforcement of this provision. The entire dispute,
																including the scope
																and enforceability of this arbitration provision shall be determined by the Arbitrator.
																This arbitration
																provision shall survive the termination of these Terms and Conditions.</p>
														
														<p className="name">Class Action Waiver</p>
														<p>Any arbitration under these Terms and Conditions will take place on an individual basis;
																class
																arbitrations and class/representative/collective actions are not permitted. THE PARTIES
																AGREE THAT A
																PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS
																A PLAINTIFF OR
																CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATIVE PROCEEDING, SUCH
																AS IN THE FORM OF
																A PRIVATE ATTORNEY GENERAL ACTION AGAINST THE OTHER. Further, unless both you and
																MeaMeal agree
																otherwise, the arbitrator may not consolidate more than one person's claims, and may not
																otherwise
																preside over any form of a representative or class proceeding</p>
														
														<p className="name">Liability Disclaimer</p>
														<p>THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE
																SITE MAY INCLUDE
																INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION
																HEREIN. MEAMEAL
																AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
																<br/>
																MEAMEAL AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY,
																AVAILABILITY,
																TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED
																GRAPHICS CONTAINED
																ON THE SITE FOR ANY PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH
																INFORMATION,
																SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY
																OR CONDITION OF
																ANY KIND. MEAMEAL AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS
																WITH REGARD TO THIS
																INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
																WARRANTIES OR
																CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
																NON-INFRINGEMENT.
																<br/>
																TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL MEAMEAL AND/OR ITS
																SUPPLIERS BE
																LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR
																ANY DAMAGES
																WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS,
																ARISING OUT OF OR IN
																ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY
																TO USE THE SITE
																OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
																INFORMATION, SOFTWARE,
																PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING
																OUT OF THE USE
																OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE,
																EVEN IF MEAMEAL
																OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE SOME
																STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR
																CONSEQUENTIAL OR
																INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED
																WITH ANY PORTION
																OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO
																DISCONTINUE USING
																THE SITE.
														</p>
														
														<p className="name">Termination/Access Restriction </p>
														<p>MeaMeal reserves the right, in its sole discretion, to terminate your access to the Site
																and the
																related services or any portion thereof at any time, without notice. To the maximum
																extent permitted by
																law, this agreement is governed by the laws of the State of California and you hereby
																consent to the
																exclusive jurisdiction and venue of courts in California in all disputes arising out of
																or relating to
																the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not
																give effect to
																all provisions of these Terms, including, without limitation, this section.</p>
														
														<p>You agree that no joint venture, partnership, employment, or agency relationship exists
																between you and
																MeaMeal as a result of this agreement or use of the Site. MeaMeal's performance of this
																agreement is
																subject to existing laws and legal process, and nothing contained in this agreement is
																in derogation of
																MeaMeal's right to comply with governmental, court and law enforcement requests or
																requirements relating
																to your use of the Site or information provided to or gathered by MeaMeal with respect
																to such use. If
																any part of this agreement is determined to be invalid or unenforceable pursuant to
																applicable law
																including, but not limited to, the warranty disclaimers and liability limitations set
																forth above, then
																the invalid or unenforceable provision will be deemed superseded by a valid, enforceable
																provision that
																most closely matches the intent of the original provision and the remainder of the
																agreement shall
																continue in effect.
																<br/>
																Unless otherwise specified herein, this agreement constitutes the entire agreement
																between the user and
																MeaMeal with respect to the Site and it supersedes all prior or contemporaneous
																communications and
																proposals, whether electronic, oral or written, between the user and MeaMeal with
																respect to the Site. A
																printed version of this agreement and of any notice given in electronic form shall be
																admissible in
																judicial or administrative proceedings based upon or relating to this agreement to the
																same extent and
																subject to the same conditions as other business documents and records originally
																generated and
																maintained in printed form. It is the express wish to the parties that this agreement
																and all related
																documents be written in English.
														</p>
														
														<p className="name">Changes to Terms</p>
														<p>MeaMeal reserves the right, in its sole discretion, to change the Terms under which
																MeaMeal.com is
																offered. The most current version of the Terms will supersede all previous versions.
																MeaMeal encourages
																you to periodically review the Terms to stay informed of our updates. </p>
														
														<p>Contact Us</p>
														<p>MeaMeal welcomes your questions or comments regarding the Terms: </p>
														
														<p className="name">MeaMeal</p>
														
														{/*<p>Duval St, Key West, FL 33040, California, USA</p>*/}
														{/*<br/>*/}
														<p>Email Address: <br/> Info@MeaMeal.com</p>
														
														<p>Telephone number: <br/> 323-632-6325</p>
														
														<p>Effective as of November 07, 2019 </p>
												</div>
										</TabPanel>
								</Tabs>
						</Modal>
				);
		}
}


PartnerEditDetails.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired,
		updatePartnerRestaurant: PropTypes.func.isRequired
};

PartnerEditDetails.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		items: null,
		restaurant: null
};

export default connect(
		state => ({restaurant: state.partnerRestaurant,}),
		{showLoading, updatePartnerRestaurant}
)(PartnerEditDetails);
