import React from 'react';
import Modal from '../HelpersComponents/Modal.Component';
import PropTypes from "prop-types";
import {showModal, showLocalLoading} from '../../actions/baseActions'
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";
import {Button, Form, Input, Spin} from "antd4";
import {sendAddressNote} from "../../services/Driver.Service";
import {updateDriverOrderProperty} from "../../actions/orderActions";

const DriverAddressNoteModal = ({updateDriverOrderProperty, showModal, showLocalLoading, driverAddressNote, localLoading, modalProps}) => {
		const handleSubmit = model => {
				if ('comment' in model && 'orderSlug' in modalProps) {
						showLocalLoading(true);
						sendAddressNote(model, modalProps.orderSlug).then(result => {
								updateDriverOrderProperty(modalProps.orderSlug, 'addressComment', result);
								showModal(ModalTypes.DRIVER_ADDRESS_NOTE_MODAL, false);
						}).finally(() => {
								showLocalLoading(false);
						});
				}
		};
		
		return (
				<Modal
						className={'DriverAddressNoteModal'}
						visible={driverAddressNote}
						onCancel={() => showModal(ModalTypes.DRIVER_ADDRESS_NOTE_MODAL, false)}
				>
						<div className={'DriverAddressNote'}>
								<Spin spinning={localLoading}>
										<Form
												onFinish={handleSubmit}
												name={'driver_note'}
										>
												<Form.Item
														label={false}
														name={'comment'}
														rules={[{required: true, message: 'Note is required'}]}
												>
														<Input.TextArea rows={8} placeholder={'Enter note for other drivers'} maxLength={600}/>
												</Form.Item>
												<div className={'submit-container'}>
														<Button type="primary" htmlType="submit">
																Submit
														</Button>
												</div>
										</Form>
								</Spin>
						</div>
				</Modal>
		);
};

DriverAddressNoteModal.propTypes = {
		driverAddressNote: PropTypes.bool.isRequired,
		localLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
		driverAddressNote: state.modalVisible.driverAddressNote,
		localLoading: state.localLoading,
		modalProps: state.modalVisible.modalProps
});

const mapDispatchToProps = {
		showModal,
		showLocalLoading,
		updateDriverOrderProperty
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps,
)(DriverAddressNoteModal));
