export const Roles = {
  ROLE_ADMIN: "ROLE_ADMIN",
  ROLE_USER: "ROLE_USER",
  ROLE_CLIENT: "ROLE_CLIENT",
  
  ROLE_PARTNER: "ROLE_PARTNER",
  
  ROLE_DRIVER: "ROLE_DRIVER",
};

export default Roles;
