import initialState from "../store/_initialState";
import {CATEGORIES, PRODUCTS} from "../actions/_actionTypes";

export const categoriesReducer = (state = initialState.categories, action) => {
	if (action.type === CATEGORIES.SET_CATEGORIES) {
		return {
			all: action.payload.all,
			main: action.payload.main,
		};
	}
	return state;
};

export const productsReducer = (state = initialState.products, action) => {
	switch (action.type) {
		case PRODUCTS.SET_PRODUCTS:
			return action.payload;
		case PRODUCTS.PAGINATE_PRODUCTS:
			return [...state, ...action.payload];
		default:
			return state;
	}
};

