import Config from 'Config';

let ClientWssConnection = undefined;

export const initClientConnection = userId => {
  if (userId) {
    ClientWssConnection = new WebSocket(`${Config.WssUrl}client`);

    ClientWssConnection.onopen = () => {
      if (ClientWssConnection) {
        console.log('Client wss connection was created successfully.');
      }
    };

    ClientWssConnection.onclose = event => {
      if (event.wasClean) {
        console.log('Client wss session disconnect successfully');
      } else {
        console.log('Code: ' + event.code + ' reason: ' + event.reason);
      }
    };

    ClientWssConnection.onerror = error => {
      console.log("error", error.message);
    };
  }
  return ClientWssConnection;
};

export const getClientWssConnection = userId => {
  if (ClientWssConnection && ClientWssConnection.readyState) {
    return ClientWssConnection;
  } else if (userId) {
    ClientWssConnection = initClientConnection(userId);
    return ClientWssConnection;
  }
  return false;
};

export const closeClientConnection = () => {
  if (ClientWssConnection) {
    ClientWssConnection.close();
    return true;
  }
  return false;
};

/**
 * @deprecated
 * @param message
 * @returns {boolean}
 */
export const sendClientWssMessage = message => {
  if (ClientWssConnection) {
    ClientWssConnection.send(JSON.stringify(message))
  }
  return false;
};
