import React from "react";

import '../../styles/AboutStyle/about.scss'
import about from '../../img/ic.svg'
import aboutImg from '../../img/people-3.jpg'
import Header from "../LayoutComponetns/Header.Component";

import BannerAboutUs from '../../img/bannerAboutUs.png';

const About = () => {
    document.title = "| About";
    return (
        <div className={'Abb'}>
            <Header
                backgroundImg={BannerAboutUs}
                title={'About us'}
                showLocation={false}
            />
            <div className={'AboutPage'}>
                <div className="container-fluid">
                    <div className="row justify-content-center text-center">
                        <div className="col-xl-6 col-lg-9">
                            <div className="WhoWe">
                                <h1><img src={about} alt=""/>Who we are</h1>
                                <p>
                                    MeaMeal was born in Hollywood by popular demand.
                                    We are dedicated to provide our customers a reliable and speedy food delivery from our local favorite restaurants.
                                    We believe food delivery is more than just delivery.
                                    As soon as the food is ready it goes straight into our driver’s insulated bag and on the way to your table.
                                    Our drivers are hand picked to provide professional customer service.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="AboutBg">
                    <div className="container-fluid">
                        <div className="row justify-content-center">
                            <div className="col-xl-4 col-lg-5 offset-xl-2 offset-lg-1">
                                <div className="WeDeliver">
                                    <h3><img src={about} alt=""/>We deliver</h3>
                                    <p>
                                        What separates MeaMeal from the rest is our approach to each order.
                                        We believe human factor is vital for the food industry.
                                        Just give us a try and see for yourself!
                                    </p>
                                </div>
                            </div>
                            <div className="col-xl-5 col-lg-5 offset-xl-1 pr-xl-0">
                                <div className="AboutImg">
                                    <img src={aboutImg} alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            
            </div>
        </div>
    );
};

export default About;
