import React from "react";
import {Form, Input, Button, Col, Row, Select} from "antd4";
import {MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";

const rules = [{required: true}];
const {Option} = Select;

const ProductsFormList = () => (
		<div className={"col-lg-4"}>
				<div className={"FieldSet"} data-title="Products">
						
						<Form.List name="products">
								{(fields, {add, remove}) => {
										return (
												<div>
														{fields.map((field, index) => (
																<Row key={field.key}>
																		<Col flex="none">
																				<MinusCircleOutlined
																						className="dynamic-delete-button"
																						onClick={() => {
																								remove(field.name);
																						}}
																				/>
																		</Col>
																		<Col>
																				<Form.Item
																						label='Count'
																						name={[field.name, "count"]}
																						fieldKey={[field.fieldKey, "count"]}
																						rules={[
																								{
																										required: true,
																										message: "Please input count"
																								}
																						]}
																				>
																						<Input placeholder="Count"/>
																				</Form.Item>
																		</Col>
																		<Col>
																				<Form.Item
																						label="Discount %"
																						name={[field.name, "discount"]}
																						fieldKey={[field.fieldKey, "discount"]}
																						rules={rules}
																				>
																						<Input placeholder="Discount %"/>
																				</Form.Item>
																		</Col>
																		
																		<Col>
																				<Form.Item name={[field.name, "product"]} label="Product"
																									 rules={[{required: true}]}>
																						<Select
																								placeholder="Select a option and change input text above"
																						>
																								<Option value="male">male</Option>
																								<Option value="female">female</Option>
																								<Option value="other">other</Option>
																						</Select>
																				</Form.Item>
																		</Col>
																
																</Row>
														))}
														<Form.Item>
																<Button
																		type="dashed"
																		onClick={() => {
																				add();
																		}}
																		style={{width: "100%"}}
																>
																		<PlusCircleOutlined/> Add Ingredient Group
																</Button>
														</Form.Item>
												</div>
										);
								}}
						</Form.List>
				</div>
		</div>
);

export default ProductsFormList;

