import Config from "Config";
import {message, notification} from 'antd4';
import {Roles as ServerRoles} from "../enums/Server.Sider.Roles.enum";
import {Redirect} from "react-router-dom";
import Paths from "./Paths";
import React from "react";
import PartnerTabs from "../enums/Partner.Tabs.Enum";
import getStore from "../store/configureStore";
import {logoutUser} from "../actions/userActions";

export const hasRole = (user, roles = []) => {
		if (user && user.id) {
				return roles.some(role => user.roles.includes(role));
		}
		return false;
};

export const hasRoleClient = (role) => {
		return role === ServerRoles.ROLE_CLIENT;
};

export const redirectToRolePage = (location, role) => {
		const homePageUrl = {
				[ServerRoles.ROLE_DRIVER]: Paths.driver,
				[ServerRoles.ROLE_PARTNER]: Paths.basePartner.replace(':tabName', PartnerTabs.orders)
		}
		return <Redirect
				to={{
						pathname: homePageUrl[role],
						state: {from: location}
				}}
		/>
}

export const handleBeforeImgUpload = file => {
		const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
		if (!isJpgOrPng) {
				showMessage(messageType.error, 'You can only upload JPG/PNG file!');
		}
		const isLt2M = file.size / 1024 / 1024 < 6;
		if (!isLt2M) {
				showMessage(messageType.error, 'Image must smaller than 6MB!');
		}
		
		return isJpgOrPng && isLt2M;
};

export function includeScript(src) {
		if (!document.querySelector(`script[src="${src}"]`)) {
				const script = document.createElement("script");
				
				script.src = src;
				script.async = true;
				
				document.body.appendChild(script);
		}
}

export function removeIncludedScript(src) {
		const script = document.querySelector(`script[src="${src}"]`);
		if (script) {
				document.body.removeChild(script)
		}
}

export const formatTime = (date, hourFormat = 24) => {
		if (hourFormat !== 12 && hourFormat !== 24) {
				hourFormat = 24
		}
		
		let hour = date.getHours(),
				minute = date.getMinutes(),
				hourFormatted = hour % hourFormat || hourFormat, // hour returned in 24 hour format
				minuteFormatted = minute < 10 ? "0" + minute : minute,
				morning = hourFormat === 12 ? (hour < 12 ? " AM" : " PM") : "";
		
		return hourFormatted + ":" + minuteFormatted + morning;
};

export const notify = (type, title, desc) => {
		notification[type]({
				message: title,
				description: desc
		});
};

export const onlyNumeric = evt => {
		const keyCode = evt.keyCode || evt.which;
		const keyValue = String.fromCharCode(keyCode);
		if (!(/[0-9]/.test(keyValue))) {
				evt.preventDefault();
		}
};
export const validateEmail = email => {
		return String(email)
				.toLowerCase()
				.match(
						/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
				);
};

export const isMobileDevice = () => window.innerWidth <= 1200; // || window.innerHeight <= 600

export const toFormData = (obj = {}, form = undefined, namespace = undefined) => {
		let fd = form || new FormData();
		let formKey;
		
		for (let property in obj) {
				if (obj.hasOwnProperty(property) && obj[property]) {
						if (namespace) {
								formKey = namespace + '[' + property + ']';
						} else {
								formKey = property;
						}
						
						// if the property is an object, but not a File, use recursivity.
						if (obj[property] instanceof Date) {
								fd.append(formKey, obj[property].toISOString());
						} else if (typeof obj[property] === 'object' && !(obj[property] instanceof File)) {
								toFormData(obj[property], fd, formKey);
						} else { // if it's a string or a File object
								fd.append(formKey, obj[property]);
						}
				}
		}
		
		return fd;
};

export const getErrorsFromResponse = responseData => {
		if (responseData.message) {
				if (
						typeof responseData.message === "object" &&
						!Array.isArray(responseData.message)
				)
						return Object.values(responseData.message);
				return null;
		}
		return null;
};

export const handleResponse = (result, prep = undefined, success = undefined, error = undefined, successMessage = undefined, errorMessage = undefined) => {
		if (prep) prep();
		if (result) {
				if (result.success) {
						if (successMessage) showMessage(messageType.success, successMessage);
						if (success) return success(result.data);
						return result.data;
				} else {
						if(result.code === 500){
							showMessage(messageType.error, getErrorsFromResponse(result));
						}else{
							showMessage(messageType.warning, getErrorsFromResponse(result));
						}
						return false;
				}
		} else {
				if (errorMessage) showMessage(messageType.error, errorMessage);
				if (error) return error();
				return false;
		}
};

export const handleResponseForbidden = (result) => {
		const store = getStore();
		const HTTP_FORBIDDEN = 403
		
		if (result) {
				if (result.success) {
						return result.data
				}
				
				if (result.code === HTTP_FORBIDDEN) {
						store.dispatch(logoutUser())
				}
		}
		
		return false
}

export const phoneNumberFormatter = val => {
		if (!val) return '';
		
		val = val.replace(/[^\d]/g, '')
				.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		
		if (val.length > 14) {
				val = val.substr(0, 14);
		}
		return val;
};

export const showMessage = (type, content, duration = 3) => {
		message[type](content, duration)
};

export const messageType = {
		success: 'success',
		error: 'error',
		info: 'info',
		warning: 'warning',
		warn: 'warn',
		loading: 'loading'
};

export const notiType = {
		success: "success",
		error: "error",
		warning: "warning",
		info: "info"
};

export const Base64ToBlob = base64String => {
		if (!base64String) {
				return Promise.reject(false)
		}
		return fetch(base64String).then(response => response.blob());
};

export const fullPath = (path) => {
		return Config.ServerUrl + path;
};

export const uniqueId = () => {
		var text = "";
		var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		
		for (var i = 0; i < 5; i++) text += possible.charAt(Math.floor(Math.random() * possible.length));
		
		return text;
};

export const addressFormatter = place => {
		if (place && place.place_id) {
				
				const componentForm = {
						street_number: 'short_name',
						route: 'long_name',
						locality: 'long_name',
						administrative_area_level_1: 'short_name',
						country: 'short_name',
						postal_code: 'short_name'
				};
				
				const location = {};
				
				for (let i = 0; i < place.address_components.length; i++) {
						const addressType = place.address_components[i].types[0];
						if (componentForm[addressType]) {
								location[addressType] = place.address_components[i][componentForm[addressType]];
						}
				}
				
				let {lat, lng} = place.geometry.location;
				
				const clientAddress = {
						address: " ",
						city: location.locality,
						state: location.administrative_area_level_1,
						zip: location.postal_code,
						lat: lat instanceof Function ? lat() : lat,
						log: lng instanceof Function ? lng() : lng
				};
				
				if ('formatted_address' in place) {
						clientAddress.address = place.formatted_address
				} else if (place.address_components) {
						clientAddress.address = `${location.street_number} ${location.route}, ${location.locality}, ${location.administrative_area_level_1} ${location.postal_code}, ${location.country}`;
				}
				//TODO check if we need googleApiContent
				// clientAddress.googleApiContent = JSON.stringify(location);
				return clientAddress;
		}
		return {}
};
// End Location & Address

export const cardExpDateFormat = expDate => {
		if (!expDate) return undefined;
		const splitDate = expDate.split('/');
		return '20' + splitDate[1] + '-' + splitDate[0]
}

export const getZipCode = () => {
		const LocationData = JSON.parse(localStorage.getItem('LocationData'));
		return LocationData ? LocationData.zipCode : null
}

export const getAudioState = () => {
		const audioState = localStorage.getItem("AudioState");
		
		return audioState ? JSON.parse(audioState) : true
};

export const addTax = (price) => {
		return price + (price * 9.5) / 100;
}

export const storeOrderDetails = (order) => {
		return new Promise(resolve => {
				if (order && "id" in order && "slug" in order) {
						const restaurants = {};
						let driverStatus = order.driverStatus;
						
						
						order.item.forEach((item, index) => {
								if (order.restaurant.slug in restaurants) {
										restaurants[order.restaurant.slug] = Object.assign({}, restaurants[order.restaurant.slug], {
												products: [item, ...restaurants[order.restaurant.slug].products],
												price: restaurants[order.restaurant.slug].price + item.price
										});
								} else {
										restaurants[order.restaurant.slug] = {
												restaurantShowStatus: order.restaurantShowStatus,
												restaurantStatus: order.restaurantStatus,
												driverStatus: order.driverStatus,
												location: order.restaurant.address,
												locationName: order.restaurant.name,
												locationSlug: order.restaurant.slug,
												locationPhone: order.restaurant.phone,
												restaurantName: order.restaurant.name,
												price: item.price,
												products: [item]
										};
								}
						});
						delete order.item;

						resolve(Object.assign({}, order, {restaurants, driverStatus}))
						
				} else {
						resolve(false)
				}
		})
};

export const getDeviceId = () => {
		let deviceId = localStorage.getItem("DeviceId");
		if(!deviceId){
				deviceId = Date.now();
				localStorage.setItem("DeviceId", deviceId);
				return deviceId;
		}
		return deviceId;
}
