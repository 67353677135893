import React from "react";
import { Form, Input, Button, Col, Row } from "antd4";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

const rules = [{ required: true }];

const IngredientFormList = () => (
  <div className={"col-lg-4"}>
    <div className={"FieldSet"} data-title="Ingredients">

      <Form.List name="ingredients">
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field, index) => (
                <Row key={field.key}>
                  <Col flex="none">
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => {
                        remove(field.name);
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Item
                      label='Name'
                      name={[field.name, "name"]}
                      fieldKey={[field.fieldKey, "name"]}
                      rules={[
                        {
                          required: true,
                          message: "Please input your name"
                        }
                      ]}
                    >
                      <Input placeholder="Name"/>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Selectable Counts"
                      name={[field.name, "selectableCounts"]}
                      fieldKey={[field.fieldKey, "selectableCounts"]}
                      rules={rules}
                    >
                      <Input placeholder="Selectable counts"/>
                    </Form.Item>
                  </Col>

                  {/*Add Ingredient part start*/}

                  <Form.List name={[field.name, "ingredient"]}>
                    {(fields, { add, remove }) => {
                      return (
                        <>
                          {fields.map((field, index) => (
                            <div key={index}>
                              <Col flex="none">
                                <MinusCircleOutlined
                                  className="dynamic-delete-button"
                                  onClick={() => {
                                    remove(field.name);
                                  }}
                                />
                              </Col>
                              <Col>
                                <Form.Item
                                  label="Ingredient name"
                                  name={[field.name, "name"]}
                                  fieldKey={[field.fieldKey, "name"]}
                                  rules={rules}
                                >
                                  <Input placeholder="Ingredient Name"/>
                                </Form.Item>
                              </Col>

                              <Col>
                                <Form.Item
                                  label="Extra Price"
                                  name={[field.name, "plusPrice"]}
                                  fieldKey={[field.fieldKey, "sizeName"]}
                                  rules={rules}
                                >
                                  <Input placeholder="$"/>
                                </Form.Item>
                              </Col>
                            </div>
                          ))}


                          <Form.Item>
                            <Button
                              type="dashed"
                              onClick={() => {
                                add();
                              }}
                              style={{ width: "100%" }}
                            >
                              <PlusCircleOutlined/> Add Ingredient
                            </Button>
                          </Form.Item>

                        </>
                      );
                    }}
                  </Form.List>

                  {/*Add Ingredient part ends*/}
                </Row>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => {
                    add();
                  }}
                  style={{ width: "100%" }}
                >
                  <PlusCircleOutlined/> Add Ingredient Group
                </Button>
              </Form.Item>
            </div>
          );
        }}
      </Form.List>
    </div>

  </div>
);

export default IngredientFormList;

