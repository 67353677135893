import React from "react";
import {Modal} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";

class PartnerPublicView extends React.Component {
		
		render() {
				const {visible, restaurant} = this.props;
				const iframeLink = 'https://meameal.com/restaurant/' + restaurant.slug + '/' + restaurant.slug;
				return (
						<Modal
								title="Edit Profile"
								className="edit_profile"
								visible={visible}
								footer={false}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: false});
								}}>
								<iframe style={{width: '100%', height: '100%'}} src={iframeLink} title="Iframe Example">
								</iframe>
						</Modal>
				);
		}
}


PartnerPublicView.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired,
};

PartnerPublicView.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		restaurant: null,
};

export default connect(
		state => ({restaurant: state.partnerRestaurant}),
		{showLoading}
)(PartnerPublicView);
