import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import Modal from "../../HelpersComponents/Modal.Component";
import full_screen from "../../../img/full_screen.jpg";
import min_screen from "../../../img/min_screen.jpg";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Button, Col, Form, InputNumber, Radio, Row} from "antd4";
import {
		updatePartnerSettings
} from "../../../services/Partner.Service";
import newOrderSound1 from "../../../sounds/sound-1.wav";
import newOrderSound2 from "../../../sounds/sound-2.mp3";
import newOrderSound3 from "../../../sounds/sound-3.mp3";
import newOrderSound4 from "../../../sounds/sound-4.mp3";
import {updatePartnerRestaurant} from "../../../actions/partnerActions";

class PartnerSettings extends React.Component {
		handleSubmit = values => {
				const {restaurant} = this.props;
				values.id = restaurant.id;
				
				this.props.showLoading(true);
				updatePartnerSettings(values).then(result => {
						if (result) {
								this.props.onVisibleChange({show: false});
								this.props.updatePartnerRestaurant(result);
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		changeSound = event => {
				const e = event.target.value;
				let newOrderNotify = new Audio(newOrderSound1);
				
				if (e === 1) {
						newOrderNotify = new Audio(newOrderSound1);
				} else if (e === 2) {
						newOrderNotify = new Audio(newOrderSound2);
				} else if (e === 3) {
						newOrderNotify = new Audio(newOrderSound3);
				} else if (e === 4) {
						newOrderNotify = new Audio(newOrderSound4);
				}
				newOrderNotify.play();
		};
		
		render() {
				const {visible, restaurant, settings} = this.props;
				console.log(restaurant, settings)
				
				return (
						<Modal
								title="Settings"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleChange({show: false});
								}}
								footer={false}
						>
								<Form onFinish={this.handleSubmit} name={"partnerSettingsForm"}>
										<Tabs style={{height: "100%"}}>
												<TabList>
														<Tab>Order Settings</Tab>
														<Tab>Printer</Tab>
														<Tab>Test Order</Tab>
												</TabList>
												
												<TabPanel>
														
														<Row gutter={16}>
																<Col>
																		<div className={"FieldSet"} data-title="Basic information">
																				<div className="GlobalInput" style={{marginTop: "15px"}}>
																						<h6>Default estimates</h6>
																						<p>
																								Please enter your default prep time. You can also adjust the prep time
																								for each order after
																								accepting.
																						</p>
																						<Form.Item
																								label={"Pickup order estimate (default prep time)"}
																								name={"pickupOrderEstimate"}
																								colon={false}
																								rules={[{
																										required: true,
																										message: "Please input Pickup order estimate!"
																								}]}
																								initialValue={settings ? settings.pickupOrderEstimate : ''}
																						>
																								<InputNumber
																										style={{width: "100%"}}
																										parser={value => value.replace(/\$\s?|(,*)/g, "")}
																										placeholder="10 min"
																										min={5}
																										step={5}/>
																						</Form.Item>
																						<Form.Item
																								label={"Default order estimate (default prep time)"}
																								colon={false}
																								name={"deliveryOrderEstimate"}
																								initialValue={restaurant.deliveryOrderEstimate}
																						>
																								<InputNumber
																										style={{width: "100%"}}
																										parser={value => value.replace(/\$\s?|(,*)/g, "")}
																										placeholder="10 min"
																								/>
																						</Form.Item>
																				</div>
																		
																		</div>
																</Col>
														</Row>
														
														<Form.Item>
																<div style={{padding: "40px 8px 0"}}>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</div>
														</Form.Item>
												</TabPanel>
												
												<TabPanel>
														<Row gutter={16}>
																<Col>
																		<div className={"FieldSet"} data-title="Basic information">
																				<div className="GlobalInput" style={{marginTop: "20px"}}>
																						<h6>Receipt printer</h6>
																						<Button disabled={true} style={{width: "100%", marginBottom: "10px"}}>
																								No printer connected
																						</Button>
																						<Button className={"ant-btn ant-btn-primary"} style={{width: "100%"}}>
																								Add printer
																						</Button>
																				</div>
																				<div className="GlobalInput" style={{marginTop: "20px"}}>
																						<h6>Auto-print</h6>
																						<p>Enable auto-printing if you would like the orders to be automatically
																								printed.</p>
																						<Form.Item
																								initialValue={settings ? settings.autoPrint : false}
																								name={"autoPrint"}>
																								<Radio.Group value={settings ? settings.autoPrint : false}>
																										<Radio selected={true} value={true}>yes</Radio>
																										<Radio selected={false} value={false}>No</Radio>
																								</Radio.Group>
																						</Form.Item>
																				</div>
																		</div>
																</Col>
														</Row>
														<Form.Item>
																<div style={{padding: "40px 8px 0"}}>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</div>
														</Form.Item>
												</TabPanel>
												<TabPanel>
														<Row gutter={16}>
																<Col>
																		<div className={"FieldSet"} data-title="Basic information">
																				<div className="GlobalInput" style={{marginTop: "20px"}}>
																						<h6>New order alerts</h6>
																						<p>How would you like your notifications to show</p>
																						
																						<div style={{
																								display: 'flex',
																								width: '70px',
																								marginLeft: '40px'
																						}}>
																								<img style={{width: '100%'}} src={full_screen} alt=""/>
																								<img style={{width: '100%', marginLeft: '70px'}} src={min_screen}
																										 alt=""/>
																						</div>
																						
																						<Form.Item
																								initialValue={settings ? settings.typeOrderAlert : 1}
																								name={"typeOrderAlert"}>
																								<Radio.Group value={settings ? settings.typeOrderAlert : 1}>
																										<Radio selected={1} value={1}>
																												Full screen + standard
																										</Radio>
																										<Radio selected={2} value={2}>
																												Standard only</Radio>
																								</Radio.Group>
																						</Form.Item>
																				</div>
																				<div className="GlobalInput" style={{marginTop: "20px"}}>
																						<h6>New order sound</h6>
																						<Form.Item
																								initialValue={settings ? settings.typeOrderSound : 1}
																								name={"typeOrderSound"}>
																								<Radio.Group value={settings ? settings.typeOrderSound : 1}
																														 onChange={e => this.changeSound(e)}>
																										<Radio selected={1} value={1}>Sound 1</Radio>
																										<Radio selected={2} value={2}>Sound 2</Radio>
																										<Radio selected={3} value={3}>Sound 3</Radio>
																										<Radio selected={4} value={4}>Sound 4</Radio>
																								</Radio.Group>
																						</Form.Item>
																				</div>
																		</div>
																</Col>
														</Row>
														<Form.Item>
																<div style={{padding: "40px 8px 0"}}>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</div>
														</Form.Item>
												</TabPanel>
										</Tabs>
								</Form>
						</Modal>
				);
		}
}


PartnerSettings.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired
};

PartnerSettings.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		updatePartnerRestaurant: f => f,
};

export default connect(
		state => ({
				restaurant: state.partnerRestaurant,
				settings: state.partnerRestaurant.settings,
		}),
		{showLoading, updatePartnerRestaurant}
)(PartnerSettings);
