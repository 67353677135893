import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {withRouter} from "react-router";

import Paths from "../../services/Paths";
import PropTypes from "prop-types";
import logo from "../../img/Logo.svg";
import notification from "../../img/ic_notification.svg";
import cart from "../../img/ic_kart.svg";
import {ReactComponent as Search} from "../../img/ic_search.svg";
import {ReactComponent as Close} from "../../img/ic_close.svg";

import {showLeftMenu, showModal} from "../../actions/baseActions";
import {resetFilter} from "../../actions/filterActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import NavSignIn from "./PartialsComponents/Nav.SignIn.Component";
import facebook from "../../img/ic_facebook footer.svg";
import instagram from "../../img/ic_instagram_footer.svg";
import youtube from "../../img/ic_youtube_footer.svg";
import {Spin} from "antd4";
import {searchItemsByText, searchProductsByText} from "../../services/Product.Service";
import Img from "../HelpersComponents/Img.Component";
import {hasRole, isMobileDevice} from "../../services/Utilities";
import {Roles as ServerRoles} from "../../enums/Server.Sider.Roles.enum";
import {logoutUser} from "../../actions/userActions";
import Scroll from "../../services/Scroll";
import {getRestaurantDetails} from "../../services/Restaurant.Service";

const SEARCH_MIN_LENGTH = 2;

class Nav extends Component {
		constructor(props) {
				super(props);
				
				this.state = {
						backgroundColor: "rgba(243, 50, 0, 0)",
						className: "",
						menuMobile: true,
						isMobile: isMobileDevice(),
						searchProducts: [],
						searchRestaurants: [],
						searchVisible: false,
						searchLoading: false,
						searchValue: ""
				};
				this.search = React.createRef();
		}
		
		componentDidMount() {
				this.props.history.listen(() => {
						const {isMobile, menuMobile} = this.state;
						
						this.setState({
								menuMobile: isMobile ? false : menuMobile,
								searchProducts: [],
								searchRestaurants: [],
								searchVisible: false,
								searchLoading: false,
								searchValue: ""
						});
						this.props.showLeftMenu(false);
				});
				window.addEventListener("scroll", this.handleScroll, {passive: true});
				window.addEventListener("resize", this.handleResize);
				window.addEventListener("click", this.handleOutsideClick);
				
				if (this.state.isMobile) {
						document.addEventListener("filterUpdate", this.handleFilterChange);
				}
				
				this.handleScroll();
				this.handleResize();
		}
		
		handleFilterChange = () => {
				this.props.showLeftMenu(false);
		};
		
		componentWillUnmount() {
				window.removeEventListener("scroll", this.handleScroll);
				window.removeEventListener("resize", this.handleResize);
				window.addEventListener("click", this.handleOutsideClick);
				
				document.removeEventListener("filterUpdate", this.handleFilterChange);
		}
		
		handleClick = evt => {
				evt.stopPropagation();
				const {isMobile, menuMobile} = this.state;
				
				if (isMobile) {
						this.setState({
								menuMobile: !menuMobile
						});
				}
		};
		
		sidebarOpen = evt => {
				evt.stopPropagation();
				const {sidebarOpen} = this.state;
				this.setState({
						sidebarOpen: !sidebarOpen
				});
		};
		
		handleOutsideClick = e => {
				const {searchVisible, isMobile} = this.state;
				// ignore clicks on the component itself
				
				if (isMobile) {
						if (this.node && !this.node.contains(e.target)) {
								this.setState({menuMobile: false});
								this.setState({sidebarOpen: false});
						}
				}
				
				if (searchVisible) {
						if (!(this.search.contains(e.target) || this.searchList.contains(e.target))) {
								this.setState({
										searchVisible: false,
										searchValue: ""
								});
						}
				}
		};
		
		handleResize = () => {
				if (window.innerWidth < 1200) {
						this.setState({
								menuMobile: false,
								isMobile: true
						});
				} else {
						this.setState({
								menuMobile: true,
								isMobile: false
						});
				}
		};
		
		handleScroll = () => {
				let scrollTop = window.scrollY / 100;
				
				if (scrollTop >= 1 && this.state.className !== "scrolled") {
						this.setState({
								backgroundColor: "rgba(255, 255, 255, 1)",
								className: "scrolled"
						});
				} else if (scrollTop < 1) {
						this.setState({
								backgroundColor: `rgba(255, 255, 255, ${scrollTop})`,
								className: ""
						});
				}
		};
		
		searchBarOpenClose = () => {
				const {showLeftMenu, leftMenuVisible} = this.props;
				const {searchVisible, isMobile, searchValue} = this.state;
				
				if (isMobile) {
						showLeftMenu(!leftMenuVisible);
				}
				if (searchValue) {
						this.setState({
								searchValue: "",
								searchVisible: searchVisible ? false : searchVisible
						});
				}
		};
		
		getPartnerButton = () => {
				const {isAuth, user} = this.props;
				if (isAuth) {
						if (hasRole(user, [ServerRoles.ROLE_PARTNER])) {
								return (
										''
								);
						} else if (hasRole(user, [ServerRoles.ROLE_DRIVER])) {
								return (
										<li>
												<Link to={Paths.driver}>Driver</Link>
										</li>
								);
						}
				}
				return "";
		};
		
		getCloseOpen = (e) => {
				const {menuMobile} = this.state;
				const {isAuth, user, partnerRestaurant} = this.props;
				const {openNow} = partnerRestaurant;
				
				if (isAuth && hasRole(user, [ServerRoles.ROLE_PARTNER])) {
						if (e) {
								if (menuMobile) {
										return (
												<ul>
														{openNow && <li className={"isRestaurantOpen open_branch"}><a href="/partner/stopTakingOrders">OPEN</a></li>}
														{!openNow && <li className={"isRestaurantOpen close_branch"}><a href="/partner/stopTakingOrders">CLOSE</a></li>}
												</ul>
										)
								} else {
										return (
												''
										)
								}
						} else {
								if (!menuMobile) {
										return (
												<ul>
														{openNow && <li className={"open_branch"}><a href="/partner/stopTakingOrders">OPEN</a></li>}
														{!openNow && <li className={"close_branch"}><a href="/partner/stopTakingOrders">CLOSE</a></li>}
												</ul>
										)
								} else {
										return (
												''
										)
								}
						}
						
				}
				return "";
		};
		
		getAnimationClassName = () => {
				const {isAddressSet} = this.props;
				return !isAddressSet && this.props.location.pathname !== Paths.about && this.props.location.pathname !== Paths.partnerUs && this.props.location.pathname !== Paths.delivery ? "dodo" : "";
		};
		
		handleSearchChange = evt => {
				if (evt.target.value.length > SEARCH_MIN_LENGTH) {
						
						this.setState({
								searchLoading: true,
								searchValue: evt.target.value
						});
						
						searchItemsByText(evt.target.value).then(data => {
								if (data) {
										this.setState({
												searchVisible: true,
												searchProducts: data.products,
												searchRestaurants: data.restaurants,
										});
								}
						}).finally(() => {
								this.setState({searchLoading: false});
						});
				} else {
						this.setState({
								searchVisible: false,
								searchValue: evt.target.value
						});
				}
		};
		
		getHomePageUrl = () => {
				const {user} = this.state;
				
				if (hasRole(user, ServerRoles.ROLE_PARTNER)) {
						return Paths.partnerOrders;
				} else if (hasRole(user, ServerRoles.ROLE_DRIVER)) {
						return Paths.driver;
				}
				return Paths.home;
		}
		
		render() {
				const {
						basketCount,
						leftMenuVisible,
						showModal,
						currentLocation,
						user,
						isAuth,
						logoutUser,
						history
				} = this.props;
				
				const {
						searchValue,
						className,
						menuMobile,
						searchProducts,
						searchRestaurants,
						searchVisible,
						searchLoading,
						isMobile
				} = this.state;
				
				const showFullNavbar = !isAuth || (user.roles && user.roles[0] === ServerRoles.ROLE_CLIENT);
				return (
						<nav
								className={currentLocation.address ? `Nav ${className} ${this.getAnimationClassName()}` : `Nav ${className}`}
								style={{backgroundColor: 'rgb(255 255 255)'}}>
								{showFullNavbar &&
								<div className={"CartIcon NotCount d-block d-xl-none"} data-count={basketCount}
										 onClick={() => showModal(ModalTypes.BASKET_MODAL)}>
										<img src={cart} alt="cart"/>
								</div>
								}
								
								<ul className={leftMenuVisible ? "SearchFood open" : "SearchFood"}>
										{showFullNavbar &&
										<li>
												{leftMenuVisible || searchValue ?
														<Close
																onClick={this.searchBarOpenClose}
																className={"SearchClose"}
														/>
														: <Search
																onClick={this.searchBarOpenClose}
																className={"SearchClose"}
														/>}
												
												<input
														type="search"
														ref={ref => this.search = ref}
														placeholder={"Search food..."}
														name={"foodSearch"}
														onChange={this.handleSearchChange}
														value={searchValue}
												/>
												{searchVisible && (
														<ul ref={ref => this.searchList = ref}>
																<Spin spinning={searchLoading}>
																		{(searchProducts.length || searchRestaurants.length) ? (
																				<React.Fragment>
																						{searchProducts.slice(0, 5).map(item => (
																								<li key={item.slug}
																										onClick={() => showModal(ModalTypes.ITEM_DETAILS_MODAL, true, {productSlug: item.slug})}>
																										<Img src={item.downloadLink} alt=""/>{item.name}</li>
																						))}
																						{searchRestaurants.slice(0, 4).map(item => (
																								<li key={item.slug}
																										onClick={() => history.push(Paths.restaurant.replace(':restaurantSlug', item.slug))}>
																										<Img src={item.downloadLink} alt=""/>{item.name}</li>
																						))}
																				</React.Fragment>
																		) : (
																				<li>No search result ...</li>
																		)}
																</Spin>
														</ul>
												)}
										</li>
										}
								</ul>
								
								<ul className={"Logo"}>
										<li>
												<Link
														to={this.getHomePageUrl}
														onClick={() => {
																if (this.props.history.location.pathname === Paths.home) {
																		Scroll.scrollToStartOfPage();
																}
														}}
												>
														<img src={logo} alt=""/>
												</Link>
										</li>
								</ul>
								{this.getCloseOpen(false)}
								
								<ul className={menuMobile ? "Menu" : "d-none"} ref={node => {
										this.node = node;
								}}>
										<li className={"NotCount d-none d-xl-block hoverable"} data-count={"0"}
												onClick={() => showModal(ModalTypes.NOTIFICATION_MODAL)}>
												<img src={notification} alt="notification"/>
										</li>
										{showFullNavbar &&
										<li className={"CartIcon NotCount d-none d-xl-block hoverable"} data-count={basketCount}
												onClick={() => showModal(ModalTypes.BASKET_MODAL)}>
												<img src={cart} alt="cart"/>
										</li>
										}
										{this.getPartnerButton()}
										{this.getCloseOpen(true)}
										<NavSignIn/>
										{isAuth && isMobile &&
										<li>
												<Link to="#" onClick={logoutUser}>Log out</Link>
										</li>
										}
										<li>
												<ul className={"SocialUl d-block d-xl-none"}>
														<li><Link to={"#"}><img src={facebook} alt="facebook"/></Link></li>
														<li><Link to={"#"}><img src={instagram} alt="instagram"/></Link></li>
														<li><Link to={"#"}><img src={youtube} alt="youtube"/></Link></li>
												</ul>
										</li>
								</ul>
								
								
								<div className={menuMobile ? "MobileMenu active d-block d-xl-none" : "MobileMenu d-block d-xl-none"}
										 onClick={this.handleClick}>
										<span/>
								</div>
						
						</nav>
				);
				// }
		}
}

Nav.propTypes = {
		showModal: PropTypes.func.isRequired,
		basketCount: PropTypes.number.isRequired,
		leftMenuVisible: PropTypes.bool.isRequired,
		isAddressSet: PropTypes.bool.isRequired,
		currentLocation: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
		basketCount: state.basket.productCount,
		leftMenuVisible: state.leftMenuVisible,
		currentLocation: state.currentLocation.address,
		isAuth: state.user.isAuth,
		user: state.user.data,
		isAddressSet: state.currentLocation.isAddressSet,
		isCatering: state.isCatering,
		partnerRestaurant: state.partnerRestaurant
});

const mapDispatchToProps = {
		showModal,
		showLeftMenu,
		resetFilter,
		logoutUser
};

export default withRouter(
		connect(
				mapStateToProps,
				mapDispatchToProps
		)(Nav)
);
