import React from 'react'

import Panel from "../HelpersComponents/Panel.Component";
import {getClientOrders, clearClientOrders} from '../../actions/userActions';
import PropTypes from "prop-types";

import {connect} from "react-redux";
import RectangleOrderComponent from "../HelpersComponents/ProductsComponents/Rectangle.Order.Component";
import numeral from "numeral";
import moment from "moment";
import {isMobileDevice} from "../../services/Utilities";
import OrderStatusNumeral from "../../enums/Order.Status.Enum";
import {showModal} from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";

class AccountOrderTab extends React.Component {
		isMobile = isMobileDevice();
		state = {
				offset: 1,
				limit: 20,
				end: false
		}
		
		UNSAFE_componentWillMount() {
				this.props.getClientOrders(this.state.offset, this.state.limit)
				document.addEventListener('scroll', this.trackScrolling);
		}
		
		componentWillUnmount() {
				this.props.clearClientOrders()
				document.removeEventListener('scroll', this.trackScrolling);
		}
		
		onReviewClick = orderSlug => {
				this.props.showModal(ModalTypes.CUSTOMER_FEEDBACK_MODAL, true, {orderSlug});
		}
		
		render() {
				const {orders} = this.props;
				return (
						<div id={'AccountOrderPanel'} className={'AccountOrderPanel'}>
								{orders.map((order, indexKey) => {
										const restaurantName = order.restaurant ? order.restaurant.name : '',
												address = order.location && order.location.address,
												allowRate = order.status === OrderStatusNumeral.IS_DONE;
										return (
												<Panel
														key={indexKey}
														className={"PopularPanel"}
														allowRate={allowRate}
														onReviewClick={allowRate && (() => this.onReviewClick(order.slug))}
														title={`Order #${order.id} - ${numeral(order.totalPrice).format('$ 0,0[.]00')}`}
														contentScrollable={false}
														description={`${address ? address : restaurantName} ∙ ${moment(order.created).format('DD MMMM YYYY, LT')}`}
												>
														{order.item && order.item.map((item, index) => {
																const isCatering = 'catering' in item;
																return (
																		<RectangleOrderComponent
																				key={index}
																				imgPath={isCatering ? item.catering.downloadLink : item.product.downloadLink}
																				productName={isCatering ? item.catering.name : item.product.name}
																				productSlug={isCatering ? item.catering.slug : item.product.slug}
																				price={item.price}
																				description={!isCatering && item.product.description}
																				modalProps={{
																						id: item.id,
																						userAddress: address,
																						restaurantName: restaurantName
																				}}
																		/>
																)
														})}
												</Panel>
										)
								})}
						</div>
				)
		}
		
		isBottom(el) {
				return el.getBoundingClientRect().bottom - (this.isMobile ? 700 : 1100) <= window.innerHeight;
		}
		
		trackScrolling = event => {
				event.preventDefault();
				const wrappedElement = document.getElementById('AccountOrderPanel');
				if (wrappedElement && this.isBottom(wrappedElement)) {
						const {offset, end, limit} = this.state;
						const {loading} = this.props;
						if (!end && !loading) {
								this.setState(
										{
												offset: offset + 1,
												loading: true
										},
										() => this.props.getClientOrders(offset + 1, limit).then(result => {
												if (result && result.length < limit) {
														this.setState({end: true})
												}
										}))
						}
				}
		};
}

AccountOrderTab.propTypes = {
		orders: PropTypes.array,
};

const mapStateToProps = state => ({
		orders: state.clientOrders,
		loading: state.loading,
});

const mapDispatchToProps = {
		getClientOrders,
		clearClientOrders,
		showModal
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(AccountOrderTab);

