import * as OrderApi from "../api/Order.API";

import { handleResponse, toFormData } from "./Utilities";

export const sendCustomerFeedback = (model, orderSlug) => {
  return OrderApi.sendCustomerFeedback(model, orderSlug).then(result => handleResponse(
    result,
    undefined,
    () => (result.data)
  ));
};

export const getAlreadySentCustomerFeedback = orderSlug => {
  return OrderApi.getAlreadySentCustomerFeedback(orderSlug).then(result => handleResponse(
    result,
    undefined,
    () => (result.data)
  ));
};

export const getCouponData = (code, restaurantSlug) => {
  return OrderApi.getCouponData(code, restaurantSlug).then(result => handleResponse(
    result,
    undefined,
    () => (result.data)
  ));
};
