import React, {Component} from "react";
import {withRouter} from "react-router";
import {showLoading, showModal} from "../../../actions/baseActions";
import {Button, Rate, Table, Tag} from "antd4";
import {connect} from "react-redux";
import ReviewShow from "./ShowReview/Review.Show.Modal.Component";
import {blockPartnerReview, getPartnerReviews, getPartnerReview} from "../../../services/Partner.Service";
import moment from "moment";

class PartnerReview extends Component {
		state = {
				selectedReview: null,
				reviews: [],
				visible: false
		};
		
		TableColumns = [
				{
						title: "Author name",
						key: "user",
						dataIndex: "user",
						render: (value, record) => {
								if (!record.isSeen) {
										return <span style={{fontWeight: '800'}}>{value.clientFullName}</span>;
								}
								return value.clientFullName;
						},
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.clientFullName === b.clientFullName) ? 0 : a.clientFullName ? -1 : 1
				},
				{
						title: "Comment",
						key: "opinion",
						dataIndex: "opinion",
						render: (value, record) => {
								if (!record.isSeen) {
										return <span style={{fontWeight: '800'}}>{value}</span>;
								}
								return value;
						},
						sortDirections: ["descend", "ascend"],
				},{
						title: "answer",
						key: "answer",
						dataIndex: "answer",
						render: (value, record) => {
								if (!record.isSeen) {
										return <span style={{fontWeight: '800'}}>{value}</span>;
								}
								return value;
						},
						sortDirections: ["descend", "ascend"],
				},
				{
						title: "Rate",
						key: "foodRate",
						dataIndex: "foodRate",
						render: value => (
								<Rate disabled style={{fontSize: '21px'}} value={value}/>
						),
						sortDirections: ["descend", "ascend"],
				},
				{
						title: "Order",
						key: "order",
						dataIndex: "order",
						render: value => (
								<Tag color={"blue"}>
										# {value.id}
								</Tag>
						),
						sortDirections: ["descend", "ascend"],
				},
				{
						title: "Blocked",
						key: "isBlock",
						dataIndex: "isBlock",
						render: isBlock => (
								isBlock ? (
										<Tag color={"red"}>
												Blocked
										</Tag>
								) : ''
						)
				}, {
						title: "Created",
						key: "created",
						dataIndex: "created",
						render: value => (
								<span>{moment(value).format('DD MMMM YYYY, LT')}</span>
						)
				},
				{
						title: "Action",
						key: "action",
						render: record => (
								<div>
										<Button onClick={() => {
												this.showAndAnswer(record);
										}}>
												Show & Answer
										</Button>
										{record.isBlock ? (
												<Button type={"default"} color={'green'} style={{color: "green"}}
																onClick={() => this.blockReview(record.id, false)}>
														Unblock
												</Button>
										) : (
												<Button type={"default"} danger={true} onClick={() => this.blockReview(record.id, true)}>
														Block
												</Button>
										)}
								</div>
						)
				}
		];
		
		UNSAFE_componentWillMount() {
				this.props.showLoading(true);
				
				getPartnerReviews().then(data => {
						if (data && 'reviews' in data) {
								this.setState({reviews: data.reviews});
								return data.reviews;
						}
				}).finally(() => {
						this.props.showLoading(false);
				})
		}
		
		blockReview = (reviewId, isBlock) => {
				blockPartnerReview(reviewId, isBlock).then(result => {
						if (result && 'review' in result) {
								const {reviews} = this.state;
								
								reviews.forEach(function (review) {
										if (review.id === result.review.id) {
												Object.assign(review, result.review)
										}
								});
								this.setState({reviews})
						}
				})
		}
		
		showAndAnswer = selectedReview => {
				this.props.showLoading(true);
				getPartnerReview(selectedReview.id).then(result => {
						if (result && 'review' in result) {
								const {reviews} = this.state;
								
								reviews.forEach(function (review) {
										if (review.id === result.review.id) {
												Object.assign(review, result.review)
										}
								});
								
								this.setState({
										reviews: reviews,
										selectedReview: result.review,
										visible: true
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				})
		};
		
		onClosePopup = visible => {
				this.setState({visible: visible});
		};
		
		onReviewAnswer = review => {
				if (review) {
						const {reviews} = this.state;
						
						reviews.forEach(function (item) {
								if (item.id === review.id) {
										Object.assign(item, review)
								}
						});
						
						this.setState({
								reviews: reviews,
								selectedReview: null,
								visible: false
						});
				}
		};
		
		render() {
				
				const {reviews, visible, selectedReview} = this.state;
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Restaurant reviews</h1>
								</section>
								<section className={"ContentBody"}>
										<div>
												<Table
														columns={this.TableColumns}
														rowClassName={(record, index) => !record.isSeen ? 'bg-yellow' : ''}
														dataSource={reviews}
														rowKey={"id"}
														size={"middle"}
														pagination={{
																defaultPageSize: 25
														}}
												/>
												<ReviewShow
														visible={visible}
														selectedReview={selectedReview}
														onClosePopup={this.onClosePopup}
														onReviewAnswer={this.onReviewAnswer}
												>
												</ReviewShow>
										</div>
								</section>
						</div>
				
				);
		}
}

export default withRouter(connect(
		() => ({}),
		{showLoading, showModal}
)(PartnerReview));
