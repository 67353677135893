import React from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";

import Modal from "../HelpersComponents/Modal.Component";
import {showLocalLoading, showModal} from "../../actions/baseActions";
import {updateCustomerOrderProperty} from '../../actions/orderActions'
import ModalTypes from "../../enums/Modal.Types.Enum";
import {Button, Form, Rate, Spin} from "antd4";
import {getAlreadySentCustomerFeedback, sendCustomerFeedback} from "../../services/Order.Service";
import {sendClientWssMessage} from "../../Wss/Client.WSS.Connection";
import TextArea from "antd4/lib/input/TextArea";
import WssAction from "../../enums/WSS.Action.Enum";

class CustomerFeedbackModal extends React.Component {
		constructor(props) {
				super(props);
				
				this.state = {
						id: null,
						driverRate: 0,
						serviceRate: 0,
						foodRate: 0,
						opinion: ''
				}
		}
		
		handleSubmit = model => {
				const {modalProps} = this.props;
				const {id, driverRate, serviceRate, foodRate, opinion} = this.state;
				
				if ('driverRate' in model && 'serviceRate' in model && 'foodRate' in model && 'orderSlug' in modalProps) {
						model = Object.assign({id, driverRate, serviceRate, foodRate, opinion}, model)
						this.props.showLocalLoading(true);
						
						sendCustomerFeedback(model, modalProps.orderSlug)
								.then(customerOpinionModel => {
										if (customerOpinionModel) {
												// if ('id' in model && !model.id) {
												// 		sendClientWssMessage({
												// 				action: WssAction.NEW_CUSTOMER_OPINION,
												// 				slug: modalProps.orderSlug,
												// 				data: customerOpinionModel
												// 		});
												// }

												this.props.updateCustomerOrderProperty(modalProps.orderSlug, 'userOpinion', customerOpinionModel);
												this.props.showModal(ModalTypes.CUSTOMER_FEEDBACK_MODAL, false);
										}
								}).finally(() => {
										this.props.showLocalLoading(false);
								});
				}
		}
		
		handleValuesChange = value => {
				const {id, driverRate, serviceRate, foodRate, opinion} = this.state;
				this.setState(Object.assign({id, driverRate, serviceRate, foodRate, opinion}, value))
		}
		
		UNSAFE_componentWillMount() {
				const {modalProps} = this.props;
				if ('orderSlug' in modalProps) {
						showLocalLoading(true);
						
						getAlreadySentCustomerFeedback(modalProps.orderSlug).then(result => {
								if (result && 'feedback' in result && 'id' in result.feedback) {
										
										const {feedback} = result;
										this.setState({
												id: feedback.id,
												driverRate: feedback.driverRate,
												serviceRate: feedback.serviceRate,
												foodRate: feedback.foodRate,
												opinion: feedback.opinion
										})
								}
						}).finally(() => showLocalLoading(false))
				}
		}
		
		render() {
				const {driverRate, serviceRate, foodRate, opinion} = this.state;
				const {showModal, customerFeedbackModalVisible, localLoading} = this.props;
				
				return (
						<div>
								<Modal
										className={"CustomerFeedback"}
										visible={customerFeedbackModalVisible}
										onCancel={() => showModal(ModalTypes.CUSTOMER_FEEDBACK_MODAL, false)}
								>
										<Spin spinning={localLoading}>
												<div className={'CustomerFeedbackTitle'}>
														<div>
																Your Opinion matter to us!
														</div>
												</div>
												<div className={'CustomerFeedbackContent'}>
														<Form onFinish={this.handleSubmit}
																	className={"CustomerFeedbackForm"}
																	fields={[
																			{name: "driverRate", value: driverRate},
																			{name: "serviceRate", value: serviceRate},
																			{name: "foodRate", value: foodRate},
																			{name: "opinion", value: opinion},
																	]}
														
																	onValuesChange={this.handleValuesChange}
														>
																<div className="feedback-item">
																		<div className="center">
																				<p className={'feedback-sub-title'}>How was quality of the Delivery?</p>
																		</div>
																		<div style={{textAlign: 'center'}}>
																				<Form.Item
																						label={false}
																						name="driverRate"
																						rules={[{required: true}]}
																				>
																						<Rate style={{fontSize: '50px'}}/>
																				</Form.Item>
																		</div>
																</div>
																<div className="feedback-item">
																		<div className="center">
																				<p className={'feedback-sub-title'}>How was quality of the Service?</p>
																		</div>
																		<div style={{textAlign: 'center'}}>
																				<Form.Item
																						label={false}
																						name="serviceRate"
																						rules={[{required: true}]}
																				>
																						<Rate style={{fontSize: '50px'}}/>
																				</Form.Item>
																		</div>
																</div>
																<div className="feedback-item">
																		<div className="center">
																				<p className={'feedback-sub-title'}>How was quality of the Food?</p>
																		</div>
																		<div style={{textAlign: 'center'}}>
																				<Form.Item
																						label={false}
																						name="foodRate"
																						rules={[{required: true}]}
																				>
																						<Rate style={{fontSize: '50px'}}/>
																				</Form.Item>
																		</div>
																</div>
																<div className="feedback-item">
																		<div className="FeedbackMessageContainer">
																				<Form.Item
																						label={false}
																						name="opinion"
																						rules={[{required: false}]}
																				>
																						<TextArea
																								name="opinion"
																								rows={4}
																								placeholder="Leave a message, if you want"
																								className="FeedbackMessage"/>
																				</Form.Item>
																		</div>
																</div>
																<Button type={driverRate && serviceRate && foodRate ? 'primary' : 'primary disabled'}
																				htmlType="submit" className="customer-feedback-rate"
																				disabled={!(driverRate && serviceRate && foodRate)}>
																		Rate now
																</Button>
														</Form>
												</div>
												<div className="CustomerFeedbackFooter"
														 onClick={() => showModal(ModalTypes.CUSTOMER_FEEDBACK_MODAL, false)}>
														<div>
																Maybe later
														</div>
												</div>
										</Spin>
								</Modal>
						</div>
				);
		}
}

CustomerFeedbackModal.propTypes = {
		customerFeedbackModalVisible: PropTypes.bool.isRequired,
		showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
		customerFeedbackModalVisible: state.modalVisible.customerFeedback,
		localLoading: state.localLoading,
		modalProps: state.modalVisible.modalProps
});

const mapDispatchToProps = {
		showLocalLoading,
		updateCustomerOrderProperty,
		getAlreadySentCustomerFeedback,
		showModal
};


export default connect(mapStateToProps, mapDispatchToProps)(CustomerFeedbackModal);
