import React from "react";
import { deleteCreditCard, makeCardDefault } from "../../../services/CreditCards.Service";
import { showLoading } from "../../../actions/baseActions";


function CreditCardsListComponent(props) {
  const { getCardList, cardsList } = props;

  const onDeleteCard = id => {
    showLoading(true);
    deleteCreditCard(id).then((res) => {
      if (res.success) {
        getCardList();
      }
    });
  };

  const makeDefaultCard = id => {
    return makeCardDefault(id).then((res) => {
      if (res.success) {
        getCardList();
      }
    });
  };

  return (
    cardsList && cardsList.length ?
      <>
        <h5 className={"CardH"}>Your Cards</h5>
        {cardsList.map((card, index) => {
          const { holderName, showNumber, isDefault, cardType, id } = card;
          return (
            <div className="payment_card" key={index}>
              <div className="card_left">
                <img src={props.cardIcons[cardType]} alt=""/>
                <div className="card_name">
                  <p>{holderName}</p>
                  <p className="card_code">{showNumber}</p>
                </div>
              </div>
              <div className="card_right">
                {isDefault
                  ? <>
                    <p>Default</p>
                    <span>|</span>
                    <button className="card_delete" onClick={() => onDeleteCard(id)}>Delete</button>
                  </>
                  : <>
                    <p className='make_default' onClick={() => makeDefaultCard(id)}>Make Default</p>
                    <span>|</span>
                    <button className="card_delete" onClick={() => onDeleteCard(id)}>Delete</button>
                  </>
                }
              </div>
            </div>
          );
        })}
      </>
      : null
  );
}

export default CreditCardsListComponent;