import {combineReducers} from 'redux';
// User Reducer
import {
		userReducer,
		clientOrdersReducer
} from './userReducer';

// Location Reducer
import {
		locationReducer
} from './locationReducer';

// Base Reducer
import {
		loadingReducer,
		localLoadingReducer,
		leftMenuReducer,
		showModalReducer,
		toggleAudioReducer,
		showHeaderReducer,
		tipTariff, updateDeliveryTimeReducer
} from "./baseReducer";

// Category Reducer
import {
		categoriesReducer,
		productsReducer
} from './categoriesReducer'
// restaurant
import {
		selectedRestaurantReducer,
		restaurantsReducer
} from './restaurantReducer'

// Basket Reducer
import {
		basketReducer
} from './basketReducer';

// Reviews Reducer
import {
		reviewReducer
} from './reviewReducer';

import {driverActualOrderReducer, driverOrdersList} from "./driverReducer";

import {filterDefaultDataReducer, filterReducer} from "./filterReducer";

// order type
import {orderDeliveryTypeReducer, orderTypeReducer} from './orderReducer';

import {creditCardReducer} from "./creditCardReducer";

import {notificationReducer} from "./notificationReducer";
import {newOrderNotificationLayout, partnerLastOrder, partnerOrders, partnerRestaurant} from "./partnerReducer";

const rootReducer = combineReducers({
		modalVisible: showModalReducer,
		leftMenuVisible: leftMenuReducer,
		audioState: toggleAudioReducer,
		user: userReducer,
		basket: basketReducer,
		loading: loadingReducer,
		localLoading: localLoadingReducer,
		currentLocation: locationReducer,
		categories: categoriesReducer,
		products: productsReducer,
		restaurants: restaurantsReducer,
		selectedRestaurant: selectedRestaurantReducer,
		reviews: reviewReducer,
		tipTariff: tipTariff,
		// filter
		filter: filterReducer,
		defaultFilterData: filterDefaultDataReducer,
		showHeader: showHeaderReducer,
		deliveryTime: updateDeliveryTimeReducer,
		// driver
		actualOrder: driverActualOrderReducer,
		ordersList: driverOrdersList,
		// client
		clientOrders: clientOrdersReducer,
		isDelivery: orderDeliveryTypeReducer,
		isCatering: orderTypeReducer,
		creditCard: creditCardReducer,
		
		notification: notificationReducer,
		
		partnerOrders: partnerOrders,
		partnerLastOrder: partnerLastOrder,
		partnerRestaurant: partnerRestaurant,
		partnerNewOrderLayoutState: newOrderNotificationLayout
});

export default rootReducer;
