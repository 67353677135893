import {BASKET, CLIENT, LOADING, LOCATION, USER} from "./_actionTypes";
import Config from "Config";
import {messageType, showMessage, getErrorsFromResponse, handleResponse} from "../services/Utilities";
import * as UserAPI from "../api/User.API";
import * as OrderAPI from "../api/Order.API";
import * as UserService from "../services/User.Service";
import * as BasketService from "../services/Basket.Service";
import * as DriverApi from "../api/Driver.API";

/**
 *  SIGN_IN / SING_UP / SIGN_OUT
 */
export const loginUser = (model = {}) => dispatch => {
		model = Object.assign({
				grant_type: "password",
				client_id: Config.ClientId,
				client_secret: Config.ClientSecret
		}, model);
		
		dispatch({type: LOADING.START_LOADING});
		
		return UserAPI.signIn(model)
				.then(result => {
						if (result) {
								if (result.success && 'access_token' in result.data) {
										UserService.setAuthData(result.data);
										return result.data;
								}
								showMessage(messageType.error, getErrorsFromResponse(result), 5);
								throw new Error(getErrorsFromResponse(result));
						}
						showMessage(messageType.error, 'There was an error. Please try again.');
						throw new Error("There was an error. Please try again.");
				})
				.then(UserAPI.getCurrentUser)
				.then(result => {
						if (result) {
								if (result.success) {
										UserService.updateAuthData(result.data);
										return result.data;
								}
								showMessage(messageType.error, getErrorsFromResponse(result), 5);
								throw new Error(getErrorsFromResponse(result));
						}
						showMessage(messageType.error, 'There was an error. Please try again.');
						throw new Error("There was an error. Please try again");
				})
				.then(user => {
						if (user && user.id) {
								dispatch({
										type: USER.LOGGED_IN,
										payload: user
								});
								
								document.dispatchEvent(new CustomEvent('successLoggedIn', {
										detail: user
								}));
								
								showMessage(messageType.success, "You have successfully logged in.");
								return user;
						}
						showMessage(messageType.error, 'There was an error. Please try again.');
						throw new Error("There was an error. Please try again");
				})
				.catch(err => {
						UserService.removeAuthData();
						return false;
				})
				.finally(() => {
						dispatch({type: LOADING.STOP_LOADING});
				});
};

export const logoutUser = () => dispatch => {
		console.log('aaaaaaaaaaaaaaaaaaaaaaaaaadd')
		dispatch({type: LOADING.START_LOADING});
		return UserAPI.signOut()
				.then(result => handleResponse(
						result,
						() => dispatch({type: LOADING.STOP_LOADING}),
						() => {
								UserService.removeAuthData();
								UserService.removeLocationData();
								UserService.removeAudioState();
								BasketService.clearBasket();
								
								dispatch({
										type: USER.LOGGED_OUT
								});
								
								dispatch({
										type: BASKET.CLEAR_CART
								});
								
								dispatch({
										type: LOCATION.REMOVE_USER_LOCATION
								})
								
								document.dispatchEvent(new CustomEvent('successLogOut'));
								
								return result.data;
						},
						undefined,
						'You have successfully logged out.',
						undefined
				))
				.catch((err => console.log(err)));
};

export const registerUser = (model = {}) => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		
		return UserAPI.signUp(model)
				.then(result => {
						if (result) {
								if (result.success) {
										showMessage(messageType.success, "You have successfully registered.");
										return result.data
								}
								showMessage(messageType.error, getErrorsFromResponse(result), 5);
								return false;
						}
						showMessage(messageType.error, 'There was an error while registering your account.');
						return false;
				})
				.catch(err => {
						console.log("Error", err);
						return false;
				})
				.finally(() => {
						dispatch({type: LOADING.STOP_LOADING});
				});
};

/**
 *    Personal Information
 */
export const editUserInfo = (model = {}) => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		
		return UserAPI.editUserInfo(model)
				.then(result => handleResponse(
						result,
						() => dispatch({type: LOADING.STOP_LOADING}),
						() => {
								dispatch({
										type: USER.UPDATE_PERSONAL_INFORMATION,
										payload: result.data
								});
								UserService.updateAuthData(result.data);
								return result.data
						},
						undefined,
						'Personal information has been successfully updated.',
						'There was an error while registering your account.'
				))
				.catch(err => {
						console.log("Error", err);
						return false;
				});
};

export const userChangePassword = model => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		
		return UserAPI.changePassword(model)
				.then(result => handleResponse(
						result,
						() => dispatch({type: LOADING.STOP_LOADING}),
						undefined,
						undefined,
						'You password was successfully change.',
						'There was an error. Please try again.'
				))
				.catch(err => console.log(err));
};

/**
 * Driver
 */
export const updateDriverActiveState = state => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		return DriverApi.updateDriverActiveState({state})
				.then(result => handleResponse(
						result,
						() => dispatch({type: LOADING.STOP_LOADING}),
						() => {
								dispatch({
										type: USER.UPDATE_PERSONAL_INFORMATION,
										payload: result.data
								});
								return result.data;
						},
						undefined,
						'Active state was updated',
				))
};


/**
 * Client
 */

export const getClientOrders = (offset, limit) => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		return OrderAPI.getClientOrders(offset, limit).then(result => handleResponse(
				result,
				() => {
						if (result.data && result.data.length) {
								dispatch({
										type: offset && offset !== 1 ? CLIENT.PAGINATE_ORDERS : CLIENT.SET_ORDERS,
										payload: result.data
								});
								return result.data
						}
						return false;
				},
				undefined,
				undefined,
				undefined,
				'There was an error. Please try again.'
		)).finally(() => {
				dispatch({type: LOADING.STOP_LOADING});
		});
		
};

export const clearClientOrders = () => dispatch => {
		dispatch({type: CLIENT.CLEAR_ORDERS})
};
