import React, { useEffect, useState, useRef } from "react";
import { addNewCreditCard, getList } from "../../../services/CreditCards.Service";
import Cleave from "cleave.js/dist/cleave";
import CreditCardsListComponent from "./CreditCards.List.Component";
import { showLoading } from "../../../actions/baseActions";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import {
  handleCardNumberChange,
  handleHolderNameChange,
  handleExpDateChange,
  handleCardCvvChange,
  onCreditCardSelect,
  onCardSaveCheck,
  updateCardList,
  handleZipCodeChange,
  clearCreditCard
} from "../../../actions/creditCardActions";

import { cardExpDateFormat, onlyNumeric } from "../../../services/Utilities";
import Paths from "../../../services/Paths";
import {Checkbox} from 'antd4';
import RenderCardSelect from "./RenderCardSelect";
import { cardIcons } from "../../../enums/Card.Icons.Enum";

function CreditCard(props) {
  const { creditCard, addressZipCode } = props;
  const isInAccount = props.match.path === Paths.account;
  const [cardType, setCardType] = useState('unknown');
  let expInput = useRef();
  let numberInput = useRef();

  useEffect(() => {
    getCardList();
    if (!creditCard.zipCode && addressZipCode) {
      props.handleZipCodeChange(addressZipCode)
    }

    rendCleave()

    return () => {
      emptyInputValues()
    }
  }, []);

  useEffect(() => rendCleave(), [creditCard.selectedCardId])

  const rendCleave = () => {
    //react cleave component gives an error on input focus and onChange so we are using simple js cleave with refs to avoid errors.
    //onChange not triggers with refs so we are using onKeyUp event on inputs to get the value
    if (numberInput && numberInput.current) {
      new Cleave(numberInput.current, {
        creditCard: true,
        required: true,
        onCreditCardTypeChanged: type => {
          const selectedType = cardIcons[type] ? type : 'unknown'
          setCardType(selectedType)
        },
      })
    }

    if (expInput && expInput.current) {
      new Cleave(expInput.current, {
        numericOnly: true,
        blocks: [4],
        uppercase: false,
        date: true,
        datePattern: ["m", "y"],
      })
    }
  }

  const isInAccountHandleSubmit = () => {
    const card = {
      number: creditCard.number.replace(/ /g,""),
      holderName: creditCard.holderName,
      expDate: cardExpDateFormat(creditCard.expDate),
      cardType: creditCard.cardType,
      zip: creditCard.zipCode,
      cvv: creditCard.cvv
    };

    props.showLoading(true);
    addNewCreditCard(card).then((res) => {
      props.showLoading(false);
      if (res.success) {
        emptyInputValues()
        getCardList();
      }
    });
  };

  const emptyInputValues = () => {
    props.clearCreditCard()

    //Credit card number
    if(numberInput && numberInput.current) {
      numberInput.current.value = ''
    }

    //Expires on
    if(expInput && expInput.current) {
      expInput.current.value = ''
    }
  }

  const getCardList = () => {
    getList().then((res) => {
      if (res.success) {
        props.updateCardList(res.data);
      }
    });
  };

  const renderCVV = () => {
    return (
      <div className='col-md-6 pl-0 pr-0 pr-md-3'>
        <div className="GlobalInput">
          <p className={"req"}>Security code</p>
          <div className="credit-card-section">
            <img src={cardIcons.unknown} alt=''/>
            <input
              type="text"
              style={{ paddingLeft: "40px" }}
              placeholder={"****"}
              value={creditCard.cvv || ''}
              onKeyPress={onlyNumeric}
              onChange={props.handleCardCvvChange}
              minLength={3}
              maxLength={4}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <h5
        className={"col-12 pl-0 pr-0"}
        style={{
          fontSize: 16,
          color: '#2E2E2E',
          fontWeight: 700,
          marginTop: 15
        }}
      >
        Add card details
      </h5>

      {!isInAccount &&
      <RenderCardSelect
        cardsList={creditCard.list}
        onCreditCardSelect={props.onCreditCardSelect}
        selectedCardId={creditCard.selectedCardId}
      />
      }

      {!creditCard.selectedCardId &&
      <>
        <div className='row' style={{ marginRight: 0, marginLeft: 0}}>

          <div className="col-md-6 pl-0 pr-0 pr-md-3 GlobalInput">
            <p className={"FormLabel"}>Credit card number</p>
            <div className={"credit-card-section"}>
              <img src={cardIcons[cardType]} alt="card"/>
              <input
                ref={numberInput}
                placeholder="**** **** **** ****"
                onKeyUp={() => props.handleCardNumberChange(numberInput.current.value, cardType)}
              />
            </div>
          </div>
          <div className="col-md-6 pl-0 pr-0 pr-md-3 GlobalInput">
            <p className={"FormLabel"}>Expires on</p>
            <input
              placeholder = "MM/YY"
              ref={expInput}
              onKeyUp={() => props.handleExpDateChange(expInput.current.value)}
            />
          </div>

          <div className="col-md-6 pl-0 pr-0 pr-md-3 GlobalInput">
            <p className={"FormLabel"}>Cardholder name</p>
            <input
              type="text"
              placeholder="Cardholder name"
              name='holderName'
              value={creditCard.holderName}
              onChange={props.handleHolderNameChange}
              required
            />
          </div>

          {isInAccount && renderCVV()}

          <div className="col-md-6 pl-0 pr-0 pr-md-3 GlobalInput">
            <p className={"FormLabel"}>Postal Code</p>
            <input
              type="number"
              placeholder="Postal code"
              name='postalCode'
              value={creditCard.zipCode || ''}
              onChange={e => props.handleZipCodeChange(e.target.value)}
              onKeyPress={onlyNumeric}
              required
              maxLength={30}
            />
          </div>

        </div>
      </>
      }

      {!!creditCard.selectedCardId && !isInAccount && renderCVV()}

      {isInAccount &&
      <div className='GuestInputs'>
        <div className={"col-12 pl-0 pr-0 SettingsUpdateButton"}>
          <button onClick={isInAccountHandleSubmit}>Add</button>
        </div>
      </div>
      }

      {!isInAccount && !creditCard.selectedCardId &&
      <div className='col-12 pl-0 pr-0'>
        <p><Checkbox
          onChange={props.onCardSaveCheck}
          checked={creditCard.saveCard}
        >
          Save card for later purchases
        </Checkbox></p>
      </div>
      }

      {isInAccount &&
      <CreditCardsListComponent
        cardsList={creditCard.list}
        getCardList={getCardList}
        cardIcons={cardIcons}
      />
      }
    </>
  );
}

const mapStateToProps = state => ({
  creditCard: state.creditCard,
  addressZipCode: state.currentLocation.address.zipCode
});

const mapDispatchToProps = {
  showLoading,
  handleCardNumberChange,
  handleHolderNameChange,
  handleExpDateChange,
  handleCardCvvChange,
  onCreditCardSelect,
  onCardSaveCheck,
  updateCardList,
  handleZipCodeChange,
  clearCreditCard
};

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(CreditCard));
