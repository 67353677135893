import React from 'react';
import Img from '../../HelpersComponents/Img.Component';
import numeral from "numeral";

export const RectangleProduct = (
	{
		restaurantName = undefined,
		imgPath = undefined,
		freeDelivery = false,
		categories = undefined,
		deliveryPrice = undefined
	}) => (
	<div className={'RectangleItem'}>
		<Img src={imgPath} alt="burger"/>
		{restaurantName && <p>{restaurantName}</p>}
		{categories && <span className={'CategoryName'} title={categories}>{categories}</span>}
		<span className={'PanelMin'}>35-45 min</span>
		{/*<span>  ∙ 0.84 mi  ∙ $$</span>*/}
		{freeDelivery ? (
			<span className={'PanelFreeDelivery'}>Free delivery</span>
		) : (
			<span className={'PanelDelivery'}>Delivery {numeral(deliveryPrice).format('$ 0,0[.]00')}</span>
		)}
	</div>
);

export default RectangleProduct;
