import * as Http from "./Http.Client";
import {StringifyUrlEncoded} from "./Http.Client";

export const getCategories = (isCatering,restaurantSlug) => {
	return Http.Get(`api/v1/product/categories?${isCatering ? 'catering=true&' : ''}${restaurantSlug ? 'restaurantSlug='+restaurantSlug : ''}`, {})
		.then(result => result ? result : false);
};

export const getCoupon = () => {
	return Http.Get(`api/v1/partner/get/coupons`, {}, true)
		.then(result => result ? result : false);
};

export const getCouponPercent = () => {
	return Http.Get(`api/v1/partner/coupon-percent`, {},  true,true)
		.then(result => result ? result : false);
};


export const getProducts = queryModel => {
	return Http.Get(`api/v1/products?${queryModel}`, {})
		.then(result => result ? result : false);
};

export const getCaterings = queryModel => {
	return Http.Get(`api/v1/catering?${queryModel}`, {})
		.then(result => result ? result : false);
};

export const searchProducts = model => {
	return Http.Get(`api/v1/products/search?${StringifyUrlEncoded(model)}`, {})
		.then(result => result ? result : false);
};

export const getSingleProductDetails = productSlug => {
	return Http.Get(`api/v1/get/product/${productSlug}`, {}, true)
		.then(result => result ? result : false);
};

export const searchItemsByText = searchText => {
		return Http.Get(`api/v1/main/search-items?search=${searchText}`, {})
				.then(result => result ? result : false);
};

export const searchProductsByText = queryModel => {
	return Http.Get(`api/v1/main/search?${queryModel}`, {})
		.then(result => result ? result : false);
};


export const getFilterDefaultData = () => {
	return Http.Get('api/v1/product/filter/data', {})
		.then(result => result ? result : false);
};

export const getCateringSingleProductDetails = productSlug => {
	return Http.Get(`api/v1/caterings/${productSlug}/single`, {})
		.then(result => result ? result : false);
};

export const subscribeEmail = (email) => {
	return Http.Post('api/v1/subscribe', {}, {email})
		.then(result => result ? result : false)
}
