import React, {Component} from 'react';
import {Link} from "react-router-dom";
import LeftMenu from "./LeftMenu.Component";
import CircleProduct from '../HelpersComponents/ProductsComponents/Circle.Product.Component';
import SquareProduct from '../HelpersComponents/ProductsComponents/Square.Product.Component';
import RectangleProduct from '../HelpersComponents/ProductsComponents/Rectangle.Product.Component';
import Panel from '../HelpersComponents/Panel.Component';

import LandingSupport from "../HelpersComponents/LandingSupport.Components";
import {StandartSettings, CircleItemSettings} from '../../settings/slickSliderSettings'
import Header from "../LayoutComponetns/Header.Component";
import DefaultBackgroundImg from '../../img/BannerHome.png';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Paths from "../../services/Paths";
import {searchProducts, getProducts, getCaterings, getRestaurants, showLoading} from "../../actions/baseActions";
import {updateFilter, resetFilter} from '../../actions/filterActions';
import {updateIsAddressSet} from '../../actions/locationActions';
import {updateOrderType} from "../../actions/orderActions";
import '../../styles/HomeStyles/home.scss'
import noResult from "../../img/no_result.png";

class HomeComponent extends Component {
		state = {dragging: false};
		
		UNSAFE_componentWillMount() {
				document.title = this.props.isAuth ? "| Home" : "MeaMeal";
				
				this.getRestaurantAndProducts();
				
				document.addEventListener('updateOrderType', this.initDependencies)
		}
		
		componentWillUnmount() {
				document.removeEventListener('updateOrderType', this.initDependencies)
		}
		
		initDependencies = evt => {
				this.getRestaurantAndProducts(!!evt.detail);
		};
		
		onAddressConfirmed = location => {
				this.getRestaurantAndProducts(this.props.isCatering)
		};
		
		getRestaurantAndProducts = (catering) => {
				const isCatering = catering !== undefined ? catering : this.props.isCatering;
				this.props.showLoading(true);
				
				const requests = [this.props.getRestaurants(isCatering)];
				
				requests.push(isCatering ? this.props.getCaterings() : this.props.getProducts());
				
				Promise.all(requests).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		//TODO Slider change
		handleSliderBeforeChange = () => {
				this.setState({dragging: true})
		};
		
		handleSliderAfterChange = () => {
				this.setState({dragging: false})
		};
		
		render() {
				const {currentLocation, products, restaurants, mainCategories, isAddressSet, isCatering} = this.props;
				const {dragging} = this.state;
				return (
						<div
								className={((!!currentLocation.address ^ isAddressSet) || (!currentLocation.address && isAddressSet)) ? 'LocationAdded' : ''}>
								<Header
										backgroundImg={DefaultBackgroundImg}
										title={'We Deliver, You Enjoy!'}
										onAddressConfirmed={this.onAddressConfirmed}
								/>
								<div className={''}>
										<div className="Landing">
												<div className="">
														<LeftMenu menuTitle={'Categories'}/>
												</div>
												<div className={'LandingPanel'}>
														{!!products.length ?
																<>
																		<Panel
																				className={'PopularPanel'}
																				title={'Local Favorites'}
																				restaurant={restaurants.length}
																				contentScrollable={false}
																		>
																				{Object.values(restaurants).map(restaurant => (
																								<Link
																										to={Paths.restaurant.replace(':restaurantSlug', restaurant.slug)}
																										key={restaurant.slug}>
																										<RectangleProduct
																												freeDelivery={restaurant.isFreeDelivery}
																												categories={false}
																												restaurantName={restaurant.name}
																												imgPath={restaurant.downloadLink}
																												deliveryPrice={restaurant.delivery}
																										/>
																								</Link>
																						)
																				)}
																		</Panel>
																		<Panel
																				className={'FirstPanel'}
																				contentScrollable={CircleItemSettings}
																				handleSliderBeforeChange={this.handleSliderBeforeChange}
																				handleSliderAfterChange={this.handleSliderAfterChange}
																		>
																				{mainCategories.map(category => (
																						<CircleProduct
																								key={category.slug}
																								slug={category.slug}
																								name={category.name}
																								imgPath={category.downloadLink}
																								handleClick={() => !dragging && this.handleCategoryClick(category.slug)}
																						/>
																				))}
																		</Panel>
																		<Panel
																				className={'TodayPanel'}
																				title={'What to eat today'}
																				contentScrollable={StandartSettings}
																				getNewItems={isCatering ? this.props.getCaterings : this.props.getProducts}
																				lazyLoadable={false}
																		>
																				{products.length ? products.map(product => (
																						<SquareProduct
																								key={product.slug}
																								name={product.name}
																								imgPath={product.downloadLink || product.restaurant.downloadLink}
																								categories={product.productCategoriesLine}
																								cal={product.cal}
																								slug={product.slug}
																								restaurantName={product.restaurant.name}
																						/>
																				)) : ''}
																		</Panel>
																</>
																:
																<div className={'homeLandingPanel'}>
																		<h4>No search result</h4>
																		<img className={"NoResult"} src={noResult} alt=""/>
																</div>
														}
														<LandingSupport/>
												</div>
										</div>
								</div>
						</div>
				);
		}
		
		handleCategoryClick = categorySlug => {
				const {selectedCategories} = this.props;
				
				let filterModel = {};
				
				if (Array.isArray(selectedCategories) && selectedCategories.indexOf(categorySlug) !== -1) {
						filterModel = Object.assign({}, this.props.filter, {selectedCategories: []});
				} else {
						filterModel = Object.assign({}, this.props.filter, {selectedCategories: [categorySlug]})
				}
				
				this.props.updateFilter(filterModel);
				this.props.history.push(Paths.search);
		}
}

HomeComponent.propTypes = {
		currentLocation: PropTypes.object.isRequired,
		isAuth: PropTypes.bool.isRequired,
		isAddressSet: PropTypes.bool.isRequired,
		products: PropTypes.array.isRequired,
		restaurants: PropTypes.array.isRequired,
		searchProducts: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
		currentLocation: state.currentLocation.address,
		isAddressSet: state.currentLocation.isAddressSet,
		isAuth: state.user.isAuth,
		isCatering: state.isCatering,
		
		products: state.products,
		
		productsHasMore: state.products.hasMore,
		restaurants: state.restaurants,
		mainCategories: state.categories.main,
		selectedCategories: state.filter.selectedCategories,
		
		filter: state.filter
});

const mapDispatchToProps = {
		getProducts,
		getCaterings,
		getRestaurants,
		searchProducts,
		showLoading,
		updateFilter,
		resetFilter,
		updateIsAddressSet,
		updateOrderType
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(HomeComponent);
