import React, {Component} from "react";
import {withRouter} from "react-router";
import {Rate} from "antd4";
import {
		EditOutlined,
		EyeOutlined,
		FieldTimeOutlined,
		SettingOutlined
} from "@ant-design/icons";
import {connect} from "react-redux";
import {showLoading} from "../../actions/baseActions";
import Img from "../HelpersComponents/Img.Component";
import PartnerEditDetails from "./ModalComponents/Partner.Edit.Details.Modal.Component";
import Config from "Config";
import PartnerTabs from "../../enums/Partner.Tabs.Enum";
import Paths from "../../services/Paths";
import PartnerPublicView from "./ModalComponents/Partner.Public.View.Modal.Component";
import PartnerSettings from "./ModalComponents/Partner.Settings.Modal.Component";
import PartnerStartTacking from "./ModalComponents/Partner.Start.Tacking.Modal.Component";
import {Card} from "react-bootstrap";

class PartnerDetails extends Component {
		state = {
				visible: false,
				restaurant: false
		};
		
		UNSAFE_componentWillMount() {
				document.title = "Restaurant";
		}
		
		handleVisibleChange = visible => {
				this.setState({visible: visible.show});
		};
		handleVisiblePublicChange = visible => {
				this.setState({visiblePublic: visible.show});
		};
		handleVisiblePublicSettingsChange = visible => {
				this.setState({visibleSettings: visible.show});
		};
		handleVisibleStartTackingChange = visible => {
				this.setState({visibleStartTacking: visible.show});
		};
		
		onMenuItemClick = (key) => {
				if (key in PartnerTabs) {
						this.setState({sidebarOpen: false, menuItemKey: key});
						this.props.history.replace(Paths.basePartner.replace(":tabName", PartnerTabs[key]));
				}
		};
		
		render() {
				const {restaurant} = this.props;
				const {visible, visiblePublic, visibleSettings, visibleStartTacking} = this.state;
				let bannerImage = restaurant.bannerFile;
				
				if (/^data:image\/(png|jpeg|gif);base64.*$/.test(restaurant.bannerFile)) {
						bannerImage = restaurant.bannerFile;
				}
				return restaurant.id ? (
						<div className={"Content"}>
								<PartnerEditDetails
										visible={visible}
										items={[]}
										onVisibleChange={this.handleVisibleChange}
								>
								</PartnerEditDetails>
								<PartnerPublicView
										visible={visiblePublic}
										onVisibleChange={this.handleVisiblePublicChange}
								>
								</PartnerPublicView>
								<PartnerSettings
										visible={visibleSettings}
										onVisibleChange={this.handleVisiblePublicSettingsChange}
								>
								</PartnerSettings>
								<PartnerStartTacking
										visible={visibleStartTacking}
										onVisibleChange={this.handleVisibleStartTackingChange}
								>
								</PartnerStartTacking>
								<section className={"ContentHeader"}>
										<h1>Restaurant details</h1>
										<div>
												<button className={"edit_restaurant"}
																onClick={() => this.setState({visibleStartTacking: true})}>
														<FieldTimeOutlined/>
												</button>
												<button className={"edit_restaurant"} onClick={() => this.setState({visibleSettings: true})}>
														<SettingOutlined/>
												</button>
												<button className={"edit_restaurant"} onClick={() => this.setState({visiblePublic: true})}>
														<EyeOutlined/>
												</button>
												<button className={"edit_restaurant"} onClick={() => this.setState({visible: true})}>
														<EditOutlined/>
												</button>
										</div>
								</section>
								<section className={"ContentBody"}>
										<div className={"banner_profile"}
												 style={{backgroundImage: "linear-gradient(45deg, #0000003b, #03030333),url(" + bannerImage + ")"}}>
												
												<div className="logo_name">
														<div className="img">
																{restaurant && restaurant.downloadLink ?
																		<Img src={restaurant.downloadLink} alt="avatar"
																				 style={{width: "100%"}}/> : ""}
														</div>
														<div className="name">
																<h3>{restaurant.name}</h3>
																<span className={'it_info'}>{restaurant.phone}</span><br/>
																<span className={'it_info'}>{restaurant.email}</span><br/>
																<span
																		className={'it_info'}>{restaurant.address && restaurant.address.showAddress ? restaurant.address.showAddress : ''}</span>
																<div style={{color: '#fff'}}><Rate disabled={true} style={{fontSize: "21px"}}
																																	 value={restaurant ? restaurant.rating : 0}/> ({restaurant ? restaurant.rating : 0})
																</div>
														
														</div>
												</div>
										</div>
										<Card style={{marginTop: "10px"}}>
												<Card.Body>
														<Card.Title>About restaurant</Card.Title>
														<Card.Text>
																{restaurant.aboutUs}
														</Card.Text>
												</Card.Body>
										</Card>
										{restaurant.settings ? (
												<Card style={{marginTop: "10px"}}>
														<Card.Body>
																<Card.Title>Driver instructions</Card.Title>
																<Card.Text>
																		{restaurant.settings.driverInstructions}
																</Card.Text>
														</Card.Body>
												</Card>
										) : ''}
										<Card style={{marginTop: "10px"}}>
												<Card.Body>
														<Card.Title>FEIN</Card.Title>
														<Card.Text style={{textTransform: 'uppercase'}}>
																{restaurant.fein}
														</Card.Text>
												</Card.Body>
										</Card>
										<Card style={{marginTop: "10px"}}>
												<Card.Body>
														<Card.Title>Sales Tax</Card.Title>
														<Card.Text>
																{restaurant.salesTax} %
														</Card.Text>
												</Card.Body>
										</Card>
								</section>
						</div>
				) : "";
		}
}

const mapStateToProps = state => ({
		restaurant: state.partnerRestaurant,
});


const mapDispatchToProps = {
		showLoading,
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps
)(PartnerDetails));
