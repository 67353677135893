import {BASKET} from "./_actionTypes";

export const addFirstItem = (product = {}, restaurant = {}, isCatering = false) => (dispatch) => {
	dispatch({
		type: BASKET.ADD_FIRST_ITEM,
		product, restaurant, isCatering
	})
};

export const updateBagItem = (item = {}, restaurant = {}) => (dispatch) => {
	dispatch({
		type: BASKET.UPDATE_ITEM,
		item: item,
		restaurant: restaurant
	})
};

export const addNewProduct = (product = {}) => dispatch => {
	dispatch({
		type: BASKET.ADD_NEW_PRODUCT,
		product: product
	})
}

export const removeBasketItem = (productKey) => dispatch => {
	dispatch({
		type: BASKET.REMOVE_ITEM,
		productKey: productKey
	});
};

export const changeItemCount = (productKey, count) => dispatch => {
	dispatch({type: BASKET.CHANGE_COUNT, payload: {productKey, count}})
};

export const calculateBasket = () => dispatch => {
	dispatch({type: BASKET.CALCULATE_BASKET});
};

export const clearBasket = () => dispatch => {
	return dispatch({type: BASKET.CLEAR_CART});
};
