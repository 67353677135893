import React, {Component} from "react";
import moment from 'moment';
import {ReactComponent as Location} from '../../img/ic_location.svg'
import PropTypes from "prop-types";
import {removeLocation} from "../../actions/locationActions";
import {getCategories, getRestaurants, updateFilterData} from "../../actions/baseActions";
import {connect} from "react-redux";
import numeral from "numeral";
import {withRouter} from "react-router";
import {updateFilter, resetFilter} from '../../actions/filterActions';
import Paths from "../../services/Paths";
import {showModal} from '../../actions/baseActions';
import ModalTypes from "../../enums/Modal.Types.Enum";
import {Slider, Checkbox, Spin} from 'antd4';
import {RetweetOutlined} from '@ant-design/icons';

class LeftMenu extends Component {
	
	UNSAFE_componentWillMount() {
		if (this.props.loadCategories) {
			this.props.getCategories(this.props.isCatering)
			
			document.addEventListener('updateOrderType', this.getCategories)
		}
	}
	
	componentWillUnmount() {
		if (this.props.loadCategories) {
			document.removeEventListener('updateOrderType', this.getCategories)
		}
	}
	
	getCategories = evt => {
		this.props.getCategories(!!evt.detail)
	};
	
	resetFilter = () => {
		this.props.resetFilter();
		this.props.onFilter({});
	};
	
	handleFilterChange = (state, sendRequest = true) => {
			if (typeof state === 'object') {
			this.props.updateFilter(state);
			const {path} = this.props.match;
			if (path === Paths.account || path === Paths.home) {
				this.props.history.push(Paths.search);
			} else {
				const filterModel = Object.assign({}, this.props.filter, state);
				sendRequest && this.props.onFilter(filterModel);
			}
		}
	};
	
	render() {
		const {filter, defaultFilterData, currentLocation, showModal, leftMenuVisible, loadCategories, featuresVisible, menuTitle, localLoading, isCatering, openRestaurantsCount} = this.props;
		const categories = loadCategories ? this.props.categories : this.props.customCategories;
		return (
			<div className={leftMenuVisible ? 'LandingLeftMenu open' : 'LandingLeftMenu'} ref={this.menu}>
				<Spin spinning={localLoading}>
					{currentLocation.address && (
						<div className="AddressAdded">
							<p title={currentLocation.address} onClick={() => showModal(ModalTypes.MAP_MODAL)}>
								<Location/>{currentLocation.address}</p>
						</div>
					)}
					
					{featuresVisible && (
						<div className="feature">
							<h5>Feature <RetweetOutlined title="Reset filter" onClick={this.resetFilter}/></h5>
							{/*{isDelivery && (*/}
							{/*	<p><Checkbox*/}
							{/*		onChange={evt => {*/}
							{/*			this.handleFilterChange({isFreeDelivery: evt.target.checked ? evt.target.checked : undefined})*/}
							{/*		}}*/}
							{/*		checked={filter.isFreeDelivery}*/}
							{/*	>Free Delivery</Checkbox></p>*/}
							{/*)}*/}
							
							<p><Checkbox
								onChange={evt => this.handleFilterChange({isOpenNow: evt.target.checked ? evt.target.checked : undefined})}
								checked={filter.isOpenNow}
							>Open now ∙ {moment().format('hh:mm a')} {openRestaurantsCount ? `(${openRestaurantsCount})` : ''}</Checkbox></p>
						</div>
					)}
					
					
					<div className="MenuS">
						<h5>{menuTitle}</h5>
						<p className="Uynushka">{!featuresVisible && (
							<RetweetOutlined title="Reset filter" onClick={this.resetFilter}/>)}</p>
						<div className="CategoryUl">
							<div className="catScroll">
								<Checkbox.Group
									onChange={values => this.handleFilterChange({selectedCategories: values})}
									value={filter.selectedCategories}
								>
									{categories.map((category) => (
											<Checkbox key={category.slug} value={category.slug}>
												{category.name} <span>{category.productsCount}</span>
											</Checkbox>
										)
									)}
								</Checkbox.Group>
							</div>
						</div>
					</div>
					<div className="Price">
						<h5>Price $</h5>
						<div>
							<span>{numeral(filter.minPrice).format('0,0[.]00')}</span>
							<Slider
								range
								defaultValue={[filter.minPrice, filter.maxPrice]}
								value={[filter.minPrice, filter.maxPrice]}
								min={defaultFilterData.price.min}
								max={defaultFilterData.price.max}
								step={0.1}
								tipFormatter={value => numeral(value).format('$ 0,0[.]00')}
								onChange={value => this.handleFilterChange({
									minPrice: value[0],
									maxPrice: value[1]
								}, false)}
								onAfterChange={value => this.handleFilterChange({
									minPrice: value[0],
									maxPrice: value[1]
								})}
							/>
							<span>{numeral(filter.maxPrice).format('0,0[.]00')}</span>
						</div>
					</div>
					{!isCatering && (
						<React.Fragment>
							<div className="Delivery">
								<h5>Delivery Time</h5>
								<div>
									<Slider
										defaultValue={defaultFilterData.deliveryTime.max}
										min={defaultFilterData.deliveryTime.min}
										max={defaultFilterData.deliveryTime.max}
										value={filter.deliveryTime}
										onChange={value => this.handleFilterChange({deliveryTime: value}, false)}
										tipFormatter={value => `${value} m`}
										onAfterChange={value => this.handleFilterChange({deliveryTime: value})}/>
									<span>{filter.deliveryTime}m</span></div>
							</div>
							<div className="Calories">
								<h5>Calories</h5>
								<div>
									<Slider
										defaultValue={defaultFilterData.cal.max}
										min={defaultFilterData.cal.min}
										max={defaultFilterData.cal.max}
										value={filter.cal}
										onChange={value => this.handleFilterChange({cal: value}, false)}
										tipFormatter={value => `${value} c`}
										onAfterChange={value => this.handleFilterChange({cal: value})}
									/>
									<span>{filter.cal}c</span></div>
							</div>
						</React.Fragment>
					)}
				</Spin>
			</div>
		);
	}
}

LeftMenu.propTypes = {
	currentLocation: PropTypes.object,
	mapModalVisible: PropTypes.bool.isRequired,
	showModal: PropTypes.func.isRequired,
	leftMenuVisible: PropTypes.bool.isRequired,
	loadCategories: PropTypes.bool.isRequired,
	
	customCategories: PropTypes.array.isRequired,
	featuresVisible: PropTypes.bool.isRequired,
	menuTitle: PropTypes.string,
	
	onFilter: PropTypes.func.isRequired,
};

LeftMenu.defaultProps = {
	loadCategories: true,
	featuresVisible: true,
	customCategories: [],
	onFilter: f => f
};

const mapStateToProps = state => ({
	currentLocation: state.currentLocation.address,
	mapModalVisible: state.modalVisible.map,
	loading: state.loading,
	leftMenuVisible: state.leftMenuVisible,
	categories: state.categories.all,
	filter: state.filter,
	isDelivery: state.isDelivery,
	isCatering: state.isCatering,
	defaultFilterData: state.defaultFilterData,
	localLoading: state.localLoading
});

const mapDispatchToProps = {
	getCategories,
	getRestaurants,
	removeLocation,
	showModal,
	updateFilter,
	resetFilter,
	updateFilterData
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(LeftMenu)
);
