import {FILTER} from "./_actionTypes";

export const updateFilter = (filterItems = {}, offset = 1, limit = 10) => (dispatch, getState) => {
	const state = getState();
	
	const model = Object.assign({}, state.filter, Object.assign({}, filterItems, {
		isFiltered: true
	}));
	
	dispatch({
		type: FILTER.UPDATE_FILTER,
		payload: model
	});
	
	// const queryModel = StringifyUrlEncoded({offset, limit, ...model});
	
	document.dispatchEvent(new CustomEvent('filterUpdate'));
};

export const resetFilter = () => (dispatch, getState) => {
	const state = getState();
	dispatch({
		type: FILTER.RESET_FILTER,
		payload: state.defaultFilterData
	});
	
	document.dispatchEvent(new CustomEvent('filterReset'));
};
