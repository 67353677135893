import React, {Component} from "react";
import {showLoading} from "../../actions/baseActions";
import {Button, Radio} from "antd4";
import {connect} from "react-redux";
import PartnerStartTacking from "./ModalComponents/Partner.Start.Tacking.Modal.Component";
import {getPartnerDetails, setStopTacking} from "../../api/Partner.API";
import Count from "./Count";
import {TakingDurationType} from "../../enums/Restaurant.Enum";
import {updatePartnerRestaurant} from "../../actions/partnerActions";

class PartnerStopTacking extends Component {
		state = {
				durationType: TakingDurationType.IS_TAKING_TYPE_START_TAKING,
				visibleStartTacking: false
		};
		
		handleVisibleStartTackingChange = visible => {
				this.setState({visibleStartTacking: visible.show});
				if (visible.getPartner) {
						this.props.showLoading(true);
						getPartnerDetails().then(result => {
								if (result) {
										this.props.updatePartnerRestaurant(result.data)
								}
						}).finally(() => this.props.showLoading(false));
				}
		};
		
		UNSAFE_componentWillMount() {
				this.props.showLoading(true);
				getPartnerDetails().then(result => {
						if (result) {
								this.props.updatePartnerRestaurant(result.data)
						}
				}).finally(() => this.props.showLoading(false));
		}
		
		startTackingOrders = () => {
				this.setState({durationType: TakingDurationType.IS_TAKING_TYPE_START_TAKING}, () => {
						this.saveTackingOrders()
				});
		};
		
		saveTackingOrders = (start = false) => {
				let {durationType} = this.state;
				if (start) {
						durationType = TakingDurationType.IS_TAKING_TYPE_START_TAKING;
						this.setState({durationType});
				}
				this.props.showLoading(true);
				
				setStopTacking(durationType).then(result => {
						if (result) {
								if (!durationType) {
										const elements = Array.from(document.getElementsByClassName("close_branch"));
										if (elements.length > 0) {
												elements[0].classList.remove('close_branch');
												elements[0].classList.add('open_branch');
												elements[0].innerHTML = 'OPEN';
										}
								} else {
										const elements = Array.from(document.getElementsByClassName("open_branch"));
										if (elements.length > 0) {
												elements[0].classList.remove('open_branch');
												elements[0].classList.add('close_branch');
												elements[0].innerHTML = 'CLOSE';
										}
								}
								
								getPartnerDetails().then(result => {
										if (result) {
												this.props.updatePartnerRestaurant(result.data)
										}
								});
						}
				}).finally(() => this.props.showLoading(false));
		};
		
		checkTime(i) {
				if (i < 10) {
						i = "0" + i;
				}
				return i;
		}
		
		parseTime(str) {
				let values = str.split(":");
				let hour = parseInt(values[0]);
				let minutes = parseInt(values[1]);
				let d = new Date();
				d.setHours(hour);
				d.setMinutes(minutes);
				return d;
		}
		
		
		render() {
				const {restaurant} = this.props;
				const {visibleStartTacking, durationType} = this.state;
				const dayIndex = new Date().getDay();
				let closeDate = [];
				let closeDatePickup = [];
				if (restaurant && restaurant.workingHours && dayIndex in restaurant.workingHours && "times" in restaurant.workingHours[dayIndex] && restaurant.workingHours[dayIndex].inputChecked) {
						closeDate = restaurant.workingHours[dayIndex].times;
				}
				if (restaurant && restaurant.workingHoursPickup && dayIndex in restaurant.workingHoursPickup && "times" in restaurant.workingHoursPickup[dayIndex] && restaurant.workingHoursPickup[dayIndex].inputChecked) {
						closeDatePickup = restaurant.workingHoursPickup[dayIndex].times;
				}
				let ee = null;
				if (restaurant && restaurant.countDownClose) {
						let temp = new Date(restaurant.countDownClose);
						let now = new Date();
						if (temp >= now) {
								ee = temp
						}
				}
				
				return (
						<div className={"Content"} style={{padding: "20px"}}>
								<PartnerStartTacking
										visible={visibleStartTacking}
										onVisibleChange={this.handleVisibleStartTackingChange}
								/>
								<h4>Today's hours</h4>
								<b>Delivery</b>
								<div className="date_time" style={{paddingLeft: "5px"}}>
										{closeDate.map((input, index) => {
												return (<p key={index + 'i'}>{input.openTime} - {input.closeTime}</p>);
										})}
								</div>
								<b>Pickup</b>
								<div className="date_time" style={{paddingLeft: "5px"}}>
										{closeDatePickup.map((input, index) => {
												return (<p key={index}>{input.openTime} - {input.closeTime}</p>);
										})}
								</div>
								<br/>
								<Button onClick={e => (this.setState({visibleStartTacking: true}))}>
										Update hours
								</Button>
								<br/>
								<br/>
								
								{ee ? <Count countdown={ee}/> : ""}
								
								<h4>Stop taking orders for</h4>
								<Radio.Group onChange={e => {
										this.setState({durationType: e.target.value});
								}}>
										<Radio value={TakingDurationType.IS_TAKING_TYPE_30_MIN}>30 minutes</Radio>
										<Radio value={TakingDurationType.IS_TAKING_TYPE_1_HOURS}>1 Hour</Radio>
										<Radio value={TakingDurationType.IS_TAKING_TYPE_2_HOURS}>2 Hours</Radio>
										<Radio value={TakingDurationType.IS_TAKING_TYPE_1_DAY}>Today</Radio>
								</Radio.Group>
								<div className="" style={{marginTop: "20px"}}>
										<a type="primary" href={'/partner/orders'} className={'btn_not_now'} style={{marginRight: '10px'}}>NOT
												NOW</a>
										<Button type="primary" style={{marginRight: '10px'}} onClick={e => {
												this.saveTackingOrders();
										}} disabled={!durationType}>Stop taking orders</Button>
										<Button type="primary" onClick={e => {
												this.saveTackingOrders(true);
										}} disabled={!ee}>Start Tacking Orders</Button>
								</div>
						</div>
				
				);
		}
}

const mapStateToProps = state => ({
		restaurant: state.partnerRestaurant,
});

const mapDispatchToProps = {
		showLoading,
		updatePartnerRestaurant,
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(PartnerStopTacking);

