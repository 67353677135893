import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading} from "../../actions/baseActions";
import {
		setPartnerOrders,
		setPartnerLastOrder,
		updatePartnerOrder,
		setNewOrderLayoutState
} from "../../actions/partnerActions";
import {getOrders, updateOrderStatus, sendTestOrder} from "../../services/Partner.Service";
import {DatePicker, Tag} from "antd4";
import {InfoCircleOutlined} from "@ant-design/icons";
import {OrderColumns} from "../../enums/Orders.Table";
import {refund} from "../../api/Partner.API";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import OrderInfoModal from "../PartnerManagerComponents/Order.Info.Component";
import {Badge, ButtonGroup, Card, Dropdown, Button} from "react-bootstrap";
import PartnerAdjustModalComponent from "./ModalComponents/Partner.Adjust.Modal.Component";
import moment from "moment";
import Paths from "../../services/Paths";
import OrderStatusNumeral, {OrderDeliveryType} from "../../enums/Order.Status.Enum";

class PartnerOrders extends Component {
		routeListener = undefined;
		CustomOrderColumns = undefined;
		
		constructor(props) {
				super(props);
				
				this.state = {
						orderModalVisible: false,
						orderSlug: null,
						reFoundInfo: {
								slug: null,
								amount: ""
						},
						reFoundInfoErr: null,
						filterStartDate: null,
						filterEndDate: null,
						visible: false,
				}
				
				this.CustomOrderColumns = [...OrderColumns, {
						title: "Action",
						key: "action",
						render: (order, record) => (
								<div>
										<InfoCircleOutlined
												style={{fontSize: "25px", color: "#08c"}}
												theme="outlined"
												title="Order information"
												onClick={() => this.toggleInfoModal(record.slug)}
										/>
								</div>
						)
				}, {
						title: "Refund",
						key: "action",
						render: (order) => (
								<Button variant="primary" onClick={() => this.setState({visible: true, items: order})}>
										Adjust order
								</Button>
						)
				}];
				
				this.getPartnerOrders();
		}
		
		componentWillUnmount() {
				this.routeListener();
		}
		
		componentDidMount() {
				// for the same page
				this.routeListener = this.props.history.listen(location => {
						let match = location.pathname.match('^\\/partner\\/orders\\/(.*)$');
						if (match && match[1]) {
								this.toggleInfoModal(match[1]);
								this.props.history.replace(`${Paths.basePartner.replace(":tabName", "orders")}`);
						}
				});
				
				// check if there is selected order view order details
				if ('actionSlug' in this.props.match.params && this.props.match.params.actionSlug) {
						const {actionSlug} = this.props.match.params;
						this.toggleInfoModal(actionSlug);
						this.props.history.replace(`${Paths.basePartner.replace(":tabName", "orders")}`);
				}
		}
		
		toggleInfoModal = (orderSlug = null) => {
				const {orderModalVisible} = this.state;
				this.setState({
						orderModalVisible: !orderModalVisible,
						orderSlug: orderSlug
				});
		};
		
		sendRefund = async () => {
				const {reFoundInfo} = this.state;
				try {
						let refundData = await refund(reFoundInfo.slug, {amount: reFoundInfo.amount});
						
						if (refundData.success === true) {
								window.location.reload();
						} else this.setState({reFoundInfoErr: refundData.message["message-0"]});
				} catch (err) {
						this.setState({reFoundInfoErr: "Something Went Wrong"});
				}
		};
		
		getPartnerOrders = () => {
				this.props.showLoading(true);
				getOrders().then(orders => {
						this.props.setPartnerOrders(orders);
						return orders;
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		setFilter = () => {
				this.props.showLoading(true);
				
				getOrders(this.state.filterStartDate, this.state.filterEndDate).then(result => {
						if (result) {
								this.props.setPartnerOrders(result)
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		handleVisibleChange = visible => {
				if (visible.getOrder) {
						this.getPartnerOrders();
				}
				
				if (visible.removeTabSocket) {
						this.props.setNewOrderLayoutState(false)
				}
				
				this.setState({visible: visible.show});
		};
		
		updateOrderStatus = (orderSlug, lastStatus, newStatus) => {
				if (orderSlug && newStatus) {
						this.props.showLoading(true);
						updateOrderStatus(orderSlug, lastStatus, newStatus).then(order => {
								if (order && "id" in order) {
										this.props.showLoading(true);
										getOrders().then(orders => {
												if (orders) {
														this.props.setNewOrderLayoutState(false)
														this.setState({
																newOrderId: null,
														});
														this.props.setPartnerOrders(orders)
												}
										}).finally(() => {
												this.props.showLoading(false);
										});
										
										// sendPartnerWssMessage({
										// 		action: WssAction.ORDER_UPDATED,
										// 		slug: orderSlug,
										// 		driver: order.driver ? order.driver.username : undefined,
										// 		restaurant: order && 'restaurant' in order ? order.restaurant.slug : false
										// });
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		
		onOrderUpdate = (order, message = null, messageType = null) => {
				const {restaurant} = this.props;
				
				if (order) {
						this.props.setNewOrderLayoutState(false);
						// sendPartnerWssMessage({
						// 		action: WssAction.ORDER_UPDATED,
						// 		slug: order.slug,
						// 		driver: order.driver ? order.driver.username : undefined,
						// 		restaurant: restaurant.slug ? restaurant.slug : false,
						// 		message, messageType
						// });
						
						getOrders().then(orders => {
								this.props.setPartnerOrders(orders);
								return orders;
						});
				}
		};
		
		getOrderItemHeader = order => {
				if (order.deliveryType === OrderDeliveryType.IS_DELIVERY) {
						return (
								<div>
										<Tag><b>Self Deivery</b></Tag>
										<Tag><b>Deliver by {moment(order.readyAt).format("DD MMMM YYYY, LT")} < /b></Tag>
								</div>
						);
				} else {
						return (
								<div>
										<Tag><b>Pickup</b></Tag>
										<Tag><b>Ready by {moment(order.readyAt).format("DD MMMM YYYY, LT")} < /b></Tag>
								</div>
						);
				}
		}
		
		
		render() {
				const {ordersActive, ordersScheduled, ordersCompleted} = this.props;
				const {
						reFoundInfo,
						orderModalVisible,
						orderSlug,
						reFoundInfoErr,
						items,
						filterStartDate,
						filterEndDate,
						visible,
						view
				} = this.state;
				
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Orders</h1>
										<div className="d-flex align-items-center">
												<PartnerAdjustModalComponent
														visible={visible}
														items={items}
														view={view}
														onVisibleChange={this.handleVisibleChange}
												>
												</PartnerAdjustModalComponent>
												<DatePicker
														placeholder="Start Date"
														format="YYYY-MM-DD"
														disabledDate={d => !d || d.isAfter(filterEndDate)}
														onChange={(e, s) => {
																this.setState({filterStartDate: s});
														}}
												/>
												<DatePicker
														placeholder="End Date"
														format="YYYY-MM-DD"
														disabledDate={d => !d || d.isSameOrBefore(filterStartDate)}
														className={"ml-2"}
														onChange={(e, s) => {
																this.setState({filterEndDate: s});
														}}
												/>
												<Button
														className={"AddNewButton ml-3"}
														onClick={this.setFilter}
												>
														Filter
												</Button>
												<Dropdown>
														<Dropdown.Toggle variant="danger" id="dropdown-basic">
														
														</Dropdown.Toggle>
														
														<Dropdown.Menu>
																<Dropdown.Item onClick={() => {
																		this.getPartnerOrders();
																}}>
																		Refresh
																</Dropdown.Item>
																<Dropdown.Item onClick={() => {
																		sendTestOrder();
																}}>Send test order</Dropdown.Item>
														</Dropdown.Menu>
												</Dropdown>
										</div>
								</section>
								<section className={"ContentBody"}>
										<Tabs>
												<TabList>
														<Tab>Active <Badge
																style={{backgroundColor: "#e7792b", color: "#fff"}}>{ordersActive.length}</Badge></Tab>
														<Tab>Scheduled <Badge
																style={{
																		backgroundColor: "#e7792b",
																		color: "#fff"
																}}>{ordersScheduled.length}</Badge></Tab>
														<Tab>Completed <Badge
																style={{
																		backgroundColor: "#e7792b",
																		color: "#fff"
																}}>{ordersCompleted.length}</Badge></Tab>
												</TabList>
												
												<TabPanel>
														<div>
																{ordersActive && ordersActive.map(orderActive => {
																		return (
																				<Card key={orderActive.slug} className={"mt-2"}>
																						<Card.Body>
																								<div className={"order_item_partnor"}>
																										<div className="item">
																												<div><b>N: #</b>{orderActive.id}</div>
																												<div>
																														{orderActive.status === OrderStatusNumeral.IS_NEW ?
																																<Tag color="magenta">New</Tag> : ""
																														}
																														{orderActive.status === OrderStatusNumeral.IS_WAITING_RESTAURANT ?
																																<Tag color="red">Waiting restaurant</Tag> : ""
																														}
																														{orderActive.status === OrderStatusNumeral.IS_IN_PREPARING ?
																																<Tag color="orange">In Preparing</Tag> : ""
																														}
																														{orderActive.status === OrderStatusNumeral.IS_READY ?
																																<Tag color="cyan">Ready to delivery</Tag> : ""
																														}
																														{orderActive.status === OrderStatusNumeral.IS_DELIVERY ?
																																<Tag color="lime">Delivery</Tag> : ""
																														}
																														{orderActive.status === OrderStatusNumeral.IS_DONE ?
																																<Tag color="Green">Done</Tag> : ""
																														}
																														
																														{orderActive.testOrder ?
																																<Tag color="Green">Test</Tag> : ""
																														}
																												</div>
																												{orderActive.extraCharge &&
																												<div><b>Extra Charge
																														Amount: </b>{orderActive.extraCharge}</div>}
																												{orderActive.extraCharge &&
																												<div><b>Extra Charge
																														Reason: </b>{orderActive.extraChargeReason}
																												</div>}
																												
																												{!orderActive.deliveryFee && orderActive.deliveryFeeRequest ?
																														<div><b>Remove delivery fee: </b><Tag
																																color="Green">True</Tag></div> : ""}
																										
																										
																										</div>
																										<div className="item">
																												{this.getOrderItemHeader(orderActive)}
																										</div>
																								
																								</div>
																								<ButtonGroup size="sm" className={"group_btn_order"}>
																										<Button variant="primary"
																														onClick={() => this.toggleInfoModal(orderActive.slug)}>
																												{orderActive.status === OrderStatusNumeral.IS_IN_PREPARING ? 'Delay or cancel order ' : 'Accept or cancel order'}
																										</Button>
																										<Button variant="outline-primary"
																														onClick={() => this.setState({
																																visible: true,
																																view: true,
																																items: orderActive
																														})}>
																												Adjust order
																										</Button>
																								
																								</ButtonGroup>
																						</Card.Body>
																				</Card>);
																})
																}
																{!ordersActive.length ? <h2 className={"empty_result"}>Empty result</h2> : ""}
														</div>
												</TabPanel>
												<TabPanel>
														{ordersScheduled && ordersScheduled.map(orderScheduled => {
																return (
																		<Card key={orderScheduled.slug} className={"mt-2"}>
																				<Card.Body>
																						<div className={"order_item_partnor"}>
																								<div className="item">
																										<div><b>N: </b>{orderScheduled.id}</div>
																										<div><b>Scheduled Date: </b>{moment(orderScheduled.scheduledTime).format("DD MMMM YYYY, LT")}
																										</div>
																										
																										{orderScheduled.extraCharge &&
																										<div><b>Extra Charge Amount: </b>{orderScheduled.extraCharge}
																										</div>}
																										{orderScheduled.extraCharge &&
																										<div><b>Extra Charge
																												Reason: </b>{orderScheduled.extraChargeReason}</div>}
																										
																										{!orderScheduled.deliveryFee && orderScheduled.deliveryFeeRequest ?
																												<div><b>Remove delivery fee: </b><Tag color="Green">True</Tag>
																												</div> : ""}
																								</div>
																								<div className="item">
																										<div>
																												<b>Created: </b>{moment(orderScheduled.created).format("DD MMMM YYYY, LT")}
																										</div>
																										
																										{orderScheduled.refund &&
																										<div><b>Refund : </b>{orderScheduled.refund}</div>}
																										{orderScheduled.refund &&
																										<div><b>Refund Reason: </b>{orderScheduled.refundReason}
																										</div>}
																								</div>
																						
																						</div>
																						<ButtonGroup size="sm" className={"group_btn_order"}>
																								<Button variant="outline-primary"
																												onClick={() => this.setState({
																														visible: true,
																														view: false,
																														items: orderScheduled
																												})}>
																										View Order
																								</Button>
																						
																						</ButtonGroup>
																				</Card.Body>
																		</Card>);
														})
														}
														{!ordersScheduled.length ? <h2 className={"empty_result"}>Empty result</h2> : ""}
												
												</TabPanel>
												<TabPanel>
														<div>
																{ordersCompleted && ordersCompleted.map(orderCompleted => {
																		return (
																				<Card key={orderCompleted.slug} className={"mt-2"}>
																						<Card.Body>
																								<div className={"order_item_partnor"}>
																										<div className="item">
																												<div><b>N: </b>{orderCompleted.id}</div>
																												<div><b>Status: </b>
																														{orderCompleted.status === OrderStatusNumeral.IS_NEW ?
																																<Tag color="magenta">New</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_WAITING_RESTAURANT ?
																																<Tag color="red">Waiting restaurant</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_IN_PREPARING ?
																																<Tag color="orange">In Preparing</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_READY ?
																																<Tag color="cyan">Ready to delivery</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_DELIVERY ?
																																<Tag color="lime">Delivery</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_DONE ?
																																<Tag color="Green">Done</Tag> : ""
																														}
																														{orderCompleted.status === OrderStatusNumeral.IS_CANCELED ?
																																<Tag color="magenta">Canceled</Tag> : ""
																														}
																												</div>
																												{orderCompleted.extraCharge &&
																												<div><b>Extra Charge
																														Amount: </b>{orderCompleted.extraCharge}</div>}
																												{orderCompleted.extraCharge &&
																												<div><b>Extra Charge
																														Reason: </b>{orderCompleted.extraChargeReason}
																												</div>}
																												
																												{!orderCompleted.deliveryFee && orderCompleted.deliveryFeeRequest ?
																														<div><b>Remove delivery fee: </b><Tag
																																color="Green">True</Tag></div> : ""}
																										</div>
																										<div className="item">
																												<div>
																														<b>Created: </b>{moment(orderCompleted.created).format("DD MMMM YYYY, LT")}
																												</div>
																												<div><b>Canceled: </b>{orderCompleted.cancel ?
																														<Tag color="red">YES</Tag> : <Tag color="Green">NO</Tag>
																												}</div>
																												{orderCompleted.refund &&
																												<div><b>Refund : </b>{orderCompleted.refund}</div>}
																												{orderCompleted.refund &&
																												<div><b>Refund
																														Reason: </b>{orderCompleted.refundReason}
																												</div>}
																										</div>
																								
																								</div>
																								<ButtonGroup size="sm" className={"group_btn_order"}>
																										<Button variant="outline-primary"
																														onClick={() => this.setState({
																																visible: true,
																																items: orderCompleted,
																																view: false
																														})}>
																												View Order
																										</Button>
																								
																								</ButtonGroup>
																						</Card.Body>
																				</Card>);
																})
																}
																{!ordersCompleted.length ? <h2 className={"empty_result"}>Empty result</h2> : ""}
																<p style={{textAlign: "center", padding: "20px", margin: "auto"}}>
																		Only showing orders that are less then 24 hours old . <br/>To see older orders,
																		<a style={{color: "blue", textDecoration: "underline"}}
																			 onClick={e => this.props.history.replace(Paths.basePartner.replace(":tabName", "balance"))}>
																				go to Transactions
																		</a>
																</p>
														</div>
												</TabPanel>
										</Tabs>
								</section>
								<div className={`refoundPopUp ${reFoundInfo.slug === null ? "d-none" : "d-flex"}`}>
										<div>
												<label>Refund Amount</label>
												<input type="number" className="form-control" defaultValue={reFoundInfo.amount}
															 onInput={(e) => {
																	 this.setState({reFoundInfo: {slug: reFoundInfo.slug, amount: e.target.value}});
															 }}/>
												<small className="text-danger">{reFoundInfoErr}</small>
												<div>
														<button className="btn btn-success" onClick={this.sendRefund}
																		disabled={reFoundInfo.amount === ""}>Refound
														</button>
														<button className="btn btn-success" onClick={this.sendRefund}
																		disabled={reFoundInfo.amount !== ""}>Refound All
														</button>
														<button className="btn btn-danger"
																		onClick={() => this.setState({reFoundInfo: {slug: null, amount: null}})}>Cancel
														</button>
												</div>
										</div>
								</div>
								{orderModalVisible && (
										<OrderInfoModal
												orderModalVisible={orderModalVisible}
												orderSlug={orderSlug}
												onUpdate={this.onOrderUpdate}
												onClose={this.toggleInfoModal}
										/>
								)}
						</div>
				);
		}
}

const mapStateToProps = state => ({
		user: state.user.data,
		loading: state.loading,
		ordersActive: state.partnerOrders.ordersActive,
		ordersScheduled: state.partnerOrders.ordersScheduled,
		ordersCompleted: state.partnerOrders.ordersCompleted,
		audioState: state.audioState,
		restaurant: state.partnerRestaurant,
		newOrderLayoutState: state.partnerNewOrderLayoutState
});

const mapDispatchToProps = {
		showLoading,
		setPartnerOrders,
		updatePartnerOrder,
		setPartnerLastOrder,
		setNewOrderLayoutState
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(withRouter(PartnerOrders));


