import React, {useState} from 'react';
import Modal from '../HelpersComponents/Modal.Component';
import PropTypes from "prop-types";
import {showLocalLoading, showModal} from '../../actions/baseActions'
import {updateDriverOrderProperty} from '../../actions/orderActions'
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";
import {Button, Form, Rate, Spin} from "antd4";
import {sendDriverFeedback} from "../../services/Driver.Service";

const DriverFeedbackModal = ({updateDriverOrderProperty, showModal, showLocalLoading, driverFeedbackVisible, localLoading, modalProps}) => {
		const [clientRate, setClientRate] = useState(0);
		const [restaurantRate, setRestaurantRate] = useState(0);
		
		const handleSubmit = model => {
				if ('clientRate' in model && 'restaurantRate' in model && 'orderSlug' in modalProps) {
						showLocalLoading(true);
						sendDriverFeedback(model, modalProps.orderSlug).then(result => {
								updateDriverOrderProperty(modalProps.orderSlug, 'driverOpinion', result);
								showModal(ModalTypes.DRIVER_FEEDBACK_MODAL, false);
						}).finally(() => {
								showLocalLoading(false);
						});
				}
		};
		
		return (
				<Modal
						className={'DriverFeedbackModal'}
						visible={driverFeedbackVisible}
						onCancel={() => showModal(ModalTypes.DRIVER_FEEDBACK_MODAL, false)}
				>
						<div className={'DriverFeedback'}>
								
								<Spin spinning={localLoading}>
										<div className={'center'}>
												<p className={'feedback-title'}>Your opinion matters to us!</p>
										</div>
										<Form
												onFinish={handleSubmit}
												name={'driver_feedback'}
										>
												<div className="center">
														<p className={'feedback-sub-title'}>How was quality of the client</p>
												</div>
												<div style={{textAlign: 'center'}}>
														<Form.Item
																label={false}
																name={'clientRate'}
																rules={[{required: true, message: 'Client Rate should be selected'}]}
														>
																<Rate style={{fontSize: '40px'}} value={clientRate} onChange={rate => setClientRate(rate)}/>
														</Form.Item>
												</div>
												<div className="center">
														<p className={'feedback-sub-title'}>How was quality of the restaurant</p>
												</div>
												<div style={{textAlign: 'center'}}>
														<Form.Item
																label={false}
																name={'restaurantRate'}
																rules={[{required: true, message: 'Restaurant Rate should be selected'}]}
														>
																<Rate style={{fontSize: '40px'}} value={restaurantRate} onChange={rate => setRestaurantRate(rate)}/>
														</Form.Item>
												</div>
												<div className={'submit-container'}>
														<Button type={clientRate && restaurantRate ? 'primary' : 'primary disabled'} htmlType="submit" disabled={!(clientRate && restaurantRate)}>
																Rate now
														</Button>
												</div>
										</Form>
								</Spin>
						</div>
				</Modal>
		)
}

DriverFeedbackModal.propTypes = {
		driverFeedbackVisible: PropTypes.bool.isRequired,
		localLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
		driverFeedbackVisible: state.modalVisible.driverFeedback,
		localLoading: state.localLoading,
		modalProps: state.modalVisible.modalProps
});

const mapDispatchToProps = {
		showModal,
		showLocalLoading,
		updateDriverOrderProperty
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps,
)(DriverFeedbackModal));
