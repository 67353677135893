import * as HomeApi from '../api/Home.API';
import {StringifyUrlEncoded} from "../api/Http.Client";
import {handleResponse} from "./Utilities";

export const searchItemsByText = searchText =>
		HomeApi.searchItemsByText(searchText).then(result => handleResponse(
				result,
				undefined,
				() => result.data
		))

/**
 * @deprecated
 */
export const searchProductsByText = (searchText, isCatering = false, onlyRestaurant = false, offset = 1, limit = 5, filterModel = {}) => {
		const queryModel = StringifyUrlEncoded({
				search: searchText,
				type: !!onlyRestaurant,
				offset,
				limit,
				catering: isCatering,
				...filterModel
		});
		return HomeApi.searchProductsByText(queryModel).then(result => {
				if (result && result.success) {
						return result.data;
				}
		})
};
