import {notify, notiType} from "../services/Utilities";
import Config from 'Config';
import getStore from "../store/configureStore";
import newOrderSound from "../sounds/sound-1.wav";
import WssAction from "../enums/WSS.Action.Enum";

const store = getStore();
const newOrderNotify = new Audio(newOrderSound);

let PartnerManagerWssConnection = undefined;

export const initPartnerManagerConnection = (brunchSlug, restaurantSlug) => {
	if (brunchSlug && restaurantSlug) {
		PartnerManagerWssConnection = new WebSocket(`${Config.WssUrl}partner-manager?restaurant=${restaurantSlug}&brunch=${brunchSlug}`);
		
		PartnerManagerWssConnection.onopen = () => {
			if (PartnerManagerWssConnection) {
				console.log('Partner manager connection was created successfully.');
			}
		};
		
		PartnerManagerWssConnection.onclose = event => {
			if (event.wasClean) {
				console.log('Partner Manager session disconnect successfully');
			} else {
				console.log('Code: ' + event.code + ' reason: ' + event.reason);
			}
		};
		
		PartnerManagerWssConnection.onerror = error => {
			console.log("error", error.message);
		};
		
		PartnerManagerWssConnection.onmessage = event => {
			try {
				const data = JSON.parse(event.data);
				if ('action' in data) {
					if (data.action === WssAction.ACTION_SUBMITTED) {
						if (store.getState().audioState) {
							newOrderNotify.play();
						}
						notify(notiType.success, 'New Order')
					}
					document.dispatchEvent(new CustomEvent('PartnerManagerConnection', {
						detail: data
					}));
				}
			} catch (e) {
				console.log(e);
			}
		};
	}
	return PartnerManagerWssConnection;
};

export const getPartnerManagerWssConnection = (brunchSlug = null, restaurantSlug = null) => {
	if (PartnerManagerWssConnection && PartnerManagerWssConnection.readyState) {
		return PartnerManagerWssConnection;
	} else if (brunchSlug && restaurantSlug) {
		PartnerManagerWssConnection = initPartnerManagerConnection(brunchSlug, restaurantSlug);
		return PartnerManagerWssConnection;
	}
	return false;
};

export const closePartnerManagerConnection = () => {
	if (PartnerManagerWssConnection) {
		PartnerManagerWssConnection.close();
		return true;
	}
	return false;
};

export const sendPartnerManagerWssMessage = (message) => {
	
	if (PartnerManagerWssConnection) {
		PartnerManagerWssConnection.send(JSON.stringify(message))
	}
	return false;
};
