import React from "react";
import {Card, Modal} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import numeral from "numeral";

class PartnerFinancialView extends React.Component {
		
		render() {
				const {visible, order} = this.props;
				let refund = 0;
				let chargeExtra = 0;
				if (order && order.refund) {
						refund = refund + order.refund;
				}
				if (order && order.extraCharge) {
						chargeExtra = chargeExtra + order.extraCharge;
				}
				let subTotal = 0;
				return (
						<Modal
								title={order ? "Order #" + order.id : ""}
								visible={visible}
								footer={false}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: false});
								}}>
								{order ?
										<div>
												<Card
														title={"Gross total " + numeral(Math.abs(order.totalPrice) + chargeExtra - refund).format("$ 0,0[.]00")}
														className={"card_order_view"}>
														<p>MINUS Commissions <span>- $0</span></p>
														<p>MINUS Order processing fees <span>- $0</span></p>
														<p>MINUS Withheld sales
																tax <span>- {numeral(Math.abs(order.tax)).format("$ 0,0[.]00")}</span></p>
														{/*<p>PLUS Charge Extra <span>+ {numeral(Math.abs(chargeExtra)).format("$ 0,0[.]00")}</span></p>*/}
														<hr/>
														<h5>Net
																total {numeral(Math.abs(order.totalPrice - refund - order.tax + chargeExtra)).format("$ 0,0[.]00")}</h5>
												</Card>
												{Object.keys(order.item).map(key => (
																<div key={key + 'ss'} className={'card_custom'}>
																		<span style={{display: 'none'}}>{subTotal = subTotal + order.item[key].price}</span>
																		<p>{order.item[key].product.name}
																				<span>{numeral(Math.abs(order.item[key].price)).format("$ 0,0[.]00")}</span></p>
																		<ul style={{listStyle: 'disc'}}>
																				{order.item[key].size ? <li>{order.item[key].size.name}</li> : ''}
																				{Object.keys(order.item[key].ingredients).map(key1 => (
																						<li key={key1}>{order.item[key].ingredients[key1].ingredient.name} - {order.item[key].ingredients[key1].ingredient.plusPrice}$</li>
																				))}
																		</ul>
																</div>
														)
												)}
												<div className="total_card">
														<p className={'bold'}>
																Subtotal <span>{numeral(Math.abs(subTotal)).format("$ 0,0[.]00")}</span>
														</p>
														<p>
																Tax <span>{numeral(Math.abs(order.tax)).format("$ 0,0[.]00")}</span>
														</p>
														<p>
																Tip <span>{numeral(Math.abs(order.tipType === 0 ? 0 : order.tipAmount)).format("$ 0,0[.]00")}</span>
														</p>
														<hr/>
														<p className={'bold'} style={{fontSize: '18px', marginBottom: 0}}>
																Restaurant
																total <span>{numeral(Math.abs(order.totalPrice - order.deliveryPrice - (order.tipType === 0 ? order.tipAmount : 0))).format("$ 0,0[.]00")}</span>
														</p>
														<p>Prepaid order</p>
												</div>
										</div> : ""
								}
						</Modal>
				);
		}
}


PartnerFinancialView.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired
};

PartnerFinancialView.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		order: null
};

export default connect(
		() => ({}),
		{showLoading}
)(PartnerFinancialView);
