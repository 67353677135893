import React from "react";
import {Button, Input, Form} from "antd4";
import {createContactUser, updateContactUser} from "../../../services/Partner.Service";
import {messageType, onlyNumeric, showMessage, validateEmail} from "../../../services/Utilities";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import Modal from "../../HelpersComponents/Modal.Component";

class ContactUsers extends React.Component {
		
		handleSubmit = values => {
				values.restaurantId = this.props.restaurantId
				this.props.showLoading(true);
				if('id' in values && values.id){
						updateContactUser(values.id, values).then(result => {
								if (result) {
										this.props.onCategoryUserAdded(result);
										this.props.onVisibleUsersChange(false);
										showMessage(messageType.success, 'Contact account has been successfully added.')
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}else{
						createContactUser(values).then(result => {
								if (result) {
										this.props.onCategoryUserAdded(result);
										this.props.onVisibleUsersChange(false);
										showMessage(messageType.success, 'Contact account has been successfully added.')
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		render() {
				const {visible, editUserInfo} = this.props;
				return (
						<Modal
								title="User"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleUsersChange(false);
								}}
								footer={false}
						>
								<div onKeyPress={this.onEnter}>
										<Form name={"addCategoryForm"} onFinish={this.handleSubmit} className="login-form">
												<div className="GlobalInput " style={{height: 0}}>
														<Form.Item
																name={"id"}
																initialValue={editUserInfo ? editUserInfo.id : null}
														>
																<Input type={'hidden'}/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"First name"}
																name={"firstName"}
																rules={[{required: true, message: "Please input first name!"}]}
																initialValue={editUserInfo ? editUserInfo.firstName : ''}
														>
																<Input placeholder="First name"/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"Last name"}
																name={"lastName"}
																rules={[{required: true, message: "Please input last name!"}]}
																initialValue={editUserInfo ? editUserInfo.firstName : ''}
														>
																<Input placeholder="Last name"/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"Position"}
																name={"position"}
																rules={[{required: true, message: "Please input position"}]}
																initialValue={editUserInfo ? editUserInfo.position : ''}
														>
																<Input placeholder="Position"/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																label={"Eamil"}
																name={"email"}
																rules={[{required: true, message: "Please input email!"}, ({getFieldValue}) => ({
																		validator(rule, value) {
																				if (validateEmail(value)) {
																						return Promise.resolve();
																				}
																				return Promise.reject("Please fill correct email");
																		}
																})]}
																initialValue={editUserInfo ? editUserInfo.email : ''}
														>
																<Input type={"email"} placeholder="Email" onKeyPress={validateEmail}/>
														</Form.Item>
												</div>
												<div className="GlobalInput ">
														<Form.Item
																name={"phone"}
																label={"Phone"}
																rules={[{required: true, message: "Please input phone!"}]}
																initialValue={editUserInfo ? editUserInfo.phone : ''}
														>
																<Input addonBefore={"+"} onKeyPress={onlyNumeric} placeholder="Phone"/>
														</Form.Item>
												</div>
												<Form.Item>
														<Button htmlType="submit">{editUserInfo ? 'Update User' : 'Add user'}</Button>
												</Form.Item>
										</Form>
								</div>
						</Modal>
				);
		}
}


ContactUsers.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleUsersChange: PropTypes.func.isRequired,
		onCategoryUserAdded: PropTypes.func.isRequired
};

ContactUsers.defaultProps = {
		visible: false,
		editUserInfo: null,
		onVisibleUsersChange: f => f,
		onCategoryUserAdded: f => f
};

export default connect(
		state => ({
				restaurantId: state.partnerRestaurant.id,
		}),
		{showLoading}
)(ContactUsers);
