import LeftMenu from "../HomeComponents/LeftMenu.Component";
import React from "react";

import RectangleProduct from "../HelpersComponents/ProductsComponents/RectangleSecond.Product.Component";

import Panel from "../HelpersComponents/Panel.Component";
import "../../styles/SearchStyle/search.scss";
import noResult from "../../img/no_result.png";
import qs from "qs";
import {searchProductsByText} from "../../services/Product.Service";
import { Spin } from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Scroll from '../../services/Scroll';
import {showLoading} from "../../actions/baseActions";
import {isMobileDevice} from "../../services/Utilities";
import {resetFilter} from '../../actions/filterActions';
import {OrderType} from "../../enums/Order.Status.Enum";

class Search extends React.Component {
	isMobile = isMobileDevice();
	state = {
		query: undefined,
		searchLoading: false,
		searchRestaurants: [],
		searchProducts: [],
		offset: 1,
		end: false
	};
	
	unlisten = undefined;
	
	UNSAFE_componentWillMount() {
		this.getSearchResult(this.props.location);
		
		this.unlisten = this.props.history.listen(this.getSearchResult);
	}
	
	componentDidMount() {
		document.addEventListener('scroll', this.trackScrolling);
		document.addEventListener('updateOrderType', this.updateOrderType);
	}
	
	componentWillUnmount() {
		document.removeEventListener('scroll', this.trackScrolling);
		document.removeEventListener('updateOrderType', this.updateOrderType);
		this.props.resetFilter();
		if (this.unlisten) {
			this.unlisten()
		}
	}
	
	updateOrderType = evt => {
		const {detail} = evt;
		
		const isCatering = detail === OrderType.IS_CATERING;
		
		this.props.showLoading(true);
		Scroll.scrollToStartOfPage();
		this.setState({
			offset: 1,
			end: false
		}, () => this.getSearchProducts({}, isCatering, false));
	};
	
	getSearchResult = location => {
		if (!location || !('search' in location)) {
			return;
		}
		
		const queryParams = qs.parse(location.search, {
			delimiter: /[?,&]/
		});
		
		this.setState({
			query: 'query' in queryParams && queryParams.query ? queryParams.query: undefined,
			searchLoading: true
		}, () => this.getSearchProducts({}, this.props.isCatering));
	};
	
	onFilter = filterModel => {
		// this.props.showLoading(true);
		Scroll.scrollToStartOfPage();
		this.setState({
			offset: 1,
			end: false
		}, () => this.getSearchProducts(filterModel, this.props.isCatering));
	};
	
	getSearchProducts = (filterModel = {}, isCatering, needToFilter = true) => {
		const {query, offset, searchRestaurants} = this.state;
		const {isFiltered} = this.props;
		
		filterModel = Object.assign({}, needToFilter && isFiltered ? this.props.filter : {}, filterModel, {catering: isCatering});
		
		const limit = isMobileDevice() ? 2 : 5;
		return searchProductsByText(query, isCatering,true, offset, limit, filterModel).then(result => {
			if (result) {
				this.setState({
					searchRestaurants: offset !== 1 ? [...searchRestaurants, ...result] : result,
					end: result.length < limit
				});
				return result;
			}
		}).finally(() => {
			// document.body.style.overflowY = "visible";
			this.setState({searchLoading: false})
			this.props.showLoading(false);
		})
	};
		
		getOpenRestaurantsCount = () => {
				const {searchRestaurants} = this.state;
				
				return searchRestaurants.filter(item => 'openNow' in item && item.openNow).length;
		}
	
	render() {
		const {query, searchLoading, searchRestaurants} = this.state;
		
		return (
			<Spin spinning={false}>
				<div className={""}>
					<div className="Search">
						<div className="">
							<LeftMenu
								menuTitle={'Menu\'s'}
								onFilter={this.onFilter}
								openRestaurantsCount={this.getOpenRestaurantsCount()}
							/>
						</div>
						<div className={"LandingPanel"} id={'LandingPanel'}>
							{!searchLoading && (
								<div className="Restaurant">
									{searchRestaurants.length ? (
										<>
											{query ? (<h4>Search result for <span>{query}</span></h4>) : (<h4>Search result</h4>)}
											<span
												className="RestaurantCount">{searchRestaurants.length} restaurants</span>
										</>
									) : (
										<>
											<h4>No search result</h4>
											<img className={"NoResult"} src={noResult} alt=""/>
										</>
									)}
								
								</div>
							)}
							
							{searchRestaurants.map((restaurant, index) => (
								<Panel
									className={"RestaurantPanel"}
									title={restaurant.name}
									key={`${restaurant.slug}-${index}`}
									contentScrollable={false}
								>
									{restaurant.products.map((product, index) => (
										<RectangleProduct
											key={`${product.slug}-${index}`}
											productName={product.name}
											productSlug={product.slug}
											imgPath={ product.downloadLink || restaurant.downloadLink}
											price={product.price}
											description={product.productCategoriesLine}
											isCatering={this.props.isCatering}
										/>
									))}
								</Panel>
							))}
						</div>
					</div>
				</div>
			</Spin>
		)
	}
	
	isBottom(el) {
		return el.getBoundingClientRect().bottom - (this.isMobile ? 700 : 1100) <= window.innerHeight;
	}
	
	trackScrolling = event => {
		event.preventDefault();
		const wrappedElement = document.getElementById('LandingPanel');
		if (wrappedElement && this.isBottom(wrappedElement)) {
			const {searchLoading, offset, end} = this.state;
			if (!searchLoading && !end) {
				this.setState({
					searchLoading: true,
					offset: offset + 1
				}, () => this.getSearchProducts({}, this.props.isCatering))
			}
		}
	};
}

Search.propTypes = {
	filter: PropTypes.object.isRequired,
	isFiltered: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	isFiltered: state.filter.isFiltered,
	filter: state.filter,
	isCatering: state.isCatering
});

const mapDispatchToProps = {
	showLoading,
	resetFilter,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Search);

