import React from 'react';
import {toggleAudio} from "../../actions/baseActions";
import {connect} from "react-redux";
import AudioOn from "../../img/notifications-on-orange.png";
import AudioOff from "../../img/notifications-off-oreange.png";

const ToggleAudio = ({toggleAudio = f => f, audioState}) => {
		const toggle = () => {
				toggleAudio(!audioState)
		};
		
		return (
				<img
						src={audioState ? AudioOn : AudioOff}
						className="NewOrderSound"
						onClick={toggle}
						title={audioState ? 'Turn Off' : 'Turn On'}
						alt='Audio'
				/>
		)
};

const mapStateToProps = state => ({
		audioState: state.audioState
});

const mapDispatchToProps = {
		toggleAudio,
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(ToggleAudio);
