import React, {Component} from "react";
import {Button, Modal, Popconfirm, Radio, Spin} from "antd4";
import {showLoading} from "../../actions/baseActions";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
		getOrder,
		updateOrderStatus,
		cancelOrder,
		partnerAcceptOrder,
		partnerFinishOrder
} from "../../services/Partner.Service";
import numeral from "numeral";
import {OrderItemRestaurantStatusNumeral} from "../../enums/Order.Status.Enum";
import * as UserService from "../../services/User.Service";
import {hasRole, messageType} from "../../services/Utilities";
import {Roles as ServerRoles} from "../../enums/Server.Sider.Roles.enum";

class OrderInfoModal extends Component {
		state = {
				order: {},
				userAccess: null,
				delay: null,
				delayReason: null
		};
		
		UNSAFE_componentWillMount() {
				const {orderSlug} = this.props;
				if (orderSlug) {
						this.props.showLoading(true);
						
						UserService.getCurrentUser().then(result => {
								if (result) {
										this.setState({userAccess: hasRole(result, [ServerRoles.ROLE_PARTNER]) ? true : result.refundAccess});
								}
						});
						
						getOrder(orderSlug).then(result => {
								if (result && "id" in result) {
										this.setState({
												order: result
										});
								} else {
										this.props.onClose();
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		}
		
		updateOrderStatus = (orderSlug, lastStatus, newStatus) => {
				if (orderSlug && newStatus) {
						this.props.showLoading(true);
						return updateOrderStatus(orderSlug, lastStatus, newStatus).then(order => {
								if (order && "id" in order) {
										this.setState({
												order: order
										}, () => {
												this.props.onUpdate(order);
												this.props.onClose();
										});
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		
		partnerAcceptOrder = orderSlug => {
				const {delay, delayReason} = this.state;
				
				this.props.showLoading(true);
				return partnerAcceptOrder(orderSlug, delay, delayReason).then(order => {
						if (order && "id" in order) {
								this.setState({
										order: order
								}, () => {
										this.props.onUpdate(order);
										this.props.onClose();
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		partnerFinishOrder = orderSlug => {
				this.props.showLoading(true);
				return partnerFinishOrder(orderSlug).then(order => {
						if (order && "id" in order) {
								this.setState({
										order: order
								}, () => {
										this.props.onUpdate(order);
										this.props.onClose();
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		sendCanceling = async orderSlug => {
				this.props.showLoading(true);
				return cancelOrder(orderSlug).then(order => {
						if (order && "id" in order) {
								this.setState({
										order: order
								}, () => {
										this.props.onUpdate(order, `The Order #${order.id} has been canceled !`, messageType.warning);
										this.props.onClose();
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
				
		};
		
		getOrderInfoButton = () => {
				const {order, delay, delayReason} = this.state;
				
				if (order && "item" in order) {
						if (order.restaurantStatus !== OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY) {
								if (order.restaurantStatus === OrderItemRestaurantStatusNumeral.IS_NEW) {
										return (
												<Button
														className={"orderAccept"}
														type={"primary"}
														disabled={!([15,20,25].includes(delay) || ([30, 45, 60].includes(delay) && delayReason))}
														onClick={() => this.partnerAcceptOrder(order.slug)}
												>
														Accept order
												</Button>
										);
								} else if (order.restaurantStatus === OrderItemRestaurantStatusNumeral.IS_RESTAURANT_PREPARING) {
										return (
												<Button
														className={"orderDone"}
														type={"primary"}
														onClick={() => this.partnerFinishOrder(order.slug)}
												>
														Order ready
												</Button>
										);
								}
						}
				}
				return "";
		};
		
		onDelayChange = ({target: {value}}) => {
				const {delay} = this.state;
				value = parseInt(value);
				value = (value === delay) ? undefined : value;
				this.setState({delay: value})
		};
		
		onDelayReasonChange = ({target: {value}}) => {
				this.setState({delayReason: value})
		};
		
		getPreparingDurationButtons = () => {
				const {order, delay, delayReason} = this.state;
				
				if (![30, 45, 60].includes(delay) && order.restaurantStatus === OrderItemRestaurantStatusNumeral.IS_NEW) {
						return (
								<div className={'preparing-duration-container'}>
										<Radio.Group value={delay} buttonStyle="solid">
												<div className={"preparing-duration-buttons"}>
														<label>Ready in</label>
														<Radio.Button onClick={this.onDelayChange} value={15}>0 - 15 min</Radio.Button>
														<Radio.Button onClick={this.onDelayChange} value={20}>20 min</Radio.Button>
														<Radio.Button onClick={this.onDelayChange} value={25}>25 min</Radio.Button>
												</div>
												<div className={"preparing-duration-buttons"}>
														<label>Need additional time?</label>
														<Radio.Button onClick={this.onDelayChange} value={35}>35 min</Radio.Button>
														<Radio.Button onClick={this.onDelayChange} value={45}>45 min</Radio.Button>
														<Radio.Button onClick={this.onDelayChange} value={60}>60+ min</Radio.Button>
												</div>
										</Radio.Group>
								</div>
						);
				} else if ([30, 45, 60].includes(delay)) {
						return (
								<div style={{marginTop: "10px"}}>
										<Radio.Group
												onChange={this.onDelayReasonChange}
												value={delayReason}>
												<Radio selected={delayReason === "Busier than normal"} value={"Busier than usual"}>Busier than usual</Radio>
												<Radio selected={delayReason === "Larger order"} value={"Larger order"}>Larger order</Radio>
												<Radio selected={delayReason === "Items require longer prep time"} value={"Items require longer prep time "}>Items require longer prep time </Radio>
										</Radio.Group>
								</div>
						)
				}
		}
		
		render() {
				const {loading, orderModalVisible, onClose} = this.props;
				const {order, userAccess} = this.state;
				
				return (
						<Modal
								title={order && order.id ? `Order #${order.id}` : ""}
								footer={!order.cancel ? (
										<div className="orderButtons">
												{userAccess ?
														<Popconfirm title="Are you sure？" okText="Yes" cancelText="No"
																				disabled={order.testOrder}
																				onConfirm={() => this.sendCanceling(order.slug)}>
																<Button danger={true} style={{backgroundColor: "red", color: "white"}} disabled={order.testOrder}>
																		Order Cancel
																</Button>
														</Popconfirm>
														: ""}
												{this.getOrderInfoButton()}
										</div>
								) : ''}
								width={"100%"}
								className={"OrderInfoModal"}
								destroyOnClose={true}
								visible={orderModalVisible}
								onCancel={onClose}
						>
								{!order.cancel ?
										<Spin spinning={loading}>
												<div className={"gridable"}>
														{order.item && order.item.map(item => (
																<div key={item.id} className="itemDiv">
																		<p className="productName">
																				<b>{item.product ? item.product.name : item.catering ? item.catering.name : ""}</b>
																		</p>
																		{item.size && (
																				<p><b>Size</b>: {item.size.name} <span
																						className="plus-price">{numeral(item.size.plusPrice).format("+ $0,0[.]00")}</span>
																				</p>
																		)}
																		<ul className="removable">
																				{item.ingredients && item.ingredients.map(ingredient => (
																						<li
																								key={ingredient.ingredient.slug}>{ingredient.ingredient.name} {ingredient.ingredient.plusPrice && (
																								<span
																										className="plus-price">{numeral(ingredient.ingredient.plusPrice).format("+ $0,0[.]00")}</span>)}</li>
																				))}
																		</ul>
																		<p><b>QTY</b>: {item.cnt}</p>
																		<p><b>Price</b>: <span
																				className="price">{numeral(item.price).format('$ 0,0[.]00')}</span></p>
																		{item.cancel ? <h2 className="cancel">Item Canceled</h2> : ""}
																</div>
														))}
														
														<div>
																{this.getPreparingDurationButtons()}
														</div>
												</div>
										</Spin>
										:
										<h2 className="cancel">Order Canceled</h2>
								}
						</Modal>
				);
		}
}

OrderInfoModal.propTypes = {
		orderModalVisible: PropTypes.bool.isRequired,
		orderSlug: PropTypes.string.isRequired,
		onUpdate: PropTypes.func.isRequired,
		onClose: PropTypes.func.isRequired
};

OrderInfoModal.defaultProps = {
		onUpdate: f => f
};

const mapStateToProps = state => ({
		loading: state.loading
});

const mapDispatchToProps = {
		showLoading
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(OrderInfoModal);
