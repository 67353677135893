import React from 'react';
import {
	OrderStatusNumeral,
	OrderItemDriverStatusNumeral,
	OrderItemRestaurantStatusNumeral
} from "./Order.Status.Enum";
import {Tag} from "antd4";
import moment from "moment";
import numeral from "numeral";

export const OrderColumns = [
	{
		title: '#',
		dataIndex: 'id',
		key: 'id',
		render: id => `# ${id}`,
		sorter: (a,b) => a.id - b.id
	}, {
		title: 'Status',
		dataIndex: 'status',
		key: 'status',
		render: status => {
			switch (status) {
				case OrderStatusNumeral.IS_NEW:
					return <Tag color="magenta">New</Tag>;
				case OrderStatusNumeral.IS_WAITING_RESTAURANT:
					return <Tag color="red">Waiting restaurant</Tag>;
				case OrderStatusNumeral.IS_IN_PREPARING:
					return <Tag color="orange">In Preparing</Tag>;
				case OrderStatusNumeral.IS_READY:
					return <Tag color="cyan">Ready to delivery</Tag>;
				case OrderStatusNumeral.IS_DELIVERY:
					return <Tag color="lime">Delivery</Tag>;
				case OrderStatusNumeral.IS_DONE:
					return <Tag color="Green">Done</Tag>;
				default:
					return ''
			}
		},
		sorter: (a,b) => a.status - b.status
	}, {
		title: 'Created',
		dataIndex: 'created',
		key: 'created',
		render: created => moment(created).format('DD MMMM YYYY, LT'),
		sorter: (a,b) => new Date(b.created) - new Date(a.created)
	}, {
		title: 'Canceled',
		dataIndex: 'cancel',
		key: 'cancel',
		render: cancel => {
			if(cancel){
				return <Tag color="red">Order Canceled</Tag>;
			}else{
				return <Tag color="Green">False</Tag>;
			}
		}
	}
];

export const OrderItemColumns = [
	{
		title: '#',
		dataIndex: 'id',
		key: 'id',
		render: id => `# ${id}`,
	},{
		title: 'Canceled',
		dataIndex: 'cancel',
		key: 'cancel',
		render: cancel => {
			if(cancel){
				return <Tag color="red">Item Canceled</Tag>;
			}else{
				return <Tag color="Green">False</Tag>;
			}
		}
	}, {
		title: 'Product',
		dataIndex: 'product',
		key: 'product',
		render: product => product && 'name' in product ? product.name : '',
	}, {
		title: 'Price',
		dataIndex: 'price',
		key: 'price',
		render: price => numeral(price).format('$ 0,0.[00]'),
	}, {
		title: 'Comment',
		dataIndex: 'comment',
		key: 'comment',
	}, {
		title: 'Size',
		dataIndex: 'size',
		key: 'size',
		render: size => {
			if (size && 'id' in size) {
				return <div>
					{size.name}
					{size.plusPrice && (
						<span className="PlusPrice">{numeral(size.plusPrice).format('+ $ 0,0.[00]')}</span>
					)}
				</div>
			}
			return '';
		},
	}, {
		title: 'Ingredients',
		dataIndex: 'ingredients',
		key: 'ingredients',
		render: ingredients => (
			<ul>
				{ingredients.map(item => (
					<li key={item.ingredient.slug}>
						{item.ingredient.name}{item.ingredient.plusPrice && (
						<span
							className="PlusPrice">{numeral(item.ingredient.plusPrice).format('+ $ 0,0.[00]')}</span>
					)}
					</li>)
				)}
			</ul>)
	}, {
		title: 'Restaurant state',
		dataIndex: 'restaurantStatus',
		key: 'restaurantStatus',
		render: status => {
			switch (status) {
				case OrderItemRestaurantStatusNumeral.IS_NEW:
					return <Tag color="magenta">New</Tag>;
				case OrderItemRestaurantStatusNumeral.IS_RESTAURANT_PREPARING:
					return <Tag color="cyan">Preparing</Tag>;
				case OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY:
					return <Tag color="green">Ready</Tag>;
				default:
					return ''
			}
		},
	}, {
		title: 'Driver state',
		dataIndex: 'driverStatus',
		key: 'driverStatus',
		render: status => {
			switch (status) {
				case OrderItemDriverStatusNumeral.IS_NEW:
					return <Tag color="magenta">New</Tag>;
				case OrderItemDriverStatusNumeral.IS_DRIVER_ACCEPTED:
					return <Tag color="red">Accept</Tag>;
				case OrderItemDriverStatusNumeral.IS_DRIVER_ARRIVED:
					return <Tag color="cyan">Arrived</Tag>;
				case OrderItemDriverStatusNumeral.IS_DRIVER_DEPARTED:
					return <Tag color="green">Delivering </Tag>;
				case OrderItemDriverStatusNumeral.IS_DRIVER_END:
					return <Tag color="green">End</Tag>;
				default:
					return ''
			}
		},
	},{
		title: 'Location',
		dataIndex: 'location',
		key: 'location',
		render: location => location && 'name' in location ? location.name : '',
	},
];
