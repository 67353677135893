import React, {Component} from "react";
import WelcomeBg from "../../img/Cover-main-page.png";
import logoBig from "../../img/Logo_Big.svg";
import moto from "../../img/ic_moto.png";
import AddAddress from "../HelpersComponents/Add.Address.Component";
import NavSignIn from "./PartialsComponents/Nav.SignIn.Component";
import {showModal} from "../../actions/baseActions";
import {connect} from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";

class Preview extends Component {
		render() {
				const {showModal} = this.props;
				return (
						<div className='WelcomePage'>
								<div className="WelcomePageNav">
										<ul>
												<NavSignIn/>
										</ul>
								</div>
								<img src={WelcomeBg} className='WelcomeBg' alt=""/>
								<div className="HomeSearchComponent Welcome">
										<img src={logoBig} alt=""/>
										<h2>Delivering best food</h2>
										<p>Food, drinks and groceries available for delivery or pickup.</p>
										<div>
												<AddAddress inputPlaceholder={"Enter your delivery address"}/>
										</div>
								</div>
								<img className='WelcomeMoto' src={moto} alt=""/>
								<div className="WelcomePageTerms">
										<ul>
												<li onClick={() => showModal(ModalTypes.TERMS_AND_CONDITIONS_MODAL)}>Terms of use</li>
												<li onClick={() => showModal(ModalTypes.PRIVACY_POLICY_MODAL)}>Privacy Policy</li>
										</ul>
								</div>
						</div>
				);
		}
}

const mapStateToProps = state => ({
		termsAndConditionsModalVisible: state.modalVisible.termsAndConditions
});

const mapDispatchToProps = {
		showModal
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(Preview);

