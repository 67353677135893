import React, {useEffect, useState} from "react";
import {Form, Button} from "antd4";
import IngredientFormList from "./Sections/IngredientFormList";
import BasicFormList from "./Sections/BasicFormList";
import SizeGroupsFormList from "./Sections/SizeGroups";
import {
		addNewPartnerProduct,
		getPartnerCategories, getPartnerDietFriendlies,
		getPartnerProduct,
		updatePartnerProduct
} from "../../../../services/Partner.Service";
import Paths from "../../../../services/Paths";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading} from "../../../../actions/baseActions";

const ProductCreateOrEdit = ({productSlug, showLoading, history}) => {
		
		const [categoriesList, setCategoriesList] = useState([]);
		const [dietFriendliesList, setDietFriendliesList] = useState([]);
		const [product, setProduct] = useState(null);
		
		useEffect(() => {
				getPartnerCategories().then(result => {
						result && !categoriesList.length && setCategoriesList(result.categories);
				});
				
				getPartnerDietFriendlies().then(result => {
						result && !dietFriendliesList.length && setDietFriendliesList(result.dietFriendlies);
				});
				
				if (productSlug) {
						if (!product) {
								showLoading(true);
								getPartnerProduct(productSlug)
										.then(result => {
												if (result) {
														setProduct({
																...result,
																sizeGroups: result.sizeGroups.map((item) => {
																		return {
																				name: item.name,
																				slug: item.slug,
																				size: item.size.map(item => {
																						return {
																								size: item.name,
																								slug: item.slug,
																								plusPrice: item.plusPrice
																						};
																				})
																		};
																}),
																categories: result.categories.map(category => category.slug)
														});
												} else {
														history.push(Paths.basePartner.replace(":tabName", "products"));
														alert("getPartnerProduct Error");
												}
										})
										.finally(() => {
												showLoading(false);
										});
						}
				} else {
						//To insure that Form renders once to get initialValues once
						!product && setProduct({});
				}
				
		}, [product]);
		
		const onFinish = values => {
				let f = false;
				Object.keys(values).forEach((e) => {
						if (values[e] !== product[e]) {
								f = true;
						}
				});
				if (!f && !values.file) {
						history.push(Paths.basePartner.replace(":tabName", "products"));
						return
				}
				values.file = values.file ? values.file.file.originFileObj : null;
				
				showLoading(true);
				if (product && product.id) {
						updatePartnerProduct(values, product.slug).then(result => {
								if(result){
									// history.push(Paths.basePartner.replace(":tabName", "products"));
								}
						}).finally(() => {
								showLoading(false);
						});
				} else {
						addNewPartnerProduct(values).then(result => {
								if (result) {
										history.push(Paths.basePartner.replace(":tabName", "products"));
								}
						}).finally(() => {
								showLoading(false);
						});
				}
		};
		return (
				product && setCategoriesList ?
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>{!productSlug ? "Create Products" : `Edit Products (#${product && product.id ? product.id : ""})`}</h1>
								</section>
								<section className={"ContentBody"}>
										<Form
												onFinish={onFinish}
												className="createOrEditProduct row"
												initialValues={product}
												layout={"vertical"}
										>
												<BasicFormList categoriesList={categoriesList} dietFriendliesList={dietFriendliesList} downloadLink={product.downloadLink}/>
												<SizeGroupsFormList/>
												<IngredientFormList/>
												<Form.Item className="col-12">
														<Button htmlType="submit" className="float-right">
																{!productSlug ? "Add" : "Update"} Product
														</Button>
												</Form.Item>
										</Form>
								</section>
						</div>
						: null
		);
};

export default withRouter(connect(null, {showLoading})(ProductCreateOrEdit));
