import React from "react";
import {Rate, Spin} from 'antd4';
import Review from "./Review";
import {getReviews, clearReviews} from "../../actions/reviewActions";
import {connect} from "react-redux";
import moment from 'moment';
import numeral from "numeral";

class RestaurantReview extends React.Component {
		
		state = {
				loading: true,
				reviews: [],
		};
		
		offset = 0;
		
		UNSAFE_componentWillMount() {
				this.getMore();
		}
		
		componentWillUnmount() {
				this.props.clearReviews();
				
		}
		
		getMore = () => {
				const {restaurantSlug, getReviews} = this.props;
				getReviews(restaurantSlug, ++this.offset)
		};
		
		render() {
				const {loading, reviews, hasMore, rate, data, isAuth, restaurantName, restaurantImg} = this.props;
				return (
						<Spin spinning={loading}>
								<div className={"RestaurantReviews"}>
										<h4>All Reviews <span>({reviews && reviews.length})</span></h4>
										<div className={"Rating"}>
												<Rate disabled defaultValue={rate}/>
												<span>{!!rate && `${numeral(rate).format('0[.]0')} ratings`}</span>
										</div>
										{isAuth && <div className="AllReviews">
												<ul>
														{reviews.map(review => (
																<Review
																		key={review.id}
																		colorCode={"#E85D42"}
																		name={review.user ? review.user.clientFullName : ''}
																		date={moment(review.created).format('DD MMMM YYYY, LT')}
																		rate={review.foodRate}
																		review={review.opinion}
																		answer={review.answer}
																		restaurantName={restaurantName}
																		restaurantImg={restaurantImg}
																/>
																))}
												</ul>
												{hasMore ? (
														<div>
																<button onClick={this.getMore}>show more</button>
														</div>
												) : ''}
										</div>}
								</div>
						</Spin>
				)
		}
}

const mapStateToProps = state => ({
		user: state.user,
		isAuth: state.user.isAuth,
		loading: state.loading,
		reviews: state.reviews.data,
		hasMore: state.reviews.hasMore,
});

const mapDispatchToProps = {
		getReviews,
		clearReviews
};
export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(RestaurantReview);
