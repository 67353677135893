import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import PropTypes from "prop-types";
import {Spin, Button, Tooltip, Checkbox, DatePicker, TimePicker} from "antd4";
import {
		addTax,
		cardExpDateFormat, handleResponse,
		onlyNumeric
} from "../../services/Utilities";

import {showModal} from "../../actions/baseActions";
import numeral from "numeral";

import OrderComplete from "./Order.Complete.Component";
import {orderCateringSubmit, orderSubmit} from "../../services/Basket.Service";
import {showLoading, updateDriverTipTariffs} from "../../actions/baseActions";
import {clearBasket} from "../../actions/basketActions";
import MapComponent from "../HelpersComponents/MapComponents/Map.Component";
import {
		DeliveryMinimumInfoModal,
		DeliveryMinimumInfo
} from "../LayoutComponetns/PartialsComponents/Delivery.Minimum.Info.Component";

import "../../styles/OrderStyles/order.scss";
import CreditCard from "../HelpersComponents/CreditCardComponents/CreditCard.Component";
import {handleZipCodeChange, clearCreditCard} from "../../actions/creditCardActions";
import Paths from "../../services/Paths";
import ModalTypes from "../../enums/Modal.Types.Enum";
import {TipType} from "../../enums/Order.Status.Enum";
import {QuestionCircleOutlined} from "@ant-design/icons";
import {sendClientWssMessage} from "../../Wss/Client.WSS.Connection";
import {getDriverTipTariffs} from "../../services/Driver.Service";
import {getCouponData} from "../../services/Order.Service";
import OrderOtherTime from "./Order.Other.Time.Modal.Component";
import moment from "moment";
import WssAction from "../../enums/WSS.Action.Enum";

const PaymentState = {
		IS_FAILED: 0,
		IS_NEW: 1,
		IS_PENDING: 2,
		IS_PAID: 3,
		IS_IN_PROGRESS: 4
};

class Order extends React.Component {
		defaultTipTariff = undefined;
		DEFAULT_DONATE_AMOUNT = 0.5;
		
		constructor(props) {
				super(props);
				
				const {isAuth, user} = props;
				
				this.state = {
						paymentStatus: PaymentState.IS_NEW,
						fullName: isAuth ? user.clientFullName : undefined,
						phone: isAuth ? user.phone : undefined,
						tipType: TipType.IS_TIP_WITH_CREDIT_CARD,
						tipTariff: undefined,
						tipAmount: undefined,
						isCustomTipAmount: false,
						donateAmount: false,
						notes: undefined,
						couponErr: "",
						couponErrMsg: "",
						couponPercent: 0,
						couponValue: ""
				};
				this.couponInput = React.createRef();
		}
		
		UNSAFE_componentWillMount() {
				const {basket} = this.props;
				if(!basket.productCount){
						this.props.history.push(Paths.home);
				}
				getDriverTipTariffs().then(result => {
						if (result) {
								this.props.updateDriverTipTariffs();
								
								// find default tariff
								const tipDefaultTariff = result.find(item => item.isDefault);
								
								if (tipDefaultTariff && tipDefaultTariff.hasOwnProperty("percentage")) {
										this.defaultTipTariff = tipDefaultTariff.percentage;
										this.setState({
												tipTariff: tipDefaultTariff.percentage,
												tipAmount: (basket.subtotal * tipDefaultTariff.percentage) / 100
										});
								}
						}
				});
		}
		
		UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
				if (!nextProps.basket.subtotal) {
						this.props.history.push("/");
				}
				if (this.props.basket.subtotal !== nextProps.basket.subtotal) {
						if (nextState.tipType === TipType.IS_TIP_WITH_CREDIT_CARD) {
								if (!this.state.isCustomTipAmount) {
										this.setState({
												tipAmount: ((nextProps.basket.subtotal * nextState.tipTariff) / 100)
										});
								}
						}
				}
		}
		
		componentWillUnmount() {
				this.props.clearCreditCard();
		}
		
		handleSubmit = evt => {
				evt.preventDefault();
				const {basket, showLoading, isDelivery, creditCard, location} = this.props;
				const {
						fullName,
						phone,
						notes,
						schedule,
						scheduleDate,
						tipAmount,
						tipType,
						couponErr,
						couponValue,
						donateAmount
				} = this.state;
				const {productCount, restaurant, products} = basket;
				
				const isCatering = basket.isCatering;
				if (couponErr === "couponErr") {
						return this.couponInput.current.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
				}
				if (isDelivery && restaurant.deliveryMinimum > basket.subtotal) {
						const modalProps = {
								deliveryMinimum: restaurant.deliveryMinimum,
								handleAddItem: this.handleAddItem
						};
						DeliveryMinimumInfoModal(modalProps);
						return;
				}
				
				showLoading(true);
				
				const card = () => {
						if (creditCard.selectedCardId) {
								return {
										cvv: creditCard.cvv,
										id: creditCard.selectedCardId
								};
						}
						
						return {
								cardType: creditCard.cardType,
								cvv: creditCard.cvv,
								holderName: creditCard.holderName,
								number: creditCard.number.replace(/ /g, ""),
								saveCard: creditCard.saveCard,
								zip: creditCard.zipCode,
								id: creditCard.id,
								expDate: cardExpDateFormat(creditCard.expDate)
						};
				};
				if (isCatering) {
						const order = {
								fullName, phone, notes, donateAmount, schedule, scheduleDate,
								coupon: couponValue,
								item: Object.keys(products).map(key => {
										const item = products[key];
										return {
												price: item.price,
												comment: item.itemComment,
												ingredients: item.ingredients,
												counts: item.counts,
												name: item.name,
												catering: item.product,
												singlePrice: item.singlePrice
										};
								}),
								deliveryType: isDelivery,
								payment: {
										creditCard: card()
								},
								restaurant: {slug: restaurant.slug},
								...(isDelivery && {
										tipAmount: tipAmount,
										tipType: tipType,
										location: location
								})
						};
						return orderCateringSubmit(order).then(result => this.handleOrderResponse(result));
				} else {
						const order = Object.assign({},
								{
										fullName,
										phone,
										notes,
										schedule,
										scheduleDate,
										isDelivery,
										productCount,
										donateAmount,
										products,
										coupon: couponValue,
										creditCard: card(),
										restaurantSlug: restaurant.slug
								},
								isDelivery && {
										tipAmount: tipAmount,
										tipType: tipType,
										location: location
								});
						
						return orderSubmit(order).then(result => {
								if (!result.success &&
										'data' in result &&
										'isClosed' in result.data &&
										'ss' in result.data &&
										result.data.isClosed &&
										result.data.ss
								) {
										this.setState({otherTime: true, order: order, date: result.data.ss});
								}
								const res = handleResponse(
										result,
										undefined,
										() => result,
										undefined,
										"Your order has been successfully submitted."
								);
								this.handleOrderResponse(res);
						});
				}
		};
		
		calculateOrderTotal = () => {
				const {basket, isDelivery} = this.props;
				const {tipAmount, couponPercent, donateAmount} = this.state;
				let total;
				if (couponPercent === 0) {
						total = addTax(basket.subtotal) + (isDelivery ? basket.delivery : 0);
				} else {
						total = addTax(basket.subtotal - (basket.subtotal * couponPercent / 100)) + (isDelivery ? basket.delivery : 0);
				}
				
				total += tipAmount + basket.serviceFee;
				
				if (donateAmount) {
						total += donateAmount;
				}
				
				return numeral(total).format("$ 0,0[.]00");
		};
		
		calculateOrderTax = () => {
				const {basket} = this.props;
				
				const {couponPercent} = this.state;
				return numeral(
						(couponPercent ? (basket.subtotal - (basket.subtotal * couponPercent / 100)) : basket.subtotal) * 0.095
				).format("$ 0,0[.]00");
		};
		
		handleCouponCodeChange = (e) => {
				this.setState({
						couponErr: "",
						couponErrMsg: "",
						couponPercent: 0,
						couponValue: e.target.value
				});
				const {basket} = this.props;
				
				if (e.target.value.length < 4 || !basket.restaurant || !basket.restaurant.slug) return;
				
				
				getCouponData(
						e.target.value,
						basket.restaurant.slug
				).then(data => {
						if (data) {
								this.setState({
										couponErr: data.couponErr,
										couponErrMsg: data.message,
										couponPercent: data.percent
								});
						}
				});
		};
		
		render() {
				const {basket, tipTariffs, isDelivery, location} = this.props;
				const {
						paymentStatus,
						fullName,
						schedule,
						phone,
						tipType,
						tipTariff,
						tipAmount,
						isCustomTipAmount,
						donateAmount,
						otherTime,
						date,
						order,
				} = this.state;
				const branchAddress = basket.restaurant && basket.restaurant && basket.restaurant.address;
				const latLng = isDelivery
						? location && {lat: location.lat, lng: location.log}
						: branchAddress && {lat: branchAddress.lat, lng: branchAddress.log};
				
				const deliveryMin = basket.restaurant ? basket.restaurant.deliveryMinimum : null;
				
				if (paymentStatus === PaymentState.IS_PAID) {
						//Todo remove or show OrderComplete
						return (
								<div className={"Order"}>
										<OrderComplete/>
								</div>
						);
				} else if (paymentStatus === PaymentState.IS_FAILED) {
						//TODO remove or show
						return (
								<div className={"Order"}>
										<h2>Order Payment is Failed</h2>
								</div>
						);
				} else {
						return (
								<Spin
										spinning={paymentStatus === PaymentState.IS_IN_PROGRESS || paymentStatus === PaymentState.IS_PENDING}
										tip={"Waiting for payment..."}>
										<OrderOtherTime visible={otherTime} order={order} date={date}
																		onVisibleChange={this.handleVisibleStartTackingChange}/>
										<form onSubmit={this.handleSubmit} onKeyPress={this.onEnter}>
												<div className={"Order"}>
														<div className={"OrderAddress"}>
																<h3>Enter your details</h3>
																<div className="container-fluid">
																		<div className="row">
																				<div className="col-12 pl-0 pr-0" ref={this.couponInput}>
																						<p className={"GuestInfo"}>Customer info</p>
																				</div>
																				
																				<div className="col-md-6 pl-0 pr-0 pr-md-3">
																						<div className="GlobalInput">
																								<p className={"req"}>Full Name</p>
																								<input
																										type="text"
																										onChange={evt => this.setState({fullName: evt.target.value})}
																										value={fullName ? fullName : ""}
																										required
																								/>
																						</div>
																				</div>
																				
																				<div className="col-md-6 pl-0 pr-0 pr-md-3">
																						<div className="GlobalInput">
																								<p className={"req"}>Phone</p>
																								<input
																										type="text"
																										maxLength={13}
																										minLength={9}
																										onKeyPress={onlyNumeric}
																										onChange={evt => this.setState({phone: evt.target.value})}
																										value={phone}
																										required
																								/>
																						</div>
																				</div>
																				
																				<div className="col-md-6 pl-0 pr-0 pr-md-3">
																						<div className="GlobalInput">
																								<p className={`${this.state.couponErr}`}>Coupon
																										Code {this.state.couponErrMsg}</p>
																								<input
																										className={`${this.state.couponErr}`}
																										type="text"
																										onChange={this.handleCouponCodeChange}
																								/>
																						</div>
																				</div>
																				
																				{isDelivery && (<div className="col-md-6 pl-0 pr-0 pr-md-3">
																						<div className="GlobalInput">
																								<p className={"req"}>Address</p>
																								<input defaultValue={location.address} disabled type="text"/>
																						</div>
																				</div>)}
																				
																				<div className="col-md-12 pl-0 pr-0 pr-md-3">
																						<div className="GlobalInput">
																								<p className={"FormLabel"}>Your notes on order.</p>
																								<textarea onChange={evt => this.setState({notes: evt.target.value})}/>
																						</div>
																				</div>
																				<div className="col-md-12 pl-0 pr-0 pr-md-3">
																						<div>
																								<h5
																										className={"col-12 pl-0 pr-0"}
																										style={{
																												fontSize: 16,
																												color: "#2E2E2E",
																												fontWeight: 700,
																												marginTop: 15
																										}}
																								>Schedule</h5>
																								<Checkbox className={"mb-2"}
																													onChange={evt => this.setState({schedule: evt.target.checked})}
																								>
																										Scheduled?
																								</Checkbox>
																								<div className="scheduled_date_input">
																										<DatePicker
																												placeholder="Date"
																												format="YYYY-MM-DD hh:mm"
																												className={"mr-2"}
																												showTime={true}
																												disabledDate={current => {
																														// Can not select days before today and today
																														return current && current < moment().startOf('day');
																												}}
																												disabledTime={current => {
																														// Can not select days before today and today
																														return current && current < moment();
																												}}
																												required={schedule}
																												disabled={!schedule}
																												onChange={(e, s) => {
																														this.setState({scheduleDate: s});
																												}}
																										/>
																								</div>
																						</div>
																				</div>
																				<CreditCard/>
																				{isDelivery && (
																						<>
																								<div className="col-12 pl-0 pr-0">
																										<p className={"GuestInfo"}>Add a tip for your driver &nbsp;
																												<Tooltip
																														title={<span><b>100% of tip money goes to the driver. </b>Braving the elements or trekking up 5 flights of stairs - tip generously to show your appreciation.</span>}>
																														<QuestionCircleOutlined/>
																												</Tooltip>
																										</p>
																								</div>
																								<div className="col-12">
																										<div className="row">
																												<div className="col-6 pl-0 pr-0 pr-md-3">
																														<div className="tipDiv">
																																<input type="radio"
																																			 id={"tip-with-credit-card"}
																																			 name="tipType"
																																			 onChange={this.handleTipTypeChange}
																																			 value={TipType.IS_TIP_WITH_CREDIT_CARD}
																																			 checked={tipType === TipType.IS_TIP_WITH_CREDIT_CARD}
																																/>
																																<label htmlFor="tip-with-credit-card">Tip with Credit
																																		Card</label>
																														</div>
																												</div>
																												<div className="col-6 pl-0 pr-0 pr-md-3">
																														<div className="tipDiv">
																																<input type="radio"
																																			 onChange={this.handleTipTypeChange}
																																			 id={"tip-with-cash"}
																																			 name="tipType"
																																			 value={TipType.IS_TIP_WITH_CASH}
																																			 checked={tipType === TipType.IS_TIP_WITH_CASH}/>
																																<label htmlFor="tip-with-cash">Tip with Cash</label>
																														</div>
																												</div>
																										</div>
																								</div>
																								{tipType === TipType.IS_TIP_WITH_CREDIT_CARD && (
																										<div className="col-md-12">
																												<div className="row">
																														
																														<div className="col-md-6 pl-0 pr-md-3 pr-0 mb-3"
																																 style={{position: 'relative'}}>
																																<div className="tipPercentage">
																																		{tipTariffs.map((item, index) => (
																																				<div key={index}>
																																						<input type="radio"
																																									 onChange={this.handleTipTariffChange}
																																									 id={`tip-tariff-${item.percentage}`}
																																									 name="tipTariff"
																																									 value={item.percentage}
																																									 checked={(tipTariff ? tipTariff === item.percentage : item.isDefault) && !isCustomTipAmount}/>
																																						<label
																																								htmlFor={`tip-tariff-${item.percentage}`}>{item.percentage} %</label>
																																				</div>
																																		))}
																																</div>
																														</div>
																														<div className="col-md-6 pl-md-3 pr-md-3 pl-0 pr-0 mb-3"
																																 style={{position: 'relative'}}>
																																{!isCustomTipAmount && (
																																		<div className="customTipActivator"
																																				 onClick={() => {
																																						 this.setState({
																																								 tipTariff: undefined,
																																								 tipAmount: parseFloat(tipAmount.toFixed(2)),
																																								 isCustomTipAmount: true
																																						 });
																																				 }}/>)}
																																<div className="customTipActive">
																																		<button type="button">Custom Tip</button>
																																		<input
																																				type="number"
																																				min="1"
																																				step="1"
																																				value={isCustomTipAmount && tipAmount ? tipAmount : ""}
																																				onChange={this.handleCustomTipChange}
																																		/>
																																</div>
																														</div>
																												</div>
																										</div>
																								)}
																						</>
																				)}
																				<div className="col-md-6 pr-md-3 pl-0 pr-0 mb-3" style={{position: 'relative'}}>
																						{!donateAmount && (
																								<div className="customTipActivator"
																										 onClick={() => {
																												 this.setState({
																														 donateAmount: this.DEFAULT_DONATE_AMOUNT
																												 });
																										 }}/>)}
																						<div className="customTipActive">
																								<button className="activateDonate" type="button" onClick={() => {
																										this.setState({donateAmount: undefined});
																								}}>
																										Donate
																								</button>
																								<input
																										type="number"
																										min={this.DEFAULT_DONATE_AMOUNT}
																										step="0.5"
																										value={donateAmount ? donateAmount : ""}
																										onChange={this.handleDonateChange}
																								/>
																						</div>
																				</div>
																		</div>
																</div>
														</div>
														<div className={"OrderDetails"}>
																<div>
																		<div className="OrderMap">
																				<MapComponent
																						center={latLng}
																						mapDraggable={false}
																						markerDraggable={false}
																						// onPlaceSelected={this.onPlaceSelected}
																				/>
																		</div>
																		<div className="OrderMenu">
																				<ul>
																						<li>
																								{basket.restaurant.name}
																						</li>
																						{basket.products &&
																						Object.keys(basket.products).map(productKey => {
																								const item = basket.products[productKey];
																								const itemDetailsProps = {
																										productSlug: item.slug,
																										uId: item.uid,
																										isCatering: item.isCatering
																								};
																								return (
																										<li
																												key={productKey}
																												onClick={() => {
																														this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, true, itemDetailsProps);
																												}}
																										>
																												<p>{item.counts}&nbsp;{item.name}</p>
																												<span>{numeral(item.price).format("$ 0,0[.]00")}</span>
																										</li>
																								);
																						})
																						}
																				</ul>
																				<ul>
																						<li>
																								<p>Items subtotal</p>
																								{/* <span>{numeral(basket.subtotal).format('$ 0,0[.]00')}</span> */}
																								<span
																										className={this.state.couponPercent !== 0 ? "underlined" : ""}>{numeral(basket.subtotal).format("$ 0,0[.]00")}</span>
																								<span
																										className={this.state.couponPercent === 0 ? "displayNone" : ""}>  {this.state.couponPercent !== 0 ? numeral(basket.subtotal - (basket.subtotal * this.state.couponPercent / 100)).format("$ 0,0[.]00") : ""}</span>
																						</li>
																						{isDelivery &&
																						<li>
																								<p>Delivery fee</p>
																								<span>{numeral(basket.delivery).format("$ 0,0[.]00")}</span>
																						</li>}
																						<li>
																								<p>Service fee</p>
																								<span>{numeral(basket.serviceFee).format("$ 0,0[.]00")}</span>
																						</li>
																						{tipType === TipType.IS_TIP_WITH_CREDIT_CARD && isDelivery && (
																								<li>
																										<p>Tip</p>
																										<span>{numeral(tipAmount).format("$ 0,0[.]00")}</span>
																								</li>
																						)}
																						<li>
																								<p>Tax</p>
																								<span>{this.calculateOrderTax()}</span>
																						</li>
																						{donateAmount && (
																								<li>
																										<p>Donate</p>
																										<span>{numeral(donateAmount).format("$ 0,0[.]00")}</span>
																								</li>
																						)}
																				</ul>
																		</div>
																		<div className="OrderPay">
																				<p>
																						You Pay <span>{this.calculateOrderTotal()}</span>
																				</p>
																				<Button htmlType="button" className="addItemButton"
																								onClick={this.handleAddItem}>Add Item</Button>
																				<Button htmlType="submit" className="orderButton">Order</Button>
																				{deliveryMin &&
																				<DeliveryMinimumInfo
																						deliveryMinimum={deliveryMin}
																						subtotal={basket.subtotal}
																						isDelivery={isDelivery}
																				/>}
																		</div>
																</div>
														</div>
												</div>
										</form>
								</Spin>
						);
				}
		}
		
		handleVisibleStartTackingChange = ({show, ordered, date}) => {
				let {order} = this.state;
				if (ordered && date) {
						
						order = Object.assign(order,
								{
										ordered: true,
										schedule: true,
										scheduleDate: date,
								});
						
						this.props.showLoading(true);
						orderSubmit(order).then(result => {
								if (!result.success && result.message["message-0"] === "Restaurant is closed") {
										this.setState({otherTime: true, order: order, date: result.data.ss});
								}
								const res = handleResponse(
										result,
										undefined,
										() => result,
										undefined,
										"Your order has been successfully submitted."
								);
								this.handleOrderResponse(res);
						}).finally(() => this.props.showLoading(false));
				}
				this.setState({otherTime: show });
		};
		
		handleTipTypeChange = evt => {
				const tipType = parseInt(evt.target.value, 10);
				
				if (tipType === TipType.IS_TIP_WITH_CREDIT_CARD) {
						this.setState({
								tipType: tipType,
								tipAmount: ((this.props.basket.subtotal * this.defaultTipTariff) / 100)
						});
				} else if (tipType === TipType.IS_TIP_WITH_CASH) {
						this.setState({
								tipType: tipType,
								tipAmount: 0,
								isCustomTipAmount: false
						});
				}
		};
		
		handleTipTariffChange = evt => {
				const {basket} = this.props;
				const tipTariffPercentage = parseInt(evt.target.value, 10);
				
				this.setState({
						tipTariff: tipTariffPercentage,
						tipAmount: (basket.subtotal * tipTariffPercentage) / 100,
						isCustomTipAmount: false
				});
		};
		
		handleCustomTipChange = evt => {
				this.setState({
						tipAmount: parseFloat(evt.target.value)
				});
		};
		
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		handleAddItem = () => {
				const basket = this.props.basket;
				this.props.history.push({
								pathname: Paths.restaurant
										.replace(":restaurantSlug", basket.restaurant.slug),
								state: {isCatering: basket.isCatering}
						}
				);
		};
		
		handleDonateChange = evt => {
				let value = parseFloat(evt.target.value);
				if (value < this.DEFAULT_DONATE_AMOUNT) {
						value = this.DEFAULT_DONATE_AMOUNT;
				}
				this.setState({
						donateAmount: value
				});
		};
		
		handleOrderResponse = (result) => {
				this.props.showLoading(false);
				if (result.success) {
						this.props.clearBasket();
						// sendClientWssMessage({
						// 		action: WssAction.NEW_ORDER,
						// 		slug: result.data.order.slug
						// });
						setTimeout(() => {
								this.props.history.push("/account");
						}, 300);
				}
		};
}

Order.propTypes = {
		basket: PropTypes.object.isRequired,
		isAuth: PropTypes.bool.isRequired,
		user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
		basket: state.basket,
		location: state.currentLocation.address,
		isAuth: state.user.isAuth,
		user: state.user.data,
		tipTariffs: state.tipTariff,
		isDelivery: state.isDelivery,
		isCatering: state.isCatering,
		creditCard: state.creditCard
});

const mapDispatchToProps = {
		showLoading,
		clearBasket,
		updateDriverTipTariffs,
		handleZipCodeChange,
		clearCreditCard,
		showModal
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps
)(Order));
