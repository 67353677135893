import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import NotificationsModal from "./Notifications.Modal.Component";
import SignInModal from "./SignIn.Modal.Component";
import BasketModal from "./Basket.Modal.Component";
import ItemDetailsModal from "./Item.Details.Modal.Component/Item.Details.Modal.Component";
import RegistrationModal from "./Registration.Modal.Component";
import MapModal from './Map.Modal.Component'
import ChangePasswordModal from "./Change.Password.Modal.Component";
import ForgotPasswordModal from "./Forgot.Password.Modal.Component";
import ForgotPasswordVerifyCodeModal from "./Forgot.Password.Verification.Code.Modal.Component";
import ShowImgModal from "./Show.Img.Modal.Component";
import TermsAndConditionsModal from "./TermsAndConditions.Modal.Component";
import PrivacyPolicyModal from "./PrivacyPolicy.Modal.Component";
import OrderItemDetailsModal from './Order.Item.Details.Modal.Component';
import CustomerFeedbackModal from "./Customer.Feedback.Component";

export const Modals = ({
													 isAuth,
													 notificationModalVisible,
													 signInModalVisible,
													 registrationModalVisible,
													 forgotPasswordModalVisible,
													 forgotPasswordVerifyCodeVisible,
													 basketModalVisible,
													 itemDetailsModalVisible,
													 mapModalVisible,
													 changePasswordModalVisible,
													 showImgVisible,
													 termsAndConditionsModalVisible,
													 privacyPolicyModalVisible,
													 orderDetailsModalVisible,
													 customerFeedbackModalVisible
											 }) => {
		return (
				<>
						{notificationModalVisible && <NotificationsModal/>}
						{basketModalVisible && <BasketModal/>}
						{itemDetailsModalVisible && <ItemDetailsModal/>}
						{mapModalVisible && <MapModal/>}
						{showImgVisible && <ShowImgModal/>}
						{termsAndConditionsModalVisible && <TermsAndConditionsModal/>}
						{privacyPolicyModalVisible && <PrivacyPolicyModal/>}
						{orderDetailsModalVisible && <OrderItemDetailsModal/>}
						{isAuth && changePasswordModalVisible && <ChangePasswordModal/>},
						{isAuth && customerFeedbackModalVisible && <CustomerFeedbackModal/>}
						{!isAuth && signInModalVisible && (<SignInModal/>)}
						{!isAuth && registrationModalVisible && (<RegistrationModal/>)}
						{!isAuth && forgotPasswordModalVisible && (<ForgotPasswordModal/>)}
						{!isAuth && forgotPasswordVerifyCodeVisible && (<ForgotPasswordVerifyCodeModal/>)}
				</>
		)
};

Modals.propTypes = {
		notificationModalVisible: PropTypes.bool.isRequired,
		basketModalVisible: PropTypes.bool.isRequired,
		signInModalVisible: PropTypes.bool.isRequired,
		registrationModalVisible: PropTypes.bool.isRequired,
		forgotPasswordModalVisible: PropTypes.bool.isRequired,
		forgotPasswordVerifyCodeVisible: PropTypes.bool.isRequired,
		itemDetailsModalVisible: PropTypes.bool.isRequired,
		mapModalVisible: PropTypes.bool.isRequired,
		changePasswordModalVisible: PropTypes.bool.isRequired,
		isAuth: PropTypes.bool.isRequired,
		showImgVisible: PropTypes.bool.isRequired,
		orderDetailsModalVisible: PropTypes.bool.isRequired,
		customerFeedbackModalVisible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
		notificationModalVisible: state.modalVisible.notification,
		basketModalVisible: state.modalVisible.basket,
		itemDetailsModalVisible: state.modalVisible.itemDetails,
		signInModalVisible: state.modalVisible.signIn,
		registrationModalVisible: state.modalVisible.registration,
		forgotPasswordModalVisible: state.modalVisible.forgotPassword,
		forgotPasswordVerifyCodeVisible: state.modalVisible.forgotPasswordVerifyCode,
		mapModalVisible: state.modalVisible.map,
		changePasswordModalVisible: state.modalVisible.changePassword,
		isAuth: state.user.isAuth,
		showImgVisible: state.modalVisible.showImgVisible,
		termsAndConditionsModalVisible: state.modalVisible.termsAndConditions,
		privacyPolicyModalVisible: state.modalVisible.privacyPolicy,
		orderDetailsModalVisible: state.modalVisible.orderDetails,
		customerFeedbackModalVisible: state.modalVisible.customerFeedback
});

export default connect(
		mapStateToProps,
		{},
)(Modals);
