import React, {Component} from "react";
import {withRouter} from "react-router";
import {
		deleteCoupon,
		getCoupon,
		getCouponPercent
} from "../../services/Partner.Service";
import {showLoading, showModal} from "../../actions/baseActions";
import {Button, Popconfirm, Table} from "antd4";
import {connect} from "react-redux";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import CouponModal from "./ModalComponents/New.Coupon.Modal.Component";
import moment from "moment";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import numeral from "numeral";
import {Badge} from "react-bootstrap";

class PartnerPromotion extends Component {
		state = {
				users: [],
				selectedTab: 0,
				visible: false,
				promotion: {}
		};
		
		TableColumns = [
				{
						title: "Code coupon",
						key: "code",
						dataIndex: "code",
						render: value => (
								value
						)
				}, {
						title: "Count",
						key: "count",
						dataIndex: "count",
						render: value => (
								value
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.count === b.count) ? 0 : a.count ? -1 : 1
				}, {
						title: "Date",
						key: "date",
						dataIndex: "date",
						render: value => (
								value ?
										moment(value).format("YYYY-MM-DD") : ""
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.date === b.date) ? 0 : a.date ? -1 : 1
				}, {
						title: "Discount percent %",
						key: "discountPercent",
						dataIndex: "discountPercent",
						render: value => (
								value
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.discountPercent === b.discountPercent) ? 0 : a.discountPercent ? -1 : 1
				}, {
						title: "Action",
						key: "action",
						render: (record) => (
								<div>
										<a onClick={() => {
												this.editUer(record);
										}}>
												<EditOutlined/>
												Edit
										</a>
										<Popconfirm title="Are you sure？" okText="Yes" cancelText="No"
																onConfirm={() => this.deleteProduct(record.id)}>
												<Button type="link">
														<DeleteOutlined/>
														Delete
												</Button>
										</Popconfirm>
								</div>
						)
				}
		];
		
		deleteProduct = couponId => {
				this.props.showLoading(true);
				deleteCoupon(couponId).then(result => {
						if (result) {
								getCoupon().then(result => {
										if (result) {
												this.setState({coupon: result});
										}
								}).finally(() => {
										this.props.showLoading(false);
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		UNSAFE_componentWillMount() {
				this.props.showLoading(true);
				Promise.all([
						getCoupon(),
						getCouponPercent()
				]).then(result => {
						if (result.length) {
								this.setState({
										coupon: result[0],
										promotion: result[1]
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		editUer = editUser => {
				this.setState({editUser, visible: true});
		};
		
		handleVisibleChange = visible => {
				this.setState({editUser: null, visible});
		};
		
		handleNewCategoryAdded = newUser => {
				if (newUser) {
						getCoupon().then(result => {
								if (result) {
										this.setState({coupon: result});
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		
		render() {
				const {coupon, visible, editUser, promotion, selectedTab} = this.state;
				const {revenueFromPromotions, customerRedemptions, returnOnInvestment} = promotion;
				console.log(revenueFromPromotions, customerRedemptions, returnOnInvestment);
				
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Coupons</h1>
										<button className={"AddNewButton"} onClick={() => this.handleVisibleChange(true)}>Add coupon
										</button>
								</section>
								<section className={"ContentBody"}>
										<Tabs onSelect={(selectedIndex) => this.setState({selectedTab: selectedIndex})}
													selectedIndex={selectedTab}>
												<TabList>
														<Tab>Summary</Tab>
														<Tab>Promotion details</Tab>
												</TabList>
												
												<TabPanel>
														<div className="promotion_item">
														<span>
															{numeral(Math.abs(revenueFromPromotions ? revenueFromPromotions.usedCouponsTotalDiscount : 0)).format("$ 0,0[.]00")}
														</span>
																<h3>Revenue from promotions</h3>
																<p>That's {numeral(Math.abs(revenueFromPromotions ? revenueFromPromotions.percent : 0)).format("0,0[.]00")}%
																		of your overall Meameal revenue
																		of {numeral(Math.abs(revenueFromPromotions ? revenueFromPromotions.ordersTotalPrice : 0)).format("$ 0,0[.]00")}</p>
														</div>
														
														<div className="promotion_item">
																<span>
																	{customerRedemptions ? customerRedemptions.usedCouponCount : 0}
																</span>
																
																<h3>Customer redemptions</h3>
																<p>That's {numeral(Math.abs(customerRedemptions ? customerRedemptions.percent : 0)).format("0,0[.]00")}%
																		of
																		your {customerRedemptions ? customerRedemptions.totalOrdersCount : 0} total
																		orders</p>
														</div>
														
														<div className="promotion_item">
																<span>
																		{numeral(Math.abs(returnOnInvestment ? returnOnInvestment.percent : 0)).format("0,0[.]00")}%
																</span>
																<h3>Return on investment</h3>
																<p>You've
																		earned {numeral(Math.abs(returnOnInvestment ? returnOnInvestment.totalAmount : 0)).format("$ 0,0[.]00")} for
																		every
																		dollar you've spent on these promotions.</p>
														</div>
														<div className="promotion_item">
																<h3 style={{cursor: 'pointer'}}
																		onClick={e => this.setState({selectedTab: 1})}> Promotions running this month <Badge
																		style={{
																				backgroundColor: "#e7792b",
																				color: "#fff",
																				fontSize: "20px"
																		}}>{coupon && coupon.length}</Badge></h3>
														</div>
												</TabPanel>
												<TabPanel>
														<div>
																<Table
																		columns={this.TableColumns}
																		dataSource={coupon}
																		rowKey={"id"}
																		size={"middle"}
																		pagination={{
																				defaultPageSize: 25
																		}}/>
														
														</div>
												</TabPanel>
										</Tabs>
										<CouponModal
												visible={visible}
												editUserInfo={editUser}
												onVisibleUsersChange={this.handleVisibleChange}
												onCategoryUserAdded={this.handleNewCategoryAdded}
										>
										</CouponModal>
								</section>
						</div>
				
				);
		}
}

export default withRouter(connect(
		() => ({}),
		{showLoading, showModal}
)(PartnerPromotion));
