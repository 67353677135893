import React from "react";
import { Link } from "react-router-dom";
import Paths from "../../services/Paths";

const NoMatch = () => (
    <div>
      <div className={'pageNotFound'}>
        <h1>Oops! We can't find this page!</h1>
        <p>Sorry, we can't find this page you are looking for. Click <Link to={Paths.home}> HERE </Link> to go back to home page.</p>
      </div>
    </div>
  );

export default NoMatch