import React from 'react';
import {connect} from "react-redux";
import Img from '../Img.Component';

export const CircleProduct = ({slug, name, imgPath, handleClick= f=>f}) => (
    <div className={'CircleItem'} onClick={handleClick}>
      <div className="circleImg"><Img src={imgPath} alt="burger"/></div>
      <span>{name}</span>
    </div>
);

const mapStateToProps = state => ({
    itemDetailsModalVisible: state.modalVisible.itemDetails,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps
)(CircleProduct);
