import React from "react";
import {addCoupon} from "../../services/Partner.Service";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../actions/baseActions";
import Modal from "../HelpersComponents/Modal.Component";
import {Button} from "antd4";

class OrderOtherTime extends React.Component {
		
		state = {
				dateCoupon: null
		};
		handleSubmit = values => {
				const {restaurant} = this.props;
				const {dateCoupon} = this.state;
				values.restaurantId = restaurant.id;
				if (dateCoupon)
						values.date = dateCoupon;
				this.props.showLoading(true);
				addCoupon(values).then(result => {
						if (result) {
								this.props.onCategoryUserAdded(result);
								this.props.onVisibleUsersChange(false);
								this.setState({dateCoupon: null});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		
		render() {
				const {visible, date} = this.props;
				
				return (
						<Modal
								title="Order another time"
								visible={visible}
								footer={[
										<Button onClick={() => {
												this.props.onVisibleChange({show: false, ordered: true, date});
										}}>Yes</Button>,
										<Button onClick={() => {
												this.props.onVisibleChange({show: false, ordered: false});
										}} danger>No</Button>
								]}
								onCancel={() => {
										this.props.onVisibleChange({show: false, ordered: false});
								}}
						>
								<h4>Do you want to order at {date}?</h4>
						</Modal>
				);
		}
}


OrderOtherTime.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleUsersChange: PropTypes.func.isRequired,
		onCategoryUserAdded: PropTypes.func.isRequired
};

OrderOtherTime.defaultProps = {
		visible: false,
		order: null,
		date: null,
		onVisibleUsersChange: f => f,
		onCategoryUserAdded: f => f
};

export default connect(
		() => ({}),
		{showLoading}
)(OrderOtherTime);
