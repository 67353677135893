import React from "react";
import Nav from "./LayoutComponetns/Nav.Component";
import Footer from "./LayoutComponetns/Footer.Component";
import {Spin} from 'antd4';
import {connect} from "react-redux";
import Modals from "./ModalComponent/Index.Modal.Component";
import Preview from "./LayoutComponetns/Preview.Component";
import PropTypes from "prop-types";
import {withRouter} from "react-router";
import {hasRoleClient} from "../services/Utilities";

const MainApp = (props) => {
		const {children, loading, currentLocation, showHeader, user} = props;
		const isClient = user && user.data && user.data.roles && hasRoleClient(user.data.roles[0])
		
		return (
				<>
						{
								(currentLocation.address || (user.isAuth && !isClient))
										? <Spin spinning={loading}>
												{showHeader ? <Nav/> : ''}
												{children}
												{showHeader ? <Footer/> : ''}
										</Spin> :
										<Preview/>
						}
						<Modals/>
				</>
		);
};

Nav.propTypes = {
		loading: PropTypes.bool,
		currentLocation: PropTypes.object
};

const mapStateToProps = state => ({
		loading: state.loading,
		currentLocation: state.currentLocation.address,
		showHeader: state.showHeader,
		user: state.user,
		isAuth: state.user.isAuth
});

export default withRouter(connect(
		mapStateToProps,
		null
)(MainApp));

