import React, {Component} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import numeral from 'numeral';
import {
		removeBasketItem,
		calculateBasket,
		changeItemCount,
		clearBasket
} from "../../actions/basketActions";
import Modal from '../HelpersComponents/Modal.Component';
import {showModal} from '../../actions/baseActions'
import ModalTypes from "../../enums/Modal.Types.Enum";
import {ReactComponent as Minus} from '../../img/ic_arrow_next.svg'
import Paths from "../../services/Paths";
import {
		DeliveryMinimumInfoModal,
		DeliveryMinimumInfo
} from "../LayoutComponetns/PartialsComponents/Delivery.Minimum.Info.Component";
import {Button} from 'antd4';
import {addTax} from "../../services/Utilities";

class BasketModal extends Component {
		
		handleCheckOut = () => {
				const {basket, isDelivery} = this.props;
				const deliveryMin = basket.restaurant.deliveryMinimum || null;
				
				if (isDelivery && deliveryMin && deliveryMin > basket.subtotal) {
						const modalProps = {
								deliveryMinimum: deliveryMin,
								handleAddItem: this.handleAddItem
						}
						DeliveryMinimumInfoModal(modalProps);
				} else if (this.props.isAuth) {
						this.props.history.push(Paths.order);
						this.props.showModal(ModalTypes.BASKET_MODAL, false);
				} else {
						this.props.showModal(ModalTypes.SIGN_IN_MODAL);
				}
		};
		
		handleClearCart = () => {
				this.props.clearBasket();
				this.props.showModal(ModalTypes.BASKET_MODAL, false);
		};
		
		handleAddItem = () => {
				const basket = this.props.basket;
				this.props.showModal(ModalTypes.BASKET_MODAL, false)
				
				this.props.history.push({
								pathname: Paths.restaurant
										.replace(':restaurantSlug', basket.restaurant.slug),
								state: {isCatering: basket.isCatering}
						}
				)
		};
		
		getOrderTotal = () => {
				const {basket, isDelivery} = this.props;
				
				return numeral(addTax(basket.subtotal) + (isDelivery ? basket.delivery : 0)).format('$ 0,0[.]00');
		};
		
		
		openItemDetailsModal = (props) => {
				this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, true, props)
		}
		
		render() {
				const {
						basket,
						showModal,
						removeBasketItem,
						changeItemCount,
						basketModalVisible,
						isDelivery
				} = this.props;
				const {restaurant, productCount, products} = basket;
				const deliveryMin = restaurant ? restaurant.deliveryMinimum : null;
				
				return (
						<Modal
								className={'CustomBasketModal'}
								prefixCls={'Modal'}
								visible={basketModalVisible}
								onCancel={() => showModal(ModalTypes.BASKET_MODAL, false)}
						>
								<p className={'BasketTitle'}>{productCount ? `${productCount} items added` : 'Basket'}</p>
								{productCount ? (
										<>
												<ul>
														{Object.keys(products).map(productKey => {
																const item = products[productKey];
																const itemDetailsProps = {
																		productSlug: item.slug,
																		uId: item.uid,
																		isCatering: item.isCatering
																}
																return (
																		<li key={productKey}
																				onClick={() => this.openItemDetailsModal(itemDetailsProps)}
																		>
																				<p className={'FoodPrice'}>
																						{item.name}
																						<span>{numeral(item.price).format('$ 0,0[.]00')}</span>
																				</p>
																				<p className={'BasketRestaurantName'}>{restaurant.name}</p>
																				<div className="RemoveCount">
																						<div className={'Count'}>
																								<button className={'RemoveCountLeft'}
																												onClick={evt => {
																														evt.stopPropagation();
																														changeItemCount(productKey, item.counts - 1)
																												}}>
																										<Minus/>
																								</button>
																								<span>{item.counts}</span>
																								<button className={'RemoveCountRight'}
																												onClick={evt => {
																														evt.stopPropagation();
																														changeItemCount(productKey, item.counts + 1)
																												}}>
																										<Minus/>
																								</button>
																						</div>
																						<div className={'Remove'}>
																								<button
																										onClick={evt => {
																												evt.stopPropagation();
																												removeBasketItem(productKey)
																										}}>Remove
																								</button>
																						</div>
																				</div>
																		</li>
																)
														})
														}
												</ul>
												<div className="DeliverySubtotal">
														<p className={'Subtotal'}>Items
																subtotal <span>{numeral(basket.subtotal).format('$ 0,0[.]00')}</span></p>
														{isDelivery ? (<p className={'Delivery'}>Delivery
																fee <span>{numeral(basket.delivery).format('$ 0,0[.]00')}</span></p>) : ''}
														<p className={'Delivery'}>Service fee <span>{numeral(0).format('$ 0,0[.]00')}</span></p>
														<p className={'Delivery'}>Tax <span>{numeral(basket.subtotal * 0.095).format('$ 0,0[.]00')}</span>
														</p>
												</div>
												<div className="CheckOut">
														<p className={'YouPay'}>You Pay <span>{this.getOrderTotal()}</span></p>
														<Button className={'AddItemButton'} onClick={this.handleAddItem}>Add Item</Button>
														<button className={'CheckOutButton'} onClick={this.handleCheckOut}>Check Out</button>
														<button className={'ClearCartButton'} onClick={this.handleClearCart}>Clear cart</button>
														{!!deliveryMin &&
														<DeliveryMinimumInfo
																deliveryMinimum={deliveryMin}
																subtotal={basket.subtotal}
																isDelivery={isDelivery}
														/>}
												</div>
										</>
								) : (
										<div className={'CheckOut Empty'}>
												<p className={'text-center'}>Basket Empty</p>
										</div>
								
								)}
						</Modal>
				)
		}
}

BasketModal.propTypes = {
		basket: PropTypes.object.isRequired,
		showModal: PropTypes.func.isRequired,
		removeBasketItem: PropTypes.func.isRequired,
		calculateBasket: PropTypes.func.isRequired,
		basketModalVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
		basket: state.basket,
		basketModalVisible: state.modalVisible.basket,
		isDelivery: state.isDelivery,
		isAuth: state.user.isAuth,
		isCatering: state.isCatering
});

const mapDispatchToProps = {
		showModal,
		removeBasketItem,
		calculateBasket,
		changeItemCount,
		clearBasket
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps,
)(BasketModal));
