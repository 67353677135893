import * as Http from "./Http.Client";
import {getDeviceId} from "../services/Utilities";

export const signIn = (model = {}) =>
	Http.Post(
		'api/v1/login',
		{"Content-Type": "application/x-www-form-urlencoded"},
		Http.StringifyUrlEncoded(model),
		false,
		true
		)
		.then(Http.HandleResponseState);

export const signUp = (model = {}) =>
	Http.Put('api/v1/user', {}, model)
		.then(Http.HandleResponseState);

export const signOut = () =>
	Http.Post('api/v1/logout', {'DeviceId': getDeviceId()}, {}, true)
		.then(Http.HandleResponseState);

export const getCurrentUser = () =>
	Http.Get('api/v1/user', {}, true)
		.then(Http.HandleResponseState);

export const editUserInfo = (model = {}) =>
	Http.Post('api/v1/user/update', {}, model, true)
		.then(Http.HandleResponseState);

export const requestVerificationCode = model =>
	Http.Post('api/v1/request/forgot-password/code', {}, model)
		.then(Http.HandleResponseState);

export const verifyVerificationCode = model =>
	Http.Post('api/v1/validate/forgot-password/code', {}, model)
		.then(Http.HandleResponseState);

export const forgotPassword = model =>
	Http.Post('api/v1/user/forgots/passwords', {}, model, false)
		.then(Http.HandleResponseState);

export const changePassword = (model = {}) =>
	Http.Post('api/v1/change-password', {}, model, true)
		.then(Http.HandleResponseState);

/**
 * Firebase Token
 */
export const sendFBTokenToServer = token =>
	Http.Put('api/v1/fcm', {'DeviceId': getDeviceId()}, token, true)
		.then(Http.HandleResponseState);
