import React, {useEffect, useRef, useState} from "react";
import {Fragment} from "react";
import PropTypes from "prop-types";

import {connect, useSelector} from "react-redux";
import Img from "./Img.Component";
import LocationIcon from "../../img/ic_location.svg";
import nextArrowIcon from '../../img/next.svg';

import Address from "./Address.Component";
import {getUserLocation} from '../../services/User.Service';
import {addressFormatter} from "../../services/Utilities";
import {updateLocation} from "../../actions/locationActions";
import {getAddressFromLatLng} from "./MapComponents/Map.Component";
import {BasketEmptyInfoModal} from "../LayoutComponetns/PartialsComponents/Basket.Empty.Info.Modal";
import {clearBasket} from '../../actions/basketActions';
import {updateClientLocation} from "../../services/Base.Service";

const usePrevious = (value) => {
		const ref = useRef();
		useEffect(() => {
				ref.current = value;
		});
		return ref.current;
}

const AddAddress = ({
												inputPlaceholder = 'Let’s start with your address',
												showButton = true,
												onAddressConfirmed = f => f,
												onAddressSelected = f => f,
												updateLocation,
												clearBasket,
										}) => {
		const currentLocation = useSelector(state => state.currentLocation.address)
		const basketProductCount = useSelector(state => state.basket.productCount)
		
		const errInputBoxMsg = useRef(null);
		
		const [location, setLocation] = useState(currentLocation);
		const prevLocation = usePrevious(currentLocation);
		
		useEffect(() => {
				if (currentLocation && prevLocation && currentLocation.address !== prevLocation.address) {
						setLocation(currentLocation)
				}
		}, [currentLocation])
		
		// select address from list
		const onPlaceSelected = async place => {
				const address = addressFormatter(place);
				
				let selectedLocation = {
						address: address.address,
						city: address.city,
						state: address.state,
						lat: address.lat,
						log: address.log,
						zipCode: address.zip
				};
				
				//In some cases we don't get zip city or state from google.
				//So we are checking address second time with log lat and merge objects.
				if (!selectedLocation.zipCode || !selectedLocation.city || !selectedLocation.state) {
						await getAddressFromLatLng(selectedLocation.lat, selectedLocation.log).then((res) => {
								const newLocation = addressFormatter(res);
								
								if (Object.keys(newLocation).length) {
										delete newLocation.address;
										delete newLocation.lat;
										delete newLocation.log;
										selectedLocation = {...selectedLocation, ...newLocation}
								}
						})
				}
				
				setLocation(selectedLocation);
				onAddressSelected && onAddressSelected(selectedLocation);
		}
		
		// confirm selected address click confirmation button
		const onPlaceConfirmed = () => {
				if (location.address !== undefined) {
						// clear error message box
						const msgBox = errInputBoxMsg.current;
						msgBox.innerHTML = "";
						msgBox.classList.remove('errInputBoxMsg');
						document.getElementsByClassName('vehicleInformation')[0].classList.remove('errInputBox');
						
						// case 1 when address is not changed
						if (currentLocation && currentLocation.address && currentLocation.address === location.address) {
								setConfirmedAddress(location)
								return;
						}
						
						// if basket isn't empty show information popup
						if (!!basketProductCount) {
								BasketEmptyInfoModal(clearBasket, () => {
										setConfirmedAddress(location)
								})
						} else {
								setConfirmedAddress(location)
						}
				} else {
						let msgBox = errInputBoxMsg.current;
						msgBox.classList.add('errInputBoxMsg');
						document.getElementsByClassName('vehicleInformation')[0].classList.add('errInputBox');
				}
		};
		
		const setConfirmedAddress = confirmedLocation => {
				updateClientLocation(confirmedLocation).then(result => {
						if (result) {
								updateLocation(confirmedLocation);
								onAddressConfirmed && onAddressConfirmed(confirmedLocation);
						}
				});
		}
		
		// get client current lat lng using browser API
		const getClientCurrentLocation = () => {
				getUserLocation().then(location => {
						if (location) {
								setLocation(location);
								onAddressSelected && onAddressSelected(location);
						}
						return location;
				})
		};
		
		return (
				<Fragment>
						<div className={'getFoodButton'}>
								<span>
									<Img src={LocationIcon} alt="Location Icon" isLocale={true} onClick={getClientCurrentLocation}/>
								</span>
								<Address
										type="search"
										placeholder={inputPlaceholder}
										defaultValue={location.address ? location.address : ''}
										title={location.address ? location.address : ''}
										onPlaceSelected={onPlaceSelected}
										types={['address']}
										componentRestrictions={{country: "us"}}
										required
								/>
								{showButton && <button onClick={onPlaceConfirmed}>
										<Img src={nextArrowIcon} alt={'Get Food'} isLocale={true} className={'getFoodIcon'}/>
								</button>}
						</div>
						<div ref={errInputBoxMsg}/>
				</Fragment>
		);
};

AddAddress.propTypes = {
		updateLocation: PropTypes.func.isRequired,
		clearBasket: PropTypes.func.isRequired,
		inputPlaceholder: PropTypes.string
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
		updateLocation,
		clearBasket
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(AddAddress);
