import * as DriverApi from '../api/Driver.API';
import {handleResponse} from "./Utilities";
import * as DriverAPI from "../api/Driver.API";

export const getDriverActualOrder = (orderSlug = null) => {
	return DriverApi.getDriverActualOrder(orderSlug).then(result => {
		if (result && result.success) {
			return result.data;
		}
		return false
	})
};

export const getDriverOrders = () => {
	return DriverApi.getDriverOrders().then(result => {
		if (result && result.success) {
			return result.data;
		}
	})
};

export const sendDriverFeedback = (model, orderSlug) => {
		return DriverApi.sendDriverFeedback(model, orderSlug).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Your opinion has been successfully sent.'
		))
}

export const sendAddressNote = (model, orderSlug) => {
		return DriverApi.sendAddressNote(model, orderSlug).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
				undefined,
				'Your note has been successfully added.'
		))
}

export const getAddressNotes = address => {
		return DriverApi.getAddressNotes(address).then(result => handleResponse(
				result,
				undefined,
				() => (result.data),
		))
}

export const getDriverTipTariffs = () => {
		return DriverAPI.getDriverTipTariffs().then(result => {
				if (result && result.success) {
						
						sessionStorage.setItem('tipTariff', JSON.stringify(result.data));
						
						return result.data;
				}
				return false;
		})
};

export const updateDriverLocation = model => {
		return DriverAPI.updateDriverLocation(model).then(result => {
				if (result && result.success) {
						return result.data
				}
				return false;
		});
}

export const getDriverBalanceHistory = () => {
		return DriverAPI.getDriverBalanceHistory().then(result => {
				if (result && result.success) {
						console.log(result.data)
						return result.data
				}
				return false;
		});
}

export const updateDriverOrderStatus = (order, lastStatus, newStatus, preparingDuration = null) => {
		return DriverApi.updateDriverOrderStatus({order,lastStatus, newStatus, preparingDuration})
				.then(result => handleResponse(
						result,
						undefined,
						() => (result.data),
						undefined,
						'Order has been successfully updated.'
				));
};
