import React from "react";
import {Link} from "react-router-dom";

import partner from '../../img/ic_patner_with_us_green.svg'
import delivery from '../../img/ic_delivery_with.svg'
import moregreen from '../../img/ic_arrow_learn_more_green.svg'
import moreorange from '../../img/ic_arrow_learn_more.svg'
import Paths from "../../services/Paths";


export const LandingSupport = () => {
		return (
				<div className={'LandingSupport'}>
						<div>
								<img src={partner} alt=""/>
								<h5>Partner with us!</h5>
								<p>Increase your sales, reach new customers, and grow your corporate catering. There are no up-front
										costs and we handle all the logistics.</p>
								<Link to={Paths.partnerUs}>Learn more <img src={moregreen} alt=""/></Link>
						</div>
						<div>
								<img src={delivery} alt=""/>
								<h5>Deliver with Meameal</h5>
								<p>Deliver food in your city and earn some extra cash. Experience the freedom of working whenever you
										want.
								</p>
								<Link to={Paths.delivery}>Learn more <img src={moreorange} alt=""/></Link>
						</div>
				</div>
		);
};

export default LandingSupport;
