import initialState from "../store/_initialState";
import {PARTNER} from "../actions/_actionTypes";

export const partnerOrders = (state = initialState.partnerOrders, action) => {
		switch (action.type) {
				case PARTNER.SET_PARTNER_ORDERS:
						return action.payload;
				case PARTNER.UPDATE_PARTNER_ORDER:
						console.log(action)
						return state
				default:
						return state;
		}
};

export const partnerLastOrder = (state = initialState.partnerLastOrder, action) => {
		switch (action.type) {
				case PARTNER.SET_PARTNER_LAST_ORDER:
						console.log(action)
						return action.payload;
				default:
						return state;
		}
};

export const partnerRestaurant = (state = initialState.partnerRestaurant, action) => {
		switch (action.type) {
				case PARTNER.SET_PARTNER_RESTAURANT:
						return action.payload;
				case PARTNER.UPDATE_PARTNER_RESTAURANT:
						return Object.assign({}, state, action.payload);
				default:
						return state;
		}
}

export const newOrderNotificationLayout = (state = initialState.partnerNewOrderLayoutState, action) => {
		switch (action.type) {
				case PARTNER.NEW_ORDER_NOTIFICATION_LAYOUT:
						return action.payload;
				default:
						return state;
		}
}
