import React from "react";
import {Button, Form, Rate, Checkbox} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../../actions/baseActions";
import Modal from "../../../HelpersComponents/Modal.Component";
import TextArea from "antd4/es/input/TextArea";
import {answerPartnerReview} from "../../../../services/Partner.Service";

class ReviewShow extends React.Component {
		handleSubmit = model => {
				const {selectedReview} = this.props;
				this.props.showLoading(true);
				answerPartnerReview(selectedReview.id, model).then(result => {
						if (result && 'review' in result) {
								this.props.onReviewAnswer(result.review);
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		render() {
				const {visible, selectedReview} = this.props;
				
				return (
						<Modal
								title="View review"
								visible={visible}
								onCancel={() => {
										this.props.onClosePopup(false);
								}}
								footer={false}
						>
								<div onKeyPress={this.onEnter}>
										<Form name={"anserComment"} onFinish={this.handleSubmit} initialValues={{
												isBlock: selectedReview && selectedReview.isBlock
										}} className="login-form">
												<div className="GlobalInput " style={{marginBottom: "20px"}}>
														<p>Is Blocked</p>
														<Form.Item
																name={"isBlock"}
																valuePropName="checked"
														>
																<Checkbox>
																		Block
																</Checkbox>
														</Form.Item>
												</div>
												<div className="GlobalInput " style={{marginBottom: "20px"}}>
														<p>Customer</p>
														<span>{selectedReview && selectedReview.user.clientFullName}</span>
														<br/>
														<span><Rate disabled style={{fontSize: "21px"}} value={selectedReview ? selectedReview.user.rating : 0}/></span>
												</div>
												<div className="GlobalInput " style={{marginBottom: "20px"}}>
														<p>Rate</p>
														<span><Rate disabled style={{fontSize: "21px"}} value={selectedReview && selectedReview.foodRate}/></span>
												</div>
												{selectedReview && selectedReview.opinion && (
														<div className="GlobalInput " style={{marginBottom: "20px"}}>
																<p>Comment</p>
																<span><b>{selectedReview && selectedReview.opinion}</b></span>
														</div>
												)}
												<div className="GlobalInput">
														<Form.Item
																label={"Answer"}
																name={"answer"}
																colon={false}
																initialValue={selectedReview && selectedReview.answer}
														>
																<TextArea
																		placeholder={"Write"}
																/>
														</Form.Item>
												</div>
												
												<Form.Item>
														<Button htmlType="submit">Submit</Button>
												</Form.Item>
										</Form>
								</div>
						</Modal>
				);
		}
}


ReviewShow.propTypes = {
		visible: PropTypes.bool.isRequired,
		onClosePopup: PropTypes.func.isRequired,
		onReviewAnswer: PropTypes.func.isRequired
};

ReviewShow.defaultProps = {
		visible: false,
		selectedReview: null,
		onClosePopup: f => f,
		onReviewAnswer: f => f
};

export default connect(
		() => ({}),
		{showLoading}
)(ReviewShow);
