import React from "react";
import {Button, Collapse, Form, Input, Popconfirm, Radio, Rate, Tag} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Card} from "react-bootstrap";
import {CloseCircleOutlined, CloseOutlined} from "@ant-design/icons";
import numeral from "numeral";
import {messageType, onlyNumeric} from "../../../services/Utilities";
import Modal from "../../HelpersComponents/Modal.Component";
import {
		cancelOrder,
		cancelOrderItem,
		getOrder,
		updateOrderStatus,
		removeDeliveryFee,
		refundOrder,
		chargeExtraOrder
} from "../../../services/Partner.Service";
import "../../../styles/PartnerStyles/partner.scss";
import PartnerAddItemModal from "./Partner.Add.Item.Modal.Component";
import {sendPartnerWssMessage} from "../../../Wss/Partner.WSS.Connection";
import WssAction from "../../../enums/WSS.Action.Enum";

const {Panel} = Collapse;

class PartnerAdjustModal extends React.Component {
		state = {
				visibleAddItem: false
		};
		
		sendOrderCancel = async orderSlug => {
				this.props.showLoading(true);
				return cancelOrder(orderSlug).then(order => {
						if (order && "id" in order) {
								let newOrder = this.props.items;
								newOrder.cancel = order.cancel;
								// sendPartnerWssMessage({
								// 		action: WssAction.ORDER_UPDATED,
								// 		slug: order.slug,
								// 		driver: order.driver ? order.driver.username : undefined,
								// 		restaurant: order && 'restaurant' in order ? order.restaurant.slug : false,
								// 		message: `The Order #${order.id} has been canceled !!!`,
								// 		messageType: messageType.warning
								// });
						}
				}).finally(() => {
						this.props.showLoading(false);
						this.props.onVisibleChange({show: false, getOrder: true});
				});
		};
		
		sendOrderItemCancel = async orderItemId => {
				this.props.showLoading(true);
				return cancelOrderItem(orderItemId).then(order => {
						if (order && "id" in order) {
								let newOrder = this.props.items;
								for (let i = 0; i < newOrder.item.length; i++) {
										if (newOrder.item[i].id === orderItemId) {
												newOrder.item[i].cancel = order.cancel;
										}
								}
						}
				}).finally(() => {
						this.props.showLoading(false);
						this.props.onVisibleChange({show: false, getOrder: true});
				});
		};
		
		callback(e) {
				console.log(e);
		}
		
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		handleSubmitChargeExtra = async event => {
				const {items} = this.props;
				chargeExtraOrder(items.slug, {
						amount: event.amount,
						extraChargeReason: event.extraChargeReason
				}).then(result => {
						if(result){
								this.props.onVisibleChange({show: false, getOrder: false});
						}
				})
		};
		
		handleSubmitRefund = async event => {
				const {items} = this.props;
				refundOrder(items.slug, {
						amount: event.amount,
						refundReason: event.refundReason
				}).then(result => {
						if(result){
								this.props.onVisibleChange({show: false, getOrder: false});
						}
				})
		};
		
		handleSubmitRemoveDeliveryFee = orderSlug => {
				
				removeDeliveryFee(orderSlug).then(order => {
						if(order){
								this.props.onVisibleChange({show: false, getOrder: false});
								
								// sendPartnerWssMessage({
								// 		action: WssAction.REMOVE_DELIVERY_FEE_REQUEST,
								// 		slug: order.slug,
								// 		restaurant: order.restaurant.slug
								// });
						}
				});
		};
		
		updateOrderStatus = (orderSlug, lastStatus, newStatus) => {
				if (orderSlug && newStatus) {
						this.props.showLoading(true);
						return updateOrderStatus(orderSlug, lastStatus, newStatus).then(order => {
								if (order && "id" in order) {
										this.props.onVisibleChange({show: false, getOrder: true, removeTabSocket: true});
										
										// sendPartnerWssMessage({
										// 		action: WssAction.ORDER_UPDATED,
										// 		slug: orderSlug,
										// 		driver: order.driver ? order.driver.username : undefined,
										// 		restaurant: order && 'restaurant' in order ? order.restaurant.slug : false
										// });
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		
		handleVisibleChangeAddItem = visible => {
				if (visible.getOrder) {
						const {items} = this.state;
						getOrder(items.slug).then(result => {
								if (result && "id" in result && result.id) {
										this.setState({
												items: result
										});
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
				this.setState({visibleAddItem: visible.show});
		};
		
		componentWillReceiveProps(nextProps) {
				// When modal is shown
				this.setState({items: nextProps.items});
		}
		
		render() {
				const {visible, view} = this.props;
				const {visibleAddItem, items} = this.state;
				console.log(items);
				return (
						<Modal
								title={view ? 'Adjust order' : 'View order'}
								visible={visible}
								// footer={[
								// 		items && items.restaurantStatus !== OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY && view ?
								// 				items.restaurantStatus === OrderItemRestaurantStatusNumeral.IS_NEW ?
								// 						<Button
								// 								key={'accept-order'}
								// 								className={"orderAccept"}
								// 								type={"primary"}
								// 								onClick={e => this.updateOrderStatus(items.slug, items.restaurantStatus, OrderItemRestaurantStatusNumeral.IS_RESTAURANT_PREPARING)}
								// 						>Accept order</Button> :
								// 						<Button
								// 								key={'order-ready'}
								// 								className={"orderDone"}
								// 								type={"primary"}
								// 								onClick={() => this.updateOrderStatus(items.slug, items.restaurantStatus, OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY)}
								// 						>
								// 								Order ready
								// 						</Button>
								// 				: ""
								// ]}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: true});
								}}>
								<PartnerAddItemModal
										visible={visibleAddItem}
										items={items}
										slug={items ? items.slug : false}
										onVisibleChange={this.handleVisibleChangeAddItem}
								/>
								<Tabs>
										<TabList>
												<Tab>{view ? 'Adjust Items' : 'Products'} </Tab>
												{view ? <>
														<Tab>Other adjustments </Tab>
												</> : ''}
												<Tab>Client Info</Tab>
										
										</TabList>
										
										<TabPanel>
												<div>
														<div className={'head_item'}>
																<b>{items ? items.item.length + ' Items' : ''}</b>
																{view ?
																		<Button onClick={e => (this.setState({visibleAddItem: true}))} type="primary">Add
																				Item</Button> : ''
																}
														</div>
														<div style={{height: '300px', overflow: 'auto'}}>
																{items && "item" in items && Object.keys(items.item).map(productKey => {
																		return (
																				<Card key={productKey.toString()}
																							className={items.item[productKey].cancel ? "canceled item_ord mt-2 itm_adj_prod" : items.item[productKey].secondaryAdd ? 'add_item itm_adj_prod item_ord mt-2' : 'itm_adj_prod mt-2'}>
																						<Card.Body className={"card_adjust"}>
																								<div className={"name_count"}>
																										<span>{items.item[productKey].cnt}</span>
																										<span className={"name"}>
                          {items.item[productKey].product ? items.item[productKey].product.name : ""}
                        </span>
																										<div className={"info_item"}>
																												<div className={"first_info"}>
																														<div><b>N:</b> {items.id}</div>
																														<div>
																																<b>Canceled:</b> {items.item[productKey].cancel ?
																																<Tag color="red">YES</Tag> : <Tag color="Green">NO</Tag>
																														}
																														</div>
																														<div>
																																<b>Size: </b> {items.item[productKey].size ? items.item[productKey].size.name : ""}
																																{items.item[productKey].size && items.item[productKey].size.plusPrice && (
																																		<span
																																				className="PlusPrice">{numeral(items.item[productKey].size.plusPrice).format("+ $ 0,0.[00]")}</span>
																																)}
																														
																														</div>
																														{items.item[productKey].ingredients.length ?
																																<div>
																																		
																																		<b>Ingredients: </b>
																																		<ul>
																																				{items.item[productKey].ingredients.map(item => (
																																						<li key={item.ingredient.slug}>
																																								{item.ingredient.name}{item.ingredient.plusPrice && (
																																								<span
																																										className="PlusPrice">{numeral(item.ingredient.plusPrice).format("+ $ 0,0.[00]")}</span>
																																						)}
																																						</li>)
																																				)}
																																		</ul>
																																</div> : ""}
																												</div>
																												
																												{/*<div>*/}
																												{/*  <div>*/}
																												{/*    <b>Restaurant state: </b>*/}
																												{/*    {items.item[productKey].restaurantStatus === OrderItemRestaurantStatusNumeral.IS_NEW ?*/}
																												{/*      <Tag color="magenta">New</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].restaurantStatus === OrderItemRestaurantStatusNumeral.IS_RESTAURANT_PREPARING ?*/}
																												{/*      <Tag color="cyan">Preparing</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].restaurantStatus === OrderItemRestaurantStatusNumeral.IS_RESTAURANT_READY ?*/}
																												{/*      <Tag color="green">Ready</Tag> : ""*/}
																												{/*    }*/}
																												{/*  </div>*/}
																												{/*  <div>*/}
																												{/*    <b>Driver state: </b>*/}
																												{/*    {items.item[productKey].driverStatus === OrderItemDriverStatusNumeral.IS_NEW ?*/}
																												{/*      <Tag color="magenta">New</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_ACCEPTED ?*/}
																												{/*      <Tag color="red">Accept</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_ARRIVED ?*/}
																												{/*      <Tag color="cyan">Arrived</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_DEPARTED ?*/}
																												{/*      <Tag color="green">Delivering</Tag> : ""*/}
																												{/*    }*/}
																												{/*    {items.item[productKey].driverStatus === OrderItemDriverStatusNumeral.IS_DRIVER_END ?*/}
																												{/*      <Tag color="green">End</Tag> : ""*/}
																												{/*    }*/}
																												{/*  </div>*/}
																												{/*  <div>*/}
																												{/*    <b>Location: </b> {items.item[productKey].location && "name" in items.item[productKey].location ? items.item[productKey].location.name : ""}*/}
																												{/*  </div>*/}
																												{/*</div>*/}
																										</div>
																								</div>
																								<span>
                        <b>${items.item[productKey].price}</b>
																										{items.item[productKey].cancel ? "" :
																												view ?
																														<button className={"remove_item"}
																																		onClick={() => this.sendOrderItemCancel(items.item[productKey].id, "item")}>
																																<CloseOutlined/>
																														</button> : ''
																												
																										}

                      </span>
																						
																						</Card.Body>
																				</Card>
																		);
																})}
														</div>
												</div>
										</TabPanel>
										
										{view ? <>
												<TabPanel>
														<Collapse accordion>
																<Panel
																		header={"Charge extra"}
																		key={1}
																		disabled={items && (items.testOrder)}
																		
																>
																		<div onKeyPress={this.onEnter}>
																				<Form name={"addCategoryForm"} onFinish={this.handleSubmitChargeExtra}
																							className="login-form">
																						<div className="GlobalInput " style={{marginTop: "20px"}}>
																								<p>Amount</p>
																								<Form.Item
																										name={"amount"}
																										rules={[
																												{
																														required: true,
																														message: "Please input your Amount!"
																												}
																										]}
																								>
																										<Input
																												addonBefore={"$"}
																												min={0}
																												step={0.1}
																												onKeyPress={onlyNumeric}
																												placeholder={"$0.00"}/>
																								</Form.Item>
																						</div>
																						<div className="GlobalInput " style={{marginTop: "10px"}}>
																								<p>Adjustment reason</p>
																								<Form.Item
																										name={"extraChargeReason"}
																										rules={[
																												{
																														required: true,
																														message: "Please input your Adjustment reason!"
																												}
																										]}
																								>
																										<Radio.Group>
																												<Radio style={{display: "block"}}
																															 value={"Custom request in special instructions"}>Custom
																														request in special instructions</Radio>
																												<Radio style={{display: "block"}} value={"Item substitution"}>Item
																														substitution</Radio>
																												<Radio style={{display: "block"}} value={"Customer add tip"}>Customer
																														add tip</Radio>
																												<Radio style={{display: "block"}} value={"Menu price in wrong"}>Menu
																														price in wrong</Radio>
																												<Radio style={{display: "block"}} value={"Delivery Fee added"}>Delivery
																														Fee added</Radio>
																										</Radio.Group>
																								</Form.Item>
																						</div>
																						
																						<Form.Item>
																								<Button htmlType="submit">Save</Button>
																						</Form.Item>
																				</Form>
																		</div>
																</Panel>
																<Panel
																		header={"Refund order"}
																		key={2}
																		disabled={items && (items.testOrder)}
																>
																		<div onKeyPress={this.onEnter}>
																				<Form name={"addCategoryForm"} onFinish={this.handleSubmitRefund}
																							className="login-form">
																						<div className="GlobalInput " style={{marginTop: "20px"}}>
																								<p>Amount</p>
																								<Form.Item
																										
																										name={"amount"}
																										rules={[
																												{
																														required: true,
																														message: "Please input your Amount!"
																												}
																										]}
																								>
																										<Input
																												addonBefore={"$"}
																												min={0}
																												step={0.1}
																												onKeyPress={onlyNumeric}
																												placeholder={"$0.00"}/>
																								</Form.Item>
																						</div>
																						<div className="GlobalInput " style={{marginTop: "10px"}}>
																								<p>Adjustment reason</p>
																								<Form.Item
																										name={"refundReason"}
																										rules={[
																												{
																														required: true,
																														message: "Please input your adjustment reason!"
																												}
																										]}
																								>
																										<Radio.Group>
																												<Radio style={{display: "block"}} value={"Tip refund"}>Tip
																														Refund</Radio>
																												<Radio style={{display: "block"}} value={"Menu price is wrong"}>Menu
																														Price is wrong</Radio>
																												<Radio style={{display: "block"}} value={"Issue with order"}>Issue
																														with order</Radio>
																												<Radio style={{display: "block"}} value={"Missing from order"}>Missing
																														from order</Radio>
																										</Radio.Group>
																								</Form.Item>
																						</div>
																						
																						<Form.Item>
																								<Button htmlType="submit">Save</Button>
																						</Form.Item>
																				</Form>
																		</div>
																</Panel>
														</Collapse>
														<Popconfirm title="Are you sure? Remove delivery fee？" okText="Yes" cancelText="No"
																				disabled={items && (items.isRemoveDeliveryPriceRequested || items.isDeliveryPriceRemoved || items.testOrder)}
																				onConfirm={() => this.handleSubmitRemoveDeliveryFee(items.slug)}>
																<Button
																		className={"btn_adjust"}
																		key={"remove_delivery"}
																>
																		<CloseCircleOutlined/>
																		Remove delivery fee <b>{items && (items.isRemoveDeliveryPriceRequested) ? (items.isDeliveryPriceRemoved ? "(Removed)": "(Requested)") : ""}</b>
																</Button>
														</Popconfirm>
														
														<Popconfirm title="Are you sure cancel order？" okText="Yes" cancelText="No"
																				disabled={items && (items.testOrder)}
																				onConfirm={() => this.sendOrderCancel(items.slug)}>
																<Button type="primary" className={"btn_adjust"}>
																		<CloseCircleOutlined/>
																		Cancel order
																</Button>
														</Popconfirm>
												</TabPanel>
										
										</> : ''}
										<TabPanel>
												<div>
														{items && "client" in items ?
																<div>
																		<Card>
																				<Card.Body>
																						<div style={{marginBottom: "0"}}><Rate disabled={true}
																																									 value={items.client.rating}/> <b
																								style={{marginLeft: '5px'}}>{numeral(items.client.rating).format('0[.]0')} ratings</b>
																						</div>
																				</Card.Body>
																		</Card>
																		<Card>
																				<Card.Body>
																						<p style={{marginBottom: "0"}}><b>Client
																								name:</b> {items.client.clientFullName}</p>
																				</Card.Body>
																		</Card>
																		<Card>
																				<Card.Body>
																						<p style={{marginBottom: "0"}}><b>Client email:</b> {items.client.email}</p>
																				</Card.Body>
																		</Card>
																		<Card>
																				<Card.Body>
																						<p style={{marginBottom: "0"}}><b>Client phone:</b> {items.client.phone}</p>
																				</Card.Body>
																		</Card>
																		
																		{items && "location" in items ? <Card>
																				<Card.Body>
																						
																						<div>
																								<p style={{marginBottom: "0"}}><b>Client
																										address:</b> {items.location.address}</p>
																						</div>
																				</Card.Body>
																		</Card> : ""
																		}
																</div> : ""
														}
												
												</div>
										</TabPanel>
								</Tabs>
						</Modal>
				);
		}
}


PartnerAdjustModal.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired
};

PartnerAdjustModal.defaultProps = {
		visible: false,
		view: true,
		onVisibleChange: f => f,
		items: null
};

export default connect(
		() => (
				{}
		),
		{
				showLoading
		}
)(PartnerAdjustModal);
