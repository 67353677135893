export const ModalTypes = {
		BASKET_MODAL: 'basket',
		NOTIFICATION_MODAL: 'notification',
		SIGN_IN_MODAL: 'signIn',
		REGISTRATION_MODAL: 'registration',
		FORGOT_PASSWORD_MODAL: 'forgotPassword',
		FORGOT_PASSWORD_VERIFY_CODE: 'forgotPasswordVerifyCode',
		ITEM_DETAILS_MODAL: 'itemDetails',
		MAP_MODAL: 'map',
		CHANGE_PASSWORD_MODAL: 'changePassword',
		ADJUST: 'adjustOrder',
		IMG_SHOW: 'showImgVisible',
		DRIVER_MAP: 'driverMap',
		TERMS_AND_CONDITIONS_MODAL: 'termsAndConditions',
		PRIVACY_POLICY_MODAL: 'privacyPolicy',
		ORDER_DETAILS_MODAL: 'orderDetails',
		DRIVER_FEEDBACK_MODAL: 'driverFeedback',
		DRIVER_ADDRESS_NOTE_MODAL: 'driverAddressNote',
		CUSTOMER_FEEDBACK_MODAL: 'customerFeedback',
		ADDRESS_NOTES: 'addressNotes'
};

export default ModalTypes;
