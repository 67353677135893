import React from 'react';
import {Select} from 'antd4';
import {cardIcons} from "../../../enums/Card.Icons.Enum";

const { Option } = Select;

const RenderCardSelect = (props) => {
    return props.cardsList.length ? (
        <div className="col-12 pl-0 pr-0 pr-md-3 col-lg-12">
          <Select
            className='GlobalInput CardSelect'
            onSelect={item => {
              props.onCreditCardSelect(item);
            }}
            value={props.selectedCardId}
          >
            <Option key={'add_New'} value={0}>
              <div className='add_New'>Add new card or select ...</div>
            </Option>
            {props.cardsList.map((item, i) =>
              <Option key={i} value={item.id}>
                <div className="inCardSelect">
                  <img src={cardIcons[item.cardType]} alt=""/>
                  <div className="card_name">
                    <p>{item.holderName}</p>
                    <p className="card_code">{item.showNumber}</p>
                  </div>
                </div>
              </Option>
            )}
          </Select>
        </div>
      )
      : null;
}

export default RenderCardSelect;