import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Collapse } from "antd4";
import locationIcon from "../../../img/maps-and-flags.png";
import phoneIcon from "../../../img/driver-phone.png";
import coinIcon from "../../../img/driver-coin.png";
import numeral from "numeral";
import userIcon from "../../../img/driver-user.png";
import chatIcon from "../../../img/driver-comment.png";
import orderIcon from "../../../img/driver-order.png";
import {getDriverOrdersList} from '../../../actions/driverActions'
import {showModal} from "../../../actions/baseActions";
import ModalTypes from "../../../enums/Modal.Types.Enum";

const { Panel } = Collapse;

const DriverOrderHistory = ({ getDriverOrdersList, orders, showModal}) => {
  useEffect(() => {
    getDriverOrdersList();
  }, []);

  const addAddressNote = orderSlug =>{
    showModal(ModalTypes.DRIVER_ADDRESS_NOTE_MODAL, true, {orderSlug})
  }
  
  const addFeedback = orderSlug =>{
    showModal(ModalTypes.DRIVER_FEEDBACK_MODAL, true, {orderSlug})
  }
  
  return (
    <div className={"OrderHistory"}>
      {orders && orders.length ?
        orders.map(order => (
          <div className="OrderHistoryItem" key={order.slug}>
            <div className="OrderHistoryItemHeader">
              <span>{`# ${order.id}`}</span>
            </div>
            <Collapse accordion>
              {order.restaurants && Object.keys(order.restaurants).map(key => (
                  <Panel
                    header={order.restaurants[key].locationName}
                    key={order.restaurants[key].locationName}
                  >
                    <p className={"branchName"}>
                      <img src={locationIcon} alt=""/>
                      <span>{`${order.restaurants[key].locationName} (${order.restaurants[key].location.address})`}</span>
                    </p>
                    <p className={"coin"}>
                      <img src={coinIcon} alt=""/>
                      <span>{numeral(order.restaurants[key].price).format("$ 0,0[.]00")}</span>
                    </p>
                    <div className="items">
                      {order.restaurants[key].products.map(item => (
                        <div className="item" key={item.id}>
                          <span>{item.product ? item.product.name : item.catering ? item.catering.name : ""}</span> {item.cnt}
                        </div>
                      ))}
                    </div>
                  </Panel>
                )
              )}
            </Collapse>
            <div className="clientInfo">
              <p className={"branchName"}>
                <img src={locationIcon} alt=""/>
                <span>{order.location && "address" in order.location && order.location.address}</span>
              </p>
              <p className={"tel"}>
                <img src={userIcon} alt=""/>
                <span>{order.fullName}</span>
              </p>
              {order.notes && (
                <p className={"message"}>
                  <img src={chatIcon} alt=""/>
                  <span>{order.notes}</span>
                </p>
              )}
              <p className={"tel"}>
                <img src={phoneIcon} alt=""/>
                <span>{order.phone}</span>
              </p>
              <p className={"order"}>
                <img src={orderIcon} alt=""/>
                Delivery {numeral(order.deliveryPrice).format("$ 0,0[.]00")}
              </p>
            </div>
            <div className='driver-action-row'>
              {!order.addressComment && (
                  <div className='driver-action-column'>
                    <button onClick={() => addAddressNote(order.slug)}>+ Address note </button>
                  </div>
              )}
              {!order.driverOpinion && (
                  <div className='driver-action-column'>
                    <button onClick={() => addFeedback(order.slug)}>+ Feedback</button>
                  </div>
              )}
            </div>
          </div>
        ))
        : null}
    </div>
  );
};

const mapStateToProps = state => ({orders: state.ordersList});

const mapDispatchToProps = {
  getDriverOrdersList,
  showModal
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DriverOrderHistory);
