import {REVIEW, LOADING} from "./_actionTypes";
import * as ReviewAPI from '../api/Review.API';
import {getErrorsFromResponse, handleResponse} from "../services/Utilities";

export const getReviews = (restaurantSlug, offset = 1, limit = 10) => dispatch => {
		dispatch({type: LOADING.START_LOADING});
		
		return ReviewAPI.getReviews(restaurantSlug, offset, limit)
				.then(result => handleResponse(
						result,
						undefined,
						() => {
								const data = result.data;
								
								dispatch({
										type: REVIEW.SET_REVIEWS,
										payload: data
								});
								return data;
						},
						undefined,
						undefined,
						getErrorsFromResponse(result),
				)).finally(() =>
						dispatch({type: LOADING.STOP_LOADING})
				);
};


export const clearReviews = () => dispatch => {
		dispatch({
				type: REVIEW.CLEAR_REVIEWS
		})
};
