import React from "react";
import {setPartnerLastOrder, setNewOrderLayoutState, updatePartnerOrder, setPartnerOrders} from "../../actions/partnerActions";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import newOrderSound1 from "../../sounds/sound-1.wav";
import newOrderSound2 from "../../sounds/sound-2.mp3";
import newOrderSound3 from "../../sounds/sound-3.mp3";
import newOrderSound4 from "../../sounds/sound-4.mp3";
import {WssAction} from "../../enums/WSS.Action.Enum";
import {cancelOrder, getOrders, getOrder, updateOrderStatus} from "../../services/Partner.Service";
import {BellOutlined, CloseOutlined} from "@ant-design/icons";
import {Button} from "react-bootstrap";
import {sendPartnerWssMessage} from "../../Wss/Partner.WSS.Connection";
import {messageType} from "../../services/Utilities";
import {showLoading} from "../../actions/baseActions";
import Paths from "../../services/Paths";

class PartnerNewOrderNotificationComponent extends React.Component{
		constructor(props) {
				super(props);
				
				this.state = {
						smallNewOrder: null,
						lastOrder: null,
				}

				document.addEventListener("PartnerConnection", this.receiveMessage);
		}
		
		componentWillUnmount() {
				document.removeEventListener("PartnerConnection", this.receiveMessage);
		}
		
		receiveMessage = wssEvent => {
				const {restaurant, audioState, orders} = this.props;
				const {settings} = restaurant;
				const {detail} = wssEvent;
				
				let smallNewOrder = false;
				if (detail && "typeOrderAlert" in detail && detail.typeOrderAlert === 2) {
						smallNewOrder = true;
				}
				
				let newOrderNotify = new Audio(newOrderSound1);
				
				if ("typeOrderSound" in detail) {
						if (detail.typeOrderSound === 1) {
								newOrderNotify = new Audio(newOrderSound1);
						} else if (detail.typeOrderSound === 2) {
								newOrderNotify = new Audio(newOrderSound2);
						} else if (detail.typeOrderSound === 3) {
								newOrderNotify = new Audio(newOrderSound3);
						} else if (detail.typeOrderSound === 4) {
								newOrderNotify = new Audio(newOrderSound4);
						}
				}
				
				if (detail && "action" in detail) {
						switch (detail.action) {
								case WssAction.ACTION_SUBMITTED:
										if ("slug" in detail) {
												getOrder(detail.slug).then(result => {
														if (result && "id" in result && result.id) {
																if (audioState) {
																		newOrderNotify.play();
																}
																
																this.setState({
																		smallNewOrder: smallNewOrder,
																		lastOrder: result
																});
																this.props.setNewOrderLayoutState(true)
														
																return result;
														}
														return false;
												}).then(result => {
														if(result){
																return getOrders().then(orders => {
																		this.props.setPartnerOrders(orders);
																		return orders;
																});
														}
														return false;
												});
										}
										break;
								case WssAction.ORDER_UPDATED:
										if ("slug" in detail) {
												getOrders().then(orders => {
														this.props.setPartnerOrders(orders);
														return orders;
												});
										}
										break;
								default:
										break;
						}
				}
		};
		
		/**
		 * non used (18.07.2022) - regarding Varazdats last requirements (view order button)
		 * @deprecated
		 * */
		sendCanceling = orderSlug => {
				this.props.showLoading(true);
				return cancelOrder(orderSlug).then(order => {
						if (order && "id" in order) {
								getOrders().then(orders => {
										if (orders) {
												this.props.setNewOrderLayoutState(false);
												this.setState({
														lastOrder: null
												});
												
												this.props.setPartnerOrders(orders)
										}
								});
								
								// sendPartnerWssMessage({
								// 		action: WssAction.ORDER_UPDATED,
								// 		slug: order.slug,
								// 		driver: order.driver ? order.driver.username : undefined,
								// 		restaurant: order && 'restaurant' in order ? order.restaurant.slug : false,
								// 		message: `The Order #${order.id} has been canceled !!!`,
								// 		messageType: messageType.warning
								// });
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		/**
		 * non used (18.07.2022) - regarding Varazdats last requirements (view order button)
		 * @deprecated
		 * */
		updateOrderStatus = (orderSlug, lastStatus, newStatus) => {
				if (orderSlug && newStatus) {
						this.props.showLoading(true);
						updateOrderStatus(orderSlug, lastStatus, newStatus).then(order => {
								if (order && "id" in order) {
										this.props.showLoading(true);
										getOrders().then(orders => {
												if (orders) {
														this.setState({lastOrder: null});
														this.props.setNewOrderLayoutState(false)
														this.props.setPartnerOrders(orders)
												}
										}).finally(() => {
												this.props.showLoading(false);
										});
										
										// sendPartnerWssMessage({
										// 		action: WssAction.ORDER_UPDATED,
										// 		slug: orderSlug,
										// 		driver: order.driver ? order.driver.username : undefined,
										// 		restaurant: order && 'restaurant' in order ? order.restaurant.slug : false
										// });
								}
						}).finally(() => {
								this.props.showLoading(false);
						});
				}
		};
		
		viewOrder = orderSlug => {
				this.props.setNewOrderLayoutState(false);
				this.props.history.replace(`${Paths.basePartner.replace(":tabName", "orders")}/${orderSlug}`);
		}
		
		render(){
				const {newOrderLayoutState} = this.props;
				const {smallNewOrder, lastOrder} = this.state;
				return (
						<div className="">
								{newOrderLayoutState ?
										!smallNewOrder ?
												<div>
														<div className="new_order_dialog blink">
																<div className="layer_click">
																</div>
																<a className={"close_modal"}
																	 onClick={() => (this.props.setNewOrderLayoutState(false))}>
																		<CloseOutlined/>
																</a>
																
																<div className="notification-block">
																		<div className="icon-notif">
																				<BellOutlined/>
																		</div>
																		<div className="info">
																				<h3>1 New order</h3>
																		</div>
																		<div className="info">
																				{/*<Button*/}
																				{/*		onClick={() => this.updateOrderStatus(lastOrder.slug, lastOrder.restaurantStatus, OrderItemRestaurantStatusNumeral.IS_RESTAURANT_PREPARING)}*/}
																				{/*		className={"dismiss-btn ml-3"}>*/}
																				{/*		Accept*/}
																				{/*</Button>*/}
																				<Button
																						onClick={() => this.viewOrder(lastOrder.slug)}
																						className={"dismiss-btn ml-3"}>
																						View Order
																				</Button>
																				<Button
																						onClick={e => (this.setState({smallNewOrder: true}))}
																						className={"dismiss-btn ml-3"}>
																						Dismiss
																				</Button>
																				{/*<Button*/}
																				{/*		onClick={e => (this.sendCanceling(lastOrder.slug))}*/}
																				{/*		className={"dismiss-btn ml-3"}>*/}
																				{/*		Deny*/}
																				{/*</Button>*/}
																		</div>
																</div>
														</div>
														<div className="new_order_layer"/>
												</div> :
												<div className="navbar_new_order blink" style={{cursor: 'pointer'}} onClick={() => this.viewOrder(lastOrder.slug)}>
														<BellOutlined/> 1 New Order
												</div>
										: ""
								}
						</div>
				);
		}
}

const mapStateToProps = state => ({
		orders: state.partnerOrders,
		audioState: state.audioState,
		restaurant: state.partnerRestaurant,
		newOrderLayoutState: state.partnerNewOrderLayoutState
});

const mapDispatchToProps = {
		showLoading,
		updatePartnerOrder,
		setPartnerLastOrder,
		setNewOrderLayoutState,
		setPartnerOrders
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(withRouter(PartnerNewOrderNotificationComponent));
