import React from 'react'
import bitmap from '../../img/Bitmap.png'
//TODO delete if not used
export const OrderComplete = ()=>(
  <div className={'OrderComplete'}>
    <img src={bitmap} alt="deliver"/>
    <h3>Thank you for you purchase</h3>
    <p>In 45 min our delivery guy will bring you best fresh meal that you ever
      taste, just wait him and you will se the magic</p>
    <button>Our Promotions</button>
  </div>
);

export default OrderComplete;