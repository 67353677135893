import React, {Component} from 'react';
import {showLoading} from "../../actions/baseActions";
import {connect} from "react-redux";

import {getOrder, getOrders} from "../../services/Partner.Service";
import {Button, Table } from 'antd4';
import {OrderColumns, OrderItemColumns} from "../../enums/Orders.Table";
import { InfoCircleOutlined, ReloadOutlined } from "@ant-design/icons";
import OrderInfoModal from './Order.Info.Component';
import {WssAction} from '../../enums/WSS.Action.Enum';
import {sendPartnerManagerWssMessage} from "../../Wss/Partner.Manager.WSS.Connection";
import '../../styles/PartnerStyles/partner.scss';
import ToggleAudio from "../HelpersComponents/Toggle.Audio.Component";

class PartnerManager extends Component {
	CustomOrderColumns = undefined;
	
	// PartnerManagerWssConnection = undefined;
	
	constructor(props) {
		super(props);
		
		this.state = {
			orders: [],
			orderModalVisible: false
		};
		
		
		this.CustomOrderColumns = [...OrderColumns, {
			title: 'Action',
			key: 'action',
			render: (text, record) => (
				<span>
					<InfoCircleOutlined
						style={{fontSize: '25px', color: '#08c'}}
						theme="outlined"
						title="Order information"
						onClick={() => this.toggleInfoModal(record.slug)}
					/>
      			</span>
			)
		}];
	}
	
	UNSAFE_componentWillMount() {
		document.addEventListener('PartnerManagerConnection', this.receiveMessage);
		this.getPartnerManagerOrders();
	}
	
	componentWillUnmount() {
		document.removeEventListener('PartnerManagerConnection', this.receiveMessage);
	}
	
	receiveMessage = event => {
		const {detail} = event;
		
		if (detail && 'action' in detail) {
			if (detail.action === WssAction.ACTION_SUBMITTED && 'order' in detail) {
				this.props.showLoading(true);
				getOrder(detail.order).then(result => {
					if ('id' in result && result.id) {
						this.setState({
							orders: [result, ...this.state.orders]
						});
					}
				}).finally(() => {
					this.props.showLoading(false);
				});
			}
		}
	};
	
	getPartnerManagerOrders = () => {
		this.props.showLoading(true);
		getOrders().then(result => {
			if (result) {
				this.setState({orders: result})
			}
		}).finally(() => {
			this.props.showLoading(false);
		})
	};
	
	toggleInfoModal = (orderSlug = null) => {
		const {orderModalVisible} = this.state;
		this.setState({
			orderModalVisible: !orderModalVisible,
			orderSlug: orderSlug
		})
	};
	
	getItemTable = data => {
		if (data && 'item' in data) {
			return <Table
				pagination={false}
				rowKey={'id'}
				columns={OrderItemColumns}
				dataSource={data.item}
			/>
		}
		return ''
	};
	
	onOrderUpdate = order => {
		const {orders} = this.state;
		const {brunch} = this.props.user;
		if (order) {
			const filteredOrder = orders.filter(item => item.slug !== order.slug);
			
			sendPartnerManagerWssMessage({
				action: WssAction.ORDER_UPDATED,
				slug: order.slug,
				driver: order.driver ? order.driver.username : undefined,
				restaurant: brunch && 'restaurant' in brunch ? brunch.restaurant.slug : false
			});
			
			this.setState({
				orders: [order, ...filteredOrder]
			})
		}
	};
	
	render() {
		const {orders, orderModalVisible, orderSlug} = this.state;
		return (
			<div className={"Content ContentManager"}>
{/*				<button onClick={() => {
					sendPartnerManagerWssMessage({
						action: WssAction.ORDER_UPDATED,
						slug: 'burger-king-partner-4',
						driver: 'driver',
						restaurant: 'burger-king-12'
					});
					
				}}>
					sendMessage
				</button>*/}
				<section className={'ContentHeader'}>
					<h1>Orders</h1>
					<div className={'partnerActionButtons'}>
						<ToggleAudio/>
						<Button
							className={'AddNewButton'}
							onClick={() => {this.getPartnerManagerOrders();}}
						>
							<ReloadOutlined/>
							Refresh
						</Button>
					</div>
				</section>
				<section className={"ContentBody"}>
					<div>
						<Table
							rowKey={'id'}
							columns={this.CustomOrderColumns}
							expandedRowRender={this.getItemTable}
							dataSource={orders}
						/>
					</div>
				</section>
				
				
				{orderModalVisible && (
					<OrderInfoModal
						orderModalVisible={orderModalVisible}
						orderSlug={orderSlug}
						onUpdate={this.onOrderUpdate}
						onClose={this.toggleInfoModal}
					/>
				)}
			</div>
		);
	}
}


const mapStateToProps = state => ({
	user: state.user.data,
	loading: state.loading
});

const mapDispatchToProps = {
	showLoading,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PartnerManager);
