export const USER = {
		LOGGED_IN: 'USER_LOGGED_IN',
		LOGGED_OUT: 'USER_LOGGED_OUT',
		
		UPDATE_PERSONAL_INFORMATION: 'UPDATE_PERSONAL_INFORMATION'
};

export const DRIVER = {
		SET_ACTUAL_ORDER: 'SET_ACTUAL_ORDER',
		SET_ACTUAL_ORDERS: 'SET_ACTUAL_ORDERS',
		REMOVE_ACTUAL_ORDER: 'REMOVE_ACTUAL_ORDER',
		REMOVE_ACTUAL_ORDERS: 'REMOVE_ACTUAL_ORDERS',
		UPDATE_ACTUAL_ORDER: 'UPDATE_ACTUAL_ORDER',
		GET_ORDERS_LIST: 'GET_ORDERS_LIST'
};

export const DRIVER_TIP_TARIFF = {
		SET_DRIVER_TIP_TARIFF: 'SET_DRIVER_TIP_TARIFF',
		REMOVE_DRIVER_TIP_TARIFF: 'REMOVE_DRIVER_TIP_TARIFF',
};

export const LOADING = {
		START_LOADING: 'START_LOADING',
		STOP_LOADING: 'STOP_LOADING',
		
		START_LOCAL_LOADING: 'START_LOCAL_LOADING',
		STOP_LOCAL_LOADING: 'STOP_LOCAL_LOADING'
};

export const CLIENT = {
		SET_ORDERS: 'SET_ORDERS',
		PAGINATE_ORDERS: 'PAGINATE_ORDERS',
		
		CLEAR_ORDERS: 'CLEAR_ORDERS',
};

export const HEADER = {
		SHOW_HEADER: 'SHOW_HEADER',
		HIDE_HEADER: 'HIDE_HEADER',
};

export const BASKET = {
		SET_ITEMS: 'SET_ITEMS',
		ADD_FIRST_ITEM: 'ADD_FIRST_ITEM',
		ADD_NEW_PRODUCT: 'ADD_NEW_PRODUCT',
		UPDATE_ITEM: 'UPDATE_ITEM',
		REMOVE_ITEM: 'REMOVE_ITEM',
		CHANGE_COUNT: 'CHANGE_COUNT',
		CALCULATE_BASKET: 'CALCULATE_BASKET',
		CLEAR_CART: 'CLEAR_CART'
};

export const PARTNER = {
		SET_PARTNER_ORDERS: 'SET_PARTNER_ORDERS',
		UPDATE_PARTNER_ORDER: 'UPDATE_PARTNER_ORDER',
		SET_PARTNER_LAST_ORDER: 'SET_PARTNER_LAST_ORDER',
		
		SET_PARTNER_RESTAURANT: 'SET_PARTNER_RESTAURANT',
		UPDATE_PARTNER_RESTAURANT: 'UPDATE_PARTNER_RESTAURANT',
		
		NEW_ORDER_NOTIFICATION_LAYOUT: 'NEW_ORDER_NOTIFICATION_LAYOUT'
};

export const LOCATION = {
		UPDATE_USER_LOCATION: 'UPDATE_USER_LOCATION',
		REMOVE_USER_LOCATION: 'REMOVE_USER_LOCATION',
		
		WRITE_LATER: 'WRITE_LATER',
		
		IS_ADDRESS_SET: 'IS_ADDRESS_SET'
};

export const AUDIO = {
		TURN_ON: 'TURN_ON',
		TURN_OFF: 'TURN_OFF',
};

export const LEFT_MENU = {
		SHOW_LEFT_MENU: 'SHOW_LEFT_MENU',
		HIDE_LEFT_MENU: 'HIDE_LEFT_MENU',
};

export const CATEGORIES = {
		SET_CATEGORIES: 'SET_CATEGORIES'
};

export const RESTAURANTS = {
		SET_RESTAURANTS: 'SET_RESTAURANTS',
		SET_SELECTED_RESTAURANT: 'SET_SELECTED_RESTAURANT',
		REMOVE_SELECTED_RESTAURANT: 'REMOVE_SELECTED_RESTAURANT',
};

export const PRODUCTS = {
		SET_PRODUCTS: 'SET_PRODUCTS',
		PAGINATE_PRODUCTS: 'PAGINATE_PRODUCTS'
};

export const MODAL = {
		SHOW_MODAL: 'SHOW_MODAL'
};

export const IMG = {
		SHOW: 'SHOW',
		HIDE: 'HIDE'
};

export const REVIEW = {
		SET_REVIEWS: 'SET_REVIEWS',
		CAN_ADD_REVIEW: 'CAN_ADD_REVIEW',
		ADD_NEW_REVIEW: 'ADD_NEW_REVIEW',
		CLEAR_REVIEWS: 'CLEAR_REVIEWS'
};

export const FILTER = {
		UPDATE_FILTER: 'UPDATE_FILTER',
		RESET_FILTER: 'RESET_FILTER',
		
		UPDATE_DEFAULT_DATA: 'UPDATE_DEFAULT_DATA'
};

export const ORDER_DELIVERY_TYPE = {
		UPDATE_ORDER_DELIVERY_TYPE: 'UPDATE_ORDER_DELIVERY_TYPE'
};

export const ORDER_TYPE = {
		UPDATE_ORDER_TYPE: 'UPDATE_ORDER_TYPE'
};

export const ORDER_UPDATE = {
		UPDATE_DRIVER_ORDER_PROPERTY: 'UPDATE_DRIVER_ORDER_PROPERTY',
		UPDATE_CUSTOMER_ORDER_PROPERTY: 'UPDATE_CUSTOMER_ORDER_PROPERTY'
};

export const ADDRESS_NOTE = {
		SET_ADDRESS_NOTE: 'SET_ADDRESS_NOTE'
};

export const DELIVERY_TIME = {
		UPDATE_DELIVERY_DAY: 'UPDATE_DELIVERY_DAY',
		UPDATE_DELIVERY_TIME: 'UPDATE_DELIVERY_TIME',
};

export const CreditCard_Type = {
		CARD_NUMBER_CHANGE: 'CARD_NUMBER_CHANGE',
		HOLDER_NAME_CHANGE: 'HOLDER_NAME_CHANGE',
		EXP_DATE_CHANGE: 'EXP_DATE_CHANGE',
		CVV_CHANGE: 'CVV_CHANGE',
		CARD_SELECT: 'CARD_SELECT',
		CARD_SAVE_CHECK: 'CARD_SAVE_CHECK',
		UPDATE_CARD_LIST: 'UPDATE_CARD_LIST',
		ZIP_CODE_CHANGE: 'ZIP_CODE_CHANGE',
		CLEAR_CARD: 'CLEAR_CARD'
}

export const NOTIFICATION = {
		ADD: 'ADD',
		REMOVE: 'REMOVE'
}
