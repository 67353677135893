import {
	DRIVER, LOADING
} from "./_actionTypes";
import { getDriverOrders } from "../services/Driver.Service";
import { storeOrderDetails } from "../services/Utilities";

export const setActualOrder = order => dispatch => {
	dispatch({
		type: DRIVER.SET_ACTUAL_ORDER,
		payload: order
	})
};

export const setActualOrders = orders => dispatch => {
	dispatch({
		type: DRIVER.SET_ACTUAL_ORDERS,
		payload: orders
	})
};

export const removeActualOrders = () => dispatch => {
	dispatch({
		type: DRIVER.REMOVE_ACTUAL_ORDERS
	})
};

export const removeActualOrder = key => dispatch => {
	dispatch({
		type: DRIVER.REMOVE_ACTUAL_ORDER,
		key: key
	})
};

export const updateActualOrder = (order, key) => dispatch => {
	dispatch({
		type: DRIVER.UPDATE_ACTUAL_ORDER,
		payload: order,
		key: key
	})
};

export const getDriverOrdersList = () => dispatch => {
	dispatch({type: LOADING.START_LOADING})

	getDriverOrders().then(async result => {
		if (result) {
			const ordersArr = [];
			for (const key in result) {
				const order = await storeOrderDetails(result[key]);
				if (order) {
					ordersArr.push(order)
				}
			}
			
			dispatch({
				type: DRIVER.GET_ORDERS_LIST,
				payload: ordersArr.length ? ordersArr : []
			})
		}
	}).finally(() => {
		dispatch({type: LOADING.STOP_LOADING})
	});
}
