import * as BaseAPI from '../api/Base.API';
import * as OrderAPI from '../api/Order.API';
import * as HomeAPI from '../api/Home.API';

export const getFilterDefaultData = isCatering => {
	const filterData = sessionStorage.getItem('filterData');
	
	const getFilterData = data => ({
		price: isCatering ? data.cateringPrice : data.price,
		cal: data.cal,
		deliveryTime: data.deliveryTime
	});
	
	if (filterData) {
		return Promise.resolve(getFilterData(JSON.parse(filterData)));
	} else {
		return HomeAPI.getFilterDefaultData().then(result => {
			if (result.success) {
				const data = getFilterData(result.data);
				sessionStorage.setItem('filterData', JSON.stringify(result.data));
				return data;
			}
			return false;
		})
	}
};

export const updateClientLocation = location => {
		return BaseAPI.updateClientLocation({
				lat: location.lat,
				lng: location.log
		})
}
