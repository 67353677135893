import { CreditCard_Type } from "../actions/_actionTypes";
import initialState from "../store/_initialState";
import { getZipCode } from "../services/Utilities";

export const creditCardReducer = (state = initialState.creditCard, action) => {
  switch (action.type) {
    case CreditCard_Type.CARD_NUMBER_CHANGE:
      return { ...state, ...action.payload };
    case CreditCard_Type.HOLDER_NAME_CHANGE:
      return { ...state, holderName: action.holderName };
    case CreditCard_Type.EXP_DATE_CHANGE:
      return { ...state, expDate: action.expDate };
    case CreditCard_Type.CVV_CHANGE:
      return {...state, cvv: action.cvv}
    case CreditCard_Type.CARD_SELECT:
      return {...state, selectedCardId: action.selectedCardId}
    case CreditCard_Type.CARD_SAVE_CHECK:
      return {...state, saveCard: !state.saveCard }
    case CreditCard_Type.UPDATE_CARD_LIST:
      return {...state, list: action.list}
    case CreditCard_Type.ZIP_CODE_CHANGE:
      return {...state, zipCode: action.zipCode || ''}
    case CreditCard_Type.CLEAR_CARD:
      return {
        number: undefined,
        holderName: '',
        expDate: undefined,
        cvv: undefined,
        isTest: false,
        cardType: 'Default',
        selectedCardId: 0,
        saveCard: false,
        list: [],
        zipCode: getZipCode()
      }
    default:
      return state;
  }
};
