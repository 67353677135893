import React from 'react';
import Modal from "../HelpersComponents/Modal.Component";
import PropTypes from "prop-types";
import {showLoading} from "../../actions/baseActions";
import {connect} from "react-redux";
import {Button, Input, Form, Spin} from 'antd4';
import {Link} from 'react-router-dom';
import {requestVerificationCode} from '../../services/User.Service'
import {showModal} from '../../actions/baseActions';
import ModalTypes from "../../enums/Modal.Types.Enum";

export const ForgotPasswordModal = ({forgotPasswordModalVisible, showModal, form, loading, showLoading}) => {
		
		showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)
		showModal(ModalTypes.FORGOT_PASSWORD_VERIFY_CODE, true, {
				email: 'amirjanyan1995@gmail.com'
		})
		
		const handleSubmit = values => {
				if ('email' in values) {
						showLoading(true);
						requestVerificationCode(values.email).then(result => {
								if (result) {
										showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)
										showModal(ModalTypes.FORGOT_PASSWORD_VERIFY_CODE, true, {
												email: result.email
										})
								}
						}).finally(() => {
								showLoading(false);
						});
				}
		};
		
		const haveAnAccount = () => {
				showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false);
				showModal(ModalTypes.SIGN_IN_MODAL);
		};
		
		return (
				<Modal
						className={"SignIn"}
						visible={forgotPasswordModalVisible}
						onCancel={() => showModal(ModalTypes.FORGOT_PASSWORD_MODAL, false)}
				>
						<Spin spinning={loading}>
								<h2 className={'SignInTitle text-center'}>Reset your password</h2>
								<div>
										<Form
												onFinish={handleSubmit}
												className="login-form"
												name={'forgot_password_request'}
										>
												<Form.Item
														label={'Email address or username'}
														name={'email'}
														rules={[{
																required: true,
																message: 'You must provide either a valid username or an email.'
														}]}
												>
														<Input/>
												</Form.Item>
												<div className={'LoginForgot'}>
														<Link to={'#'} onClick={() => {
																console.log('aaa');
														}} className="login-form-forgot">
																I already have a Code
														</Link>
												</div>
												<Button type="primary" htmlType="submit" className="login-form-button">
														Send verification code
												</Button>
												<p className={'DontHaveAccount'}>
														Have an account?
														<Link to={'#'} onClick={haveAnAccount}>Login</Link></p>
										</Form>
								</div>
						</Spin>
				</Modal>
		)
};

ForgotPasswordModal.propTypes = {
		forgotPasswordModalVisible: PropTypes.bool.isRequired,
		showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
		forgotPasswordModalVisible: state.modalVisible.forgotPassword,
		loading: state.loading
});

const mapDispatchToProps = {
		showModal,
		showLoading
};

export default connect(
		mapStateToProps,
		mapDispatchToProps,
)(ForgotPasswordModal);

//TODO check if this component is used.
