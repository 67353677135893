import React, {useState} from "react";
import Modal from "../HelpersComponents/Modal.Component";
import { Spin } from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import MapComponent from '../HelpersComponents/MapComponents/Map.Component';
import {addressFormatter} from '../../services/Utilities';
import Img from "../HelpersComponents/Img.Component";
import LocationIcon from "../../img/ic_location.svg";
import Address from "../HelpersComponents/Address.Component";
import {getUserLocation} from "../../services/User.Service";
import {updateLocation} from "../../actions/locationActions";
import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { BasketEmptyInfoModal } from "../LayoutComponetns/PartialsComponents/Basket.Empty.Info.Modal";
import { clearBasket } from "../../actions/basketActions";
import { updateOrderType } from "../../actions/orderActions";
import nextArrowIcon from "../../img/next.svg";


const MapModal = ({
                      loading,
                      currentLocation,
                      mapModalVisible,
                      showModal,
                      updateLocation,
                      clearBasket,
                      basketProductCount,
                      isCatering,
                      updateOrderType
}) => {
    const [state, setState] = useState({
        location: currentLocation
    });

    const onPlaceSelected = place => {
        const address = addressFormatter(place);
        if (address.address) {
            const location = {
                address: address.address,
                city: address.city,
                state: address.state,
                lat: address.lat,
                log: address.log,
                zipCode: address.zip
            };

            setState({location})
        }
    };

    const getClientLocation = () => {
        getUserLocation().then(location => {
            if (location) {
                setState({location});
            }
            return location;
        })
    };

    const confirmClientLocation = () => {
        const {location} = state;
        if (location.address !== undefined) {
            if (currentLocation && currentLocation.address && currentLocation.address === location.address) {
                handleLocationChange(location)
                return
            }
            if(!!basketProductCount) {
                BasketEmptyInfoModal(clearBasket, () => handleLocationChange(location))
            } else {
                handleLocationChange(location)
            }
        }
    };

    const handleLocationChange = (location) => {
        updateLocation(location);
        updateOrderType(isCatering)
        showModal(ModalTypes.MAP_MODAL, false);
    }
    
    return (
        <Modal
            className={"CustomItemDetails"}
            visible={mapModalVisible}
            onCancel={() => showModal(ModalTypes.MAP_MODAL, false)}
        >
            <Spin spinning={loading}>
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-md-6 pl-0 pr-0 order-1 order-md-0">
                            <div className="ModalMap">
                                <MapComponent
                                    center={{lat: state.location.lat, lng: state.location.log}}
                                    onPlaceSelected={onPlaceSelected}
                                    markerDraggable={true}
                                    mapDraggable={true}
                                />
                            </div>
                            <p className={'MapModalLocation'}>{state.location.address}</p>
                        </div>

                        <div className="col-md-6 pl-0 pr-0 order-0 order-md-1">
                            <div className="HomeSearchComponent ItemDetailsRightAddress">
                                <h4>
                                    Please fill in the
                                    address to see the
                                    delivery terms
                                </h4>
                                <div>
                                    <span>
                                        <Img src={LocationIcon} alt="Location Icon" isLocale={true}
                                             onClick={getClientLocation}/>
                                    </span>
                                    <Address
                                        type="search"
                                        placeholder={"Enter your delivery address"}
                                        defaultValue={state.location.address ? state.location.address : ''}
                                        title={state.location.address ? state.location.address : ''}
                                        onPlaceSelected={onPlaceSelected}
                                        types={['address']}
                                        componentRestrictions={{country: "us"}}
                                        required
                                    />
                                </div>
                                <div className="GetFood">
                                    <button onClick={confirmClientLocation}>
                                        <Img src={nextArrowIcon} alt={'Get Food'} isLocale={true}/>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        </Modal>
    )
};

MapModal.propTypes = {
    updateLocation: PropTypes.func.isRequired,
    currentLocation: PropTypes.object,
    mapModalVisible: PropTypes.bool.isRequired,
    showModal: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    currentLocation: state.currentLocation.address,
    mapModalVisible: state.modalVisible.map,
    loading: state.loading,
    basketProductCount: state.basket.productCount,
    isCatering: state.isCatering
});

const mapDispatchToProps = {
    showModal,
    updateLocation,
    clearBasket,
    updateOrderType
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MapModal);
