import {
	LOADING,
	LEFT_MENU,
	CATEGORIES,
	PRODUCTS,
	RESTAURANTS,
	MODAL,
	AUDIO,
	HEADER,
	DELIVERY_TIME,
	DRIVER_TIP_TARIFF,
	FILTER
} from "./_actionTypes";

import * as HomeAPI from '../api/Home.API';
import * as RestaurantAPI from '../api/Restaurant.API';
import * as HomeService from '../services/Home.Service';
import * as DriverAPI from '../api/Driver.API'

import {getErrorsFromResponse, handleResponse} from "../services/Utilities";
import {OrderType} from "../enums/Order.Status.Enum";
import * as BaseService from "../services/Base.Service";

export const showLoading = state => dispatch => {
	if (state) {
		dispatch({type: LOADING.START_LOADING})
	} else {
		dispatch({type: LOADING.STOP_LOADING})
	}
};

export const changeIsOpen = state => dispatch => {
	if (state) {
		dispatch({type: LOADING.START_LOADING})
	} else {
		dispatch({type: LOADING.STOP_LOADING})
	}
};

export const showLocalLoading = state => dispatch => {
	if (state) {
		dispatch({type: LOADING.START_LOCAL_LOADING})
	} else {
		dispatch({type: LOADING.STOP_LOCAL_LOADING})
	}
};

export const showLeftMenu = (state = true) => dispatch => {
	if (state) {
		dispatch({type: LEFT_MENU.SHOW_LEFT_MENU})
	} else {
		dispatch({type: LEFT_MENU.HIDE_LEFT_MENU})
	}
};

export const showHeader = (state = true) => dispatch => {
	if (state) {
		dispatch({type: HEADER.SHOW_HEADER})
	} else {
		dispatch({type: HEADER.HIDE_HEADER})
	}
};

export const getProducts = (offset = 1, limit = 10) => dispatch =>
	HomeService.getProducts(offset, limit).then(result => {
		if (result && result.success) {
			const {data} = result;
			
			if (data) {
				dispatch({
					type: offset === 1 ? PRODUCTS.SET_PRODUCTS : PRODUCTS.PAGINATE_PRODUCTS,
					payload: data
				})
			}
		}
	});

export const getCaterings = (offset = 1, limit = 10) => dispatch =>
	HomeService.getCaterings(offset, limit).then(result => {
		if (result && result.success) {
			const {data} = result;
			
			if (data) {
				dispatch({
					type: offset === 1 ? PRODUCTS.SET_PRODUCTS : PRODUCTS.PAGINATE_PRODUCTS,
					payload: data
				})
			}
		}
	});

export const getRestaurants = (isCatering = false) => dispatch => {
	return RestaurantAPI.getRestaurants(isCatering).then(result => {
		if (result && result.success) {
			const {data} = result;
			if (data && Array.isArray(data)) {
				dispatch({
					type: RESTAURANTS.SET_RESTAURANTS,
					payload: data
				})
			} else {
				dispatch({
					type: RESTAURANTS.SET_RESTAURANTS,
					payload: {}
				})
			}
		}
	})
};

export const getCategories = (isCatering = OrderType.IS_NOT_CATERING, restaurantSlug) => dispatch => {
	dispatch({type: LOADING.START_LOCAL_LOADING});
	return HomeAPI.getCategories(isCatering, restaurantSlug)
		.then(result => handleResponse(
			result,
			undefined,
			() => {
				if (result.data) {
					dispatch({
						type: CATEGORIES.SET_CATEGORIES,
						payload: {
							all: result.data.categories && result.data.categories.length ? result.data.categories : [],
							main: result.data.main && result.data.main.length ? result.data.main : [],
						}
					})
				}
				return result.data
			}
		)).finally(() => {
			dispatch({type: LOADING.STOP_LOCAL_LOADING});
		})
};

export const showModal = (modalType, state = true, modalProps = {}) => dispatch => {
	dispatch({
		type: MODAL.SHOW_MODAL,
		payload: {modalType, modalProps, state}
	})
};

export const toggleAudio = (state = true) => dispatch => {
	if (state) {
		dispatch({type: AUDIO.TURN_ON})
	} else {
		dispatch({type: AUDIO.TURN_OFF})
	}
};

export const searchProducts = model => dispatch => {
	dispatch({type: LOADING.START_LOADING});
	
	return HomeAPI.searchProducts(model)
		.then(result => handleResponse(
			result,
			undefined,
			() => {
				const products = result.data;
				
				dispatch({
					type: PRODUCTS.SET_PRODUCTS,
					payload: products
				});
				
				const uniqueRestaurants = {};
				products.forEach(product => {
					if (!uniqueRestaurants[product.restaurant.slug]) {
						uniqueRestaurants[product.restaurant.slug] = product.restaurant
					}
				});
				
				dispatch({
					type: RESTAURANTS.SET_RESTAURANTS,
					payload: uniqueRestaurants
				});
				
				return products;
			},
			undefined,
			undefined,
			getErrorsFromResponse(result),
		)).finally(() =>
			dispatch({type: LOADING.STOP_LOADING})
		);
};

export const updateSelectedRestaurant = (restaurantSlug = false) => dispatch => {
	if (restaurantSlug) {
		dispatch({
			type: RESTAURANTS.SET_SELECTED_RESTAURANT,
			payload: restaurantSlug
		})
	} else {
		dispatch({type: RESTAURANTS.REMOVE_SELECTED_RESTAURANT})
	}
};

export const updateDriverTipTariffs = () => dispatch => {
	const tipTariff = sessionStorage.getItem('tipTariff');
	if (tipTariff) {
		dispatch({
			type: DRIVER_TIP_TARIFF.SET_DRIVER_TIP_TARIFF,
			payload: JSON.parse(tipTariff)
		});
		Promise.resolve(JSON.parse(tipTariff));
	} else {
		return DriverAPI.getDriverTipTariffs().then(result => {
			if (result && result.success) {
				
				sessionStorage.setItem('tipTariff', JSON.stringify(result.data));
				
				dispatch({
					type: DRIVER_TIP_TARIFF.SET_DRIVER_TIP_TARIFF,
					payload: result.data
				})
			}
		})
	}
};

export const updateDeliveryWhen = (day, time = undefined) => dispatch =>{

		dispatch ({
			type: DELIVERY_TIME.UPDATE_DELIVERY_DAY,
			payload: day
		});

		dispatch ({
			type: DELIVERY_TIME.UPDATE_DELIVERY_TIME,
			payload: time
		});

};

export const updateDeliveryTime = time => dispatch =>{
	dispatch ({
		type: DELIVERY_TIME.UPDATE_DELIVERY_TIME,
		payload: time
	});
};

export const updateFilterData = isCatering => dispatch => {
	return BaseService.getFilterDefaultData(isCatering).then(result => {
		if(result){
			dispatch({ type: FILTER.UPDATE_DEFAULT_DATA, payload: result});
		}
		return false;
	})
}
