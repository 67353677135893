export const StandartSettings = {
    slidesToShow: 9,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    infinite: false,
    // loop: true,
    // centerMode: true,
    responsive: [
        {
            breakpoint: 1900,
            settings: {
                slidesToShow: 8,
            }
        },
        {
            breakpoint: 1650,
            settings: {
                slidesToShow: 7,
            }
        },
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1050,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 3,
                arrows: false,
            }
        },
        {
            breakpoint: 444,
            settings: {
                slidesToShow: 2,
                arrows: false,
            }
        }
    ]
};

export const CircleItemSettings = {
    slidesToShow: 7,
    slidesToScroll: 1,
    draggable: true,
    arrows: true,
    infinite: false,
    // loop: true,
    // centerMode: true,
    responsive: [
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 5,
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
            }
        },
        {
            breakpoint: 1050,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 3,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 2.3,
                arrows: false,
            }
        },
        {
            breakpoint: 444,
            settings: {
                slidesToShow: 2.3,
                arrows: false,
            }
        }
    ]
};

export const CustomSettings = {
    slidesToScroll: 1,
    slidesToShow: 4.5,
    draggable: true,
    arrows: true,
    loop: false,
    responsive: [
        {
            breakpoint: 1439,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1111,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 790,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
        }
    ]
};

export default StandartSettings;
