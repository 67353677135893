import React, {Component} from "react";
import {withRouter} from "react-router";
import {
		deleteContactUser,
		getRestaurantUserContact
} from "../../services/Partner.Service";
import {showLoading, showModal} from "../../actions/baseActions";
import {Button, Popconfirm, Table} from "antd4";
import {connect} from "react-redux";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import ContactUsers from "./ModalComponents/New.Contact.Users.Modal.Component";

class PartnerContactUsers extends Component {
		state = {
				users: [],
				visible: false
		};
		
		TableColumns = [
				{
						title: "First name",
						key: "firstName",
						dataIndex: "firstName",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => {
								if (a.firstName < b.firstName) {
										return -1;
								}
								if (a.firstName > b.firstName) {
										return 1;
								}
								return 0;
						}
				},
				{
						title: "Last Name",
						key: "lastName",
						dataIndex: "lastName",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => {
								if (a.lastName < b.lastName) {
										return -1;
								}
								if (a.lastName > b.lastName) {
										return 1;
								}
								return 0;
						}
				}, {
						title: "Phone",
						key: "phone",
						dataIndex: "phone",
						render: value => (
								value
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.phone === b.phone) ? 0 : a.phone ? -1 : 1
				}, {
						title: "Email",
						key: "email",
						dataIndex: "email",
						render: value => (
								value
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.email === b.email) ? 0 : a.email ? -1 : 1
				}, {
						title: "Position",
						key: "position",
						dataIndex: "position",
						render: value => (
								value
						),
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => (a.position === b.position) ? 0 : a.position ? -1 : 1
				}, {
						title: "Action",
						key: "action",
						render: (record) => (
								<div>
										<Button onClick={() => {
												this.editUer(record);
										}}>
												<EditOutlined/>
												Edit
										</Button>
										<Popconfirm title="Are you sure to delete？" okText="Yes" cancelText="No"
																onConfirm={() => this.deleteProduct(record.id)}>
												<Button type="link">
														<DeleteOutlined/>
														Delete
												</Button>
										</Popconfirm>
								</div>
						)
				}
		];
		
		UNSAFE_componentWillMount() {
				this.props.showLoading(true);
				getRestaurantUserContact(this.props.restaurantId).then(result => {
						if (result) {
								this.setState({users: result});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		editUer = editUser => {
				this.setState({editUser, visible: true});
		};
		handleVisibleChange = visible => {
				this.setState({editUser: null, visible});
		};
		
		handleNewCategoryAdded = newUser => {
				if (newUser) {
						this.setState({users: newUser});
				}
		};
		
		deleteProduct = userId => {
				this.props.showLoading(true);
				deleteContactUser(userId).then(result => {
						if (result) {
								this.setState({
										users: result
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		render() {
				const {users, visible, editUser} = this.state;
				
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Users</h1>
										<button className={"AddNewButton"} onClick={() => this.handleVisibleChange(true)}>Add user</button>
								</section>
								<section className={"ContentBody"}>
										<div>
												<Table
														columns={this.TableColumns}
														dataSource={users}
														rowKey={"id"}
														size={"middle"}
														pagination={{
																defaultPageSize: 25
														}}/>
												<ContactUsers
														visible={visible}
														editUserInfo={editUser}
														onVisibleUsersChange={this.handleVisibleChange}
														onCategoryUserAdded={this.handleNewCategoryAdded}
												>
												</ContactUsers>
										</div>
								</section>
						</div>
				
				);
		}
}
export default withRouter(connect(
		state => ({
				restaurantId: state.partnerRestaurant.id,
		}),
		{showLoading, showModal}
)(PartnerContactUsers));
