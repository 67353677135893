import React, { useEffect } from "react";
import {withRouter} from "react-router-dom";
import {Button, Input, Form } from 'antd4';
import Paths from "../../../services/Paths";
import {forgotPassword} from "../../../services/User.Service";
import {showLoading, showModal} from "../../../actions/baseActions";
import {connect} from "react-redux";
import ModalTypes from "../../../enums/Modal.Types.Enum";

const regexp = /((?=^.{6,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$)/;

const ResetPassword = (props) => {

	const token= props.match.params.token;

	useEffect(() => {
		if (!token) {
			props.history.push(Paths.home)
		}
	}, [])

	const handleSubmit = values => {
		if (token) {
			props.showLoading(true);

			forgotPassword({ ...values, token }).then(result => {
				if (result) {
					props.history.push(Paths.home);
					props.showModal(ModalTypes.SIGN_IN_MODAL);
				}
			}).finally(() => {
				props.showLoading(false)
			});
		}
	};

		return (
			<div className="container-fluid">
				<div className="ResetForm">
					<h2 className={'ResetTitle text-center'}>Reset your password</h2>
					<Form onFinish={handleSubmit} className={'ChangePasswordForm'}>

						<Form.Item
							name="first"
							label="New Password"
							rules={[
								{
									required: true,
									message: 'Please input your new password!',
								},
								() => ({
									validator(rule, value) {
										if(!value || value.match(regexp)) {
											return Promise.resolve()
										}

										return Promise.reject('Password must contains at least 6 characters, including UPPER/lower case and numbers.')
									}
								})
							]}
							hasFeedback
						>
							<Input type='password' />
						</Form.Item>

						<Form.Item
							name="second"
							label="Confirm Password"
							dependencies={['first']}
							hasFeedback
							rules={[
								{
									required: true,
									message: 'Please confirm your password!',
								},
								({ getFieldValue }) => ({
									validator(rule, value) {
										if (!value || getFieldValue('first') === value) {
											return Promise.resolve();
										}

										return Promise.reject('Two passwords that you enter is inconsistent!');
									},
								}),
							]}
						>
							<Input type='password' />
						</Form.Item>

						<Button type="primary" htmlType="submit" className="change-password-form-button">
							Reset
						</Button>
					</Form>
				</div>
			</div>
		);
}

export default connect(
	null,
	{
		showLoading,
		showModal
	},
)(withRouter(ResetPassword));