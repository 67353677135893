export const DefaultFilterItems = {
	isFiltered: false,
	deliveryTime: 30,
	cal: 150,
	isFreeDelivery: undefined,
	isOpenNow: undefined,
	minPrice: 1,
	maxPrice: 20,
	selectedCategories: [],
};

export const DefaultFilterData = {
	price: {
		min: 0.1,
		max: 60
	},
	cal: {
		min: 1,
		max: 350
	},
	deliveryTime: {
		min: 10,
		max: 60
	}
};
