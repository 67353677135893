import * as Http from "./Http.Client";

import {HandleResponseState} from "./Http.Client";

export const sendDriverFeedback = (model, orderSlug) =>
		Http.Post(`api/v1/driver/feedback/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);

export const sendAddressNote = (model, orderSlug) =>
		Http.Put(`api/v1/driver/address-note/${orderSlug}`, {}, model, true)
				.then(HandleResponseState);

export const getAddressNotes = address =>
		Http.Get(`api/v1/driver/address-note${address ? '?address='+address : ''}`, {},  true)
				.then(HandleResponseState);

export const deleteAddressNote = (noteId) =>
		Http.Get(`api/v1/driver/address-note/${noteId}`, {},  true)
				.then(HandleResponseState);

export const getDriverOrders = () =>
		Http.Get('api/v1/driver/orders/history', {}, true)
				.then(Http.HandleResponseState);

export const getDriverBalanceHistory = () =>
		Http.Get('api/v1/driver/balance-history', {}, true)
				.then(Http.HandleResponseState);

export const getDriverActualOrder = (orderSlug = null) =>
	Http.Get(`api/v1/driver/orders/actual${orderSlug ? '?order=' + orderSlug : ''}`, {}, true)
		.then(Http.HandleResponseState);

export const getDriverTipTariffs = () =>
		Http.Get('api/v1/order/tip/tariffs', {}, true)
				.then(result => result ? result : false)

export const updateDriverActiveState = model =>
		Http.Post('api/v1/driver/update/state', {}, model, true)
				.then(Http.HandleResponseState);

export const updateDriverLocation = (model = {}) =>
		Http.Post('api/v1/driver/update/location', {}, model, true)
				.then(Http.HandleResponseState);

export const updateDriverOrderStatus = model =>
		Http.Post("api/v1/order/update/status", {}, model, true)
				.then(HandleResponseState);
