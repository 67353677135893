import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import "../../styles/DriverStyles/driver.scss";
import {Tabs} from "antd4";
import {SettingOutlined, HomeOutlined, ClockCircleOutlined, AlignCenterOutlined} from "@ant-design/icons";
import map from "../../img/map.png";
import {showHeader, showModal} from "../../actions/baseActions";
import DriverActualOrder from "./Tabs/Driver.Actual.Order.Component";
import DriverOrdersHystory from "./Tabs/Driver.Hystory.Order.Component";
import DriverSettings from "./Tabs/Driver.Settings.Component";
import DriverBalanceHistory from "./Tabs/Driver.Balance.Hystory.Component";
import ToggleAudio from "../HelpersComponents/Toggle.Audio.Component";
import ModalTypes from "../../enums/Modal.Types.Enum";
import DriverMapModal from "../ModalComponent/Driver.Map.Modal.Component";
import DriverAddressNoteModal from "../ModalComponent/Driver.Send.Address.Note.Modal.Component";
import DriverFeedbackModal from "../ModalComponent/Driver.Feedback.Modal.Component";
import DriverOrderAddressNotesModal from "../ModalComponent/Driver.Order.Address.Notes.Modal.Component";
import {getUserLocationCoordinates} from "../../services/User.Service";
import {updateDriverLocation} from "../../services/Driver.Service";

const {TabPane} = Tabs;

const DriverTabs = {
		actual: "actualOrder",
		history: "orderHistory",
		balanceHistory: "BalanceHistory",
		settings: "settings"
};

const Driver = ({
										showHeader,
										showModal,
										driverMapVisible,
										driverFeedbackVisible,
										driverSendAddressNoteVisible,
										driverOrderAddressNotesVisible,
										isActive,
										isAuth
								}) => {
		const [activeTab, setActiveTab] = useState(DriverTabs.actual);
		
		useEffect(() => {
				showHeader(false)
		}, []);
		
		const getTitle = () => {
				switch (activeTab) {
						case DriverTabs.history:
								return 'Orders history';
						case DriverTabs.balanceHistory:
								return 'Balance history';
						case DriverTabs.settings:
								return 'Settings';
						default:
								return 'Orders';
				}
		}

		const updateLocationInterval = setInterval(() => {
				if (isActive && isAuth) {
						getUserLocationCoordinates().then(result => {
								if (result && 'lat' in result && 'log' in result) {
										updateDriverLocation({
												lat: result.lat,
												log: result.log,
										})
								}
						}).catch(err => {
								console.log(err)
						})
				}
		}, 10000)
		
		document.addEventListener("successLogOut", () => {
				if(updateLocationInterval){
						clearInterval(updateLocationInterval);
				}
		});
		
		return (
				<div className="DriverPage">
						<div className="driverHeader">
								<div className="driverMap">
										<button onClick={() => showModal(ModalTypes.DRIVER_MAP, true)}>
												<img src={map} alt=""/>
										</button>
								</div>
								<div className="driverOrder">
										{getTitle()}
								</div>
								<div className="driverNotification">
										<ToggleAudio/>
								</div>
						</div>
						<Tabs
								tabPosition="bottom"
								onTabClick={key => setActiveTab(key)}
								activeKey={activeTab}
						>
								<TabPane
										tab={<span>
							  <HomeOutlined/>
							</span>}
										key={DriverTabs.actual}
								>
										<DriverActualOrder
												redirectToActualTab={() => setActiveTab(DriverTabs.actual)}
										/>
								</TabPane>
								
								<TabPane
										tab={
												<span>
							  <ClockCircleOutlined/>
							</span>
										}
										key={DriverTabs.history}
								>
										<DriverOrdersHystory/>
								</TabPane>
								
								<TabPane
										tab={
												<span>
							  <AlignCenterOutlined/>
							</span>
										}
										key={DriverTabs.balanceHistory}
								>
										<DriverBalanceHistory/>
								</TabPane>
								<TabPane
										tab={
												<span>
							  <SettingOutlined/>
							</span>
										}
										key={DriverTabs.settings}
								>
										<DriverSettings/>
								</TabPane>
						</Tabs>
						
						{driverMapVisible && <DriverMapModal/>}
						{driverFeedbackVisible && <DriverFeedbackModal/>}
						{driverSendAddressNoteVisible && <DriverAddressNoteModal/>}
						{driverOrderAddressNotesVisible && <DriverOrderAddressNotesModal/>}
				</div>
		);
};

const mapStateToProps = state => ({
		driverMapVisible: state.modalVisible.driverMap,
		driverFeedbackVisible: state.modalVisible.driverFeedback,
		driverSendAddressNoteVisible: state.modalVisible.driverAddressNote,
		driverOrderAddressNotesVisible: state.modalVisible.addressNotes,
		isActive: state.user.data.active,
		isAuth: state.user.isAuth
});

const mapDispatchToProps = {
		showHeader,
		showModal
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(Driver);
