import React from 'react';
import {Button, Input, Form, Modal} from 'antd4';
import {addNewCategory} from "../../../services/Partner.Service";
import {messageType, showMessage} from "../../../services/Utilities";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";

class NewCategoryModal extends React.Component {
		
		handleSubmit = values => {
				this.props.showLoading(true);
				addNewCategory(values).then(result => {
						if (result && 'id' in result) {
								this.props.onVisibleChange(false)
								this.props.onCategoryAdded(result);
								
								showMessage(messageType.success, 'Category has been successfully added.')
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		onEnter = event => {
				if (event.key === "Enter") {
						event.preventDefault();
				}
		};
		
		render() {
				const {visible} = this.props;
				
				return (
						<Modal
								title="Add category"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleChange(false)
								}}
								footer={false}
						>
								<div onKeyPress={this.onEnter}>
										<Form name={'addCategoryForm'} onFinish={this.handleSubmit} className="login-form">
												<Form.Item
														name={'name'}
														rules={[{required: true, message: 'Please input Category Name!'}]}
												>
														<Input placeholder="Name"/>
												</Form.Item>
												<Form.Item>
														<Button htmlType="submit">
																Add category
														</Button>
												</Form.Item>
										</Form>
								</div>
						</Modal>
				)
		}
}


NewCategoryModal.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired,
		onCategoryAdded: PropTypes.func.isRequired,
};

NewCategoryModal.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		onCategoryAdded: f => f,
};

export default connect(
		() => ({}),
		{showLoading},
)(NewCategoryModal);
