import * as Http from "../api/Http.Client";
import {getAuthData} from "./User.Service";

var isUpdated = false;

export const updateAccessToken = () => {
    if (isUpdated) return Promise.resolve(true);

    isUpdated = true;

    const authData = getAuthData();
    const formData = new FormData();
    formData.append('refresh_token', authData.refreshToken);

    return Http.Post(
        `api/v1/refresh/token`,
        {},
        formData,
        false,
        true
    ).then(result => {
        
        if ('token' in result) {
            isUpdated = true;

            authData.refreshToken = result.refresh_token;
            authData.token = result.token;
            authData.refreshTokenExpiresIn = result.refresh_token_expiration;

            localStorage.setItem("AuthData", JSON.stringify(authData));
            setTimeout(() => {
                isUpdated = false;
            }, 5000);

            return true;
        } else {
            isUpdated = false;
            return false;
        }
    });
};
