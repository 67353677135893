import React, {Component} from 'react';
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../../actions/baseActions";
import Paths from "../../../services/Paths";
import {Link} from 'react-router-dom'

class PartnerCaterings extends Component {
		render() {
				return (
						<div className={"Content"}>
								<section className={'ContentHeader'}>
										<h1>Caterings</h1>
										<Link to={`${Paths.basePartner.replace(':tabName', 'caterings')}/create`}
													className={'AddNewButton'}>Add
												catering</Link>
								</section>
						</div>
				);
		}
}


export default withRouter(connect(
		() => ({}),
		{showLoading, showModal},
)(withRouter(PartnerCaterings)));

