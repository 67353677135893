import Config from 'Config';
import WssAction from '../enums/WSS.Action.Enum';

let PartnerWssConnection = undefined;

export const initPartnerConnection = (restaurantSlug) => {
		
		if (restaurantSlug) {
				PartnerWssConnection = new WebSocket(`${Config.WssUrl}partner?restaurant=${restaurantSlug}`);
				
				PartnerWssConnection.onopen = () => {
						if (PartnerWssConnection) {
								console.log('Partner connection was created successfully.');
						}
				};
				
				PartnerWssConnection.onclose = event => {
						if (event.wasClean) {
								console.log('Partner  session disconnect successfully');
						} else {
								console.log('Code: ' + event.code + ' reason: ' + event.reason);
						}
				};
				
				PartnerWssConnection.onerror = error => {
						console.log("error", error.message);
				};
				
				PartnerWssConnection.onmessage = event => {
						try {
								const data = JSON.parse(event.data);
								if ('action' in data) {
										if (data.action === WssAction.ACTION_SUBMITTED) {
										}
										document.dispatchEvent(new CustomEvent('PartnerConnection', {
												detail: data
										}));
								}
						} catch (e) {
								console.log(e);
						}
				};
		}
		return PartnerWssConnection;
};

export const getPartnerWssConnection = (restaurantSlug = null) => {
		if (PartnerWssConnection && PartnerWssConnection.readyState) {
				return PartnerWssConnection;
		} else if (restaurantSlug) {
				PartnerWssConnection = initPartnerConnection(restaurantSlug);
				return PartnerWssConnection;
		}
		return false;
};

export const closePartnerConnection = () => {
		if (PartnerWssConnection) {
				PartnerWssConnection.close();
				return true;
		}
		return false;
};

/**
 * @deprecated
 * @param message
 * @returns {boolean}
 */
export const sendPartnerWssMessage = (message) => {
		if (PartnerWssConnection) {
				PartnerWssConnection.send(JSON.stringify(message))
		}
		return false;
};
