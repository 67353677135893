import React from 'react';
import { Upload, message, Form } from "antd4";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import Img from '../../../../HelpersComponents/Img.Component';

function getBase64(img, callback) {
		const reader = new FileReader();
		reader.addEventListener('load', () => callback(reader.result));
		reader.readAsDataURL(img);
}

function beforeUpload(file) {
		const isValidType = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
		if (!isValidType) {
				message.error('You can only upload JPG/JPEG/PNG file!');
		}
		const isLt3M = file.size / 1024 / 1024 < 3;
		if (!isLt3M) {
				message.error('Image must be smaller than 3MB!');
		}
		return isValidType && isLt3M;
}

class ProductImg extends React.Component {
		state = {
				loading: false,
				imageUrl: null,
		};
		
		handleCustomBannerRequest = file => {
				const reader = new FileReader();
				// Closure to capture the file information.
				reader.onload = (() => (e) => {
						const base64String = window.btoa(e.target.result);
						if (base64String) {
								this.setState({
										imageUrl: `data:${file.file.type};base64,` + base64String
								})
						}
				})(file.file);
				// Read in the image file as a data URL.
				reader.readAsBinaryString(file.file);
		};
		
		render() {
				const uploadButton = (
						<div>
								{this.state.loading ? <LoadingOutlined /> : <PlusOutlined />}
								<div className="ant-upload-text">Upload</div>
						</div>
				);
				const { imageUrl } = this.state;
				
				return (
						<Form.Item
								name='file'
								label='File'
								required={true}
						>
								<Upload
										name="file"
										listType="picture-card"
										className="file-uploader"
										showUploadList={false}
										accept={["image/jpeg", "image/jpg", "image/png"]}
										beforeUpload={beforeUpload}
										customRequest={this.handleCustomBannerRequest}
								>
										{imageUrl
												? <img src={imageUrl} alt="file" style={{ width: '100%' }} />
												: this.props.downloadLink
														? <Img src={this.props.downloadLink} alt={'file'} style={{ width: '100%' }} />
														: uploadButton
										}
								</Upload>
						</Form.Item>
				);
		}
}

export default ProductImg;
