import React, { useState } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import logo from "../../img/Logo.svg";
import facebook from '../../img/ic_facebook footer.svg'
import instagram from '../../img/ic_instagram_footer.svg'
import youtube from '../../img/ic_youtube_footer.svg'
import moto from '../../img/ic_moto.png'
import partner from '../../img/ic_patner_with_us.svg'
import { showModal } from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";
import { subscribe } from "../../services/Home.Service";
import Paths from "../../services/Paths";

const Footer = ({isAuth, showModal}) => {
  const [email, setEmail] = useState('');

  return (
    <footer>
      <div className="footer">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 pl-0 pr-0">
              <div className="Socials">
                <img src={logo} alt=""/>
                <h4>Best food delivery</h4>
                <ul>
                  <li><Link to={'#'}><img src={facebook} alt="facebook"/></Link></li>
                  <li><Link to={'#'}><img src={instagram} alt="instagram"/></Link></li>
                  <li><Link to={'#'}><img src={youtube} alt="youtube"/></Link></li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 pl-0 pr-0 position-static">
              <div className="GetAround">
                <img src={moto} alt=""/>
                <h5>Get around</h5>
                <ul>
                  {!isAuth && <li onClick={() => showModal(ModalTypes.SIGN_IN_MODAL)}>Login</li>}
                  <li onClick={() => showModal(ModalTypes.TERMS_AND_CONDITIONS_MODAL)}>
                    Terms and Conditions
                  </li>
                  <li onClick={() => showModal(ModalTypes.PRIVACY_POLICY_MODAL)}>
                    <Link to={'#'}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={Paths.about}>About</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4 pl-0 pr-0">
              <div className="Newsletter">
                <img src={partner} alt=""/>
                <h5>Newsletter</h5>
                <p>Join our newsletter to keep up to date with our offers and news</p>
                <div>
                  <input
                    type={'email'}
                    name={'email'}
                    placeholder={'Your mail'}
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                  />
                  <button onClick={() => subscribe(email)}>Subscribe</button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div className="Copyright">
        <div>
          <p>&#169; Meameal All rights reserved.</p>
          <ul>
            <li>
              <Link to={Paths.about}>About</Link>
            </li>
            <li onClick={()=>showModal(ModalTypes.TERMS_AND_CONDITIONS_MODAL)}>
              <Link to={'#'}>Terms of use</Link>
            </li>
            <li onClick={()=>showModal(ModalTypes.PRIVACY_POLICY_MODAL)} >
              <Link to={'#'}>Privacy Policy</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
};




const mapStateToProps = state => ({
  isAuth: state.user.isAuth
});

const mapDispatchToProps = {
  showModal,
};


export default connect(mapStateToProps, mapDispatchToProps)(Footer);