import React, { Component } from "react";
import Modal from '../HelpersComponents/Modal.Component';
import { showModal } from "../../actions/baseActions";
import { connect } from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";

class PrivacyPolicyModal extends Component {
  render() {
    const {privacyPolicyVisible, showModal} = this.props;
    return (
      <Modal
        visible={privacyPolicyVisible}
        className={'termsModal'}
        onCancel={() => showModal(ModalTypes.PRIVACY_POLICY_MODAL, false)}
      >
        <h3>Privacy Policy</h3>
        <p>Welcome to MeaMeal.com. The MeaMeal.com website (the "Site") is comprised of various web pages operated by MeaMeal ("MeaMeal"). MeaMeal.com is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herein (the "Terms"). Your use of MeaMeal.com constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy of them for your reference. </p>
        <br/>
        <p>MeaMeal.com is an E-Commerce Site.</p>
        <br/>
        <p>MeaMeal is an online food ordering platform for pick up, delivery and catering. </p>
        <br/>
        <p className="name">Privacy</p>
        <p>Your use of MeaMeal.com is subject to MeaMeal's Privacy Policy. Please review our Privacy Policy, which also governs the Site and informs users of our data collection practices.</p>
        <br/>
        <p className='name'>Electronic Communications</p>
        <p>Visiting MeaMeal.com or sending emails to MeaMeal constitutes electronic communications. You consent to receive electronic communications and you agree that all agreements, notices, disclosures and other communications that we provide to you electronically, via email and on the Site, satisfy any legal requirement that such communications be in writing.</p>
        <br/>
        <p className="name">Your Account</p>
        <p>If you use this site, you are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer, and you agree to accept responsibility for all activities that occur under your account or password. You may not assign or otherwise transfer your account to any other person or entity. You acknowledge that MeaMeal is not responsible for third party access to your account that results from theft or misappropriation of your account. MeaMeal and its associates reserve the right to refuse or cancel service, terminate accounts, or remove or edit content in our sole discretion.</p>
        <br/>
        <p className="name">Children Under Thirteen</p>
        <p>MeaMeal does not knowingly collect, either online or offline, personal information from persons under the age of thirteen. If you are under 18, you may use MeaMeal.com only with permission of a parent or guardian.</p>
        <br/>
        <p className="name">Cancellation/Refund Policy</p>
        <p>At MeaMeal.com once your credit/debit card transaction is complete it is final. Refunds or credits are solely on MeaMeal’s discretion.</p>
        <br/>
        <p className="name">Links to Third Party Sites/Third Party Services</p>
        <p>MeaMeal.com may contain links to other websites ("Linked Sites"). The Linked Sites are not under the control of MeaMeal and MeaMeal is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site. MeaMeal is providing these links to you only as a convenience, and the inclusion of any link does not imply endorsement by MeaMeal of the site or any association with its operators.
          <br/>
          Certain services made available via MeaMeal.com are delivered by third party sites and organizations. By using any product, service or functionality originating from the MeaMeal.com domain, you hereby acknowledge and consent that MeaMeal may share such information and data with any third party with whom MeaMeal has a contractual relationship to provide the requested product, service or functionality on behalf of MeaMeal.com users and customers.
        </p>
        <br/>
        <p className="name">No Unlawful or Prohibited Use/Intellectual Property</p>
        <p>You are granted a non-exclusive, non-transferable, revocable license to access and use MeaMeal.com strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to MeaMeal that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the Site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
          <br/>
          All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the property of MeaMeal or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights. You agree to observe and abide by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
          <br/>
          You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site. MeaMeal content is not for resale. Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution notices in any content. You will use protected content solely for your personal use, and will make no other use of the content without the express written permission of MeaMeal and the copyright owner. You agree that you do not acquire any ownership rights in any protected content. We do not grant you any licenses, express or implied, to the intellectual property of MeaMeal or our licensors except as expressly authorized by these Terms.
        </p>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  privacyPolicyVisible: state.modalVisible.privacyPolicy
});

const mapDispatchToProps = {showModal};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PrivacyPolicyModal);
