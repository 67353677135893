import {CLIENT, ORDER_UPDATE, USER} from '../actions/_actionTypes';
import initialState from '../store/_initialState';

export const userReducer = (state = initialState.user, action) => {
		switch (action.type) {
				case USER.LOGGED_IN:
						const data = action.payload;
						if ('restId' in data) {
								data.restaurant = {
										id: data.restId
								};
								
								delete data.restId;
						}
						if ('restSlug' in data) {
								data.restaurant = {
										slug: data.restSlug
								};
								
								delete data.restSlug;
						}
						return {
								isAuth: true,
								data: data
						};
				case USER.UPDATE_PERSONAL_INFORMATION:
						return {
								...state,
								data: {...state.data, ...action.payload}
						}
				case USER.LOGGED_OUT:
						return {
								isAuth: false,
								data: {}
						};
				default:
						return state;
		}
};

export const clientOrdersReducer = (state = initialState.clientOrders, action) => {
		switch (action.type) {
				case CLIENT.SET_ORDERS:
						return action.payload;
				case CLIENT.PAGINATE_ORDERS:
						return [...state, ...action.payload];
				case ORDER_UPDATE.UPDATE_CUSTOMER_ORDER_PROPERTY:
						const {orderSlug, property, model} = action.payload;
						const index = state.findIndex(order => order.slug === orderSlug)
						state[index][property] = model;
						return [...state];
				case CLIENT.CLEAR_ORDERS:
						return [];
				default:
						return state;
		}
}
