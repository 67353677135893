import React from "react";
import { Form, Input, Select, Upload, InputNumber, Checkbox } from "antd4";
import { UploadOutlined } from "@ant-design/icons";

const rules = [{ required: true }];
const { Option } = Select;
const { Dragger } = Upload;
const { TextArea } = Input;

const props = {
  name: "file",
  multiple: true,
  action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
  onChange(info) {
    const { status } = info.file;
    if (status !== "uploading") {
      console.log(info.file, info.fileList);
    }
    if (status === "done") {
      console.log(`${info.file.name} file uploaded successfully.`);
    } else if (status === "error") {
      console.log(`${info.file.name} file upload failed.`);
    }
  }
};


const BasicFormList = () => (
  <div className={"col-lg-4"}>

    <div className={"FieldSet"} data-title="Basic information">
      <Form.Item
        label='Name'
        name={"name"}
        fieldKey={"name"}
        rules={[
          {
            required: true,
            message: "Please input name"
          }
        ]}
      >
        <Input placeholder="Name"/>
      </Form.Item>

      <Form.Item name='branch' label="Branch" rules={[{ required: true }]}>
        <Select
          placeholder="Select an option and change input text above"
        >
          <Option value="branch1">branch1</Option>
          <Option value="female">female</Option>
          <Option value="other">other</Option>
        </Select>
      </Form.Item>

      <Dragger {...props}>
        <p className="ant-upload-drag-icon">
          <UploadOutlined/>
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload</p>
        <p className="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other
          band files
        </p>
      </Dragger>

      <Form.Item
        label="Price"
        name={"price"}
        fieldKey={"discount"}
        rules={rules}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          placeholder="price"
          min={0}
          step={1}/>
      </Form.Item>

      <Form.Item
        label="Head count"
        name={"headCount"}
        fieldKey='headCount'
        rules={rules}
      >
        <InputNumber
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          placeholder="Head Count"
          min={0}
          step={1}/>
      </Form.Item>

      <Form.Item name='unit' label="Unit" rules={[{ required: true }]}>
        <Select
          placeholder="Select Unit"
          showSearch
        >
          <Option value="unit">unit</Option>
          <Option value="unit2">unit2</Option>
          <Option value="unit3">unit3</Option>
        </Select>
      </Form.Item>

      <Form.Item name='description' label="Description" rules={[{ required: true }]}>
        <TextArea/>
      </Form.Item>

      <Form.Item name='categories' label="Product Categories" rules={[{ required: true }]}>
        <Select
          placeholder="Categories"
          mode='multiple'
        >
          <Option value="category1">category1</Option>
          <Option value="category2">category2</Option>
          <Option value="category3">category3</Option>
        </Select>
      </Form.Item>

      <Form.Item name='enabled'>
        <Checkbox>Enabled</Checkbox>
      </Form.Item>

    </div>
  </div>
);

export default BasicFormList;