import {PARTNER} from "./_actionTypes";

export const setPartnerOrders = orders => dispatch => {dispatch({type: PARTNER.SET_PARTNER_ORDERS, payload: orders})};

export const updatePartnerOrder = order => dispatch => {dispatch({type: PARTNER.UPDATE_PARTNER_ORDER, payload: order})};

export const setPartnerRestaurant = restaurant => dispatch => {dispatch({type: PARTNER.SET_PARTNER_RESTAURANT, payload: restaurant})};

export const updatePartnerRestaurant = restaurant => dispatch => {dispatch({type: PARTNER.UPDATE_PARTNER_RESTAURANT, payload: restaurant})};

export const setPartnerLastOrder = order => dispatch => {dispatch({type: PARTNER.SET_PARTNER_LAST_ORDER, payload: order})};

export const setNewOrderLayoutState = state => dispatch => {dispatch({type: PARTNER.NEW_ORDER_NOTIFICATION_LAYOUT, payload: state})};
