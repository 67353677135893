import React from "react";
import {Button, Collapse, Popconfirm} from "antd4";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../../actions/baseActions";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {Badge} from "react-bootstrap";
import {CloseOutlined} from "@ant-design/icons";
import numeral from "numeral";
import {showMessage} from "../../../services/Utilities";
import Modal from "../../HelpersComponents/Modal.Component";
import {getPartnerProducts, setAddItemProduct} from "../../../services/Partner.Service";
import "../../../styles/PartnerStyles/partner.scss";
import Img from "../../HelpersComponents/Img.Component";
import PartnerIngridientAddItemModal from "./Partner.Ingridient.Add.Item.Modal.Component";

class PartnerAddItemModal extends React.Component {
		state = {
				products: [],
				addItems: []
		};
		
		UNSAFE_componentWillMount() {
				
				this.props.showLoading(true);
				getPartnerProducts().then(result => {
						if (result && 'products' in result) {
								this.setState({products: result.products});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		addInState = e => {
				const {addItems} = this.state;
				const index = addItems.indexOf(e);
				if (index > -1) {
						addItems.splice(index, 1);
				} else {
						e.counts = 1;
						addItems.push(e);
				}
				this.setState({addItems: addItems});
		};
		
		addItem = () => {
				const {addItems} = this.state;
				const {slug} = this.props;
				setAddItemProduct(addItems, slug).then(data => {
						if (data.success) {
								showMessage('success', 'Successfully Add Items');
								this.props.onVisibleChange({show: false, getOrder: true})
						}
				});
		};
		handleVisibleChangeAddItemIngrident = visible => {
				this.setState({visibleAddItemIngridient: visible.show});
		};
		updateItem = e => {
				const {addItems} = this.state;
				let s = addItems.filter(item => item.id === e.id);
				if (s.length > 0) {
						let index = addItems.indexOf(s[0]);
						addItems[index] = e;
				} else {
						addItems.push(e);
				}
				this.setState({addItems, visibleAddItemIngridient: false});
		};
		
		singleProduct = e => {
				this.setState({slug: e, visibleAddItemIngridient: true});
		};
		removeItem = e => {
				const {addItems} = this.state;
				addItems.splice(e, 1);
				this.setState({addItems});
		};
		
		ingrident = item => {
				if (item.ingredients) {
						for (const itemKey in item.ingredients) {
								let w = item.details.indexedIngredients[itemKey]
								for (let i = 0; i < item.ingredients[itemKey].length; i++) {
										return (<li>
												<b>{w.indexedIngredient[item.ingredients[itemKey][i]].name}</b> {numeral(Math.abs(w.indexedIngredient[item.ingredients[itemKey][i]].plusPrice)).format("$ 0,0[.]00")}
										</li>)
								}
						}
				}
		};
		
		render() {
				const {visible} = this.props;
				const {products, addItems, visibleAddItemIngridient, dataProd, slug} = this.state;
				const sum = addItems.reduce((accumulator, object) => {
						return accumulator + object.price;
				}, 0);
				return (
						<Modal
								title="Add Items"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleChange({show: false, getOrder: false});
										this.setState({addItems: []});
								}}>
								<Tabs>
										<TabList>
												<Tab>Products<Badge
														style={{backgroundColor: "#e7792b", color: "#fff"}}>{products.length}</Badge></Tab>
												<Tab>Selected Products<Badge
														style={{backgroundColor: "#e7792b", color: "#fff"}}>{addItems.length}</Badge></Tab>
										</TabList>
										
										<TabPanel>
												{products ?
														<div className={"bodi_add_item"} style={{paddingTop: '5px'}}>
																{Object.keys(products).map(key => (
																		<div key={key}
																				 className={addItems.filter(item => item.id === products[key].id).length > 0 ? "product_item_add active" : "product_item_add"}
																				 onClick={() => this.singleProduct(products[key].slug)}>
																				<div className="body_it">
																						<div className={"img"}><Img className={"TableListImg"}
																																				src={products[key].downloadLink}/></div>
																						<div className={"name"}>{products[key].name}</div>
																						<div
																								className={"price"}>{numeral(Math.abs(products[key].price)).format("$ 0,0[.]00")}</div>
																				</div>
																		</div>
																))}
														</div> : ""
												}
										</TabPanel>
										<TabPanel>
												{products ?
														<div className={"bodi_add_item"}>
																{addItems.length ?
																		<div>
																				<div style={{height: "260px", overflow: "auto", paddingTop: '5px'}}>
																						{Object.keys(addItems).map(key => {
																								let size = null;
																								if (addItems[key].size) {
																										size = addItems[key].details.indexedSizeGroups[addItems[key].size.group]["indexedSize"][addItems[key].size.slug];
																								}
																								return (
																										<div key={key + "333"}
																												 className={"product_item_add"}>
																												
																												<div className="body_it">
																														<div className={"img"}><Img className={"TableListImg"}
																																												src={addItems[key].details.downloadLink}/>
																														</div>
																														<div className={"name"}>{addItems[key].details.name}</div>
																														<div
																																className={"price"}>{numeral(Math.abs(addItems[key].price)).format("$ 0,0[.]00")}</div>
																														<div className={"price"}>Count: {addItems[key].counts}</div>
																														<div className={"price"}>
																																<Popconfirm title="Are you sure？" okText="Yes"
																																						cancelText="No"
																																						onConfirm={() => this.removeItem(key)}>
																																		<Button style={{
																																				background: "transparent",
																																				border: "none",
																																				color: "red"
																																		}}>
																																				<CloseOutlined/>
																																		</Button>
																																</Popconfirm>
																														</div>
																												</div>
																												<div className="size_and_comment">
																														<ul>
																																{size ? <li>
																																		<b>{size.name}</b> {size && size.plusPrice ? numeral(Math.abs(size.plusPrice)).format("$ 0,0[.]00") : ""}
																																</li> : ""}
																																{addItems[key].itemComment ?
																																		<li><b>Comment:</b> {addItems[key].itemComment}
																																		</li> : ""}
																																{this.ingrident(addItems[key])}
																														</ul>
																												</div>
																										</div>
																								);
																						})}
																				</div>
																				<Button onClick={e => (this.addItem())}>Save</Button>
																				<b style={{float: 'right'}}> Total
																						Amount: {numeral(Math.abs(sum)).format("$ 0,0[.]00")}</b>
																		</div>
																		: <h4 style={{textAlign: "center", marginTop: "40px"}}>Empty</h4>}
														</div> : ""
												}
										</TabPanel>
								
								</Tabs>
								
								
								<PartnerIngridientAddItemModal
										visible={visibleAddItemIngridient}
										dataProd={dataProd}
										slug={slug}
										onVisibleChange={this.handleVisibleChangeAddItemIngrident}
										onUpdateItem={this.updateItem}
								/>
						</Modal>
				);
		}
}


PartnerAddItemModal.propTypes = {
		visible: PropTypes.bool.isRequired,
		onVisibleChange: PropTypes.func.isRequired
};

PartnerAddItemModal.defaultProps = {
		visible: false,
		onVisibleChange: f => f,
		items: null,
		slug: null
};

export default connect(
		() => (
				{}
		),
		{
				showLoading,
				showModal
		}
)(PartnerAddItemModal);
