import React from "react";
import PropTypes from "prop-types";
import DefaultBackgroundImg from "../../img/BannerHome.png";
import AddAddress from "../HelpersComponents/Add.Address.Component";
import {updateOrderDeliveryType, updateOrderType} from "../../actions/orderActions";
import {connect} from "react-redux";
import {OrderDeliveryType, OrderType} from "../../enums/Order.Status.Enum";
import {updateDeliveryTime, updateDeliveryWhen, updateFilterData} from "../../actions/baseActions";

const DayAsString = dayIndex => {
		let weekdays = [];
		weekdays[0] = "Sun";
		weekdays[1] = "Mon";
		weekdays[2] = "Tue";
		weekdays[3] = "Wed";
		weekdays[4] = "Thu";
		weekdays[5] = "Fri";
		weekdays[6] = "Sat";
		
		return weekdays[dayIndex];
};

const MonthAsString = monthIndex => {
		let month = [];
		month[0] = "Jan";
		month[1] = "Feb";
		month[2] = "Mar";
		month[3] = "Apr";
		month[4] = "May";
		month[5] = "Jun";
		month[6] = "Jul";
		month[7] = "Aug";
		month[8] = "Sep";
		month[9] = "Oct";
		month[10] = "Nov";
		month[11] = "Dec";
		
		return month[monthIndex];
};


const getDates = (startDate = new Date(), daysToAdd = 7) => {
		let aryDates = [];
		
		for (let i = 1; i <= daysToAdd; i++) {
				let currentDate = new Date();
				currentDate.setDate(startDate.getDate() + i);
				
				if (i === 1) {
						aryDates.push(<option key={currentDate.getDate()}
																	value={currentDate.getFullYear() + "-" + currentDate.getMonth() + "-" + currentDate.getDate()}>Tomorrow</option>);
						continue;
				}
				aryDates.push(<option key={currentDate.getDate()}
															value={currentDate.getFullYear() + "-" + currentDate.getMonth() + "-" + currentDate.getDate()}>
						{MonthAsString(currentDate.getMonth()) + ", " + currentDate.getDate() + " " + DayAsString(currentDate.getDay())}
				</option>);
		}
		
		return aryDates;
};

const deliveryTimes = (deliveryTime) => {
		let int = 15,
				times = [],
				timeStart = 0,
				AM_PM = ["AM", "PM"];
		
		for (let i = 0; timeStart < 24 * 60; i++) {
				let hours = Math.floor(timeStart / 60),
						minutes = (timeStart % 60),
						value = ("0" + (hours % 12)).slice(-2) + ":" + ("0" + minutes).slice(-2) + ' ' + AM_PM[Math.floor(hours / 12)];
				times[i] = <option key={i} value={value}>{value}</option>;
				timeStart = timeStart + int;
		}
		
		if (deliveryTime.when === "later") {
				const hours = new Date().getHours() + 1;
				return times.splice(hours * 4)
		}
		
		return times;
};

const Header = ({
										backgroundImg = DefaultBackgroundImg,
										showLocation = true,
										onAddressConfirmed = f => f,
										updateOrderDeliveryType = f => f,
										updateDeliveryWhen = f => f,
										updateDeliveryTime = f => f,
										updateFilterData = f => f,
										updateOrderType = f => f,
										isDelivery = true,
										deliveryTime,
										title,
										isCatering
								}) => {
		
		const whenChange = (e, time) => {
				
				if (e.target.value !== "asap") {
						
						let currentDate = new Date(),
								currentHour = currentDate.getHours(),
								currentMin = currentDate.getMinutes();
						let min = 0,
								hour = currentHour,
								AM_PM = ["AM", "PM"];
						
						if (currentHour >= 12) {
								hour = currentHour - 12;
						}
						
						if (e.target.value === "later") {
								hour += 1;
								min = '00';
						} else {
								if (currentMin < 15) {
										min = 15;
								} else if (currentMin >= 15 && currentMin < 30) {
										min = 30;
								} else if (currentMin >= 30 && 45 > currentMin) {
										min = 45;
								} else if (currentMin >= 45) {
										min = '00';
										hour = hour + 1;
								}
						}
						
						if (hour < 10) {
								hour = "0" + (hour)
						}
						
						let currentTime = (hour) + ":" + min + " " + AM_PM[Math.floor(currentHour / 12)];
						updateDeliveryWhen(e.target.value, currentTime);
						setTimeout(() => {
								document.querySelector('.whenTime select').value = currentTime;
						}, 500)
				} else {
						
						updateDeliveryWhen(e.target.value, undefined);
				}
				
		};
		
		const orderTypeToCatering = () => {
				updateFilterData(true);
				updateOrderType(OrderType.IS_CATERING);
		};
		
		const orderTypeToRestaurant = () => {
				updateFilterData(false);
				updateOrderType(OrderType.IS_NOT_CATERING);
		};
		
		return (
				<header style={{backgroundImage: `url(${backgroundImg})`}}>
						<div className="HomeSearchComponent">
								<h2>{title}</h2>
								{showLocation && <div>
										
										<div className={"getFoodType"}>
												<div>
														<div>
																<button className={isDelivery ? 'active' : ''}
																				onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_DELIVERY)}>Delivery
																</button>
																<button className={!isDelivery ? 'active' : ''}
																				onClick={() => updateOrderDeliveryType(OrderDeliveryType.IS_PICK_UP)}>Pick Up
																</button>
														</div>
														
														<div>
																<button className={!isCatering ? 'active' : ''} onClick={orderTypeToRestaurant}>Regular
																		Menu
																</button>
																<button className={isCatering ? 'active' : ''} onClick={orderTypeToCatering}>Catering
																		Menu
																</button>
														</div>
												</div>
												{/*TODO delete if not used*/}
												{/*<div className={'getFoodTypeSelects'}>*/}
												{/*  <div className={"when"}>*/}
												{/*    <p>When:</p>*/}
												{/*    <div>*/}
												{/*      <select onChange={whenChange} name="when" id="">*/}
												{/*        <option value="asap">ASAP</option>*/}
												{/*        <option value="later">Later today</option>*/}
												{/*        {getDates().map(val => (*/}
												{/*          val*/}
												{/*        ))}*/}
												{/*      </select>*/}
												{/*    </div>*/}
												{/*  </div>*/}
												{/*  {*/}
												{/*    deliveryTime.when !== "asap" ? (*/}
												{/*      <div className={"whenTime"}>*/}
												{/*        <p>At:</p>*/}
												{/*        <div>*/}
												{/*          <select onChange={e => updateDeliveryTime(e.target.value)} name="time" id="">*/}
												{/*            {deliveryTimes(deliveryTime).map(val => (*/}
												{/*              val*/}
												{/*            ))}*/}
												{/*          </select>*/}
												{/*        </div>*/}
												{/*      </div>*/}
												{/*    ) : ""*/}
												{/*  }*/}
												{/*</div>*/}
										</div>
										<AddAddress onAddressConfirmed={onAddressConfirmed}/>
								</div>}
						</div>
				</header>
		);
};

Header.propTypes = {
		backgroundImg: PropTypes.string,
		title: PropTypes.string,
		showLocation: PropTypes.bool
};

const mapStateToProps = state => ({
		isDelivery: state.isDelivery,
		deliveryTime: state.deliveryTime,
		isCatering: state.isCatering
});

const mapDispatchToProps = {
		updateOrderType,
		updateDeliveryWhen,
		updateDeliveryTime,
		updateOrderDeliveryType,
		updateFilterData
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(Header);
