import React from 'react';
import {Modal as BaseModal} from 'antd4';

const Modal = ({title = false, footer = false, children, ...rest}) => (
		<BaseModal
				title={title}
				footer={footer}
				maskClosable={true}
				destroyOnClose={true}
				{...rest}
		>
				{children}
		</BaseModal>
);
export default Modal;
