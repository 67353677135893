import React from "react";
import { Form, Button } from "antd4";
import IngredientFormList from "./IngredientFormList";
import ProductsFormList from "./ProductsFormList";
import BasicFormList from "./BasicFormList";


const PartnerCateringsCreateOrEdit = ({actionSlug, product = null}) => {
  const onFinish = values => {
    console.log("Received values of form:", values);
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <div className={"Content"}>
      <section className={'ContentHeader'}>
        <h1>{!actionSlug ? 'Create Products' : `Edit Products (#${product.id ? product.id : ''})`}</h1>
      </section>
      <section className={"ContentBody"}>
        <Form
          onFinish={onFinish}
          className="my-form row"
          onFinishFailed={onFinishFailed}
          layout={'vertical'}
        >
          <BasicFormList/>
          <ProductsFormList/>
          <IngredientFormList/>
          <Form.Item className='col-12'>
            <Button htmlType="submit" className='float-right'>
              Save
            </Button>
          </Form.Item>
        </Form>
      </section>
    </div>
  )
}

export default PartnerCateringsCreateOrEdit
