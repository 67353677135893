import React, {Component} from "react";
import CustomModal from "../../HelpersComponents/Modal.Component";
import Img from "../../HelpersComponents/Img.Component";
import {Modal, Input, Spin} from 'antd4'
import PropTypes from "prop-types";
import {showModal} from "../../../actions/baseActions";
import {connect} from "react-redux";
import ModalTypes from "../../../enums/Modal.Types.Enum";
import {getCateringSingleProductDetails, getSingleProductDetails} from "../../../api/Home.API";
import {messageType, showMessage} from "../../../services/Utilities";
import {addFirstItem, addNewProduct, clearBasket, updateBagItem} from "../../../actions/basketActions";
import numeral from "numeral";
import uniqid from "uniqid";
import {updateLocation} from "../../../actions/locationActions";
import LeftComponent from './Partials/LeftComponent';
import AddressComponent from "./Partials/Address.Component";
import SizeGroups from "./Partials/SizeGroups";
import Ingredients from "./Partials/Ingredients";
import {updateOrderType} from '../../../actions/orderActions';
import {withRouter} from "react-router";

const productState = (result) => {
		return {
				cal: result.cal,
				sizeGroups: result.sizeGroups,
				indexedSizeGroups: result.indexedSizeGroups,
				id: result.id,
				deliveryTime: result.deliveryTime
		}
}

const cateringState = (result) => {
		return {
				products: result.products,
				catering: {
						quantity: 1
				},
				headCount: result.headCount,
				unit: result.unit,
				productCategoriesLine: result.productCategoriesLine
		}
}

const mutualState = (currentLocation, result) => {
		return {
				location: {
						address: currentLocation.address,
						city: currentLocation.city,
						lat: currentLocation.lat,
						log: currentLocation.log,
						zipCode: currentLocation.zipCode
				},
				loading: false,
				description: result.description,
				downloadLink: result.downloadLink,
				ingredients: result.ingredients,
				indexedIngredients: result.indexedIngredients,
				name: result.name,
				price: result.price,
				slug: result.slug,
				restaurant: result.restaurant,
				item: {
						counts: 1,
						size: {
								group: undefined,
								slug: undefined
						},
						itemComment: '',
						ingredients: {},
						price: undefined,
						singlePrice: undefined,
				}
		}
};

class ItemDetailsModal extends Component {
		state = {
				renderContent: false,
				emptyAdresErr: ""
		};
		
		async UNSAFE_componentWillMount() {
				const {customProps, currentLocation, basketProducts} = this.props;
				
				//Get single product or catering.
				if ('productSlug' in customProps) {
						const result = customProps.isCatering
								? await this.getCateringSingleProduct(customProps.productSlug)
								: await this.getSingleProduct(customProps.productSlug);
						
						if (result) {
								console.log(result);
								const mutual_state = mutualState(currentLocation, result);
								const state = customProps.isCatering
										? cateringState(result)
										: productState(result);
								
								let model = {...mutual_state, ...state};
								
								if ('uId' in customProps) {
										model.item = basketProducts[customProps.uId]
								}
								
								if (!customProps.isCatering && (!model.item || !model.item.size.slug)) {
										model.item.size = this.findDefaultSize(result.sizeGroups)
								}
								
								this.setState({...model, renderContent: true}, () => {
										console.log(this.state)
										this.calculatePrice();
								});
						} else {
								this.closeAndShowErrorMsg()
						}
				} else {
						this.closeAndShowErrorMsg()
				}
		}
		
		findDefaultSize = groups => {
				for (let i = 0; i < groups.length; i++) {
						if (groups[i] && 'size' in groups[i] && groups[i].size[0]) {
								return {
										slug: groups[i].size[0].slug,
										group: groups[i].slug
								};
						}
				}
				return false;
		};
		
		onAddressSelected = location => {
				this.setState({emptyAdresErr: ""});
				document.getElementsByClassName('vehicleInformation')[0].classList.remove('errInputBox');
				if (location && 'address' in location) {
						this.setState({
								location: {
										address: location.address,
										city: location.city,
										lat: location.lat,
										log: location.log,
										zipCode: location.zipCode
								}
						})
				}
		};
		
		confirmLocation = async () => {
				const {location} = this.state;
				if (location.address === undefined) {
						document.getElementsByClassName('vehicleInformation')[0].classList.add('errInputBox');
						return this.setState({emptyAdresErr: 'Please fill in the delivery address'});
				}
				const {updateLocation, customProps, showModal, updateOrderType} = this.props;
				updateLocation(location);
				const item = this.props.isCatering
						? await this.getCateringSingleProduct(customProps.productSlug)
						: await this.getSingleProduct(customProps.productSlug);
				
				if (!item) {
						showMessage(messageType.warning, 'Product is not available for your location')
						showModal(ModalTypes.ITEM_DETAILS_MODAL, false)
						updateOrderType()
				}
		};
		
		addToBag = () => {
				const {item, id, name, deliveryTime, slug, restaurant } = this.state;
				const {customProps, basketRestaurant, isCatering, basketCatering} = this.props;
				const restaurantName = basketRestaurant && basketRestaurant.name;
				
				if ('uId' in customProps) {
						//We are getting here if we already have current product with uId in basket
						this.props.updateBagItem(item, restaurant);
						this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
				} else {
						const bagItem = {
								...item,
								isCatering: customProps && 'isCatering' in customProps
										? customProps.isCatering
										: basketCatering !== undefined
												? basketCatering
												: isCatering,
								uid: uniqid(),
								id, name, deliveryTime, slug
						};
						
						if (restaurantName) {
								if (restaurantName !== restaurant.name || (basketCatering !== undefined && basketCatering !== isCatering)) {
										Modal.confirm({
												className: 'deleteBasketItemModal',
												title: `Error. Your cart contains item(s) from '${restaurantName}'. This item is from '${restaurant.name}'. You must clear cart to switch restaurant.`,
												okType: 'showButtonOk',
												okText: 'Open Cart',
												okCancel: true,
												onOk: () => {
														this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
														this.props.showModal(ModalTypes.BASKET_MODAL, true);
												},
												onCancel: () => {
														this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
												},
										});
								} else {
										this.props.addNewProduct(bagItem);
										this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
								}
								
						} else {
								this.props.addFirstItem(bagItem, restaurant, customProps.isCatering);
								this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
						}
						
				}
		};
		
		calculatePrice = () => {
				const {price, indexedIngredients, indexedSizeGroups} = this.state;
				const {counts, size, ingredients} = this.state.item;
				
				const productSize = size && size.group && 'group' in size ? indexedSizeGroups[size.group]['indexedSize'][size.slug] : false;
				const sizePlusPrice = productSize && productSize.plusPrice ? productSize.plusPrice : 0;
				
				let ingredientPlusPrice = 0;
				
				for (let groupKey in ingredients) {
						if (ingredients.hasOwnProperty(groupKey)) {
								for (let ingredientKey of ingredients[groupKey]) {
										let ingredient = indexedIngredients[groupKey]['indexedIngredient'][ingredientKey];
										ingredientPlusPrice += ingredient && ingredient.plusPrice ? ingredient.plusPrice : 0;
								}
						}
				}
				
				this.setState({
						item: Object.assign({}, this.state.item, {
								price: (price + sizePlusPrice + ingredientPlusPrice) * counts,
								singlePrice: price + sizePlusPrice + ingredientPlusPrice
						})
				})
		};
		
		handleSizeChange = event => {
				const {indexedSizeGroups} = this.state;
				
				const productSize = indexedSizeGroups[event.target.group]['indexedSize'][event.target.value];
				
				if (productSize) {
						this.setState({
								item: Object.assign({}, this.state.item, {
										size: {
												slug: event.target.value,
												group: event.target.group
										}
								})
						}, this.calculatePrice)
				}
		};
		
		handleCommentChange = event => {
				this.setState({item: Object.assign({}, this.state.item, {itemComment: event.target.value})})
		};
		
		handleIngredientsChange = (groupSlug, values) => {
				const {item, ingredients} = this.state;
				
				const group = ingredients.find(item => item.slug === groupSlug);
				if ('selectableCounts' in group && values.length > group.selectableCounts) {
						values = values.splice(1, group.selectableCounts)
				}
				item.ingredients[groupSlug] = values;
				
				this.setState({item}, this.calculatePrice)
		};
		
		handleQuantityChange = counts => {
				if (counts === 0) return;
				
				this.setState({item: Object.assign({}, this.state.item, {counts: counts})}, this.calculatePrice)
		};
		
		closeAndShowErrorMsg = () => {
				showMessage(messageType.warning, 'Product not found.');
				this.props.showModal(ModalTypes.ITEM_DETAILS_MODAL, false);
		}
		
		getSingleProduct = productSlug => getSingleProductDetails(productSlug).then(result => result && result.success ? result.data : false);
		
		getCateringSingleProduct = productSlug => getCateringSingleProductDetails(productSlug).then(result => result && result.success ? result.data : false)
		
		render() {
				const {showModal, itemDetailsModalVisible, currentLocation, customProps, isDelivery, isCatering} = this.props;
				const {
						loading,
						description,
						downloadLink,
						ingredients,
						name,
						sizeGroups,
						item,
						location,
						restaurant,
						products,
						headCount
				} = this.state;
				const restaurantName = restaurant && restaurant.name;
				
				return (
						<CustomModal
								className={"CustomItemDetails"}
								visible={itemDetailsModalVisible}
								onCancel={() => showModal(ModalTypes.ITEM_DETAILS_MODAL, false)}
						>
								<Spin spinning={loading}>
										<div className="container-fluid">
												{this.state.renderContent &&
												<div className="row">
														
														<LeftComponent
																restaurantName={restaurantName}
																name={name}
																downloadLink={downloadLink}
																description={description}
																item={item}
																handleQuantityChange={this.handleQuantityChange}
																headCount={headCount}
														/>
														
														<div className="col-md-6 pl-0 pr-0">
																{isDelivery && !currentLocation.address
																		?
																		<AddressComponent
																				location={location}
																				confirmLocation={this.confirmLocation}
																				onAddressSelected={this.onAddressSelected}
																				err={this.state.emptyAdresErr}
																		/>
																		: (
																				<div className={"ItemDetailsRight"}>
																						<div className={'Options'}>
																								
																								{isCatering && products && !!products.length &&
																								<div className={'itemDetailsCatering'}>
																										
																										<div className={'cateringProducts'}>
																												<div className="GroupTitle">{this.state.unit.name}</div>
																												{products.map((item, index) => {
																														return (
																																<div key={index}>
																																		<div>
																																				<Img src={item.product.downloadLink}/>
																																				<span>{item.product.name} x {item.count}</span>
																																		</div>
																																		<span> - {item.discount} %</span>
																																</div>
																														)
																												})}
																										</div>
																								</div>
																								}
																								
																								{sizeGroups && sizeGroups.length ?
																										<SizeGroups
																												sizeGroups={sizeGroups}
																												itemSizeSlug={item.size.slug}
																												handleSizeChange={this.handleSizeChange}
																										/>
																										: null
																								}
																								
																								{ingredients && ingredients.length ?
																										<Ingredients
																												item={item}
																												ingredients={ingredients}
																												handleIngredientsChange={this.handleIngredientsChange}
																										/>
																										: null
																								}
																								
																								<div className="AddComment">
																										<h4>Add a Comment</h4>
																										<Input.TextArea
																												name="item_comment"
																												id="ItemComment"
																												rows={3}
																												value={item.itemComment}
																												style={{resize: 'none'}}
																												onChange={this.handleCommentChange}
																										/>
																								</div>
																						</div>
																						<div className="AddBug">
																								<button
																										onClick={this.addToBag}
																								>
																										{'uId' in customProps ? 'Update ' : 'Add to Cart '}- {numeral(item.price).format('$ 0,0[.]00')}
																								</button>
																						</div>
																				</div>
																		)}
														</div>
												</div>
												}
										</div>
								</Spin>
						</CustomModal>
				);
		};
}


ItemDetailsModal.propTypes = {
		itemDetailsModalVisible: PropTypes.bool.isRequired,
		isCatering: PropTypes.bool.isRequired,
		showModal: PropTypes.func.isRequired,
		customProps: PropTypes.object.isRequired,
		currentLocation: PropTypes.object,
		updateLocation: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
		itemDetailsModalVisible: state.modalVisible.itemDetails,
		isCatering: state.isCatering,
		customProps: state.modalVisible.modalProps,
		currentLocation: state.currentLocation.address,
		basketRestaurant: state.basket.restaurant,
		basketProducts: state.basket.products,
		basketCatering: state.basket.isCatering,
		isDelivery: state.isDelivery,
});

const mapDispatchToProps = {
		updateLocation,
		showModal,
		addFirstItem,
		clearBasket,
		updateBagItem,
		addNewProduct,
		updateOrderType
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps
)(ItemDetailsModal));
