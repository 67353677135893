import React from 'react'
import {connect} from "react-redux";

import { logoutUser } from "../../actions/userActions";

import GuestInfoForm from './Form/GuestInfo.Form.Component';
import CreditCard from "../HelpersComponents/CreditCardComponents/CreditCard.Component";

import {showModal} from "../../actions/baseActions";
import ModalTypes from "../../enums/Modal.Types.Enum";

export const AccountSettingsTab =({logoutUser, showModal})=>(
  <div className={'GuestInfo'}>

    {/* Guest info */}
    <GuestInfoForm/>

    {/* Card details */}
    <CreditCard/>

    {/* password */}
    <h5 className={"PasswordH"}>Change Password <span onClick={() => showModal(ModalTypes.CHANGE_PASSWORD_MODAL)}>Edit</span></h5>
    <span className={"LogOut"} onClick={logoutUser}>Log out</span>

  </div>
);

const mapStateToProps = state => ({
  changePasswordModalVisible: state.modalVisible.changePassword
});

const mapDispatchToProps = {
    logoutUser,
    showModal
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountSettingsTab);
