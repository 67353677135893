import * as Http from "./Http.Client";

export const getRestaurants = isCatering => {
	return Http.Get(`api/v1/restaurants?catering=${isCatering}`, {}, true)
		.then(result => result ? result : false);
};

export const getRestaurantDetails = (restaurantSlug, isCatering = false) => {
	return Http.Get(`api/v1/restaurant/details/${restaurantSlug}?catering=${isCatering}`, {}, true)
		.then(result => result ? result : false);
};

export const getRestaurantProducts = (restaurantSlug, queryModel) => {
	return Http.Get(`api/v1/restaurant/${restaurantSlug}/products?${queryModel}`, {}, true)
		.then(result => result ? result : false);
};
