import React from "react";
import { onlyNumeric, phoneNumberFormatter } from "../../../services/Utilities";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { editUserInfo } from "../../../actions/userActions";
import { Form, Input, Button } from "antd4";

export const GuestInfoForm = ({ user, editUserInfo }) => {

  const handleSubmit = values => {
    editUserInfo({
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone
    });
  };

  return (
    <Form
      className={"GuestInfoForm"}
      onFinish={handleSubmit}
      hideRequiredMark={true}
    >
      <h5>Guest Info</h5>
      <div className="GuestInputs">
        <div>
          <div className="GlobalInput">
            <Form.Item
              label={"First name"}
              colon={false}
              name={"firstName"}
              initialValue={user.firstName}
              rules={[
                {
                  required: true,
                  message: "First name must be at least 3 characters!",
                  min: 3
                }
              ]}
            >
              <Input placeholder={"First name"}/>
            </Form.Item>
          </div>
          <div className="GlobalInput">
            <Form.Item
              label={"Last name"}
              name={"lastName"}
              colon={false}
              initialValue={user.lastName}
              rules={[
                {
                  required: true,
                  message: "Last name must be at least 3 characters!",
                  min: 3
                }
              ]}
            >
              <Input placeholder={"Last name"}/>
            </Form.Item>
          </div>
        </div>
      </div>

      <div className="GuestInputs after">
        <div>
          <div className="GlobalInput">
            <Form.Item
              label={"Phone"}
              colon={false}
              initialValue={user.phone}
              name={"phone"}
              normalize={phoneNumberFormatter}
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!"
                }
              ]}
            >
              <Input
                addonBefore={"+1"}
                onKeyPress={onlyNumeric}
                placeholder={"Phone"}
              />
            </Form.Item>
          </div>
        </div>
        <div className={"SettingsUpdateButton"}>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </div>
      </div>
    </Form>
  );
};


GuestInfoForm.propTypes = {
  user: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user.data
});

const mapDispatchToProps = {
  editUserInfo
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GuestInfoForm);
