import {ADDRESS_NOTE, DRIVER, ORDER_UPDATE} from "../actions/_actionTypes";
import initialState from "../store/_initialState";

export const driverActualOrderReducer = (state = initialState.actualOrder, action) => {
	switch (action.type) {
		
		case DRIVER.SET_ACTUAL_ORDER:
			const key = state.findIndex(order => order.id === action.payload.id);
			if (key >= 0) {
				state[key] = action.payload
				return [...state]
			}
			return [...state, action.payload];
			case DRIVER.SET_ACTUAL_ORDERS:
			return [...action.payload];
		case DRIVER.UPDATE_ACTUAL_ORDER:
			const index = state.findIndex(order => order.id === action.payload.id)
			state[index] = action.payload
			return [...state]
		case DRIVER.REMOVE_ACTUAL_ORDER:
			state.splice(action.key, 1)
			return [...state]
		case DRIVER.REMOVE_ACTUAL_ORDERS:
			return [];
		default:
			return state;
	}
};

export const driverOrdersList = (state = initialState.ordersList, action) => {
	switch (action.type) {
		case DRIVER.GET_ORDERS_LIST:
			return action.payload
			case ORDER_UPDATE.UPDATE_DRIVER_ORDER_PROPERTY:
				const {orderSlug, property, model} = action.payload;
				const index = state.findIndex(order => order.slug === orderSlug)
				state[index][property] = model;
				return [...state];
		default:
			return state;
	}
};

export const addressNotes = (state = initialState.addressNotes, action) => {
	switch (action.type) {
		case ADDRESS_NOTE.SET_ADDRESS_NOTE:
			return action.payload
		default:
			return state;
	}
};
