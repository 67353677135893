import React, { useEffect, useRef, useState } from "react";
import {connect} from "react-redux";
// components
import LeftMenu from "../HomeComponents/LeftMenu.Component";
import AccountOrderTab from "./Account.Order.Tab";
import AccountSettingsTab from "./Account.Settings.Tab";

import Tabs from "antd4/lib/tabs";

import "../../styles/AccountStyle/account.scss";
import AccountTabs from "../../enums/Account.Tabs.Enum";
import Paths from "../../services/Paths";

const TabPane = Tabs.TabPane;

const usePrevious = (value) => {
	const ref = useRef();
	useEffect(() => {
		ref.current = value;
	});
	return ref.current;
}

export const Account = ({ user, location, history }) => {
	const locationActiveTab = location.state ? location.state.activeTabKey : null;
	const [activeTabKey, setActiveTabKey] = useState(locationActiveTab || AccountTabs.orders)
	const prevLocationActiveTab = usePrevious(locationActiveTab);

	useEffect(() => {
		document.title = `| Account - ${activeTabKey === AccountTabs.orders ? 'Orders' : 'Settings'}`;

		if (locationActiveTab && locationActiveTab !== prevLocationActiveTab) {
			setActiveTabKey(locationActiveTab)
		}

	}, [activeTabKey, location.state, user])

	const handleTabClick = tabKey => {
		if (tabKey === AccountTabs.orders) {
			document.title = "| Account - Orders";
			//We are using history.replace instead of setActiveTabKey to have correct tab opened on page refresh.
			history.replace(Paths.account, {activeTabKey: AccountTabs.orders})

		} else if (tabKey === AccountTabs.settings) {
			document.title = "| Account - Settings";
			history.replace(Paths.account, {activeTabKey: AccountTabs.settings})
		}
	};

	return (
		<div className="Account">
			<div>
				<LeftMenu menuTitle={'Menu\'s'}/>
			</div>
			<div className={"LandingPanel"}>
				<div>
					<h3>Hi, {user.clientFullName}</h3>
					<div className={"OrderReviews"}>
						{'ordersCount' in user && (
							<p>{user.ordersCount}<br/>
								<span>Orders</span>
							</p>
						)}
						{'ordersCount' in user && (
							<p>{user.reviewsCount}<br/>
								<span>Reviews Left</span>
							</p>
						)}
					</div>
				</div>
				<div className="AccountTabs">
					<Tabs onTabClick={handleTabClick} activeKey={activeTabKey}>
						{/* orders tab */}
						<TabPane tab="Orders" key={AccountTabs.orders}>
							<AccountOrderTab/>
						</TabPane>
						
						{/* settings tab */}
						<TabPane tab="Settings" key={AccountTabs.settings}>
							<AccountSettingsTab/>
						</TabPane>
					</Tabs>
				</div>
			</div>
		</div>
	)
};


const mapStateToProps = (state) => ({
	user: state.user.data
});

export default connect(mapStateToProps, {})(Account);

