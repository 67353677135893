import React, {Component} from "react";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import {showLoading, showModal} from "../../../actions/baseActions";
import {Table, Button, Popconfirm, Select, Input} from "antd4";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {deletePartnerProduct, getPartnerCategories, getPartnerProducts} from "../../../services/Partner.Service";
import Img from "../../HelpersComponents/Img.Component";
import numeral from "numeral";
import Paths from "../../../services/Paths";
import {Link} from "react-router-dom";
import ModalTypes from "../../../enums/Modal.Types.Enum";
import ImgServer from "../../HelpersComponents/Img.Component";

const {Option} = Select;

class PartnerProducts extends Component {
		state = {
				products: [],
				categorySearchValue: null,
				categories: [],
				filterResult: null,
				timeOut: null
		};
		search = React.createRef();
		
		Columns = [
				{
						title: "Name",
						key: "name",
						dataIndex: "name",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => {
								if (a.name < b.name) {
										return -1;
								}
								if (a.name > b.name) {
										return 1;
								}
								return 0;
						}
				},
				{
						title: "Price",
						key: "price",
						dataIndex: "price",
						sortDirections: ["descend", "ascend"],
						sorter: (a, b) => a.price - b.price,
						render: price => (
								numeral(price).format("$ 0,0[.]00")
						)
				},
				{
						title: "Image",
						key: "downloadLink",
						dataIndex: "downloadLink",
						render: downloadLink => (
								downloadLink ? <Img onClick={() => this.props.showModal(ModalTypes.IMG_SHOW, true, {downloadLink})}
																		className={"TableListImg"} src={downloadLink}/> : ""
						)
				}, {
						title: "Action",
						key: "action",
						render: (record) => (
								<div>
										<Link to={`${Paths.basePartner.replace(":tabName", "products")}/edit/${record.slug}`}>
												<EditOutlined/>
												Edit
										</Link>
										<Popconfirm title="Are you sure？" okText="Yes" cancelText="No"
																onConfirm={() => this.deleteProduct(record.slug)}>
												<Button type="link">
														<DeleteOutlined/>
														Delete
												</Button>
										</Popconfirm>
								</div>
						)
				}
		];
		
		UNSAFE_componentWillMount() {
				
				this.props.showLoading(true);
				getPartnerProducts().then(result => {
						if (result && 'products' in result) {
								this.setState({products: result.products});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
				getPartnerCategories().then(result => {
						if (result) {
								this.setState({categories: result.categories});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		}
		
		deleteProduct = productSlug => {
				this.props.showLoading(true);
				deletePartnerProduct(productSlug).then(result => {
						if (result) {
								this.setState({
										products: this.state.products.filter(item => item.slug !== result.slug)
								});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		filterByCategory = e => {
				this.props.showLoading(true);
				getPartnerProducts(e).then(result => {
						if (result && 'products' in result) {
								this.setState({products: result.products, categorySearchValue: e});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		filterProductName = e => {
				const {products} = this.state
				clearTimeout(this.state.timeOut);
				this.setState({
						timeOut: setTimeout(() => {
								const result = products.filter(item => (!this.search.state.value || item.name.toUpperCase().indexOf(this.search.state.value.toUpperCase()) > -1));
								this.setState({filterResult: result});
						}, 1000)
				})
		};
		
		render() {
				let {products, categorySearchValue, categories, filterResult} = this.state;
				if (filterResult) {
						products = filterResult
				}
				return (
						<div className={"Content"}>
								<section className={"ContentHeader"}>
										<h1>Products</h1>
										<Link to={`${Paths.basePartner.replace(":tabName", "products")}/create`} className={"AddNewButton"}>Add
												product</Link>
								
								</section>
								<div>
										<div style={{display: 'flex'}}>
												<Select
														className={"form-control p-0 mb-2"}
														allowClear
														placeholder="Select category"
														onChange={this.filterByCategory}
												>
														{categories.map(category => (
																<Option key={category.slug} value={category.slug}>
																		<ImgServer
																				src={category.downloadLink}
																				className={"item-img"}
																		/>
																		{category.name} ({category.productsCount})
																</Option>
														))}
												</Select>
												{categorySearchValue &&
												<b style={{whiteSpace: 'nowrap', padding: '5px'}}>{products.length} items</b>
												}
										</div>
										
										<Input placeholder={"Search product"} ref={ref => this.search = ref}
													 onKeyUp={this.filterProductName}/>
								</div>
								<section className={"ContentBody"}>
										<div>
												<Table
														columns={this.Columns}
														dataSource={products}
														rowKey={"id"}
														size={"middle"}
														pagination={{defaultPageSize: 25}}/>
										</div>
								</section>
						</div>
				);
		}
}

const mapStateToProps = state => ({
		user: state.user.data,
});

export default withRouter(connect(mapStateToProps, {showLoading, showModal})(PartnerProducts));
