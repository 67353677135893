import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import serviceWorker from './serviceWorker';
import FCMInitializer from './components/FCMInitializer';

ReactDOM.render(
	<App/>,
	document.getElementById('root')
);

FCMInitializer.initializeApp();

serviceWorker();
