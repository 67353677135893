import initialState from "../store/_initialState";
import {FILTER} from "../actions/_actionTypes";

export const filterReducer = (state = initialState.filter, action) => {
	switch (action.type) {
		case FILTER.UPDATE_FILTER:
			return Object.assign({}, state, action.payload);
		case FILTER.RESET_FILTER:
			
			return Object.assign({}, state, {
				cal: action.payload.cal.max,
				deliveryTime: action.payload.deliveryTime.max,
				minPrice: action.payload.price.min,
				maxPrice: action.payload.price.max,
				isFiltered: false,
				isFreeDelivery: undefined,
				isOpenNow: undefined,
				selectedCategories: [],
			});
		case FILTER.UPDATE_DEFAULT_DATA:
			return Object.assign({}, state, {
				cal: action.payload.cal.max,
				deliveryTime: action.payload.deliveryTime.max,
				minPrice: action.payload.price.min,
				maxPrice: action.payload.price.max
			});
		default:
			return state;
	}
};

export const filterDefaultDataReducer = (state = initialState.defaultFilterData, action) => {
	if (action.type === FILTER.UPDATE_DEFAULT_DATA) {
		return Object.assign({}, state, action.payload);
	}
	return state;
};
