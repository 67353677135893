import React, {Component} from "react";
import LeftMenu from "../HomeComponents/LeftMenu.Component";
import "../../styles/RestaurantStyle/restaurant.scss";
import {Rate, Tabs, Skeleton} from 'antd4';

import back from "../../img/next.png";
import RestaurantMenu from "./Restaurant.Menu.Component";
import RestaurantReview from "./Restaurant.Review.Component";
import {getRestaurantDetails, getRestaurantProducts} from "../../services/Restaurant.Service";
import {showLoading} from "../../actions/baseActions";
import {resetFilter,} from "../../actions/filterActions";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import Img from '../HelpersComponents/Img.Component';
import numeral from "numeral";
import Scroll from "../../services/Scroll";
import {isMobileDevice} from "../../services/Utilities";
import Paths from "../../services/Paths";
import {OrderType} from "../../enums/Order.Status.Enum";
import RestaurantMap from "./Partials/Restaurant.Map.Component";
import moment from "moment";

const {TabPane} = Tabs;
const initialState = {
		categories: [],
		categoryProducts: [],
		restaurant: {
				id: undefined,
				name: undefined,
				slug: undefined,
				isFreeDelivery: undefined,
				isOllDay: undefined,
				workingHours: undefined,
				downloadLink: undefined,
				rating: undefined,
				address: undefined,
				delivery: undefined,
				phone: undefined
		},
		allowReview: false,
		closestLocation: false,
		infinityLoading: false,
		offset: 1,
		end: false,
		activeTabKey: '1'
};

class Restaurant extends Component {
		isMobile = isMobileDevice();
		state = initialState;
		routeListener = undefined;
		
		UNSAFE_componentWillMount() {
				const {restaurantSlug} = this.props.match.params;
				this.routeListener = this.props.history.listen(location => {
						let slug = location.pathname.match(/^\/restaurant\/(.*)$/);
						if (slug && 1 in slug) {
								//TODO remove if not used
								// this.getBranchInformation(slug[1], this.props.isCatering)
						}
				});
				this.getBranchAndRestaurant(restaurantSlug, this.props.isCatering)
		}
		
		componentDidMount() {
				document.addEventListener('updateOrderType', this.updateOrderType);
				document.addEventListener('scroll', this.trackScrolling);
		}
		
		componentWillUpdate(nextProps, prevState, snapshot) {
				const {restaurantSlug} = nextProps.match.params;
				const nextCatering = nextProps.location.state ? nextProps.location.state.isCatering : undefined;
				const prevCatering = this.props.isCatering;
				
				//router will not refresh the page if we push to route another params with same pathname
				// (for ex. we are in current component and we click on add item from basket modal)
				//so we just check if any parameter changed and getting new restaurant and branch information to show.
				if ((restaurantSlug && restaurantSlug !== this.props.match.params.restaurantSlug)
						|| (nextCatering !== undefined && prevCatering !== nextCatering)) {
						
						this.getBranchAndRestaurant(restaurantSlug, nextCatering)
				}
		}
		
		componentWillUnmount() {
				document.removeEventListener('scroll', this.trackScrolling);
				document.removeEventListener('updateOrderType', this.updateOrderType);
				
				this.routeListener();
				if (this.props.isFiltered) {
						this.props.resetFilter();
				}
		}
		
		updateOrderType = evt => {
				const {detail} = evt;
				const isCatering = detail === OrderType.IS_CATERING;
				
				this.getBranchAndRestaurant(this.state.restaurant.slug, isCatering)
		};
		
		getRestaurantProducts = (restaurantSlug, filterModel = {}, isCatering = false, needToFilter = true) => {
				const {offset, categoryProducts} = this.state;
				const {isFiltered} = this.props;
				
				filterModel = Object.assign({}, needToFilter && isFiltered ? this.props.filter : {}, filterModel, {catering: isCatering});
				
				const limit = isMobileDevice() ? 2 : 5;
				
				return getRestaurantProducts(restaurantSlug, offset, limit, filterModel).then(result => {
						if (result) {
								this.setState({
										categoryProducts: offset !== 1 ? [...categoryProducts, ...result] : result,
										end: result < limit
								});
								return result
						}
						return false
				}).finally(() => {
						this.setState({infinityLoading: false})
				});
		};
		
		getRestaurantDetails = (restaurantSlug, isCatering) => {
				return getRestaurantDetails(restaurantSlug, isCatering).then(result => {
						if (result) {
								const {categories, restaurant, allowReview, closestLocation} = result;
								this.setState({
										restaurant: {
												id: restaurant.id,
												name: restaurant.name,
												slug: restaurant.slug,
												isFreeDelivery: restaurant.isFreeDelivery,
												isAllDay: restaurant.isOllDay,
												workingHours: restaurant.openAt && restaurant.closeAt ? {
														openAt: moment.parseZone(restaurant.openAt).format('hh:mm A'),
														closeAt: moment.parseZone(restaurant.closeAt).format('hh:mm A'),
												} : undefined,
												downloadLink: restaurant.downloadLink,
												rating: restaurant.rating,
												address: restaurant.address,
												delivery: restaurant.delivery,
												phone: restaurant.phone,
										},
										categories,
										allowReview,
										closestLocation
								});
								return result
						}
						return false;
				})
		};
		
		onFilter = filterModel => {
				Scroll.scrollToStartOfPage();
				this.props.showLoading(true);
				
				this.setState({
								offset: 1,
								end: false
						}, () =>
								this.getRestaurantProducts(this.state.restaurant.slug, filterModel, this.props.isCatering).finally(() => {
										this.props.showLoading(false);
								})
				);
		};
		
		onRatingUpdate = newRating => {
				if (newRating) {
						this.setState({
								restaurant: Object.assign({}, this.state.restaurant, {rating: newRating})
						})
				}
		};
		
		getBranchAndRestaurant = (restaurantSlug, nextCatering) => {
				if (restaurantSlug) {
						this.props.showLoading(true);
						this.setState(initialState, () => {
								Promise.all([
										this.getRestaurantDetails(restaurantSlug, nextCatering),
										this.getRestaurantProducts(restaurantSlug, {}, nextCatering)
								]).finally(() => {
										this.props.showLoading(false);
								})
						})
				} else {
						this.props.history.push(Paths.home);
				}
		}
		
		render() {
				const {
						name,
						slug,
						isFreeDelivery,
						isAllDay,
						workingHours,
						downloadLink,
						rating,
						phone,
						address,
						delivery
				} = this.state.restaurant;
		
				const {categories, categoryProducts, allowReview, closestLocation, activeTabKey} = this.state;
				const {loading, isAuth} = this.props;
				return (
						<div className="Restaurant">
								<div className="">
										<LeftMenu
												loadCategories={false}
												featuresVisible={false}
												menuTitle={name ? `${name} Menu's` : ''}
												customCategories={categories}
												onFilter={this.onFilter}
										/>
								</div>
								<div className={"LandingPanel"}>
										<p className={"RestaurantBack"}><a href={Paths.home}><img src={back} alt=""/><span>Home</span></a> /
												Restaurants / {name}</p>
										
										<div className={"RestaurantInfo"}>
												{/* Restaurant Logo */}
												<div className="RestaurantLogo">
														<Skeleton
																loading={loading}
																avatar={{size: 140, shape: 'square'}}
																title={{width: 150}}
																paragraph={{rows: 3, width: [300, 450, 220]}}
														>
																<div>
																		{downloadLink && <Img src={downloadLink} alt=""/>}
																</div>
																<div>
																		<h4>{name}</h4>
																		<p className={"OpenTime"}>{isAllDay ?
																				'Open Hours: 24 hours'
																				:
																				workingHours && (`Open Hours: ${workingHours.openAt} - ${workingHours.closeAt}`)
																		}</p>
																		<div className={"Rating"}><Rate disabled defaultValue={rating}/>
																				<span>{!!rating && `${numeral(rating).format('0[.]0')} ratings`}</span>
																				{/*<p>*/}
																				{/*	<span className={"Good"}><b>85%</b> Food was good</span>*/}
																				{/*	<span className={"Delivery"}><b>58%</b> Delivery was on time</span>*/}
																				{/*</p>*/}
																		</div>
																		<div className={"LastInfo"}>
																				<p>
																						{isFreeDelivery !== undefined && (
																								isFreeDelivery ? (
																										<span className={'PanelFreeDelivery'}>Free delivery</span>
																								) : (
																										<React.Fragment>
																												{numeral(delivery).format('$ 0,0[.]00')} <br/>
																												<span>Delivery</span>
																										</React.Fragment>
																								)
																						)}
																				</p>
																				<p>25-35 <br/>
																						<span>Minutes</span>
																				</p>
																				{closestLocation && (
																						<p>{closestLocation}<br/>
																								<span>Miles</span>
																						</p>
																				)}
																		</div>
																</div>
														</Skeleton>
												</div>
												{/* Restaurant Map */}
												<RestaurantMap
														address={address}
														name={name}
														phone={phone}
												/>
										</div>
										<div className="RestaurantTabs">
												<Tabs
														// activeKey={'1'}
														activeKey={activeTabKey}
														onChange={key => {
																this.setState({activeTabKey: key})
														}}
												>
														{/* Most Loved tab */}
														<TabPane tab="Menu" key="1">
																<RestaurantMenu
																		categories={categoryProducts}
																		isCatering={this.props.isCatering}
																		restaurantDownloadLink={downloadLink}
																/>
														</TabPane>
														{isAuth && (
																<TabPane tab={"Reviews"} key="2">
																		<RestaurantReview
																				rate={rating}
																				restaurantSlug={slug}
																				restaurantName={name}
																				restaurantImg={downloadLink}
																		/>
																</TabPane>
														)}
												</Tabs>
										</div>
								</div>
						</div>
				)
		}
		
		isBottom(el) {
				return el.getBoundingClientRect().bottom - (this.isMobile ? 700 : 1100) <= window.innerHeight;
		}
		
		trackScrolling = event => {
				event.preventDefault();
				const wrappedElement = document.getElementById('RestaurantMenu');
				if (wrappedElement && this.isBottom(wrappedElement)) {
						const {offset, end, infinityLoading} = this.state;
						if (this.state.restaurant.slug && !infinityLoading && !end) {
								this.setState({
												infinityLoading: true,
												offset: offset + 1
										}, () => this.getRestaurantProducts(this.state.restaurant.slug, {}, this.props.isCatering)
								)
						}
				}
		};
}

const mapStateToProps = (state, props) => {
		const isCatering = props.location.state && 'isCatering' in props.location.state
				? props.location.state.isCatering
				: state.isCatering;
		
		return {
				loading: state.loading,
				isFiltered: state.filter.isFiltered,
				filter: state.filter,
				isCatering: isCatering,
				isAuth: state.user.isAuth,
		}
};

const mapDispatchToProps = {
		showLoading,
		resetFilter,
};

export default withRouter(connect(
		mapStateToProps,
		mapDispatchToProps,
)(Restaurant));



