import initialState from "../store/_initialState";
import {BASKET} from "../actions/_actionTypes";
import * as BasketService from '../services/Basket.Service';

export const basketReducer = (state = initialState.basket, action) => {
		switch (action.type) {
				case BASKET.SET_ITEMS:
						return BasketService.calculateBasket({
								...state,
								products: action.products,
								restaurant: action.restaurant,
								isCatering: state.isCatering
						});
				case BASKET.ADD_FIRST_ITEM:
						const {product, restaurant, isCatering} = action;
						return BasketService.calculateBasket({
								...state,
								restaurant: Object.assign({}, state.restaurant, restaurant),
								products: {[product.uid]: product},
								isCatering: isCatering
						});
				case BASKET.ADD_NEW_PRODUCT:
						return BasketService.calculateBasket({
								...state,
								restaurant: {
										...state.restaurant,
								},
								products: {
										...state.products,
										[action.product.uid]: action.product
								}
						})
				case BASKET.UPDATE_ITEM:
						if ('restaurant' in action && 'slug' in action.restaurant) {
								return BasketService.calculateBasket({
										...state,
										restaurant: {
												...state.restaurant,
										},
										products: {
												...state.products,
												[action.item.uid]: action.item
										}
								})
						}
						return state;
				case BASKET.REMOVE_ITEM:
						if (action.productKey in state.products) {
								
								delete state.products[action.productKey];
								
								if (!Object.keys(state.products).length) {
										BasketService.clearBasket();
										return {
												restaurant: {},
												subtotal: 0,
												delivery: 0,
												productCount: 0
										};
								}
								return BasketService.calculateBasket({
										...state,
										restaurant: state.restaurant
								});
						}
						return state;
				case BASKET.CHANGE_COUNT:
						const {productKey, count} = action.payload;
						
						if (count > 0) {
								if (productKey in state.products) {
										state.products[productKey].counts = count;
										
										return BasketService.calculateBasket({
												...state,
												restaurant: state.restaurant
										})
								}
						}
						return state;
				case BASKET.CALCULATE_BASKET:
						return BasketService.calculateBasket(state);
				case BASKET.CLEAR_CART:
						BasketService.clearBasket();
						return {
								restaurant: {},
								subtotal: 0,
								delivery: 0,
								productCount: 0
						};
				default:
						return state;
		}
};
