import {DefaultFilterData, DefaultFilterItems} from '../enums/Base.Enum';
import {getZipCode, getAudioState} from "../services/Utilities";
import { getStoredUserLocation } from "../services/User.Service";
import { getBasket } from "../services/Basket.Service";
import * as BasketService from "../services/Basket.Service";

//TODO check isDelivery
const isDelivery = () => {
    // sessionStorage.setItem('isDelivery', 'true');
    const isDelivery = sessionStorage.getItem('isDelivery');
    return isDelivery ? sessionStorage.getItem('isDelivery') === 'true' : !isDelivery;
};

let isAddressSet = false;

const address = () => {
    const location = getStoredUserLocation()
    if (location) {
        isAddressSet = !!(('address' in location && location.address) && ('lat' in location && location.lat) && ('log' in location && location.log))
        return location
    }
    return {}
}

const isCatering = () => {
    const isCatering = JSON.parse(localStorage.getItem('isCatering'));
    return isCatering === true;
}

const basketState = () => {
    const localBasket = getBasket();
    const basket = {
        subtotal: 0,
        delivery: 0,
        serviceFee: 0,
        deliveryMinimum: 0,
        productCount: 0,
        deliveryTime: {
            min: undefined,
            max: undefined
        },
        singleBrunch: undefined
    }
    if (localBasket && localBasket.restaurant && localBasket.products) {
        return BasketService.calculateBasket({
            products: localBasket.products,
            restaurant: localBasket.restaurant,
            isCatering: localBasket.isCatering
        })
    }
    return basket
}

export default {
    user: {
        isAuth: false,
        data: {},
    },
    deliveryTime: {
      when: 'asap',
      time: undefined
    },
    currentLocation: {
        address: address(),
        isAddressSet: isAddressSet
    },
    basket: basketState(),
    categories: {
        all: [],
        main: [],
    },
    restaurants: [],
    reviews: {
        data:[],
        hasMore: false
    },
    products: [],
    cateringProducts: {},
    modalVisible: {
        basket: false,
        notification: false,
        itemDetails: false,
        showImgVisible: false,
        driverMap: false,
        termsAndConditions: false,
        privacyPolicy: false,
        signIn: false,
        registration: false,
        forgotPassword: false,
        forgotPasswordVerifyCode: false,
        map : false,
        changePassword: false,
        orderDetails: false,
        driverFeedback: false,
        driverAddressNote: false,
        addressNotes: false,
        customerFeedback: false,
        modalProps: {},
    },
    tipTariff: [],
    clientOrders: [],
    filter: DefaultFilterItems,
    defaultFilterData: DefaultFilterData,
    actualOrder: [],
    ordersList: [],
    addressNotes: [],
    creditCard: {
        number: undefined,
        holderName: '',
        expDate: undefined,
        cvv: undefined,
        isTest: false,
        cardType: 'Default',
        selectedCardId: 0,
        saveCard: false,
        list: [],
        zipCode: getZipCode()
    },
    showHeader: true,
    selectedRestaurant: false,
    audioState: getAudioState(),
    leftMenuVisible: false,
    loading: false,
    localLoading: false,
    isDelivery: isDelivery(),
    isCatering: isCatering(),
    notification: {
        messages: []
    },
    partnerOrders: {
        ordersActive: [],
        ordersScheduled: [],
        ordersCompleted: []
    },
    partnerLastOrder: null,
    partnerRestaurant: {},
    partnerNewOrderLayoutState: false
}
