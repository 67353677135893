import React, {Component} from 'react';
import Config from 'Config';
import Modal from '../HelpersComponents/Modal.Component';
import PropTypes from "prop-types";
import {showModal} from '../../actions/baseActions'
import {withRouter} from "react-router";
import {connect} from "react-redux";
import ModalTypes from "../../enums/Modal.Types.Enum";
import {getUserLocation} from "../../services/User.Service";
import {GoogleApiWrapper, Map as MapComponent, Marker, InfoWindow} from "google-maps-react";
import userLocationMarker from '../../img/user-location-icon.png';
import { SyncOutlined } from "@ant-design/icons";
import {updateDriverLocation} from "../../services/Driver.Service";

class DriverMapModal extends Component {
	state = {
		currentLocation: {
			address: undefined,
			lat: undefined,
			log: undefined
		},
		showingInfoWindow: false,
		activeMarker: {},
		selectedPlace: {},
		
	};
	
	UNSAFE_componentWillMount() {
		this.updateLocation();
	}
	
	updateLocation = () => {
		getUserLocation().then(result => {
			if (result && 'lat' in result && 'log' in result) {
				this.setState({
						currentLocation: {
							address: result.address,
							lat: result.lat,
							log: result.log
						}
					}, () => {
						if (this.props.isActive) {
							updateDriverLocation({
								lat: result.lat,
								log: result.log,
								address: result.address,
								city: result.city,
								state: result.state
							})
						}
					}
				)
			}
		})
	};
	
	onMarkerClick = (props, marker, e) => {
		this.setState({
			selectedPlace: props,
			activeMarker: marker,
			showingInfoWindow: true
		});
	};
	
	onMapClicked = (props) => {
		if (this.state.showingInfoWindow) {
			this.setState({
				showingInfoWindow: false,
				activeMarker: null
			})
		}
	};
	
	render() {
		const {driverMapVisible, showModal, actualOrder} = this.props;

		const {currentLocation} = this.state;
		const {lat, log, address} = currentLocation;
		const {location, fullName, restaurants, phone} = actualOrder;
		return (
			<Modal
				className={'DriverMapModal'}
				visible={driverMapVisible}
				onCancel={() => showModal(ModalTypes.DRIVER_MAP, false)}
			>
				<div className={'DriverMapHeader'}>
					<button
						className="refresh"
						onClick={this.updateLocation}
					>
						<span>Refresh</span>
						<SyncOutlined/>
					</button>
				</div>
				<div className={'DriverMap'}>
					<MapComponent
						google={this.props.google}
						onClick={this.onMapClicked}
						initialCenter={(lat && log) ? {
							lat: lat,
							lng: log
						} : undefined}
						center={(lat && log) ? {
							lat: lat,
							lng: log
						} : undefined}
						mapTypeControl={false}
						streetViewControl={false}
					>
						{/* driver marker */}
						{(lat && log) && (
							<Marker
								position={{lat: lat, lng: log}}
								onClick={this.onMarkerClick}
								name={'Driver Location'}
								address={address}
								icon={{
									url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
								}}
							/>
						)}
						
						{'id' in actualOrder && (
							('lat' in location && 'log' in location) && (
								<Marker
									position={{lat: location.lat, lng: location.log}}
									onClick={this.onMarkerClick}
									name={fullName}
									address={location.address}
									phone={phone}
									icon={{
										url: userLocationMarker
									}}
								/>
							)
						)}
						
						{'id' in actualOrder && (
							restaurants && Object.keys(restaurants).map((key, index) => (
									<Marker
										key={index}
										position={{
											lat: restaurants[key].location.lat,
											lng: restaurants[key].location.log
										}}
										onClick={this.onMarkerClick}
										name={restaurants[key].locationName}
										address={restaurants[key].location.address}
										phone={restaurants[key].locationPhone}
										icon={{
											url: 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
										}}
									/>
								)
							)
						)}
						
						<InfoWindow
							marker={this.state.activeMarker}
							visible={this.state.showingInfoWindow}>
							<div>
								<p><b>Name:</b> &nbsp;{this.state.selectedPlace.name}</p>
								<p><b>Address:</b> &nbsp;{this.state.selectedPlace.address}</p>
								{'phone' in this.state.selectedPlace && (
									<p><b>Phone:</b> &nbsp;{this.state.selectedPlace.phone}</p>
								)}
							</div>
						</InfoWindow>
					</MapComponent>
				</div>
			</Modal>
		)
	}
}

DriverMapModal.propTypes = {
	driverMapVisible: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
	driverMapVisible: state.modalVisible.driverMap,
	actualOrder: state.actualOrder,
	isActive: state.user.data.active
});

const mapDispatchToProps = {
	showModal,
};

export default withRouter(connect(
	mapStateToProps,
	mapDispatchToProps,
)(GoogleApiWrapper({apiKey: Config.GoogleMapKey})(DriverMapModal)));
