import React, {useEffect, useState} from "react";
import {logoutUser, updateDriverActiveState} from "../../../actions/userActions";
import {connect} from "react-redux";
import {Switch} from "antd4";
import {closeDriverConnection, initDriverConnection} from "../../../Wss/Driver.WSS.Connection";

const DriverSettings = ({updateDriverActiveState, logoutUser, user, actualOrders}) => {
		const [isActive, setActive] = useState(false);
		
		useEffect(() => {
				if (user && "active" in user) {
						setActive(user.active);
				}
		}, []);
		
		const handleActiveChange = value => {
				updateDriverActiveState(value).then(result => {
						if (result && "active" in result) {
								if (result.active) {
										initDriverConnection(result.username);
								} else {
										closeDriverConnection();
								}
								setActive(result.active);
						}
				});
		};
		
		return (
				<div className={"DriverSettingsTab"}>
						<div className={"DriverSettingsTabContent"}>
								<div className={"Active"}>
										Active
										<Switch
												onChange={handleActiveChange}
												disabled={actualOrders.length}
												checked={isActive}
										/>
								</div>
						</div>
						<div className={"DriverSettingsTabFooter"}>
								<span className={"LogOut"} onClick={logoutUser}>Log out</span>
						</div>
				</div>
		);
};

const mapStateToProps = state => ({
		user: state.user.data,
		actualOrders: state.actualOrder
});

const mapDispatchToProps = {
		updateDriverActiveState,
		logoutUser,
};

export default connect(
		mapStateToProps,
		mapDispatchToProps
)(DriverSettings);
