import Img from "../../../HelpersComponents/Img.Component";
import numeral from "numeral";
import { ReactComponent as Minus } from "../../../../img/ic_arrow_next.svg";
import React from "react";

const LeftComponent = ({downloadLink, name, restaurantName, description, item, handleQuantityChange, headCount }) => (
  <div className="col-md-6 pl-0 pr-0 position-static">
    <div className={"ItemDetailsLeft"}>
      {downloadLink && <Img src={downloadLink} alt=""/>}
      <h4>{name && name}</h4>
      {!!restaurantName && <p className={"ItemDetailsCal"}>{restaurantName}</p>}
      <p className={"ItemDetailsText"}>{description && description}</p>
      <p className={"ItemDetailsPrice"}>{numeral(item.price).format('$ 0,0[.]00')}</p>
      <div className={"Count"}>
        <p className={"Quantity"}>Quantity</p>
        <button
          className={"RemoveCountLeft"}
          onClick={() => handleQuantityChange(item.counts - 1)}
        >
          <Minus/>
        </button>
        <span>{item.counts}</span>
        <button
          className={"RemoveCountRight"}
          onClick={() => handleQuantityChange(item.counts + 1)}
        >
          <Minus/>
        </button>
      </div>
      {!!headCount &&
      <span style={{float: 'right', paddingTop: '5px' }}>Will serve {item.counts * headCount} people</span>
      }
    </div>
  </div>
);

export default LeftComponent;
