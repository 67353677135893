import React from "react";
import { Form, Input, Select, InputNumber } from "antd4";
import ProductImg from "./Product.Img";

const rules = [{ required: true }];
const { Option } = Select;
const { TextArea } = Input;

const BasicFormList = ({categoriesList, dietFriendliesList, downloadLink}) => (
  <div className={"col-lg-4"}>

    <div className={"FieldSet"} data-title="Basic information">
      <Form.Item
        label='Name'
        name={"name"}
        fieldKey={"name"}
        rules={[
          {
            required: true,
            message: "Please input name"
          }
        ]}
      >
        <Input placeholder="Name"/>
      </Form.Item>

      <ProductImg downloadLink={downloadLink}/>

      <Form.Item
        label="Price"
        name={"price"}
        fieldKey={"price"}
        rules={rules}
      >
        <InputNumber
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          placeholder="Price"
          min={0}
          step={1}
        />
      </Form.Item>

      <Form.Item
        label="Cal"
        name={"cal"}
        fieldKey={"cal"}
      >
        <InputNumber
          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          placeholder="cal"
          min={0}
          step={1}/>
      </Form.Item>

      <Form.Item
        label="Preparing duration (min)"
        name={"preparingDuration"}
        fieldKey='preparingDuration'
        rules={rules}
      >
        <InputNumber
          parser={value => value.replace(/\$\s?|(,*)/g, "")}
          placeholder="Preparing duration"
          min={0}
          step={1}/>
      </Form.Item>

      <Form.Item name='description' label="Description" rules={[{ required: true }]}>
        <TextArea className='basic_form_description'/>
      </Form.Item>

      <Form.Item name='categories' label="Product Categories" rules={[{ required: true }]}>
        <Select
          placeholder="Categories"
          mode='multiple'
        >
          {categoriesList.map(category => (
            <Option key={category.slug} value={category.slug}>{category.name}</Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='dietFriendlies' label="Diet Friendlies" rules={[{ required: false }]}>
        <Select
          placeholder="Diet Friendlies"
          mode='multiple'
        >
          {dietFriendliesList.map(dietFriendly => (
            <Option key={dietFriendly.slug} value={dietFriendly.slug}>{dietFriendly.name}</Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  </div>
);

export default BasicFormList;
