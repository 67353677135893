import * as Http from "./Http.Client";
import {HandleResponseState} from "./Http.Client";

export const getReviews = (restaurantSlug, offset = 1, limit = 10) => {
		const model = Http.StringifyUrlEncoded({
				offset, limit
		});
	return Http.Get( `api/v1/get/restaurant/review/${restaurantSlug}?${model}`,{}, true)
		.then(result => result ? result : false);
};

export const getPartnerReviews = () => {
		return Http.Get("api/v1/partner/reviews", {}, true)
				.then(HandleResponseState);
};

export const getPartnerReview = reviewId => {
		return Http.Get(`api/v1/partner/review/${reviewId}`, {}, true)
				.then(HandleResponseState);
};

export const blockPartnerReview = (reviewId, action) =>
		Http.Post(`api/v1/partner/${action}/review/${reviewId}`, {}, {}, true, true)
				.then(HandleResponseState);

export const answerPartnerReview = (reviewId, model) =>
		Http.Post(`api/v1/partner/answer/review/${reviewId}`, {}, model, true)
				.then(HandleResponseState);
