import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {showLoading} from "../../../actions/baseActions";
import Modal from "../../HelpersComponents/Modal.Component";
import {Button, Checkbox, Col, Form, Row, TimePicker} from "antd4";
import {getPartnerDetails, updateWorkingHours, updateStartStopTimePickup} from "../../../services/Partner.Service";
import moment from "moment";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import {updatePartnerRestaurant} from "../../../actions/partnerActions";


class PartnerStartTacking extends React.Component {
		state = {
				splitDailyHours: false
		};
		inputFields = [
				{
						day: 0,
						checkboxName: "Sunday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 1,
						checkboxName: "Monday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 2,
						checkboxName: "Tuesday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 3,
						checkboxName: "Wednesday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 4,
						checkboxName: "Thursday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 5,
						checkboxName: "Friday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				},
				{
						day: 6,
						checkboxName: "Saturday",
						inputChecked: false,
						times: [
								{openTime: null, closeTime: null}
						]
				}
		];
		
		constructor(props, context, state) {
				super(props, context);
				getPartnerDetails().then(result => {
						if (result) {
								if ("workingHours" in result) {
										for (let i = 0; i < result.workingHours.length; i++) {
												if ("times" in result.workingHours[i]) {
												} else {
														result.workingHours[i].times = [{
																openTime: null, closeTime: null
														}];
												}
										}
										this.setState({inputFields: result.workingHours});
								} else {
										this.setState({inputFields: this.inputFields});
								}
								if ("workingHoursPickup" in result) {
										for (let i = 0; i < result.workingHoursPickup.length; i++) {
												if ("times" in result.workingHoursPickup[i]) {
												} else {
														result.workingHoursPickup[i].times = [{
																openTime: null, closeTime: null
														}];
												}
										}
										this.setState({inputFieldsPickup: result.workingHoursPickup});
								} else {
										this.setState({inputFieldsPickup: this.inputFields});
								}
						}
				});
		}
		
		checkCloseTime = inputFields => {
				let d = new Date();
				const day = d.getDay();
				if (inputFields && inputFields[day] && "times" in inputFields[day]) {
						let open = false;
						for (let i = 0; i < inputFields[day].times.length; i++) {
								if (inputFields[day].times[i].openTime && inputFields[day].times[i].closeTime) {
										let now = new Date();
										let start = new Date();
										let end = new Date();
										start.setHours(inputFields[day].times[i].openTime.split(":")[0]);
										start.setMinutes(inputFields[day].times[i].openTime.split(":")[1]);
										end.setHours(inputFields[day].times[i].closeTime.split(":")[0]);
										end.setMinutes(inputFields[day].times[i].closeTime.split(":")[1]);
										if (now >= start && now <= end) {
												open = true;
										} else {
												this.isClose();
												open = false;
												break;
										}
								}
						}
						if (open) {
								const {restaurant} = this.props;
								if (restaurant && restaurant.countDownClose) {
										let temp = new Date(restaurant.countDownClose);
										if (temp <= new Date()) {
												this.isOpen();
										}
								} else {
										this.isOpen();
								}
								
						}
				}
		};
		
		isOpen = () => {
				const elements = Array.from(document.getElementsByClassName("close_branch"));
				if (elements.length > 0) {
						elements[0].classList.remove("close_branch");
						elements[0].classList.add("open_branch");
						elements[0].innerHTML = "OPEN";
				}
		};
		
		isClose = () => {
				const elements = Array.from(document.getElementsByClassName("open_branch"));
				if (elements.length > 0) {
						elements[0].classList.remove("open_branch");
						elements[0].classList.add("close_branch");
						elements[0].innerHTML = "CLOSE";
				}
		};
		
		handleSubmit = values => {
				console.log(values);
				
				// const {inputFields, splitDailyHours} = this.state;
				// this.props.showLoading(true);
				//
				// updateWorkingHours(inputFields).then(result => {
				// 		if (result) {
				// 				this.props.updatePartnerRestaurant(result.restaurant)
				// 				this.setState({splitDailyHours});
				// 				this.props.onVisibleChange({show: false, getPartner: true});
				// 				this.checkCloseTime(inputFields);
				// 		}
				// }).finally(() => {
				// 		this.props.showLoading(false);
				// });
		};
		
		handleSubmitPickup = values => {
				const {inputFieldsPickup, splitDailyHoursPickup} = this.state;
				this.props.showLoading(true);
				updateStartStopTimePickup({workingHoursPickup: inputFieldsPickup}).then(result => {
						if (result) {
								this.setState({splitDailyHoursPickup});
								this.props.onVisibleChange({show: false, getPartner: true});
						}
				}).finally(() => {
						this.props.showLoading(false);
				});
		};
		
		changeCheckbox = (index, e) => {
				const {inputFields} = this.state;
				inputFields[index].inputChecked = e.target.checked;
				this.setState({inputFields});
		};
		
		changeCheckboxPickup = (index, e) => {
				const {inputFieldsPickup} = this.state;
				inputFieldsPickup[index].inputChecked = e.target.checked;
				this.setState({inputFieldsPickup});
		};
		
		setTime = (index, index1, e, type) => {
				const {splitDailyHours, inputFields} = this.state;
				inputFields[index].times[index1][type] = e;
				
				if (splitDailyHours && e && index1 === inputFields[index].times.length - 1) {
						inputFields[index].times.push({
								openTime: null, closeTime: null
						});
				}
				
				if (splitDailyHours && !inputFields[index].times[index1].openTime && !inputFields[index].times[index1].closeTime && inputFields[index].times.length > 1) {
						inputFields[index].times.splice(index1, 1);
				}
				
				this.setState({inputFields});
		};
		
		setTimePickup = (index, index1, e, type) => {
				const {splitDailyHoursPickup, inputFieldsPickup} = this.state;
				inputFieldsPickup[index].times[index1][type] = e;
				
				if (splitDailyHoursPickup && e && index1 === inputFieldsPickup[index].times.length - 1) {
						inputFieldsPickup[index].times.push({
								openTime: null, closeTime: null
						});
				}
				
				if (splitDailyHoursPickup && !inputFieldsPickup[index].times[index1].openTime && !inputFieldsPickup[index].times[index1].closeTime && inputFieldsPickup[index].times.length > 1) {
						inputFieldsPickup[index].times.splice(index1, 1);
				}
				
				this.setState({inputFieldsPickup});
		};
		
		changeSplitChebox = (e) => {
				let {splitDailyHours, inputFields} = this.state;
				splitDailyHours = e.target.checked;
				if (!splitDailyHours) {
						for (let i = 0; i < inputFields.length; i++) {
								if (inputFields[i].times.length)
										for (let j = inputFields[i].times.length - 1; j > 0; j--) {
												inputFields[i].times.splice(j, 1);
										}
						}
				}
				this.setState({splitDailyHours, inputFields});
		};
		
		changeSplitCheboxPickup = (e) => {
				let {splitDailyHoursPickup, inputFieldsPickup} = this.state;
				splitDailyHoursPickup = e.target.checked;
				if (!splitDailyHoursPickup) {
						for (let i = 0; i < inputFieldsPickup.length; i++) {
								if (inputFieldsPickup[i].times.length)
										for (let j = inputFieldsPickup[i].times.length - 1; j > 0; j--) {
												inputFieldsPickup[i].times.splice(j, 1);
										}
						}
				}
				this.setState({splitDailyHoursPickup, inputFieldsPickup});
		};
		
		getDisabledHours = (e, type) => {
				let hours = [];
				if (e) {
						if (type) {
								for (let i = e.split(":")[0]; i < 24; i++) {
										hours.push(i);
								}
						} else {
								for (let i = 0; i < e.split(':')[0]; i++) {
										hours.push(i);
								}
						}
				}
				return hours;
		};
		
		getDisabledMinutes = (selectedHour, e, type) => {
				let minutes = [];
				
				if (selectedHour) {
						if (selectedHour.split(":")[0] === e) {
								if (type) {
										for (let i = Number(selectedHour.split(":")[1]); i <= 59; i++) {
												minutes.push(i);
										}
								} else {
										for (let i = 0; i <= Number(selectedHour.split(':')[1]); i++) {
												minutes.push(i);
										}
								}
								
						}
				}
				return minutes;
		};
		
		render() {
				const {visible} = this.props;
				const {splitDailyHours, inputFields, splitDailyHoursPickup, inputFieldsPickup} = this.state;
				console.log(inputFields);
				return (
						<Modal
								title="Update hours"
								visible={visible}
								onCancel={() => {
										this.props.onVisibleChange({show: false});
								}}
								footer={false}
						>
								<Tabs>
										<TabList>
												<Tab>Delivery</Tab>
												<Tab>Pickup</Tab>
										</TabList>
										<TabPanel>
												<Checkbox checked={splitDailyHours} onChange={(e) => this.changeSplitChebox(e)}>
														Split daily hours
												</Checkbox>
												{inputFields &&
												<Form onFinish={this.handleSubmit} name={"partnerSettingsForm"}>
														<Row gutter={16}>
																<Col>
																		{inputFields.map((input, index) => {
																				return (
																						<div key={index}
																								 style={{
																										 display: "flex",
																										 justifyContent: "space-between",
																										 marginBottom: "10px"
																								 }}>
																								<Form.Item
																										name={"day" + input.day}
																										initialValue={input.day}
																								>
																										<Checkbox
																												onChange={(e) => this.changeCheckbox(index, e)}
																												value={input.day}
																												checked={input.inputChecked}
																										>
																												{input.checkboxName}
																										</Checkbox>
																								</Form.Item>
																								<div>
																										{input.times.map((item, index1) => {
																												return (
																														<div key={index1}
																																 style={{display: "flex", marginBottom: "5px"}}>
																																<Form.Item
																																		
																																		name={"openTime_" + index + index1}
																																		colon={false}
																																		// rules={[
																																		//   () => ({
																																		//     validator(rule, value) {
																																		//       if ((input.inputChecked && !value)) {
																																		//         return Promise.reject("Please input Open at!");
																																		//       }
																																		//       return Promise.resolve();
																																		//     }
																																		//   })
																																		// ]}
																																>
																																		<TimePicker
																																				onChange={(e, s) => {
																																						this.setTime(index, index1, s, "openTime");
																																				}}
																																				disabledHours={() => this.getDisabledHours(item.closeTime ? item.closeTime : null, true)}
																																				disabledMinutes={(e) => this.getDisabledMinutes(item.closeTime ? item.closeTime : null, e, true)}
																																				required={true}
																																				format="H:mm"
																																				allowClear
																																				placeholder="Start"
																																				defaultValue={item.openTime ? moment(item.openTime, "HH:mm") : ""}
																																				disabled={!input.inputChecked}
																																		/>
																																</Form.Item>
																																<Form.Item
																																		name={"closeTime_" + index + index1}
																																		colon={false}
																																		// rules={[
																																		//   () => ({
																																		//     validator(rule, value) {
																																		//       if (input.inputChecked && !value) {
																																		//         return Promise.reject("Please input Close at!");
																																		//       }
																																		//       return Promise.resolve();
																																		//     }
																																		//   })
																																		// ]}
																																>
																																		<TimePicker
																																				required={true}
																																				onChange={(e, s) => {
																																						this.setTime(index, index1, s, "closeTime");
																																				}}
																																				disabledHours={() => this.getDisabledHours(item.openTime ? item.openTime : null)}
																																				disabledMinutes={(e) => this.getDisabledMinutes(item.openTime ? item.openTime : null, e)}
																																				format={"H:mm"}
																																				disabled={!input.inputChecked}
																																				placeholder="End"
																																				defaultValue={item.closeTime ? moment(item.closeTime, "HH:mm") : ""}
																																		/>
																																</Form.Item>
																														</div>
																												);
																										})
																										}
																								</div>
																						</div>
																				);
																		})}
																</Col>
														</Row>
														<Form.Item>
																<div style={{padding: "40px 8px 0"}}>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</div>
														</Form.Item>
												</Form>
												}
										</TabPanel>
										<TabPanel>
												<Checkbox checked={splitDailyHoursPickup} onChange={(e) => this.changeSplitCheboxPickup(e)}>
														Split daily hours
												</Checkbox>
												{inputFieldsPickup &&
												<Form onFinish={this.handleSubmitPickup} name={"partnerSettingsForm"}>
														<Row gutter={16}>
																<Col>
																		
																		{inputFieldsPickup.map((input, index) => {
																				return (
																						<div key={index}
																								 style={{
																										 display: "flex",
																										 justifyContent: "space-between",
																										 marginBottom: "10px"
																								 }}>
																								<Form.Item
																										name={"day" + input.day}
																										initialValue={input.day}
																								>
																										<Checkbox
																												onChange={(e) => this.changeCheckboxPickup(index, e)}
																												value={input.day}
																												checked={input.inputChecked}
																										>
																												{input.checkboxName}
																										</Checkbox>
																								</Form.Item>
																								<div>
																										{input.times.map((item, index1) => {
																												return (
																														<div key={index1}
																																 style={{display: "flex", marginBottom: "5px"}}>
																																<Form.Item
																																		name={"openTime_" + index + index1}
																																		colon={false}
																																		// rules={[
																																		//   () => ({
																																		//     validator(rule, value) {
																																		//       if ((input.inputChecked && !value)) {
																																		//         return Promise.reject("Please input Open at!");
																																		//       }
																																		//       return Promise.resolve();
																																		//     }
																																		//   })
																																		// ]}
																																>
																																		<TimePicker
																																				onChange={(e, s) => {
																																						this.setTimePickup(index, index1, s, "openTime");
																																				}}
																																				required={true}
																																				format="H:mm"
																																				disabledHours={() => this.getDisabledHours(item.closeTime ? item.closeTime : null, true)}
																																				disabledMinutes={(e) => this.getDisabledMinutes(item.closeTime ? item.closeTime : null, e, true)}
																																				allowClear
																																				placeholder="Start"
																																				defaultValue={item.openTime ? moment(item.openTime, "HH:mm") : ""}
																																				disabled={!input.inputChecked}
																																		/>
																																</Form.Item>
																																<Form.Item
																																		name={"closeTime_" + index + index1}
																																		colon={false}
																																		// rules={[
																																		//   () => ({
																																		//     validator(rule, value) {
																																		//       if (input.inputChecked && !value) {
																																		//         return Promise.reject("Please input Close at!");
																																		//       }
																																		//       return Promise.resolve();
																																		//     }
																																		//   })
																																		// ]}
																																>
																																		<TimePicker
																																				required={true}
																																				onChange={(e, s) => {
																																						this.setTimePickup(index, index1, s, "closeTime");
																																				}}
																																				format={"H:mm"}
																																				disabledHours={() => this.getDisabledHours(item.openTime ? item.openTime : null)}
																																				disabledMinutes={(e) => this.getDisabledMinutes(item.openTime ? item.openTime : null, e)}
																																				disabled={!input.inputChecked}
																																				placeholder="End"
																																				defaultValue={item.closeTime ? moment(item.closeTime, "HH:mm") : ""}
																																		/>
																																</Form.Item>
																														</div>
																												);
																										})
																										}
																								</div>
																						</div>
																				);
																		})}
																</Col>
														</Row>
														<Form.Item>
																<div style={{padding: "40px 8px 0"}}>
																		<Button htmlType="submit">
																				Save Changes
																		</Button>
																</div>
														</Form.Item>
												</Form>
												}
										</TabPanel>
								</Tabs>
						
						</Modal>
				);
		}
}


PartnerStartTacking.propTypes = {
		visible: PropTypes.bool.isRequired,
		restaurant: PropTypes.object.isRequired,
		onVisibleChange: PropTypes.func.isRequired,
		updatePartnerRestaurant: PropTypes.func.isRequired,
};

PartnerStartTacking.defaultProps = {
		visible: false,
		restaurant: {},
		onVisibleChange: f => f,
		updatePartnerRestaurant: f => f,
};
export default connect(
		state => ({
				restaurant: state.partnerRestaurant,
		}), {
				showLoading,
				updatePartnerRestaurant
		}
)(PartnerStartTacking);

