import * as Http from "./Http.Client";
import {HandleResponseState} from "./Http.Client";

export const submitOrder = model => {
	return Http.Put('api/v1/order', {}, model, true)
		.then(result => result ? result : false);
};

export const submitCateringOrder = model => {
	return Http.Put('api/v1/order/catering', {}, model, true)
		.then(result => result ? result : false);
}

export const getClientOrders = (offset = 1, limit = 10) =>
	Http.Get(`api/v1/client/orders?offset=${offset}&limit=${limit}`, {}, true)
		.then(result => result ? result : false)

export const getOrderItemById = (id) =>
	Http.Get(`api/v1/client/order/item/${id}`, {}, true)
		.then(result => result ? result : false)

export const sendCustomerFeedback = (model, orderSlug) =>
		Http.Post(`api/v1/order/${orderSlug}/feedback`, {}, model, true)
				.then(HandleResponseState);

export const getAlreadySentCustomerFeedback = orderSlug =>
		Http.Get(`api/v1/order/${orderSlug}/feedback`, {},  true)
				.then(HandleResponseState);

export const getCouponData = (code, restaurantSlug) =>
		Http.Post(`api/v1/coupon/check`, {}, {code, restaurantSlug}, true)
				.then(HandleResponseState);
